import React from "react";
import ListAcceptanceRate from "../listAcceptanceRate";
import ListChannelMissing from "../listChannelMissing";
import ListAccepted from "../listAccepted";
import ListStatusChannelInMonth from "../listStatusChannelInMonth";

import { get } from "../../../api";
import Typography from "@material-ui/core/Typography";
import { getCurrentUser } from "../../../auth";
import {
  PieChart as PieChartIcon,
  Today as TodayIcon,
  Equalizer as EqualizerIcon,
  DesktopAccessDisabled as DesktopAccessDisabledIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
} from "@material-ui/icons";
import Select from "react-select";
import moment from "moment";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countStatusChannel: 0,
      countNewChannelPartner: 0,
      recentSelected: null,
      recents: [],
    };
  }
  _getStatusChannels() {
    let { recents, recentSelected } = this.state;
    if (recentSelected)
      get("channels/count/" + recentSelected.value, (result) => {
        this.setState({
          countStatusChannel: result[0],
        });
      });
  }
  _getNewChannelPartner() {
    get("channels/Total-New-Channel-Partner", (result) => {
      this.setState({
        countNewChannelPartner: result[0],
      });
    });
  }
  _handleGetRecent() {
    get("recents", (result) => {
      const year = moment().year();
      this.setState(
        {
          recents: result.items,
          recentSelected: result.items.find((item) => item.value == year),
        },
        () => {
          this._getStatusChannels();
        }
      );
    });
  }

  componentWillMount() {
    this._handleGetRecent();
  }
  componentDidMount() {
    this._getStatusChannels();
    this._getNewChannelPartner();
  }

  render() {
    let { countNewChannelPartner, recentSelected, recents } = this.state;
    const currentUser = getCurrentUser();

    return (
      <div className="dashboardPartnerManager partner-management">
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          className="box-title"
        >
          <div>
            Bảng điều khiển [{" "}
            {currentUser && currentUser.roleName ? currentUser.roleName : ""} ]
            <p>
              Thông tin điều khiển dành cho tài khoản{" "}
              {currentUser && currentUser.roleName ? currentUser.roleName : ""}.
            </p>
          </div>
        </Typography>
        <div className="reward-box">
          <div className="">
            <TodayIcon />
            <span>Thống kê trong năm </span>
            <div>
              <Select
                className="custom-select-list"
                options={recents}
                value={recentSelected}
                onChange={(value) =>
                  this.setState({
                    recentSelected: value,
                  })
                }
              />
            </div>
          </div>
          <div className="reward">
            <img src="https://image.flaticon.com/icons/svg/1441/1441132.svg" />
            <b>Đối tác mới trong 48h: </b>
            <span className="">{countNewChannelPartner.totalNewPartner}</span>
          </div>
          <div className="reward">
            <img src="https://image.flaticon.com/icons/svg/1865/1865002.svg" />
            <b>Kênh mới trong 48h: </b>
            <span>{countNewChannelPartner.totalNewChannel}</span>
          </div>
        </div>
        <div className="admin-box">
          <span>
            <PieChartIcon />
            Tỉ lệ Acceptance rate
          </span>
          {recentSelected ? (
            <ListAcceptanceRate recentYear={recentSelected.value} />
          ) : (
            ""
          )}
        </div>
        <div className="admin-box">
          <span>
            <AssignmentTurnedInIcon />
            Số lượng kênh của đối tác đã được chuyển sang trạng thái accepted
            mỗi tháng
          </span>
          {recentSelected ? (
            <ListAccepted recentYear={recentSelected.value} />
          ) : (
            ""
          )}
        </div>
        <div className="admin-box">
          <span>
            <DesktopAccessDisabledIcon />
            Danh sách các kênh đang còn thiếu thông tin
          </span>
          {recentSelected ? (
            <ListChannelMissing recentYear={recentSelected.value} />
          ) : (
            ""
          )}
        </div>
        <div className="admin-box">
          <span>
            <EqualizerIcon />
            Trạng thái kênh trong tháng
          </span>
          {recentSelected ? (
            <ListStatusChannelInMonth recentYear={recentSelected.value} />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default Index;
