import React from "react";
import { put } from "../../api";
import Modal from '../common/modal'
import Button from '@material-ui/core/Button'
import TextInput from '../common/text-input'
import { notEmpty, emailAddress, isValid } from '../../utils/validators'

import { showSuccess } from "../../utils/app";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactFullName: "",
            contactPhone: "",
            email: "",
            address: "",
            position: "",
            isDefault: false
        };
        this.validationData = {
            contactFullName: [notEmpty],
            contactPhone: [notEmpty],
            email: [emailAddress, notEmpty],
            address: [notEmpty],
        };
    }

    _updateContact() {
        let {
            contactFullName,
            contactPhone,
            email,
            address,
            position,
            isDefault
        } = this.state

        let param = {
            fullName: contactFullName,
            phone: contactPhone,
            email: email,
            address: address,
            position: position,
            isDefault: isDefault,
            id: this.props.info.id
        }
        if (isValid(this.validationData)) {

            put("contacts", param, () => {
                showSuccess("Thay đổi thông tin thành công.")
                this.props.onUpdateSuccess()
                this._resetState()
            })
        }
    }

    _setDefaultValue() {
        const { info } = this.props
        if (!info) return
        this.setState({
            contactFullName: info.fullName,
            contactPhone: info.phone,
            email: info.email,
            address: info.address,
            position: info.position,
            isDefault: info.isDefault
        })
    }

    _resetState() {
        this.setState({
            contactFullName: "",
            contactPhone: "",
            email: "",
            address: "",
            position: "",
            isDefault: false
        })
    }

    _handleCheckboxChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    // componentWillReceiveProps() {
    //     this._setDefaultValue()
    // }

    componentDidMount() {
        this._setDefaultValue()
    }

    render() {
        const validationData = this.validationData
        const {
            contactFullName,
            contactPhone,
            email,
            address,
            position,
        } = this.state
        const { open } = this.props
        return (
            <Modal
                className="edit-contact"
                open={open}
                onClose={() => this.props.onClose()}
                fullWidth={true}
                fullScreen={false}
                header={
                    <div>
                        <span>THAY ĐỔI THÔNG TIN LIÊN HỆ</span>
                    </div>
                }
                content={
                    <table className="table border-none input-form">
                        <tbody>
                            <tr>
                                <td>
                                    <label>Họ tên</label>
                                    <TextInput
                                        className="contactFullName"
                                        value={contactFullName}
                                        name={"contactFullName"}
                                        placeholder={"Nhập họ tên"}
                                        displayName={"Họ tên"}
                                        onChange={e => {
                                            this.setState({ contactFullName: e.target.value })
                                        }}
                                        validations={validationData.contactFullName}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Số điện thoại</label>
                                    <TextInput
                                        className="contactPhone"
                                        value={contactPhone}
                                        name={"contactPhone"}
                                        placeholder={"Nhập sdt"}
                                        displayName={"Họ tên"}
                                        onChange={e => {
                                            this.setState({ contactPhone: e.target.value })
                                        }}
                                        validations={validationData.phone}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Email</label>
                                    <TextInput
                                        className="email"
                                        value={email}
                                        name={"contactEmail"}
                                        placeholder={"Nhập Email"}
                                        displayName={"Email"}
                                        onChange={e => {
                                            this.setState({ email: e.target.value })
                                        }}
                                        validations={validationData.email}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Địa chỉ</label>
                                    <TextInput
                                        className="address"
                                        value={address}
                                        name={"contactAddress"}
                                        placeholder={"Nhập địa chỉ"}
                                        displayName={"Địa chỉ"}
                                        onChange={e => {
                                            this.setState({ address: e.target.value })
                                        }}
                                        validations={validationData.address}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Chức vụ</label>
                                    <TextInput
                                        className="position"
                                        value={position}
                                        name={"contactPosition"}
                                        placeholder={"Nhập chức vụ"}
                                        displayName={"Chức vụ"}
                                        onChange={e => {
                                            this.setState({ position: e.target.value })
                                        }}
                                        validations={validationData.position}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            {/* <tr>
                                <td className="text-right border-none">

                                </td>
                                <td className="text-right border-none">
                                    <FormControlLabel
                                        className="color-primary"
                                        control={
                                            <Checkbox
                                                checked={isDefault}
                                                onChange={this._handleCheckboxChange('isDefault')}
                                                value={"isDefault"}
                                                color="primary"
                                            />
                                        }
                                        label="Đặt làm mặc định"
                                    />
                                </td>
                            </tr> */}
                        </tbody>
                    </table>

                }
                action={
                    <Button
                        color="primary"
                        onClick={() => this._updateContact()}
                    >
                        Lưu
                    </Button>
                }
                allowCancel={true}
            />
        );
    }
}

export default Index;
