import React from "react";
import List from './list'
import AddNew from './add-new'
import { get } from "../../api";
import { checkRolePM, objToQuery } from '../../utils/common'
import { TableSizeDefault, MetubRoles, OptionType } from '../../constants/constants'
import { getCurrentUser, isInRole } from '../../auth'
import SelectOption from '../common/select-option'
import UserSelectList from '../common/user-select-list'
import TextInput from '../common/text-input'
import { Button, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { BASE_API } from '../../constants/appSettings'

import { VIDEO_FILTER } from "../../constants/localStorageKeys";
import {
    Typography
} from '@material-ui/core'
import {
    OndemandVideo as OndemandVideoIcon,
    FilterList as FilterListIcon,
    Videocam as VideocamIcon
} from '@material-ui/icons'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            total: 0,
            openAddNewDialog: false,
            searchParam: { skip: 0, take: TableSizeDefault.value },
            listType: "list",
            showMoreOption: false,
            selectedStatus: null,
            selectedSharingRate: null,
            managedByEmailId: null,
            partnerManagerId: null,
            optimizerId: null,
            categoryId: null,
            searchKey: "",
            currentPage: 1,
            isResetPage: false
        };
    }

    _handleGetVideos(param) {

        if (!param) return
        let {
            isResetPage
        } = this.state
        let currentPage = param.page
        if (isResetPage == true) {
            currentPage = 1
        }

        this.setState({
            searchParam: param,
            currentPage: currentPage
        }, () => {
            let {
                searchKey,
                statuses,
                partnerManagerId,
                optimizerId,
                selectedSharingRate,
            } = this.state

            let getParam = {
                skip: (currentPage - 1 >= 0 ? currentPage - 1 : 0) * param.take,
                take: param.take,
                search: searchKey,
                statuses: statuses,
                partnerManagerId: partnerManagerId ? partnerManagerId : 0,
                optimizerId: optimizerId ? optimizerId : 0,
                sharingRateIds: selectedSharingRate ? selectedSharingRate.value : [],
                verifyStatus: "None"
            }
            var query = objToQuery(getParam);

            window.localStorage.setItem(VIDEO_FILTER, JSON.stringify(this.state))

            if (window.location.pathname === "/videos") {
                get("/videos" + query, result => {
                    this.setState({
                        videos: result.items,
                        total: result.total,
                        currentPage: param.page,
                        isResetPage: false
                    })
                }, () => {
                    this.setState({
                        isResetPage: false
                    })
                })
            }
            else {

                let { partnerId } = this.props
                if (!partnerId) return
                getParam.partnerId = partnerId

                get("partners/" + partnerId + "/videos" + query, result => {
                    this.setState({
                        videos: result.items,
                        total: result.total,
                        currentPage: param.page,
                        isResetPage: false
                    })
                }, () => {
                    this.setState({
                        isResetPage: false
                    })
                })
            }
        })


    }

    _handleSearch() {
        let param = this.state.searchParam
        this.setState({
            isResetPage: true
        }, () => {
            this._handleGetVideos(param)
        })
    }

    _exportList() {
        let param = this.state.searchParam
        let { selectedStatus } = this.state
        let statuses = []

        if (selectedStatus && selectedStatus.length > 0)
            selectedStatus.map(item => statuses.push(item.value))
        // if (selectedSharingRate && selectedSharingRate.length > 0)
        //     selectedSharingRate.map(item => sharingRateIds.push(item.value))


        if (!param) return
        else {
            let {
                searchKey,
                partnerManagerId,
                optimizerId,
                selectedSharingRate,
                currentPage
            } = this.state

            let getParam = {
                skip: (currentPage - 1 >= 0 ? currentPage - 1 : 0) * param.take,
                take: param.take,
                search: searchKey,
                partnerManagerId: partnerManagerId ? partnerManagerId : 0,
                optimizerId: optimizerId ? optimizerId : 0,
                sharingRateIds: selectedSharingRate ? selectedSharingRate.value : [],
                verifyStatus: "None"
            }
            var query = objToQuery(getParam);
            get("/videos/export" + query, result => {
                window.open(BASE_API + result.relativePath)
            })
        }
    }

    componentWillMount() {
        let filter = JSON.parse(window.localStorage.getItem(VIDEO_FILTER))
        if (filter) this.setState({
            ...filter,
            selectedSharingRate: filter.selectedSharingRate ? filter.selectedSharingRate.value : [],
            openAddNewDialog: false,
            showMoreOption: false,
            isResetPage: false
        })
    }

    componentDidMount() {
        let { searchParam } = this.state

        this._handleGetVideos(searchParam)
    }

    render() {
        let {
            videos,
            openAddNewDialog,
            total, searchParam,
            showMoreOption,
            selectedSharingRate,
            partnerManagerId,
            optimizerId,
            searchKey,
            currentPage
        } = this.state
        const { partnerId, disableHeader, partner } = this.props
        const pathname = window.location.pathname

        let currentUser = getCurrentUser()

        if (!isInRole(MetubRoles.VIEW_VIDEO)) return

        return (

            <div className="contact-page">
                {
                    disableHeader == true ? "" : <Typography variant="h6" gutterBottom align="center" className="box-title">
                        <div>
                            Quản lí video
                            <p>Quản lí thông tin video đang có trong hệ thống.</p>
                        </div>
                    </Typography>
                }
                <div className="action">
                    <div className="admin-box">
                        <div className="channel-reward">
                            <span><OndemandVideoIcon />Tổng số video hiện có: {total ? total : 0}</span>
                            {
                                partner && partner.verifyStatus !== "Discontinued" && <>
                                    {
                                        pathname !== "/videos" && isInRole(MetubRoles.CREATE_VIDEO, () => checkRolePM(partner)) && <Button
                                            color={"primary"}
                                            onClick={() => this.setState({ openAddNewDialog: !openAddNewDialog })}
                                        >
                                            Tạo mới
                                        </Button>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div className="admin-box">
                        <span>
                            <FilterListIcon />
                            Bộ lọc dữ liệu
                        </span>
                        <div className="input-search">
                            <TextInput
                                name="searchKey"
                                displayName="Thông tin tìm kiếm"
                                placeholder="Tìm theo tên hoặc ID Kênh"
                                type="text"
                                value={searchKey}
                                onChange={(e) => this.setState({ searchKey: e.target.value })}
                                edit={true}
                                outLine
                                height={42}
                                label="asdf"
                            />
                            <span className="search-bt" onClick={() => this._handleSearch()}>Tìm nhanh</span>
                        </div>
                        <div className="search-option">
                            <table className="table border-none input-form ">
                                <tbody>
                                    <tr>
                                        <td className="pl00 width33pc">
                                            <label>Tỉ lệ</label>
                                            <SelectOption
                                                optionType={OptionType.SharingRate.value}
                                                onChange={(value) => this.setState({ selectedSharingRate: value ? value : null }, () => this._handleSearch())}
                                                edit={true}
                                                value={selectedSharingRate}
                                                allowNull={true}
                                                isMulti
                                                outLine
                                            />
                                        </td>
                                        <td className="text-left">
                                            <label>PM</label>
                                            <UserSelectList
                                                edit={true}
                                                value={partnerManagerId}
                                                onChange={(value) => this.setState({ partnerManagerId: value ? value.id : null }, () => this._handleSearch())}
                                                outLine
                                                className="for-search"
                                                isHideUserSelected={true}
                                            />
                                        </td>
                                        <td className="text-left">
                                            <label>Người tối ưu</label>
                                            <UserSelectList
                                                edit={true}
                                                value={optimizerId}
                                                onChange={(value) => this.setState({ optimizerId: value ? value.id : null }, () => this._handleSearch())}
                                                outLine
                                                className="for-search"
                                                isHideUserSelected={true}
                                                filter={(users) => {
                                                    return users.filter(item => item.roleCode == MetubRoles.OPTIMIZE || item.roleCode == MetubRoles.OPTIMIZE_MANAGER)
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {
                    isInRole(MetubRoles.VIEW_VIDEO) &&
                    <div className="admin-box">
                        <span>
                            <VideocamIcon />
                            Danh sách video
                            <Button color={"primary"} onClick={() => this._exportList()}>
                                Export

                            </Button>
                        </span>
                        <List
                            videos={videos}
                            total={total}
                            onUpdateSuccess={() => this._handleGetVideos(searchParam)}
                            onChange={param => this.setState({ isResetPage: false }, () => this._handleGetVideos(param))}
                            listType="list"
                            currentPage={currentPage}
                        />
                    </div>
                }
                {
                    isInRole(MetubRoles.CREATE_VIDEO) && <AddNew
                        open={openAddNewDialog}
                        onClose={() => this.setState({ openAddNewDialog: false })}
                        onAddSuccess={() => {
                            this.setState({ openAddNewDialog: false })
                            this._handleGetVideos(searchParam)
                        }}
                        partnerId={partnerId} />
                }
            </div>
        );
    }
}

export default Index;
