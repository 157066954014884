import React from "react";
import DataTable from "../common/datatable";
import { get } from "../../api";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            acceptanceRates: null,
        };
    }

    componentWillMount() {
        if (this.props.recentYear)
            this._getStatusChannels(this.props.recentYear)
    }

    componentWillReceiveProps(nextprops) {
        if (nextprops.recentYear != this.props.recentYear)
            this._getStatusChannels(nextprops.recentYear)
    }
    _getStatusChannels(recentYear) {
        if (!recentYear) return
        get("channels/Acceptance-Rate/" + recentYear, result => {
            this.setState({
                acceptanceRates: result,
            });
        }, null, true);
    }

    render() {
        let { acceptanceRates } = this.state;

        return (
            <div className="partner-list">
                <DataTable
                    total={acceptanceRates ? acceptanceRates.length : null}
                    data={acceptanceRates}
                    renderHeader={() => {
                        return (
                            <thead>
                                <tr>
                                    <th className="text-left">Tên CMS</th>
                                    <th className="text-center">Tháng 1</th>
                                    <th className="text-center">Tháng 2</th>
                                    <th className="text-center">Tháng 3</th>
                                    <th className="text-center">Tháng 4</th>
                                    <th className="text-center">Tháng 5</th>
                                    <th className="text-center">Tháng 6</th>
                                    <th className="text-center">Tháng 7</th>
                                    <th className="text-center">Tháng 8</th>
                                    <th className="text-center ">Tháng 9</th>
                                    <th className="text-center ">Tháng 10</th>
                                    <th className="text-center ">Tháng 11</th>
                                    <th className="text-center ">Tháng 12</th>
                                </tr>
                            </thead>
                        );
                    }}
                    renderBody={items => {
                        if (!items || items.length < 1) return
                        return (
                            <tbody>
                                {items.map((item, i) => (
                                    item.cms ? <tr key={i}>
                                        <td className="text-left">{item.cms.name ? item.cms.name : ""}</td>
                                        {
                                            item.dataCMS && item.dataCMS.length > 0 && item.dataCMS.map((subItem, j) =>
                                                <td className="text-center">{subItem.acceptanceRate ? subItem.acceptanceRate : ""}</td>
                                            )
                                        }
                                    </tr> : ""

                                ))}
                                {items.map((item, i) => (
                                    item.acceptedInvited ? <tr key={i}>
                                        <td className="text-left">{item.acceptedInvited ? item.acceptedInvited : ""}</td>
                                        {
                                            item.dataAcceptedInvited && item.dataAcceptedInvited.length > 0 && item.dataAcceptedInvited.map((subItem, j) =>
                                                <td key={j} className="text-center">{subItem.acceptedInvited ? subItem.acceptedInvited : ""}</td>
                                            )
                                        }
                                    </tr> : ""
                                ))}
                            </tbody>
                        );
                    }}
                />
            </div>
        );
    }
}

export default Index;

