import React from "react";
import { get, post } from "../../api";
import Modal from "../common/modal";
import Button from "@material-ui/core/Button";
import TextInput from "../common/text-input";
import { notEmpty, justNumber, isValid } from "../../utils/validators";
import SelectOption from "../common/select-option";
import UserSelectList from "../common/user-select-list";

import { showSuccess, showError } from "../../utils/app";
import {
  OptionType,
  ChannelStatus,
  MetubRoles,
} from "../../constants/constants";
import Select from "../common/select";
import { objToArray } from "../../utils/common";
import { getCurrentUser } from "../../auth";
import { TrendingUpOutlined } from "@material-ui/icons";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      youtubeId: "",
      name: "",
      thumbnail: "",
      position: "",
      isDefault: false,
      channelInfo: null,
      isChannelInfoLoading: false,
      description: "",
      subscriberCount: "0",
      sharingRateId: null,
      verticalId: null,
      categoryId: null,
      subCategories: [],
      contentId: null,
      priorityId: null,
      assistantId: null,
      optimizerId: null,
      optimizerIdErrors: null,
      managedByEmailId: null,
      managedByEmailIdError: null,
      cmsId: null,
      cmsIdError: null,
      selectedStatus: null,
      notes: "",
    };
    this.validationData = {
      url: [notEmpty],
      youtubeId: [notEmpty],
      name: [notEmpty],
      description: [notEmpty],
      subscriberCount: [notEmpty, justNumber],
    };
  }

  _checkValid() {
    let {
      optimizerId,
      categoryId,
      priorityId,
      contentId,
      sharingRateId,
      assistantId,
      managedByEmailId,
      cmsId,
      verticalId
    } = this.state;
    let valid = true;

    if (!isValid(this.validationData)) {
      valid = false;
    }

    if (!managedByEmailId) {
      valid = false;
      this.setState({
        managedByEmailIdError: "Email quản lý không được bỏ trống.",
      });
    }

    if (!assistantId) {
      valid = false;
      this.setState({
        assistantIdError: "Hổ trợ không được bỏ trống.",
      });
    }

    if (!sharingRateId) {
      valid = false;
      this.setState({
        sharingRateIdError: "Tỷ lệ chia sẻ không được bỏ trống.",
      });
    }

    if (managedByEmailId != 69 && !optimizerId) {
      valid = false;
      this.setState({
        optimizerIdErrors: "Người tối ưu không được bỏ trống.",
      });
    }

    if (!categoryId) {
      valid = false;
      this.setState({
        categoryIdError: "Category không được bỏ trống",
      });
    }
    if (!verticalId) {
      valid = false;
      this.setState({
        verticalIdError: "Vertical không được bỏ trống."
      })
    }

    if (!priorityId) {
      valid = false;
      this.setState({
        priorityIdError: "Ưu tiên không được bỏ trống",
      });
    }

    if (!contentId) {
      valid = false;
      this.setState({
        contentIdError: "Content không được bỏ trống",
      });
    }
    if (!cmsId) {
      valid = false;
      this.setState({
        cmsIdError: "CMS không được bỏ trống",
      });
    }

    return valid;
  }

  _addChannel() {
    let {
      youtubeId,
      url,
      thumbnail,
      name,
      description,
      subscriberCount,
      notes,
      managedByEmailId,
      sharingRateId,
      verticalId,
      categoryId,
      priorityId,
      contentId,
      optimizerId,
      assistantId,
      cmsId,
      selectedStatus,
      tagIds,
      subCategoryIds
    } = this.state;

    let valid = this._checkValid();

    if (valid) {
      let param = {
        youtubeId: youtubeId,
        url: url,
        thumbnail: thumbnail,
        name: name,
        description: description,
        subscriberCount: subscriberCount,
        notes: notes,
        managedByEmailId: managedByEmailId,
        sharingRateId: sharingRateId,
        partnerId: this.props.partnerId ? this.props.partnerId : null,
        verticalId: verticalId,
        categoryId: categoryId,
        priorityId: priorityId,
        contentId: contentId,
        optimizerId: managedByEmailId === 69 ? 9 : optimizerId,
        assistantId: assistantId,
        cmsId: cmsId,
        status: selectedStatus.value,
        tagIds: tagIds ? tagIds : [],
        subCategoryIds: subCategoryIds ? subCategoryIds : [],
      };

      if (!param.partnerId) {
        delete param.partnerId;
      }

      post("channels", param, () => {
        showSuccess("Thêm kênh thành công.");
        this.props.onAddSuccess();
        this._resetState();
      });
    } else {
      showError(
        "Nội dung không hợp lệ, vui lòng kiểm tra lại thông tin vừa nhập."
      );
    }
  }

  _resetState() {
    this.setState({
      youtubeId: "",
      url: "",
      thumbnail: "",
      name: "",
      description: "",
      subscriberCount: 0,
      notes: "",
      managedByEmailId: null,
      sharingRateId: 0,
      verticalId: 0,
      categoryId: 0,
      subCategories: [],
      priorityId: 0,
      contentId: 0,
      optimizerId: null,
      assistantId: null,
      cmsId: null,
      subCategoryIds: []
    });
  }

  _getChannelInfoFromYoutube() {
    if (this.state.url === "") return;
    let url = this.state.url.split("/");
    if (url.length > 0) {
      this.setState(
        {
          isChannelInfoLoading: true,
        },
        () => {
          let channelId = url[url.length - 1];
          get(
            "youtube/channels/" + channelId,
            (result) => {
              this.setState({
                youtubeId: result.id,
                name: result.snippet.title,
                thumbnail: result.snippet.thumbnails.high.url,
                description: result.snippet.description,
                isChannelInfoLoading: false,
                subscriberCount:
                  result.statistics && result.statistics.subscriberCount
                    ? result.statistics.subscriberCount
                    : "0",
                url: "https://www.youtube.com/channel/" + channelId,
              });
            },
            () => {
              this.setState({ isChannelInfoLoading: false });
            }
          );
        }
      );
    }
  }

  render() {
    const validationData = this.validationData;
    const {
      url,
      name,
      isChannelInfoLoading,
      youtubeId,
      thumbnail,
      description,
      subscriberCount,
      sharingRateId,
      verticalId,
      verticalIdError,
      categoryId,
      priorityId,
      contentId,
      optimizerId,
      optimizerIdErrors,
      assistantId,
      managedByEmailId,
      notes,
      categoryIdError,
      priorityIdError,
      contentIdError,
      sharingRateIdError,
      assistantIdError,
      managedByEmailIdError,
      cmsId,
      cmsIdError,
      selectedStatus,
      tagIds,
      subCategoryIds
    } = this.state;
    const { open } = this.props;

    let ChannelStatusOptions = objToArray(ChannelStatus);
    if (ChannelStatusOptions && ChannelStatusOptions.length > 0)
      ChannelStatusOptions = ChannelStatusOptions.filter(
        (e) => e.value !== "None"
      );

    let currentUser = getCurrentUser();

    if (
      currentUser.roleCode == MetubRoles.PARTNER_MANAGER ||
      currentUser.roleCode == MetubRoles.LEADER_PARTNER_MANAGER
    ) {
      ChannelStatusOptions = ChannelStatusOptions.filter(
        (e) => e.value == "Submit"
      );
    }

    return (
      <Modal
        className="add-new-contact"
        open={open}
        onClose={() => this.props.onClose()}
        fullWidth={true}
        fullScreen={false}
        header={
          <div>
            <span>THÊM MỚI CHANNEL</span>
          </div>
        }
        content={
          <div>
            <table className="table border-none">
              <tbody>
                <tr>
                  <td>
                    <label>Url</label>
                    <TextInput
                      className="url"
                      value={url}
                      name={"url"}
                      placeholder={"Nhập đường dẫn hoặc Id kênh"}
                      displayName={"URL"}
                      onChange={(e) => {
                        this.setState({ url: e.target.value });
                      }}
                      validations={validationData.url}
                      edit={true}
                      onBlur={() => this._getChannelInfoFromYoutube()}
                      disable={isChannelInfoLoading}
                    />
                  </td>
                </tr>
                {thumbnail && (
                  <tr>
                    <td className="border-none text-center">
                      {thumbnail.length > 0 && (
                        <img alt="" style={{ width: "50%" }} src={thumbnail} />
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <label>Thông tin kênh</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table border-none">
              <tbody>
                <tr>
                  <td className="width150 ">YouTube ID</td>
                  <td>
                    <TextInput
                      className="youtubeId"
                      value={youtubeId}
                      name={"youtubeId"}
                      placeholder={"YouTube ID"}
                      displayName={"YouTube ID"}
                      onChange={(e) => {
                        this.setState({ youtubeId: e.target.value });
                      }}
                      validations={validationData.youtubeId}
                      edit={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="">Tên kênh</td>
                  <td>
                    <TextInput
                      className="name"
                      value={name}
                      name={"name"}
                      placeholder={"Tên kênh"}
                      displayName={"Tên kênh"}
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      validations={validationData.name}
                      edit={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="">Verital</td>
                  <td>
                    <SelectOption
                      optionType={OptionType.Vertical.value}
                      onChange={(value) =>
                        this.setState({
                          verticalId: value ? value.value : null,
                          verticalIdError: null,
                        })
                      }
                      edit={true}
                      value={verticalId}
                      allowNull={false}
                      errors={verticalIdError}
                      initNull
                    />
                  </td>
                </tr>
                <tr>
                  <td className="">Category</td>
                  <td>
                    <SelectOption
                      optionType={OptionType.ChannelCategory.value}
                      onChange={(value) =>
                        this.setState({
                          categoryId: value ? value.value : null,
                          categoryIdError: null,
                        })
                      }
                      edit={true}
                      value={categoryId}
                      allowNull={false}
                      errors={categoryIdError}
                      initNull
                    />
                  </td>
                </tr>
                <tr>
                  <td className="">Sub Category</td>
                  <td>
                    <SelectOption
                      optionType={OptionType.SubCategory.value}
                      onChange={(value) =>
                        this.setState({
                          subCategoryIds: value ? value.value : null,
                        })
                      }
                      edit={true}
                      value={subCategoryIds}
                      allowNull={false}
                      initNull
                      isMulti={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="">Content</td>
                  <td>
                    <SelectOption
                      optionType={OptionType.ChannelContentType.value}
                      onChange={(value) =>
                        this.setState({
                          contentId: value ? value.value : null,
                          contentIdError: null,
                        })
                      }
                      edit={true}
                      value={null}
                      allowNull={false}
                      errors={contentIdError}
                      initNull
                    />
                  </td>
                </tr>
                <tr>
                  <td className="">CMS</td>
                  <td>
                    <SelectOption
                      optionType={OptionType.CMS.value}
                      onChange={(value) =>
                        this.setState({
                          cmsId: value ? value.value : null,
                          cmsIdError: null,
                        })
                      }
                      edit={true}
                      value={cmsId}
                      allowNull={false}
                      errors={cmsIdError}
                      initNull
                    />
                  </td>
                </tr>
                <tr>
                  <td className="">Ưu tiên</td>
                  <td>
                    <SelectOption
                      optionType={OptionType.ChannelPriority.value}
                      onChange={(value) =>
                        this.setState({
                          priorityId: value ? value.value : null,
                          priorityIdError: null,
                        })
                      }
                      edit={true}
                      value={priorityId}
                      allowNull={false}
                      errors={priorityIdError}
                      initNull
                    />
                  </td>
                </tr>

                <tr>
                  <td className="">Lượt theo dõi</td>
                  <td>
                    <TextInput
                      className="subscriberCount"
                      value={subscriberCount}
                      name={"subscriberCount"}
                      placeholder={"Lượt theo dõi"}
                      displayName={"Lượt theo dõi"}
                      onChange={(e) => {
                        this.setState({ subscriberCount: e.target.value });
                      }}
                      validations={validationData.subscriberCount}
                      edit={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="">Mô tả</td>
                  <td>
                    <TextInput
                      className="sharingRateId"
                      value={description}
                      name={"description"}
                      placeholder={"Mô tả"}
                      displayName={"Mô tả"}
                      multiline={true}
                      onChange={(e) => {
                        this.setState({ description: e.target.value });
                      }}
                      validations={validationData.description}
                      edit={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="">Tỷ lệ chia sẻ</td>
                  <td>
                    <SelectOption
                      optionType={OptionType.SharingRate.value}
                      onChange={(value) =>
                        this.setState({
                          sharingRateId: value ? value.value : null,
                          sharingRateIdError: null,
                        })
                      }
                      edit={true}
                      value={sharingRateId}
                      allowNull={false}
                      errors={sharingRateIdError}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="">Trạng thái</td>
                  <td>
                    <Select
                      options={ChannelStatusOptions}
                      value={selectedStatus}
                      onChange={(value) =>
                        this.setState({
                          selectedStatus: value,
                        })
                      }
                      edit={true}
                      allowNull={false}
                      initNull
                    />
                  </td>
                </tr>
                <tr>
                  <td className="">Email quản lý</td>
                  <td>
                    <SelectOption
                      optionType={OptionType.ManagerEmail.value}
                      onChange={(value) =>
                        this.setState({
                          managedByEmailId: value ? value.value : null,
                          managedByEmailIdError: null,
                        })
                      }
                      edit={true}
                      value={managedByEmailId}
                      allowNull={false}
                      errors={managedByEmailIdError}
                      initNull
                    />
                  </td>
                </tr>
                {
                  managedByEmailId != 69 && <tr>
                    <td className="">Người tối ưu</td>
                    <td>
                      <UserSelectList
                        edit={true}
                        value={optimizerId}
                        onChange={(value) =>
                          this.setState({
                            optimizerId: value ? value.id : null,
                            optimizerIdErrors: null,
                          })
                        }
                        outLine
                        filter={(users) => {
                          return users.filter(
                            (item) =>
                              item.roleCode == MetubRoles.OPTIMIZE ||
                              item.roleCode == MetubRoles.OPTIMIZE_MANAGER
                          );
                        }}
                        errors={optimizerIdErrors}
                      />
                    </td>
                  </tr>
                }
                <tr>
                  <td className="">Hỗ trợ</td>
                  <td>
                    <UserSelectList
                      edit={true}
                      value={assistantId}
                      onChange={(value) =>
                        this.setState({
                          assistantId: value ? value.id : null,
                          assistantIdError: null,
                        })
                      }
                      outLine
                      filter={(users) => {
                        return users.filter(
                          (item) =>
                            item.roleCode == MetubRoles.PARTNER_MANAGER ||
                            item.roleCode == MetubRoles.LEADER_PARTNER_MANAGER
                        );
                      }}
                      errors={assistantIdError}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="">Tags</td>
                  <td>
                    <SelectOption
                      optionType={OptionType.Tag.value}
                      onChange={(value) =>
                        this.setState({ tagIds: value ? value.value : null })
                      }
                      edit={true}
                      value={tagIds}
                      allowNull={true}
                      isMulti={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="">Ghi chú</td>
                  <td>
                    <TextInput
                      className="notes"
                      value={notes}
                      name={"notes"}
                      placeholder={"Ghi chú"}
                      displayName={"Ghi chú"}
                      multiline={true}
                      onChange={(e) => {
                        this.setState({ notes: e.target.value });
                      }}
                      edit={true}
                      minHeight={"200px"}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
        action={
          <Button color="primary" onClick={() => this._addChannel()}>
            Lưu
          </Button>
        }
        allowCancel={true}
      />
    );
  }
}

export default Index;
