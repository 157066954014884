import React from "react";
import List from './list'

import SearchIcon from '@material-ui/icons/Search';
import TextInput from '../common/text-input';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { getCurrentUser } from "../../auth";
import { UserStatus, UserRole, UserBranch } from "../../constants/constants";
import Select from "../common/select";
import {
  objToArray,
  objToQuery,
  formatDateTime,
  confirmSubmit
} from "../../utils/common";
import {
  Button
} from '@material-ui/core'
import {
  FilterList as FilterListIcon,
  AssignmentInd as AssignmentIndIcon
} from '@material-ui/icons'
import { USER_FILTER } from "../../constants/localStorageKeys";
import DateTimePicker from '../common/datetime-picker';
import { get } from "../../api";
import { BASE_API } from "../../constants/appSettings";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      input: "",
      userStatusOptions: objToArray(UserStatus),
      selectedUserStatusOption: null,
      isResetPage: false,
      selectedUserRole: null,
      fromDate: null,
      selectedUserBranch: null
    };
  }

  componentWillMount() {
    let filter = JSON.parse(window.localStorage.getItem(USER_FILTER))
    if (filter) {
      this.setState({
        ...filter,
        isResetPage: false
      })
    }
  }

  handleSearch() {
    this.setState({
      isResetPage: true
    }, () => {
      let filter = JSON.parse(window.localStorage.getItem(USER_FILTER))
      if (filter) {
        filter.currentPage = 1
        window.localStorage.setItem(USER_FILTER, JSON.stringify(filter))
        this.setState({
          searchKey: this.state.input
        })
      }
    })
  }

  _exportList() {

    let {
      selectedUserStatusOption,
      selectedUserRole,
      searchKey,
      fromDate,
      selectedUserBranch
    } = this.state

    let getParam = {
      Status: selectedUserStatusOption ? selectedUserStatusOption.value : 'None',
      RoleCode: selectedUserRole ? selectedUserRole.value : '',
      Search: searchKey,
      CreateTime: fromDate ? fromDate : '',
      Branch: selectedUserBranch ? selectedUserBranch.value : '',
    }
    var query = objToQuery(getParam);
    get("/users/export" + query, result => {
      window.open(BASE_API + result.relativePath)
    })
  }

  hadleSaveFilter() {
    window.localStorage.setItem(USER_FILTER, JSON.stringify(this.state))
  }

  render() {
    let {
      searchKey,
      input,
      selectedUserStatusOption,
      userStatusOptions,
      isResetPage,
      selectedUserRole,
      fromDate,
      selectedUserBranch
    } = this.state;
    const currentUser = getCurrentUser();
    let UserRoleOptions = objToArray(UserRole)
    let UserBranchOptions = objToArray(UserBranch)

    return (
      <div className="box manage-user">
        <Typography variant="h6" gutterBottom align="center" className="box-title">
          <div>
            Quản lý user [ {currentUser && currentUser.roleName ? currentUser.roleName : ""} ]
            <p>Quản lí thông tin nhân viên Metub.</p>
          </div>
        </Typography>
        <div className="admin-box">
          <span><FilterListIcon />Bộ lọc dữ liệu</span>
          <div className="search-content">
            <div className="search-key">
              <label>Lọc theo từ khoá</label>
              <div>
                <TextInput
                  name="searchKey"
                  displayName="Thông tin tìm kiếm"
                  placeholder="Tìm kiếm"
                  type="text"
                  value={input}
                  onChange={(e) => this.setState({ input: e.target.value })}
                  edit={true}
                  outLine
                  height={35}
                  label="asdf"
                />
                <span className="search-bt" onClick={() => this.handleSearch()}>Tìm nhanh</span>
              </div>

            </div>

          </div>
          <div className="search-content">
            <div className="search-option mr20">
              <label>Lọc theo trạng thái</label>
              <Select
                className="select-status"
                options={userStatusOptions}
                onChange={(value) => {
                  this.setState({
                    selectedUserStatusOption: value,
                    isResetPage: true
                  })
                }}
                value={selectedUserStatusOption}
                edit={true}
                allowNull={true}
                placeholder="Lọc theo trạng thái"
              />
            </div>
            <div className="search-option mr20">
              <label>Lọc theo chức vụ</label>
              <Select
                className="select-status width250"
                options={UserRoleOptions}
                onChange={(value) => {
                  this.setState({
                    selectedUserRole: value,
                    isResetPage: true
                  })
                }}
                value={selectedUserRole}
                edit={true}
                allowNull={true}
                placeholder="Lọc theo chức vụ"
              />
            </div>
            <div className="search-option mr20">
              <label>Lọc theo chi nhánh</label>
              <Select
                className="select-status width250"
                options={UserBranchOptions.filter(item => item.value != "None")}
                onChange={(value) => {
                  this.setState({
                    selectedUserBranch: value,
                    isResetPage: true
                  })
                }}
                value={selectedUserBranch}
                edit={true}
                allowNull={true}
                placeholder="Lọc theo chức vụ"
              />
            </div>
            <div className="search-option width250 ">
              <label>Ngày hợp tác</label>
              <div className="date-box" style={{ border: "1px solid #dee2e6", borderRadius: "4px" }}>
                <DateTimePicker
                  className="fromDate"
                  type="date"
                  placeholder={"Chọn ngày"}
                  onChange={value => this.setState({ fromDate: value })}
                  value={fromDate}
                  edit={true}
                  border={false}
                  isClearable={true}
                />
                {/* <ChevronRightIcon />
                <DateTimePicker
                  className="toDate"
                  type="date"
                  placeholder={"Đến ngày"}
                  onChange={value => this.setState({ toDate: value }, () => this._getNotification())}
                  value={toDate}
                  edit={true}
                  border={false}
                  minDate={fromDate ? fromDate : null}
                  isClearable={true}
                /> */}
              </div>
            </div>
          </div>
        </div>

        <div className="admin-box">
          <span>
            <AssignmentIndIcon />Danh sách nhân viên
            <Button color={"primary"} onClick={() => this._exportList()}>Export</Button>
          </span>
          <List
            onGetListSuccess={() => this.hadleSaveFilter()}
            isResetPage={isResetPage}
            searchParam={
              {
                searchKey: searchKey,
                selectedUserStatusOption: selectedUserStatusOption ? selectedUserStatusOption.value : null,
                selectedUserRole: selectedUserRole ? selectedUserRole.value : null,
                fromDate: fromDate ? fromDate : null,
                selectedUserBranch: selectedUserBranch ? selectedUserBranch.value : null
              }
            }
          />
        </div>
      </div >
    );
  }
}

export default Index;
