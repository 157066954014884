import React from "react";
import Button from "@material-ui/core/Button";
import { OptionType } from "../../constants/constants";
import List from './list'
import AddNew from './add-new/index'
import {
  Typography
} from '@material-ui/core'

import {
  Apps as AppsIcon,
  FilterList as FilterListIcon,
} from '@material-ui/icons'
import TextInput from '../common/text-input'
import Select from '../common/select'
import { optionManagerStatus } from '../../constants/constants';
import { objToArray } from "../../utils/common";
import { OPTION_FILTER } from "../../constants/localStorageKeys";

class Index extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pageType: null,
      openAddNewOption: false,
      searchKey: '',
      statusSelected: null
    };
    this.getList = React.createRef()
  }

  _getList = () => {
    let {
      searchKey,
      statusSelected
    } = this.state
    this.getList.current._getList({
      searchKey: searchKey,
      statusSelected: statusSelected,
      currentPage: 1
    })
  }

  componentWillReceiveProps(props) {
    let pageType = props.match.params.type
    if (!pageType) return;
    this.setState({
      pageType: pageType
    })
  }

  componentDidMount() {
    let pageType = this.props.match.params.type
    if (!pageType) return;
    this.setState({
      pageType: pageType
    })
    let filter = JSON.parse(window.localStorage.getItem(OPTION_FILTER))
    if (filter) {
      this.setState({
        searchKey: filter.searchKey,
        statusSelected: filter.statusSelected
      })
    }
  }
  _closeAddOption = () => {
    this.setState({
      openAddNewOption: false
    })
  }
  render() {
    let {
      pageType,
      openAddNewOption,
      searchKey,
      statusSelected
    } = this.state;
    let optionStatuOptions = objToArray(optionManagerStatus)
    return (
      <div>
        <div className="box manage-user">
          <Typography variant="h6" gutterBottom align="center" className="box-title">
            <div>
              Quản lí tuỳ chọn [ {pageType && OptionType[pageType].label} ]
              <p>Cài đặt và quản lý những {pageType && OptionType[pageType].label} sử dụng trong hệ thống.</p>
            </div>

          </Typography>
          <div className="admin-box" style={{ position: "relative" }}>
            <span><FilterListIcon />Bộ lọc dữ liệu</span>
            <div className="search-content">
              <div className="search-key">
                <label>Lọc theo từ khoá</label>
                <div>
                  <TextInput
                    name="searchKey"
                    displayName="Thông tin tìm kiếm"
                    placeholder="ID đối tác, ID kênh ..."
                    type="text"
                    value={searchKey}
                    onChange={(e) => this.setState({ searchKey: e.target.value })}
                    edit={true}
                    outLine
                    height={35}
                    label="asdf"
                  />
                  <span className="search-bt" onClick={() => this._getList()}>Tìm nhanh</span>
                </div>
              </div>
            </div>
            {/* <div className="search-option input-form">
                        <div className="width35pc mr00">
                            <label>Trạng thái</label>
                            <Select
                                className="select-status"
                                options={optionStatuOptions}
                                onChange={(value) => this.setState({
                                    statusSelected: value
                                }, () => this._getList())}
                                value={statusSelected}
                                edit={true}
                                allowNull={true}
                                placeholder="Lọc theo trạng thái"
                            />
                        </div>

                    </div> */}
          </div>
          <div className="admin-box">
            <span>
              <AppsIcon />
              Danh sách tuỳ chọn
              <Button
                color="primary"
                className="custom-button"
                onClick={() =>
                  this.setState({
                    openAddNewOption: true,
                    optionName: "",
                    description: ""
                  })
                }
              >
                Tạo mới
              </Button>
            </span>
            <List pageType={this.state.pageType}
              ref={this.getList} />
          </div>

          <AddNew open={openAddNewOption}
            onClose={this._closeAddOption}
            pageType={this.state.pageType}
            getList={() => this._getList()} />
        </div>
      </div >
    );
  }
}

export default Index;
