import React from "react";
import Button from "@material-ui/core/Button";
import List from './list'
import AddNew from './add'


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddCampaign: false
    };
    this._getListCampaigns = React.createRef()
  }

  _onGetListCampaigns = () => {
    this._getListCampaigns.current._getList()
  }
  _onCloseAddCampaign = () => {
    this.setState({
      openAddCampaign: false
    })
  }
  componentDidMount() {
    this._onGetListCampaigns()
  }

  render() {
    let {
      openAddCampaign
    } = this.state;
    return (
      <div className="box manage-user">
        <div className="box-header">
          <h3 className="box-title">Campaigns</h3>
        </div>
        <div className="box-body product-list">
          <div className="filters-form mb15 balance-search date-time">
            <table className="table list-item border-none">
              <tbody>
                <tr>
                  <td className="text-right">
                    <div className="create-bt">
                      <Button
                        color="primary"
                        className="custom-button"
                        onClick={() =>
                          this.setState({
                            openAddCampaign: true
                          })
                        }
                      >
                        Thêm Campaign
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <List ref={this._getListCampaigns} />
          <AddNew open={openAddCampaign}
            onClose={this._onCloseAddCampaign}
            onAddSucceed={this._onGetListCampaigns} />
        </div >
      </div>
    );
  }
}

export default Index;
