import React from "react";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";
// import { SortableHoc, SortableHocItem } from "../common/sortable-hoc";
// import FileDropZone from '../common/file-drop-zone'
// import DatetimePicker from '../common/datetime-picker'
// import { getDate } from "../../utils/common";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: "dsfa",
            selectValue: { label: "abc", value: "abc" },
            userSelected: 3,
            isEditing: false,
            openModal: false,
            list: [],
            // signDate: getDate(),
            sharingRateId: 0
        };
    }

    componentDidMount() {

        this.setState({
            files: ["Uploads/Avatars/12_01_2019_12_02_b18d49b36abc4217ae9fe4b6ac22ccde.png", "Uploads/Avatars/12_01_2019_12_02_b18d49b36abc4217ae9fe4b6ac22ccde.png", "Uploads/Avatars/12_01_2019_12_02_b18d49b36abc4217ae9fe4b6ac22ccde.png"]
        })
    }



    render() {
        return null;
        let { signDate, signDateError } = this.state;
        // return (
        //     <div className="home-page" >
        //         <Tabs>
        //             <TabList>
        //                 <Tab>Notes</Tab>
        //                 <Tab>Basic Info</Tab>
        //             </TabList>
        //             <TabPanel>
        //                 <SortableHoc onChange={(oldIndex, newIndex) => { }}>
        //                     <SortableHocItem index={-10}>Item -10</SortableHocItem>
        //                     <SortableHocItem index={1}>Item 1</SortableHocItem>
        //                     <SortableHocItem index={5}>Item 5</SortableHocItem>
        //                 </SortableHoc>
        //             </TabPanel>
        //             <TabPanel>

        //                 <FileDropZone
        //                     type="Avatars"
        //                     edit={true}
        //                     multiImages={true}
        //                     onAddFile={(file) => console.log(file)}
        //                     files={this.state.files}
        //                 />
        //                 <DatetimePicker
        //                     className="signDate"
        //                     placeholder={"Ngày ký"}
        //                     onChange={value => this.setState({ signDate: value, signDateError: null })}
        //                     value={signDate}
        //                     edit={true}
        //                     errors={signDateError}
        //                 />
        //             </TabPanel>
        //         </Tabs>
        //     </div>
        // );
    }
}

export default Index;
