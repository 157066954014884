import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import red from '@material-ui/core/colors/red';
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ToolTip from '@material-ui/core/Tooltip'

const styles = theme => ({
    card: {
        maxWidth: 400,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
});

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            openEditChannel: false
        };
    }

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    _onUpdateSuccess() {
        this.props.onUpdateSuccess()
        this.setState({
            openEditChannel: false
        })
    }

    render() {
        const { classes, info } = this.props;
        return (
            <div>
                {
                    info && <Card className={classes.card + " custom-channel-card"}>
                        <CardContent>
                            <table className="table border-none">
                                <tbody>
                                    <tr>
                                        <td className="width150 vertical-top">
                                            <div className="thumnail-image">
                                                <img alt="" src={info.thumbnail} style={{ width: "100%" }} />
                                            </div>
                                        </td>
                                        <td className="vertical-top">
                                            <div className="channel-basic-info">
                                                <span className="channel-name">{info.name}</span>
                                                <table className="table border-none">
                                                    <tbody>
                                                        <tr>
                                                            <td className="width120 ">
                                                                Email quản lý:
                                                            </td>
                                                            <td className="text-left">
                                                                {info.managerEmail}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="width120 ">
                                                                Lượt theo dõi:
                                                            </td>
                                                            <td className="text-left">
                                                                {info.subscriberCount}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardContent>
                        <CardActions className={classes.actions} disableActionSpacing>
                            <ToolTip title="Chỉnh sửa kênh" placement="top">
                                <IconButton onClick={() => this.setState({ openEditChannel: true })}>
                                    <EditIcon />
                                </IconButton>
                            </ToolTip>
                            <ToolTip title="Xoá kênh" placement="top">
                                <IconButton>
                                    <DeleteIcon />
                                </IconButton>
                            </ToolTip>
                            <ToolTip title="Chi tiết" placement="top">
                                <IconButton
                                    className={classnames(classes.expand, {
                                        [classes.expandOpen]: this.state.expanded,
                                    })}
                                    onClick={this.handleExpandClick}
                                    aria-expanded={this.state.expanded}
                                    aria-label="Show more"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </ToolTip>
                        </CardActions>
                        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                            <CardContent className="more-info">
                                <table className="table border-none">
                                    <tbody>
                                        <tr>
                                            <td className="width100 text-left">
                                                <span className="chanel-description-label">Mô tả</span>
                                                <pre style={{ width: "100%" }}>{info.description}</pre>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </CardContent>
                        </Collapse>
                    </Card>
                }
            </div>
        );
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);