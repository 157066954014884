import React from "react";
import { put, _delete, post } from "../../api";
import { Button, CardHeader, Avatar, Drawer } from "@material-ui/core";
import TextInput from "../common/text-input";
import {
  notEmpty,
  emailAddress,
  isValid,
  justNumber,
} from "../../utils/validators";
import SelectOption from "../common/select-option";
import UserSelectList from "../common/user-select-list";

import { showSuccess, showError } from "../../utils/app";
import {
  OptionType,
  DefaultUserAvatar,
  PartnerType,
  ChannelStatus,
  MetubRoles,
  VerifyStatus,
  ChooseTier,
  UploadFileType,
} from "../../constants/constants";
import { get } from "../../api/index";
import { confirmSubmit, objToArray } from "../../utils/common";
import Select from "../common/select";
import { isInRole, getCurrentUser } from "../../auth";
import DatetimePicker from "../common/datetime-picker";
import moment from "moment";
import { Dvr as DvrIcon } from "@material-ui/icons";
import { StickyContainer, Sticky } from "react-sticky";
import FileDropZone from "../common/file-drop-zone";
import { CHANNEL_FILTER } from "../../constants/localStorageKeys";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      youtubeId: "",
      name: "",
      thumbnail: "",
      position: "",
      isDefault: false,
      channelInfo: null,
      isChannelInfoLoading: false,
      description: "",
      subscriberCount: "0",
      sharingRateId: 0,
      verticalId: null,
      categoryId: 0,
      subCategories: [],
      contentId: 0,
      priorityId: 0,
      optimizerId: null,
      managedByEmailId: "",
      notes: "",
      edit: false,
      defaultValue: null,
      partner: null,
      managedByEmailIdError: null,
      cmsId: null,
      cmsIdError: null,
      cmsName: "",
      selectedStatus: null,
      partnerManager: null,
      tier: "",
      optionTier: objToArray(ChooseTier),
      selectedTier: null,
      openUlinkDateSelect: false,
      unlinkDate: null,
      unlinkDateError: null,
      deniedReason: "",
      unlinkRequesDescription: "",
      unlinkRequesDescriptionError: null,
      requestImages: "",
      requestImagesError: null,
      appealingReason: "",
      invitationExpiredReason: ""
    };
    this.requestImagesRef = React.createRef();
    this.validationData = {
      url: [notEmpty],
      youtubeId: [notEmpty],
      name: [notEmpty],
      description: [notEmpty],
      subscriberCount: [notEmpty, justNumber],
      unlinkRequesDescription: [notEmpty],
    };
  }

  handleCheckStatus() {
    const { defaultChannel, selectedStatus, cmsId, cmsName } = this.state
    if (defaultChannel && defaultChannel.status != selectedStatus.value) {
      confirmSubmit(
        "Thông báo",
        `Bạn có chắc chắn muốn chọn CMS [${cmsId} - ${cmsName}]?`,
        () => this.handleCheckPartnerId()
      )
    } else {
      this.handleCheckPartnerId()
    }
  }

  handleCheckPartnerId() {
    const { defaultChannel, partnerId } = this.state
    if (defaultChannel && defaultChannel.partner &&
      defaultChannel.partner.id != partnerId)
      confirmSubmit(
        "Thông báo",
        `Parter Id đã được thay đổi từ ${defaultChannel.partner.id} thành ${partnerId}. Bạn vẫn muốn tiếp tục lưu thông tin?`,
        () => this._updateChannel()
      )
    else this._updateChannel()
  }

  _updateChannel() {
    let {
      url,
      youtubeId,
      name,
      thumbnail,
      description,
      subscriberCount,
      sharingRateId,
      categoryId,
      verticalId,
      contentId,
      priorityId,
      optimizerId,
      managedByEmailId,
      cmsId,
      notes,
      selectedStatus,
      selectedTier,
      cooperationDate,
      tagIds,
      subCategoryIds,
      partnerId,
      deniedReason,
      appealingReason,
      invitationExpiredReason,
      submitTime,
      createTime,
      deniedTime,
      inviteTime,
      unlink1Time,
      unlink2Time,
      appealingTime,
      invitationExpiredTime
    } = this.state;

    if (this._checkValid()) {
      let param = {
        youtubeId: youtubeId,
        url: url,
        thumbnail: thumbnail,
        name: name,
        description: description,
        subscriberCount: subscriberCount,
        notes: notes,
        managedByEmailId: managedByEmailId,
        sharingRateId: sharingRateId,
        verticalId: verticalId,
        categoryId: categoryId,
        subCategoryIds: subCategoryIds,
        contentId: contentId,
        priorityId: priorityId,
        optimizerId: optimizerId,
        cmsId: cmsId,
        status: selectedStatus
          ? selectedStatus.value
          : ChannelStatus.None.value,
        id: this.props.channelId,
        tier: selectedTier.value,
        cooperationDate: cooperationDate,
        tagIds: tagIds ? tagIds : [],
        subCategoryIds: subCategoryIds ? subCategoryIds : [],
        partnerId: partnerId,
        deniedReason: deniedReason,
        appealingReason: appealingReason,
        invitationExpiredReason: invitationExpiredReason,
        submitTime: submitTime,
        createTime: createTime,
        deniedTime: deniedTime,
        appealingTime: appealingTime,
        invitationExpiredTime: invitationExpiredTime,
        inviteTime: inviteTime,
        unlink1Time: unlink1Time,
        unlink2Time: unlink2Time,
      };
      if (
        selectedStatus &&
        selectedStatus.value != ChannelStatus.Denied.value
      ) {
        delete param.deniedReason;
      }

      put("channels", param, () => {
        this.setState({
          edit: false,
        });
        showSuccess("Thay đổi thông tin thành công.");
      });
    } else {
      showError(
        "Nội dung không hợp lệ, vui lòng kiểm tra lại thông tin vừa nhập."
      );
    }
  }

  _setDefaultValue() {
    const { channelId } = this.props;
    if (!channelId) return;
    get("channels/" + channelId, (result) => {
      console.log("result", result);
      let tagIds = [];
      let subCategoryIds = []
      if (result.tags && result.tags.length > 0) {
        result.tags.map((item) => tagIds.push(item.option.id));
      }
      if (result.subCategories && result.subCategories.length > 0) {
        result.subCategories.map((item) => subCategoryIds.push(item.option.id));
      }

      this.setState({
        notes: result.notes,
        url: result.url,
        youtubeId: result.youtubeId,
        name: result.name,
        thumbnail: result.thumbnail,
        description: result.description,
        subscriberCount: result.subscriberCount ? result.subscriberCount : "0",
        sharingRateId: result.sharingRate ? result.sharingRate.id : null,
        verticalId: result.vertical ? result.vertical.id : null,
        categoryId: result.category ? result.category.id : null,
        contentId: result.content ? result.content.id : null,
        priorityId: result.priority ? result.priority.id : null,
        optimizerId: result.optimizer ? result.optimizer.id : null,
        managedByEmailId: result.managedByEmail
          ? result.managedByEmail.id
          : null,
        defaultValue: result,
        partner: result.partner,
        cmsId: result.cms ? result.cms.id : null,
        selectedStatus: ChannelStatus[result.status],
        partnerManager: result.partnerManager,
        tier: result.tier,
        selectedTier: ChooseTier[result.tier],
        cooperationDate: result.cooperationDate,
        tagIds: tagIds,
        subCategoryIds: subCategoryIds,
        partnerId: result.partner ? result.partner.id : null,
        updateSubcribeDate: result.updateSubcribeDate,
        deniedReason: result.deniedReason ? result.deniedReason : "",
        appealingReason: result.appealingReason ? result.appealingReason : "",
        invitationExpiredReason: result.invitationExpiredReason ? result.invitationExpiredReason : "",
        submitTime: result.submitTime ? result.submitTime : null,
        createTime: result.createTime ? result.createTime : null,
        deniedTime: result.deniedTime ? result.deniedTime : null,
        invitationExpiredTime: result.invitationExpiredTime ? result.invitationExpiredTime : null,
        appealingTime: result.appealingTime ? result.appealingTime : null,
        inviteTime: result.inviteTime ? result.inviteTime : null,
        unlink1Time: result.unlink1Time ? result.unlink1Time : null,
        unlink2Time: result.unlink2Time ? result.unlink2Time : null,
        defaultChannel: result,
      });
    });
  }

  _checkValid() {
    let {
      optimizerId,
      categoryId,
      priorityId,
      contentId,
      sharingRateId,
      selectedStatus,
      verticalId
    } = this.state;

    let valid = true;

    if (!isValid(this.validationData)) {
      valid = false;
    }

    if (!sharingRateId) {
      valid = false;
      this.setState({
        sharingRateIdError: "Tỷ lệ chia sẻ không được bỏ trống.",
      });
    }

    if (!verticalId) {
      valid = false;
      this.setState({
        verticalIdError: "Vertical không được bỏ trống."
      })
    }

    if (!categoryId) {
      valid = false;
      this.setState({
        categoryIdError: "Category không được bỏ trống",
      });
    }

    if (!priorityId) {
      valid = false;
      this.setState({
        priorityIdError: "Ưu tiên không được bỏ trống",
      });
    }

    if (!contentId) {
      valid = false;
      this.setState({
        contentIdError: "Content không được bỏ trống",
      });
    }
    if (selectedStatus && selectedStatus.value !== ChannelStatus.InvitationExpired.value && selectedStatus.value !== ChannelStatus.Appealing.value) {
      if (!this.state[selectedStatus.key]) {
        valid = false;
        this.setState({
          dateError:
            "Ngày " +
            selectedStatus.label.toString().toLowerCase() +
            " không được bỏ trống",
        });
      }
    }


    return valid;
  }

  _getChannelInfoFromYoutube() {
    if (this.state.url === "") return;
    let url = this.state.url.split("/");
    if (url.length > 0) {
      this.setState(
        {
          isChannelInfoLoading: true,
        },
        () => {
          let channelId = url[url.length - 1];
          get(
            "youtube/channels/" + channelId,
            (result) => {
              this.setState({
                youtubeId: result.id,
                name: result.snippet.title,
                thumbnail: result.snippet.thumbnails.high.url,
                description: result.snippet.description,
                isChannelInfoLoading: false,
                subscriberCount:
                  result.statistics && result.statistics.subscriberCount
                    ? result.statistics.subscriberCount
                    : "0",
                url: "https://www.youtube.com/channel/" + channelId,
              });
            },
            () => {
              this.setState({ isChannelInfoLoading: false });
            }
          );
        }
      );
    }
  }

  _cancelUpdate() {
    confirmSubmit(
      <h4>
        <b>Huỷ thay đổi.</b>
      </h4>,
      <div className="mt10">
        Bạn có thật sự muốn huỷ thay đổi? Mọi thay đổi sẽ không được áp dụng.
      </div>,
      () => {
        let { defaultValue } = this.state;
        this._setDefaultValue();
        this.setState({
          // ...defaultValue,
          edit: false,
        });
      }
    );
  }

  _deleteChannel(id) {
    confirmSubmit(
      <h4>
        <b>Xoá thông tin kênh.</b>
      </h4>,
      <div className="mt10">Bạn có thật sự muốn xoá kênh này?</div>,
      () => {
        const { channelId } = this.props;
        _delete("channels/" + channelId, null, () => {
          showSuccess("Xoá thông tin thông tin thành công.");
          this.props.history.goBack();
        });
      }
    );
  }
  _checkOptimizeRole() {
    let currentUser = getCurrentUser();
    const { optimizer } = this.props;
    let output = false;
    if (currentUser.roleCode != MetubRoles.OPTIMIZE_MANAGER) {
      output = true;
    } else {
      if (currentUser && optimizer && currentUser.id == optimizer.id)
        output = true;
    }
    return output;
  }

  _canUpdate() {
    let { partnerManager, selectedStatus, defaultChannel } = this.state;
    const currentUser = getCurrentUser();
    let output = false;
    if (
      isInRole(MetubRoles.UPDATE_CHANNEL, () => {
        return partnerManager && currentUser.id === partnerManager.id;
      }) ||
      currentUser.roleCode === MetubRoles.DATA_MANAGER ||
      currentUser.roleCode == MetubRoles.OPTIMIZE_MANAGER
    )
      output = true;

    if (
      (currentUser.roleCode == MetubRoles.PARTNER_MANAGER ||
        currentUser.roleCode == MetubRoles.LEADER_PARTNER_MANAGER) &&
      selectedStatus &&
      selectedStatus.value != ChannelStatus.Submit.value &&
      selectedStatus.value != ChannelStatus.Denied.value
    ) {
      output = false;
    }
    return output;
  }
  _canUnlink() {
    let { partnerManager, selectedStatus } = this.state;
    const currentUser = getCurrentUser();
    let output = false;
    if (
      (currentUser.roleCode == MetubRoles.PARTNER_MANAGER ||
        currentUser.roleCode == MetubRoles.LEADER_PARTNER_MANAGER) &&
      partnerManager &&
      currentUser.id === partnerManager.id &&
      selectedStatus.value != ChannelStatus.Unlink1.value &&
      selectedStatus.value != ChannelStatus.Unlink2.value
    )
      output = true;
    return output;
  }

  _handleRequestUnlink() {
    let { channelId } = this.props;
    let { unlinkDate, requestImages, unlinkRequesDescription } = this.state;
    let valided = true;
    if (!isValid(this.validationData)) {
      valided = false;
    }
    if (!unlinkDate) {
      this.setState({
        unlinkDateError: "Vui lòng chọn ngày unlink trước khi gửi yêu cầu.",
      });
      valided = false;
      return;
    }
    if (unlinkRequesDescription == "") {
      valided = false;
      return;
    }

    if (valided == false) return;
    confirmSubmit(
      <h4 style={{ color: "#EB5A46" }}>
        <b>Unlink kênh</b>
      </h4>,
      <div className="mt10" style={{ color: "#EB5A46" }}>
        Bạn có thật sự muốn unlink kênh này?
      </div>,
      () => {
        let param = {
          referenceId: channelId,
          implementDate: unlinkDate,
          files: [requestImages],
          content: unlinkRequesDescription,
        };
        post("requests/request-unlink", param, () => {
          showSuccess("Gửi kêu cầu cập nhật thành công.");
          this.setState({
            openUlinkDateSelect: false,
            unlinkDate: null,
            unlinkDateError: null,
          });
        });
      }
    );
  }

  componentDidMount() {
    this._setDefaultValue();
  }

  render() {
    const { edit } = this.state;
    const validationData = this.validationData;
    const {
      url,
      name,
      isChannelInfoLoading,
      youtubeId,
      thumbnail,
      description,
      subscriberCount,
      sharingRateId,
      verticalId,
      categoryId,
      priorityId,
      contentId,
      optimizerId,
      managedByEmailId,
      notes,
      categoryIdError,
      verticalIdError,
      priorityIdError,
      contentIdError,
      sharingRateIdError,
      optimizerIdErrors,
      managedByEmailIdError,
      partner,
      cmsId,
      cmsIdError,
      selectedStatus,
      partnerManager,
      optionTier,
      selectedTier,
      dateError,
      tagIds,
      subCategoryIds,
      partnerId,
      updateSubcribeDate,
      openUlinkDateSelect,
      unlinkDate,
      unlinkDateError,
      defaultChannel,
      unlinkRequesDescription,
      requestImages,
      requestImagesError,
    } = this.state;

    let { channelId } = this.props;

    let ChannelStatusOptions = objToArray(ChannelStatus);
    ChannelStatusOptions = ChannelStatusOptions.filter(
      (e) => e.value !== "None"
    );
    const currentUser = getCurrentUser();

    if (currentUser.roleCode == MetubRoles.PARTNER_MANAGER) {
      ChannelStatusOptions = ChannelStatusOptions.filter(
        (item) =>
          item.value == ChannelStatus.Denied.value ||
          item.value == ChannelStatus.Submit.value
      );
    }

    console.log("selectedStatus", selectedStatus)

    return (
      <React.Fragment>
        <StickyContainer style={{ padding: 0, position: "relative" }}>
          <div className="admin-box">
            <Sticky topOffset={-50}>
              {({ style }) => (
                <span
                  className="box-head"
                  style={{
                    ...style,
                    top: 50,
                    background: "#fff",
                    zIndex: 1000000,
                  }}
                >
                  <DvrIcon />
                  Thông tin chi tiết kênh
                  <div className="modify">
                    {!edit ? (
                      <div className="text-right width100pc">
                        {this._canUnlink() ? (
                          <Button
                            color={"primary"}
                            onClick={() =>
                              this.setState({ openUlinkDateSelect: true })
                            }
                          >
                            <i
                              className="fas fa-exclamation-circle"
                              style={{
                                marginRight: "5px",
                                fontSize: "1.4rem",
                                color: "#FF0202",
                              }}
                            ></i>
                            <span style={{ color: "#FF0202" }}>
                              Yêu cầu unlink
                            </span>
                          </Button>
                        ) : (
                          ""
                        )}
                        {this._canUpdate() ? (
                          <Button
                            color={"primary"}
                            className="ml20"
                            onClick={() => this.setState({ edit: true })}
                          >
                            Chỉnh sửa
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div className="text-right">
                        <Button
                          color={"primary"}
                          onClick={() => this.handleCheckStatus()}
                        >
                          LƯU
                        </Button>
                        <Button onClick={() => this._cancelUpdate()}>
                          HUỶ
                        </Button>
                      </div>
                    )}
                    {(currentUser.roleCode === MetubRoles.PARTNER_MANAGER ||
                      currentUser.roleCode ===
                      MetubRoles.LEADER_PARTNER_MANAGER) &&
                      defaultChannel &&
                      defaultChannel.status === ChannelStatus.Accepted.value ? (
                      ""
                    ) : (
                      <React.Fragment>
                        {!edit &&
                          (isInRole(MetubRoles.DELETE_CHANNEL, () => {
                            return (
                              partnerManager &&
                              currentUser.id === partnerManager.id
                            );
                          }) ||
                            currentUser.roleCode === "DATA_MANAGER") && (
                            <div className="text-right width70">
                              <Button
                                color={"primary"}
                                onClick={() => this._deleteChannel()}
                              >
                                XOÁ
                              </Button>
                            </div>
                          )}
                      </React.Fragment>
                    )}
                  </div>
                </span>
              )}
            </Sticky>

            {this._canUnlink() ? (
              <Drawer
                anchor={"right"}
                open={openUlinkDateSelect}
                onClose={() =>
                  this.setState({
                    openUlinkDateSelect: false,
                    unlinkDate: null,
                    unlinkDateError: null,
                  })
                }
              >
                <div className="drawer-header">
                  <span>Yêu cầu unlink</span>
                </div>
                <div style={{ maxWidth: "400px" }}>
                  <div className="drawer-content unlink-date-drawer pb20">
                    <p>Xác định ngày unlink kênh</p>
                    {unlinkDateError ? <label>{unlinkDateError}</label> : ""}
                    <span
                      style={{
                        opacity: !unlinkDate ? "0.5" : "1",
                        fontSize: !unlinkDate ? "1rem" : "1.2rem",
                      }}
                    >
                      {unlinkDate
                        ? moment(unlinkDate).format("DD / MM / YYYY")
                        : "Ngày / Tháng / Năm"}
                    </span>
                    <div style={{ height: "270px" }}>
                      <DatetimePicker
                        className="unlink-date"
                        type="date"
                        placeholder={"Ngày Unlink"}
                        onChange={(value) =>
                          this.setState({
                            unlinkDate: value,
                            unlinkDateError: null,
                          })
                        }
                        value={unlinkDate}
                        edit={true}
                        inline={true}
                        maxDate={new Date()}
                        minDate={new Date().setDate(new Date().getDate() - 5)}
                      />
                    </div>
                    <p style={{ marginTop: "30px" }}>Mô tả chi tiết yêu cầu</p>
                    <TextInput
                      className="sharingRateId"
                      value={unlinkRequesDescription}
                      name={"unlinkRequesDescription"}
                      placeholder={"Mô tả"}
                      displayName={"Mô tả"}
                      multiline={true}
                      onChange={(e) => {
                        this.setState({
                          unlinkRequesDescription: e.target.value,
                        });
                      }}
                      validations={validationData.unlinkRequesDescription}
                      edit={true}
                    />
                    <p>Hình minh hoạ</p>
                    <FileDropZone
                      type={UploadFileType.Contracts.value}
                      edit={true}
                      onChange={(files) => {
                        this.setState({
                          requestImages:
                            files.length > 0 ? files[0].relativePath : null,
                          requestImagesError: null,
                        });
                      }}
                      multiImages={false}
                      errors={requestImagesError}
                      ref={this.requestImagesRef}
                      files={[requestImages]}
                    />
                  </div>
                </div>
                <div className="drawer-actions">
                  <span
                    className="cancel-bt"
                    onClick={() =>
                      this.setState({
                        openUlinkDateSelect: false,
                        unlinkDate: null,
                        unlinkDateError: null,
                      })
                    }
                  >
                    Huỷ
                  </span>
                  <span
                    className="search-bt"
                    onClick={() => this._handleRequestUnlink()}
                  >
                    Gửi yêu cầu
                  </span>
                </div>
              </Drawer>
            ) : (
              ""
            )}
            <div className="text-left channel-info">
              <table className="table border-none">
                <tbody>
                  <tr>
                    {partner ? (
                      <td>
                        <label>Thông tin đối tác</label>
                        <CardHeader
                          avatar={<Avatar src={DefaultUserAvatar}></Avatar>}
                          title={
                            partner.type === PartnerType.Individual.value ? (
                              <a
                                onClick={() => {
                                  window.localStorage.removeItem(
                                    CHANNEL_FILTER
                                  );
                                  this.props.history.push(
                                    "/partners/" + partner.id
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {partner.fullName}
                              </a>
                            ) : (
                              <a
                                onClick={() => {
                                  window.localStorage.removeItem(
                                    CHANNEL_FILTER
                                  );
                                  this.props.history.push(
                                    "/partners/" + partner.id
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {partner.companyName}
                              </a>
                            )
                          }
                          subheader={
                            partner.type === PartnerType.Individual.value
                              ? partner.stageName
                              : ""
                          }
                        />
                        <hr />
                      </td>
                    ) : null}
                  </tr>
                  <tr>
                    <td className={""}>
                      <label>ID kênh</label>
                      <a href={url} target="_blank">
                        <TextInput
                          className="url"
                          value={channelId ? channelId : "-/-"}
                          name={"url"}
                          placeholder={"Nhập đường dẫn kênh"}
                          displayName={"URL"}
                          edit={false}
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className={""}>
                      <label>Đường dẫn kênh</label>
                      {
                        <TextInput
                          className="url"
                          value={url}
                          name={"url"}
                          type="link"
                          linkTarget="_blank"
                          placeholder={"Nhập đường dẫn kênh"}
                          displayName={"URL"}
                          onChange={(e) => {
                            this.setState({ url: e.target.value });
                          }}
                          validations={validationData.url}
                          edit={
                            edit &&
                            currentUser.roleCode != MetubRoles.OPTIMIZE_MANAGER
                          }
                          onBlur={() => this._getChannelInfoFromYoutube()}
                          disable={isChannelInfoLoading}
                        />
                      }
                    </td>
                  </tr>
                  {thumbnail ? (
                    <tr>
                      <td className="border-none text-center">
                        {thumbnail.length > 0 && (
                          <img
                            alt=""
                            className="channel-thumbnail"
                            style={{ width: "50%" }}
                            src={thumbnail}
                          />
                        )}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              <div className="mt40">
                <div className="left-box ">
                  <table className="table border-none input-form mb10pc ">
                    <tbody>
                      <tr>
                        <td>
                          <label>YouTube ID</label>
                          <TextInput
                            className="youtubeId"
                            value={youtubeId}
                            name={"youtubeId"}
                            placeholder={"YouTube ID"}
                            displayName={"YouTube ID"}
                            onChange={(e) => {
                              this.setState({ youtubeId: e.target.value });
                            }}
                            validations={validationData.youtubeId}
                            edit={
                              edit &&
                              currentUser.roleCode !=
                              MetubRoles.OPTIMIZE_MANAGER
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Tên kênh</label>
                          <TextInput
                            className="name"
                            value={name}
                            name={"name"}
                            placeholder={"Tên kênh"}
                            displayName={"Tên kênh"}
                            onChange={(e) => {
                              this.setState({ name: e.target.value });
                            }}
                            validations={validationData.name}
                            edit={
                              edit &&
                              currentUser.roleCode !=
                              MetubRoles.OPTIMIZE_MANAGER
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Vertical</label>
                          <SelectOption
                            optionType={OptionType.Vertical.value}
                            onChange={(value) =>
                              this.setState({
                                verticalId: value ? value.value : null,
                                verticalIdError: null,
                              })
                            }
                            edit={
                              currentUser.roleCode ===
                                MetubRoles.DATA_MANAGER ||
                                currentUser.roleCode === MetubRoles.ADMIN ||
                                currentUser.roleCode ===
                                MetubRoles.PARTNER_MANAGER ||
                                currentUser.roleCode ===
                                MetubRoles.LEADER_PARTNER_MANAGER
                                ? edit
                                : false
                            }
                            value={verticalId}
                            allowNull={true}
                            errors={verticalIdError}
                            outLine={true}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Category</label>
                          <SelectOption
                            optionType={OptionType.ChannelCategory.value}
                            onChange={(value) =>
                              this.setState({
                                categoryId: value ? value.value : null,
                                categoryIdError: null,
                              })
                            }
                            edit={
                              currentUser.roleCode ===
                                MetubRoles.DATA_MANAGER ||
                                currentUser.roleCode === MetubRoles.ADMIN ||
                                currentUser.roleCode ===
                                MetubRoles.PARTNER_MANAGER ||
                                currentUser.roleCode ===
                                MetubRoles.LEADER_PARTNER_MANAGER
                                ? edit
                                : false
                            }
                            value={categoryId}
                            allowNull={false}
                            errors={categoryIdError}
                            outLine={true}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Sub Category</label>
                          <SelectOption
                            optionType={OptionType.SubCategory.value}
                            onChange={(value) =>
                              this.setState({
                                subCategoryIds: value ? value.value : null,
                              })
                            }
                            edit={
                              currentUser.roleCode ===
                                MetubRoles.DATA_MANAGER ||
                                currentUser.roleCode === MetubRoles.ADMIN ||
                                currentUser.roleCode ===
                                MetubRoles.PARTNER_MANAGER ||
                                currentUser.roleCode ===
                                MetubRoles.LEADER_PARTNER_MANAGER
                                ? edit
                                : false
                            }
                            value={subCategoryIds}
                            allowNull={true}
                            outLine={true}
                            isMulti={true}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Content</label>
                          <SelectOption
                            optionType={OptionType.ChannelContentType.value}
                            onChange={(value) =>
                              this.setState({
                                contentId: value ? value.value : null,
                                contentIdError: null,
                              })
                            }
                            edit={
                              currentUser.roleCode ===
                                MetubRoles.DATA_MANAGER ||
                                currentUser.roleCode === MetubRoles.ADMIN ||
                                currentUser.roleCode ===
                                MetubRoles.PARTNER_MANAGER ||
                                currentUser.roleCode ===
                                MetubRoles.LEADER_PARTNER_MANAGER
                                ? edit
                                : false
                            }
                            value={contentId}
                            allowNull={false}
                            errors={contentIdError}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>CMS</label>
                          <SelectOption
                            optionType={OptionType.CMS.value}
                            onChange={(value) =>
                              this.setState({
                                cmsId: value ? value.value : null,
                                cmsIdError: null,
                                cmsName: value ? value.label : ""
                              })
                            }
                            edit={
                              currentUser.roleCode ===
                                MetubRoles.DATA_MANAGER ||
                                currentUser.roleCode === MetubRoles.ADMIN ||
                                currentUser.roleCode ===
                                MetubRoles.PARTNER_MANAGER ||
                                currentUser.roleCode ===
                                MetubRoles.LEADER_PARTNER_MANAGER
                                ? edit
                                : false
                            }
                            value={cmsId}
                            allowNull={false}
                            errors={cmsIdError}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Ưu tiên</label>
                          <SelectOption
                            optionType={OptionType.ChannelPriority.value}
                            onChange={(value) =>
                              this.setState({
                                priorityId: value ? value.value : null,
                                priorityIdError: null,
                              })
                            }
                            edit={
                              edit &&
                              currentUser.roleCode !=
                              MetubRoles.OPTIMIZE_MANAGER
                            }
                            value={priorityId}
                            allowNull={false}
                            errors={priorityIdError}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ position: "relative" }}>
                          <label>Lượt theo dõi</label>
                          <TextInput
                            className="subscriberCount"
                            value={subscriberCount}
                            name={"subscriberCount"}
                            placeholder={"Lượt theo dõi"}
                            displayName={"Lượt theo dõi"}
                            onChange={(e) => {
                              this.setState({
                                subscriberCount: e.target.value,
                              });
                            }}
                            validations={validationData.subscriberCount}
                            edit={
                              edit &&
                              currentUser.roleCode !=
                              MetubRoles.OPTIMIZE_MANAGER
                            }
                          />
                          {subscriberCount &&
                            subscriberCount != "" &&
                            updateSubcribeDate &&
                            !edit ? (
                            <span
                              style={{
                                top: "calc(50% - 13px)",
                                fontStyle: "italic",
                                color: "rgba(0,0,0,0.4)",
                              }}
                            >
                              Cập nhật lần cuối lúc{" "}
                              {moment(updateSubcribeDate).format("HH:mm")} ngày{" "}
                              {moment(updateSubcribeDate).format("DD/MM/YYYY")}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      {currentUser.roleCode != MetubRoles.OPTIMIZE_MANAGER &&
                        currentUser.roleCode != MetubRoles.OPTIMIZE ? (
                        <tr>
                          <td>
                            <label>Tỷ lệ chia sẻ</label>
                            <SelectOption
                              optionType={OptionType.SharingRate.value}
                              onChange={(value) =>
                                this.setState({
                                  sharingRateId: value ? value.value : null,
                                  sharingRateIdError: null,
                                })
                              }
                              edit={
                                edit &&
                                currentUser.roleCode !=
                                MetubRoles.OPTIMIZE_MANAGER
                              }
                              value={sharingRateId ? sharingRateId : ""}
                              allowNull={false}
                              errors={sharingRateIdError}
                            />
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      <tr>
                        <td>
                          <label>Mô tả</label>
                          <TextInput
                            className="sharingRateId"
                            value={description}
                            name={"description"}
                            placeholder={"Mô tả"}
                            displayName={"Mô tả"}
                            multiline={true}
                            onChange={(e) => {
                              this.setState({ description: e.target.value });
                            }}
                            validations={validationData.description}
                            edit={
                              edit &&
                              currentUser.roleCode !=
                              MetubRoles.OPTIMIZE_MANAGER
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="right-box">
                  <table className="table border-none input-form mb10pc">
                    <tbody>
                      <tr>
                        <td>
                          <label>Trạng thái</label>
                          <Select
                            options={ChannelStatusOptions}
                            value={selectedStatus}
                            onChange={(value) =>
                              this.setState({
                                selectedStatus: value,
                              })
                            }
                            edit={
                              currentUser.roleCode === "DATA_MANAGER" ||
                                currentUser.roleCode === "ADMIN" ||
                                (currentUser.roleCode ==
                                  MetubRoles.PARTNER_MANAGER &&
                                  selectedStatus &&
                                  selectedStatus.value ==
                                  ChannelStatus.Denied.value)
                                ? edit
                                : false
                            }
                            allowNull={false}
                          />
                        </td>
                      </tr>
                      {selectedStatus && selectedStatus.value ? (
                        <tr>
                          <td>
                            <label>
                              Ngày{" "}
                              {selectedStatus.label.toString().toLowerCase()}
                            </label>
                            <DatetimePicker
                              className="issuedOn"
                              type="date"
                              placeholder={
                                "Ngày " +
                                selectedStatus.label.toString().toLowerCase()
                              }
                              onChange={(value) =>
                                this.setState({
                                  [selectedStatus.key]: value,
                                  dateError: null,
                                })
                              }
                              value={this.state[selectedStatus.key]}
                              edit={
                                edit &&
                                currentUser.roleCode !=
                                MetubRoles.OPTIMIZE_MANAGER &&
                                currentUser.roleCode !=
                                MetubRoles.PARTNER_MANAGER
                              }
                              errors={dateError}
                            />
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {selectedStatus &&
                        (selectedStatus.value == ChannelStatus.Denied.value || selectedStatus.value == ChannelStatus.Appealing.value || selectedStatus.value == ChannelStatus.InvitationExpired.value) ? (
                        <tr>
                          <td className="text-left width300">
                            <label>Lý do {selectedStatus.label}</label>
                            <TextInput
                              className="deniedReason"
                              value={this.state[selectedStatus.key1]}
                              name={"deniedReason"}
                              placeholder={`Lý do ${selectedStatus.label}`}
                              displayName={`Lý do ${selectedStatus.label}`}
                              multiline={true}
                              onChange={(e) => {
                                this.setState({ [selectedStatus.key1]: e.target.value });
                              }}
                              edit={edit}
                            />
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      <tr>
                        <td>
                          <label>Partner ID</label>
                          <TextInput
                            className="partnerId"
                            value={partnerId}
                            name={"partnerId"}
                            placeholder={"ID đối tác"}
                            displayName={"ID đối tác"}
                            onChange={(e) => {
                              this.setState({ partnerId: e.target.value });
                            }}
                            edit={
                              currentUser.roleCode === MetubRoles.ADMIN ||
                                currentUser.roleCode === MetubRoles.DATA_MANAGER
                                ? edit
                                : false
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Email quản lý</label>
                          <SelectOption
                            optionType={OptionType.ManagerEmail.value}
                            onChange={(value) =>
                              this.setState({
                                managedByEmailId: value ? value.value : null,
                                managedByEmailIdError: null,
                              })
                            }
                            // edit={edit && currentUser.roleCode != MetubRoles.OPTIMIZE_MANAGER}
                            edit={edit}
                            value={managedByEmailId}
                            allowNull={false}
                            errors={managedByEmailIdError}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Người tối ưu</label>
                          <UserSelectList
                            edit={
                              currentUser.roleCode === "DATA_MANAGER" ||
                                currentUser.roleCode === "ADMIN" ||
                                currentUser.roleCode === "OPTIMIZE_MANAGER"
                                ? edit
                                : false
                            }
                            value={optimizerId}
                            onChange={(value) =>
                              this.setState({
                                optimizerId: value ? value.id : null,
                                optimizerIdErrors: null,
                              })
                            }
                            errors={optimizerIdErrors}
                            outLine
                            filter={(users) => {
                              return users.filter(
                                (item) =>
                                  item.roleCode == MetubRoles.OPTIMIZE ||
                                  item.roleCode == MetubRoles.OPTIMIZE_MANAGER
                              );
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="coin text-left width300">
                          <label>Tier</label>
                          <Select
                            options={optionTier}
                            onChange={(value) =>
                              this.setState({ selectedTier: value })
                            }
                            value={selectedTier}
                            // edit={(verifyStatus === "Accepted" && (currentUser.roleCode === "DATA_MANAGER" || currentUser.roleCode === "ADMIN")) ? edit : false}
                            edit={
                              currentUser.roleCode ===
                                MetubRoles.DATA_MANAGER ||
                                currentUser.roleCode === MetubRoles.ADMIN ||
                                currentUser.roleCode ===
                                MetubRoles.PARTNER_MANAGER ||
                                currentUser.roleCode ===
                                MetubRoles.LEADER_PARTNER_MANAGER
                                ? edit
                                : false
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Tags</label>
                          <SelectOption
                            optionType={OptionType.Tag.value}
                            onChange={(value) =>
                              this.setState({
                                tagIds: value ? value.value : null,
                              })
                            }
                            edit={
                              edit &&
                              currentUser.roleCode !=
                              MetubRoles.OPTIMIZE_MANAGER
                            }
                            value={tagIds}
                            allowNull={true}
                            isMulti={true}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <label>Ghi chú</label>
                          <TextInput
                            className="notes"
                            value={notes}
                            name={"notes"}
                            placeholder={"Ghi chú"}
                            displayName={"Ghi chú"}
                            multiline={true}
                            onChange={(e) => {
                              this.setState({ notes: e.target.value });
                            }}
                            edit={
                              edit &&
                              currentUser.roleCode !=
                              MetubRoles.OPTIMIZE_MANAGER
                            }
                            minHeight={"200px"}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </StickyContainer>
      </React.Fragment>
    );
  }
}

export default Index;
