import React, { Component } from "react";
import { get } from "../../api";
import { Button, FormControlLabel, Checkbox } from "@material-ui/core";
import { Mounths } from "../../constants/constants";
import Select from "../common/select";
import { objToArray, objToQuery } from "../../utils/common";
import moment from "moment";
import Modal from "../common/modal";
import { BASE_API } from "../../constants/appSettings";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countStatusChannel: 0,
      justThisMounth: true,
      fromMounth: null,
      fromYear: null,
      toMounth: null,
      toYear: null,
      openExportModal: false,
    };
    this.yearOptions = [
      {
        value: moment().year(),
        label: moment().year().toString(),
      },
    ];
    for (var i = 1; i < 20; i++) {
      let thisYear = moment().year();
      this.yearOptions.push({
        value: thisYear - i,
        label: (thisYear - i).toString(),
      });
      this.yearOptions.push({
        value: thisYear + i,
        label: (thisYear + i).toString(),
      });
    }
    this.yearOptions.sort(function (a, b) {
      return a["value"] - b["value"];
    });
  }

  componentWillReceiveProps(nextprops) {
    if (
      Object.entries(this.props).toString() ==
      Object.entries(nextprops).toString()
    )
      return;
    this._getStatusChannels(nextprops.recentYear);
  }
  _getStatusChannels(recentYear) {
    if (!recentYear) return;
    get("channels/count/" + recentYear, (result) => {
      this.setState({
        countStatusChannel: result[0],
      });
    });
  }

  _exportList() {
    let { fromMounth, fromYear, toMounth, toYear, justThisMounth } = this.state;
    let param = {
      fromTime: null,
      toTime: null,
    };
    if (justThisMounth == true) {
      param.fromTime = moment().format("MM/YYYY");
      param.toTime = moment().format("MM/YYYY");
    } else {
      param.fromTime =
        fromMounth && fromYear ? fromMounth.value + "/" + fromYear.value : "";
      param.toTime =
        toMounth && toYear ? toMounth.value + "/" + toYear.value : "";
    }
    let queryParam = objToQuery(param);
    get("channels/export-statistic" + queryParam, (result) => {
      window.open(BASE_API + result.relativePath);
    });
  }

  render() {
    var {
      countStatusChannel,
      justThisMounth,
      fromMounth,
      fromYear,
      toMounth,
      toYear,
      openExportModal,
    } = this.state;

    const MounthOptions = objToArray(Mounths);

    console.log("countStatusChannel", countStatusChannel);

    return (
      <React.Fragment>
        <div className=" width100pc">
          <Button
            color={"primary"}
            onClick={() =>
              this.setState({
                openExportModal: true,
              })
            }
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
            }}
          >
            Export
          </Button>

          <div className="info reward-info">
            <div className="">
              <span className="title">
                {countStatusChannel && countStatusChannel.totalSubmitChannel
                  ? countStatusChannel.totalSubmitChannel
                  : "0"}
              </span>
              <span className="">Submit Channel</span>
            </div>
            <div className="">
              <span className="title">
                {countStatusChannel && countStatusChannel.totalInvitedChannel
                  ? countStatusChannel.totalInvitedChannel
                  : "0"}
              </span>
              <span className="">Invite Channel</span>
            </div>
            <div className="">
              <span className="title">
                {countStatusChannel && countStatusChannel.totalAcceptedChannel
                  ? countStatusChannel.totalAcceptedChannel
                  : "0"}
              </span>
              <span className="">Accepted Channel</span>
            </div>
            <div className="">
              <span className="title">
                {countStatusChannel && countStatusChannel.totalDeniedChannel
                  ? countStatusChannel.totalDeniedChannel
                  : "0"}
              </span>
              <span className="">Denied Channel</span>
            </div>
            <div className="">
              <span className="title">
                {countStatusChannel && countStatusChannel.totalUnlink1Channel
                  ? countStatusChannel.totalUnlink1Channel
                  : "0"}
              </span>
              <span className="">Unlink 1</span>
            </div>
            <div className="">
              <span className="title">
                {countStatusChannel && countStatusChannel.totalUnlink2Channel
                  ? countStatusChannel.totalUnlink2Channel
                  : "0"}
              </span>
              <span className="">Unlink 2</span>
            </div>
          </div>
          <Modal
            className="export-modal"
            open={openExportModal}
            onClose={() => this.setState({ openExportModal: false })}
            fullWidth={true}
            fullScreen={false}
            header={
              <div>
                <span>XUẤT DANH SÁCH TRẠNG THÁI KÊNH</span>
              </div>
            }
            content={
              <div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={justThisMounth}
                        onChange={() =>
                          this.setState({ justThisMounth: !justThisMounth })
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={<span>Thống kê trong tháng này</span>}
                  />
                </div>
                <table
                  className={
                    "border-none table width100pc mb20 " +
                    (justThisMounth ? "disable" : "")
                  }
                >
                  <tbody>
                    <tr>
                      <td>
                        <div className="mounth-select">
                          <span className="pr20">Từ</span>
                          <div className="box-select">
                            <Select
                              options={MounthOptions}
                              edit={true}
                              onChange={(value) =>
                                this.setState({
                                  fromMounth: value ? value : null,
                                })
                              }
                              value={fromMounth}
                              allowNull={true}
                              placeholder="Tháng"
                            />
                            <Select
                              options={this.yearOptions}
                              edit={true}
                              onChange={(value) =>
                                this.setState({
                                  fromYear: value ? value : null,
                                })
                              }
                              value={fromYear}
                              allowNull={true}
                              placeholder="Năm"
                            />
                          </div>
                          <span className="pr20 pl20">Đến</span>
                          <div className="box-select">
                            <Select
                              options={MounthOptions}
                              edit={true}
                              onChange={(value) =>
                                this.setState({
                                  toMounth: value ? value : null,
                                })
                              }
                              value={toMounth}
                              allowNull={true}
                              placeholder="Tháng"
                            />
                            <Select
                              options={this.yearOptions}
                              edit={true}
                              onChange={(value) =>
                                this.setState({ toYear: value ? value : null })
                              }
                              value={toYear}
                              allowNull={true}
                              placeholder="Năm"
                            />
                          </div>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                {justThisMounth ? (
                  <p>
                    Danh sách sau khi export chỉ thống kê thông tin trạng thái
                    kênh trong tháng {moment().format("MM/YYYY")} (tháng hiện
                    tại). Mọi thống ke trong những tháng trước đó sẽ không xuất
                    hiện trong file đã export.
                  </p>
                ) : (
                  <p>
                    Danh sách sau khi export sẽ thống kê thông tin trạng thái
                    kênh ở những tháng mà bạn đã chọn.
                  </p>
                )}
              </div>
            }
            action={
              <Button color="primary" onClick={() => this._exportList()}>
                Export
              </Button>
            }
            allowCancel={true}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Index;
