import React from "react";
import Modal from '../../../common/modal'
import Button from "@material-ui/core/Button";
import TextInput from '../../../common/text-input'
import SelectQuotations from '../../../common/select-fanpage-quotation'
import { post } from '../../../../api'
import { notEmpty, justNumber, isValid } from "../../../../utils/validators";
import { showSuccess, showError } from "../../../../utils/app";
import {  getDate } from "../../../../utils/common";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            price: null,
            selectedQuotation: null
        }
        this.validationData = {
            price: [justNumber, notEmpty]
        };
    }


    _onAddQuotation = () => {
        const {
            id,
            selectedQuotation,
            price
        } = this.state;
        const {
            onAddSucceed,
            onClose
        } = this.props
        if (selectedQuotation) {
            let param = {
                quotationId: selectedQuotation.value,
                fanpageId: id,
                price: price
            }
            if (isValid(this.validationData))
                post("fanpage-quotations", param, () => {
                    if (onClose)
                        onClose()
                    if (onAddSucceed)
                        onAddSucceed()
                    this.setState({
                        id: null,
                        price: null,
                        selectedQuotation: null
                    },
                        () => {
                            showSuccess("Thêm báo giá thành công")
                        })
                });
        }
        else {
            showError("Dữ liệu không hợp lệ, vui lòng kiểm tra lại")
        }
    }

    componentWillReceiveProps(props) {
        let { id } = props
        if (!id) return
        this.setState({
            id: id,
            paymentDate: getDate()
        })
    }
    componentDidMount() {
        let { id } = this.props
        if (!id) return
        this.setState({
            id: id,
            paymentDate: getDate()
        })
    }
    render() {
        let {
            id,
            price,
            selectedQuotation
        } = this.state
        let {
            open,
            onClose
        } = this.props
        return (
            <Modal
                className="add-new-options"
                open={open}
                onClose={onClose}
                fullWidth={true}
                header={
                    <div>
                        <span>THÊM FANPAGE</span>
                    </div>
                }
                content={
                    <table className="table list-item border-bottom">
                        <tbody>
                            <tr>
                                <td className="coin text-left width100">
                                    <span>Loại báo giá</span>
                                </td>
                                <td className="coin text-left width300">
                                    <SelectQuotations id={id}
                                        onChange={(value) => this.setState({ selectedQuotation: value })}
                                        value={selectedQuotation}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left">
                                    <span>Giá</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="description"
                                        value={price}
                                        label={"Giá"}
                                        name={"price"}
                                        placeholder={"Kinh phí"}
                                        onChange={e =>
                                            this.setState({ price: e.target.value })
                                        }
                                        displayName={"Kinh phí"}
                                        validations={this.validationData.price}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                action={
                    <Button
                        color="primary"
                        onClick={this._onAddQuotation}
                    >
                        Thêm
                    </Button>
                }
                allowCancel={true}
            />
        )
    }
}


export default Index;

