import React from "react";
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Tooltip,
} from '@material-ui/core'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment';
import { UploadFileType } from '../../../constants/constants'
import FileDropZone from '../../common/file-drop-zone'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    lightTooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 1)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    }
});

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: true
        };
    }

    componentDidMount() {
        let { logItem } = this.props
        this.setState({
            expanded: !logItem.isNew
        })
    }

    render() {
        let {
            expanded
        } = this.state
        let {
            logItem
        } = this.props

        return (
            <ExpansionPanel
                expanded={expanded}
                className={"log-item " + (logItem.isNew ? " new" : " update")}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon onClick={() => this.setState({ expanded: !expanded })} />}>
                    <ul className={"item-header width100pc"}>
                        <li className="width40pc">
                            <span>{logItem.title}</span>
                        </li>
                        <li className="width30pc">
                            <span>{logItem.modifiedBy.split("-")[0]}</span>
                            <Tooltip title={
                                <div style={{ padding: "20px" }}>
                                    <p style={{ fontSize: "15px" }}>{logItem.modifiedBy.split("-")[0]}</p>
                                    <p style={{ fontSize: "12px", display: "block", paddingTop: "10px" }}>{logItem.modifiedBy.split("-")[1]}</p>
                                    <p style={{ fontSize: "12px" }}>{logItem.modifiedBy.split("-")[2]}</p>
                                </div>
                            }
                            >
                                <i className="far fa-question-circle"></i>
                            </Tooltip>
                        </li>
                        <li className="width20pc">
                            <span>{moment(logItem.createTime).format("DD/MM/YYYY  hh:mm")}</span>
                        </li>
                        <li className="width10pc">
                        </li>
                    </ul>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className="log-detail">
                        <div><label>Nội dung cập nhật</label></div>
                        {
                            !logItem.isNew ? <div>
                                {
                                    logItem.modificationLogItems.map((item, i) => <div key={i}>
                                        <span className="field-name">{item.fieldName}</span>
                                        <span className="update-text ">
                                            {
                                                item.type === "Text" && item.previous
                                            }
                                            {
                                                item.type === "DateTime" && (item.previous ? moment(item.previous).format("DD/MM/YYYY") : '-/-')
                                            }
                                            {
                                                item.type === "File" && <FileDropZone
                                                    type={UploadFileType.Avatars.value}
                                                    edit={false}
                                                    multiImages={true}
                                                    files={[item.previous]}
                                                />
                                            }
                                        </span>
                                        <KeyboardArrowRightIcon />
                                        <span className="update-text">
                                            {
                                                item.type === "Text" && item.current
                                            }
                                            {
                                                item.type === "DateTime" && (item.current ? moment(item.current).format("DD/MM/YYYY") : '-/-')
                                            }
                                            {
                                                item.type === "File" && <FileDropZone
                                                    type={UploadFileType.Avatars.value}
                                                    edit={false}
                                                    multiImages={true}
                                                    files={[item.current]}
                                                />
                                            }
                                        </span>
                                    </div>)
                                }
                            </div> : <div>
                                    <table className="table border-bottom">
                                        <tbody>
                                            {
                                                logItem.modificationLogItems.map((item, i) => <tr key={i}>
                                                    <td className="text-left ">{item.fieldName}</td>
                                                    <td className="text-left p10">
                                                        {
                                                            item.type === "Text" && item.current
                                                        }
                                                        {
                                                            item.type === "DateTime" && (item.current ? moment(item.current).format("DD/MM/YYYY") : '-/-')
                                                        }
                                                        {
                                                            item.type === "File" && <FileDropZone
                                                                type={UploadFileType.Avatars.value}
                                                                edit={false}
                                                                multiImages={true}
                                                                files={[item.current]}
                                                            />
                                                        }
                                                    </td>
                                                </tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        }
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
