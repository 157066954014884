import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import red from "@material-ui/core/colors/red";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ToolTip from "@material-ui/core/Tooltip";
import { get } from "../../api";
import moment from "moment";
import { BASE_API } from "../../constants/appSettings";

const styles = (theme) => ({
  card: {
    maxWidth: 400,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
});

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      openEditChannel: false,
      thumbnail: "",
    };
  }

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  _onUpdateSuccess() {
    this.props.onUpdateSuccess();
    this.setState({
      openEditChannel: false,
    });
  }

  _getVideoInfo() {
    let { info } = this.props;
    if (info) {
      get(
        "youtube/videos/" + info.youtubeId,
        (result) => {
          this.setState({
            thumbnail: result.snippet.thumbnails.high.url,
            isVideoInfoLoading: false,
          });
        },
        () => {
          this.setState({ isVideoInfoLoading: false });
        }
      );
    }
  }

  componentWillReceiveProps() {
    this._getVideoInfo();
  }

  componentDidMount() {
    this._getVideoInfo();
  }

  render() {
    const { classes, info } = this.props;
    const { thumbnail } = this.state;

    return (
      <div>
        {info && (
          <Card className={classes.card + " custom-video-card"}>
            <CardMedia
              className={classes.media}
              image={thumbnail}
              title={info.name.substr(0, 50) + "..."}
            />
            <CardHeader
              title={info.name.substr(0, 60) + "..."}
              subheader={
                "Lượt xem: " +
                info.viewCount +
                " - Ngày đăng: " +
                moment(info.createTime).format("DD/MM/YYYY")
              }
              className="card-header"
            />
            <CardActions className={classes.actions} disableActionSpacing>
              <ToolTip title="Chỉnh sửa kênh" placement="top">
                <IconButton
                  onClick={() => this.setState({ openEditChannel: true })}
                >
                  <EditIcon />
                </IconButton>
              </ToolTip>
              <ToolTip title="Xoá kênh" placement="top">
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </ToolTip>
              <ToolTip title="Chi tiết" placement="top">
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: this.state.expanded,
                  })}
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  aria-label="Show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </ToolTip>
            </CardActions>
            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
              <CardContent className="more-info">
                <table className="table border-none">
                  <tbody>
                    <tr>
                      <td className="width150 vertical-top">YouTube ID</td>
                      <td className="vertical-top">{info.youtubeId}</td>
                    </tr>
                    <tr>
                      <td className="vertical-top">Tỷ lệ chia sẻ</td>
                      <td className="vertical-top">{info.sharingRate.name}</td>
                    </tr>
                  </tbody>
                </table>
                {info.optimizer && (
                  <div>
                    <label>Người tối ưu</label>
                    <div className="optimizer">
                      <div className="left">
                        <div
                          className="avatar"
                          style={{
                            background:
                              "url(" +
                              (info.optimizer.avatar
                                ? BASE_API + info.optimizer.avatar
                                : "https://png.pngtree.com/svg/20161027/service_default_avatar_182956.png") +
                              ")",
                          }}
                        ></div>
                      </div>
                      <div className="right">
                        <span className="user-info">
                          <span className="name">
                            {info.optimizer.fullName
                              ? info.optimizer.fullName
                              : ""}
                          </span>
                          <span className="email">
                            {info.optimizer.roleName
                              ? info.optimizer.roleName
                              : ""}
                          </span>
                        </span>
                        <span className="role">
                          {info.optimizer.email ? info.optimizer.email : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {info.notes && info.notes.length > 0 && (
                  <div>
                    <label>Ghi chú</label>
                    <pre>{info.notes}</pre>
                  </div>
                )}
              </CardContent>
            </Collapse>
          </Card>
        )}
      </div>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
