
import React from "react";
import TextInput from "../../common/text-input";
import { notEmpty, emailAddress, justNumber, isValidAsync } from "../../../utils/validators";
import { objToQuery } from "../../../utils/common";
import { PartnerType, DefaultUserAvatar, InviteStatus } from '../../../constants/constants'
import { post, getAsync } from "../../../api";
import { showSuccess, showError } from '../../../utils/app'
import {
    RadioGroup,
    FormControlLabel,
    Radio
} from '@material-ui/core'

const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: PartnerType.Company.value,
            companyPhone: "",
            companyEmail: "",
            emailcc: "",
            companyEmailErrors: "",
            taxCode: "",
            companyName: "",
            companyAddress: "",
            tradingAddress: "",
            representativeName: "",
            representativePosition: "",
            exitedPartner: null,
            gender: "Male",
            emailccErrors: ""
        }
        this.validationData = {
            companyAddress: [notEmpty],
            tradingAddress: [notEmpty],
            companyPhone: [notEmpty, justNumber],
            companyEmail: [notEmpty, emailAddress, this._handlePartnerExit],
            taxCode: [notEmpty, this._handlePartnerExit],
            companyName: [notEmpty],
            representativeName: [notEmpty],
            representativePosition: [notEmpty],
        }
    }



    _handleReset() {
        this.setState({
            type: PartnerType.Company.value,
            companyPhone: "",
            companyEmail: "",
            emailcc: "",
            stageName: "",
            dateOfBirth: "",
            hometown: "",
            identityNumber: "",
            issuedOn: "",
            issuedBy: "",
            currentAddress: "",
            idImageFrontSide: "",
            idImageBackSide: "",
            taxCode: "",
            companyName: "",
            companyAddress: "",
            tradingAddress: "",
            representativeName: "",
            representativePosition: "",
            exitedPartner: null,
            companyEmailErrors: null,
            gender: "Male"
        })
    }

    _handlePartnerExit = async (displayName, value) => {
        if (!value)
            return "";
        this.setState({
            exitedPartner: null,
            companyEmailErrors: null,
            exitedPartnerByEmail: null
        })

        let { taxCode, companyEmail } = this.state
        let param = {
            search: "",
            identityNumber: "",
            TaxCode: taxCode,
            email: companyEmail,
            skip: 0,
            take: 1
        };

        if (param.TaxCode == '' || !param.TaxCode) delete param.TaxCode
        if (param.email == '' || !param.email) delete param.email

        var query = objToQuery(param);
        var repsonse = await getAsync("partners" + query, null, null, true);
        var result = await repsonse.json();

        if (result.total > 0) {
            result.items = result.items.filter(item => item.verifyStatus != InviteStatus.Denied.value && item.verifyStatus != InviteStatus.Discontinued.value)
            this.setState({
                exitedPartner: param.TaxCode ? result.items[0] : null,
                exitedPartnerByEmail: param.email ? result.items[0] : null,
            })
            return ""
        }

        return ""
    }

    async _handleAddCompany() {
        const {
            fullName,
            companyPhone,
            companyEmail,
            emailcc,
            stageName,
            dateOfBirth,
            hometown,
            identityNumber,
            issuedOn,
            issuedBy,
            currentAddress,
            idImageFrontSide,
            idImageBackSide,
            taxCode,
            companyName,
            companyAddress,
            tradingAddress,
            representativeName,
            representativePosition,
            exitedPartner,
            exitedPartnerByEmail,
            gender
        } = this.state
        let param = {
            fullName: fullName,
            type: PartnerType.Company.value,
            phone: companyPhone,
            email: companyEmail,
            emailCCs: emailcc && emailcc.length > 0 ? emailcc.split('\n') : [],
            stageName: stageName,
            dateOfBirth: dateOfBirth,
            hometown: hometown,
            identityNumber: identityNumber,
            issuedOn: issuedOn,
            issuedBy: issuedBy,
            currentAddress: currentAddress,
            idImageFrontSide: idImageFrontSide,
            idImageBackSide: idImageBackSide,
            taxCode: taxCode,
            companyName: companyName,
            companyAddress: companyAddress,
            tradingAddress: tradingAddress,
            representativeName: representativeName,
            representativePosition: representativePosition,
            gender: gender
        }

        let valid = true;

        if (!await isValidAsync(this.validationData)) {
            valid = false
        }

        param.emailCCs.map(item => {
            if (item && !emailPattern.test(item.trim())) {
                valid = false
                this.setState({
                    emailccErrors: "Email cc không đúng định dạng."
                })
            }
        })

        if (exitedPartner) {
            valid = false
            this.setState({
                taxCodeError: "MST đối tác đã tồn tại."
            })
        }
        if (exitedPartnerByEmail) {
            valid = false
            this.setState({
                companyEmailErrors: "Email đối tác đã tồn tại."
            })
        }
        if (valid) {
            post("partners", param, (result) => {
                showSuccess("Thêm đối tác thành công!")
                this._handleReset();
                this.props.onAdd();
                let info = {
                    fullName: companyName,
                    phone: companyPhone,
                    email: companyEmail,
                    emailcc: emailcc,
                    address: companyAddress,
                    position: "",
                    isDefault: true,
                    partnerId: result.id
                }
                this._addContact(info)
            }, error => {
                showError(error)
            }
            )
        }
        else {
            showError("Nội dung không hợp lệ, vui lòng kiểm tra lại thông tin vừa nhập.")
        }
    }

    _addContact(info) {
        post("contacts", info, () => {
        })
    }

    render() {

        let {
            companyPhone,
            companyEmail,
            taxCode,
            companyName,
            companyAddress,
            tradingAddress,
            representativeName,
            representativePosition,
            exitedPartner,
            companyEmailErrors,
            taxCodeError,
            exitedPartnerByEmail,
            gender,
            emailcc,
            emailccErrors
        } = this.state

        let validationData = this.validationData


        return (
            <table className="table list-item border-none input-form">
                <tbody>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Mã số thuế</label>
                            <TextInput
                                className="taxCode"
                                value={taxCode}
                                name={"taxCode"}
                                placeholder={"Nhập mã số thuế"}
                                displayName={"Mã số thuế"}
                                onChange={e => {
                                    this.setState({ taxCode: e.target.value })
                                }}
                                validations={validationData.taxCode}
                                edit={true}
                                errors={taxCodeError}
                            />
                            {
                                exitedPartner && <div className="exited-partner">
                                    <span style={{ color: "#ef4502" }}>Mã số thuế của đối tác đã tồn tại.</span>
                                    <label>Thông tin đối tác đã tồn tại:</label>
                                    <table className="table border-none">
                                        <tbody>
                                            <tr>
                                                <td className="width50">
                                                    <img alt="" src={DefaultUserAvatar} style={{ width: "100%" }} />
                                                </td>
                                                <td>
                                                    <table className="table border-none">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        exitedPartner.companyName
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        exitedPartner.email + " | ID: " + exitedPartner.id
                                                                    }
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Tên công ty</label>

                            <TextInput
                                className="companyName"
                                value={companyName}
                                name={"companyName"}
                                placeholder={"Tên công ty"}
                                displayName={"Tên công ty"}
                                onChange={e => {
                                    this.setState({ companyName: e.target.value })
                                }}
                                validations={validationData.companyName}
                                edit={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Số điện thoại</label>

                            <TextInput
                                className="companyPhone"
                                value={companyPhone}
                                name={"companyPhone"}
                                placeholder={"Nhập sdt"}
                                displayName={"Số điện thoại"}
                                onChange={e => {
                                    this.setState({ companyPhone: e.target.value })
                                }}
                                validations={validationData.companyPhone}
                                edit={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Email</label>

                            <TextInput
                                className="companyEmail"
                                value={companyEmail}
                                name={"companyEmail"}
                                displayName={"Email"}
                                placeholder={"Nhập email"}
                                onChange={e => {
                                    this.setState({ companyEmail: e.target.value })
                                }}
                                validations={validationData.companyEmail}
                                edit={true}
                                errors={companyEmailErrors}

                            />
                            {
                                exitedPartnerByEmail && <div className="exited-partner">
                                    <span style={{ color: "#ef4502" }}>Email của đối tác đã tồn tại.</span>
                                    <label>Thông tin đối tác đã tồn tại:</label>
                                    <table className="table border-none">
                                        <tbody>
                                            <tr>
                                                <td className="width50">
                                                    <img alt="" src={DefaultUserAvatar} style={{ width: "100%" }} />
                                                </td>
                                                <td>
                                                    <table className="table border-none">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        exitedPartnerByEmail.companyName
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        exitedPartnerByEmail.email + " | ID: " + exitedPartnerByEmail.id
                                                                    }
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Email CC</label>
                            <p style={{ opacity: 0.5 }}>Vui lòng nhập đúng định dạng email. Ví dụ: abc@gmail.com</p>
                            <p style={{ opacity: 0.5 }}>Mỗi email một dòng.</p>
                            <TextInput
                                className="emailcc"
                                value={emailcc}
                                name={"emailcc"}
                                displayName={"Email CC"}
                                placeholder={"Email CC"}
                                multiline={true}
                                onChange={e => {
                                    this.setState({ emailcc: e.target.value, emailccErrors: "" });
                                }}
                                edit={true}
                                errors={emailccErrors}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Địa chỉ giao dịch</label>
                            <TextInput
                                className="tradingAddress"
                                value={tradingAddress}
                                name={"tradingAddress"}
                                displayName={"Địa chỉ giao dịch"}
                                placeholder={"Nhập địa chỉ"}
                                onChange={e => {
                                    this.setState({ tradingAddress: e.target.value })
                                }}
                                validations={validationData.tradingAddress}
                                edit={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Địa chỉ xuất hoá đơn</label>
                            <TextInput
                                className="companyAddress"
                                value={companyAddress}
                                name={"companyAddress"}
                                displayName={"Địa chỉ xuất hoá đơn"}
                                placeholder={"Nhập địa chỉ"}
                                onChange={e => {
                                    this.setState({ companyAddress: e.target.value })
                                }}
                                validations={validationData.companyAddress}
                                edit={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Tên người đại diện</label>
                            <TextInput
                                className="representativeName"
                                value={representativeName}
                                name={"representativeName"}
                                displayName={"Người đại diện"}
                                placeholder={"Người đại diện"}
                                onChange={e => {
                                    this.setState({ representativeName: e.target.value })
                                }}
                                validations={validationData.representativeName}
                                edit={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Giới tính</label>

                            <RadioGroup aria-label="quiz" name="quiz" style={{ display: "flex", flexDirection: "row", border: "1px solid #dee2e6", borderRadius: "4px", padding: "0px 20px" }} value={gender} onChange={e => this.setState({ gender: e.target.value })}>
                                <FormControlLabel value="Male" style={{ marginRight: "40px", marginBottom: "0px" }} control={<Radio color="primary" />} label="Nam" />
                                <FormControlLabel value="Female" style={{ marginRight: "40px", marginBottom: "0px" }} control={<Radio />} label="Nữ" />
                                <FormControlLabel value="Other" style={{ marginRight: "40px", marginBottom: "0px" }} control={<Radio color="default" />} label="Khác" />
                            </RadioGroup>
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Chức vụ</label>
                            <TextInput
                                className="representativePosition"
                                value={representativePosition}
                                name={"representativePosition"}
                                displayName={"Chức vụ"}
                                placeholder={"Chức vụ"}
                                onChange={e => {
                                    this.setState({ representativePosition: e.target.value })
                                }}
                                validations={validationData.representativePosition}
                                edit={true}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

export default Index;



