import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import DataTable from "../common/datatable";
import { get, _delete } from "../../api";
import { UserStatus, TableSizeDefault, UserBranch } from "../../constants/constants";
import Button from "@material-ui/core/Button";
import {
    objToArray,
    objToQuery,
    formatDateTime,
    confirmSubmit
} from "../../utils/common";
import Select from "react-select";

import { isInRole } from "../../auth";
import {
    IconButton,
    Drawer
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { showSuccess } from "../../utils/app";
import { getCurrentUser } from '../../auth';
import { USER_FILTER } from "../../constants/localStorageKeys";
import Info from './update/info'
import Role from './update/role'
import { MetubRoles } from "../../constants/constants";
import moment from 'moment'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: null,
            total: 0,
            userStatusOptions: objToArray(UserStatus),
            selectedUserStatusOption: objToArray(UserStatus)[0],
            selectedUser: null,
            openUpdateUser: false,
            statusSelected: {},
            filter: {
                status: UserStatus.None
            },
            skip: 0,
            take: TableSizeDefault.value,
            search: "",
            currentPage: 1
        };
        this.UpdateInfo = React.createRef();
        this.UpdateRole = React.createRef();
    }

    componentDidMount() {

        let {
            searchParam,
            isResetPage
        } = this.props


        let filter = JSON.parse(window.localStorage.getItem(USER_FILTER))
        let currentPage = filter && filter.currentPage ? filter.currentPage : 1
        if (isResetPage == true) {
            currentPage = 1
        }

        this.setState({
            currentPage: currentPage
        }, () =>
            this.handleSearch(searchParam)
        )
    }

    componentWillReceiveProps(nextProps) {
        let {
            searchParam
        } = nextProps
        if (Object.entries(this.props.searchParam).toString() != Object.entries(searchParam).toString()) {
            let filter = JSON.parse(window.localStorage.getItem(USER_FILTER))
            let currentPage = filter && filter.currentPage ? filter.currentPage : 1
            if (nextProps.isResetPage == true) {
                currentPage = 1
            }

            this.setState({
                currentPage: currentPage
            }, () =>
                this.handleSearch(searchParam)
            )
        }
    }

    handleSearch(props) {
        let {
            selectedUserStatusOption,
            searchKey,
            selectedUserRole,
            fromDate,
            selectedUserBranch
        } = props
        let {
            take,
            currentPage
        } = this.state
        let param = null
        param = {
            status: selectedUserStatusOption,
            search: searchKey ? searchKey : "",
            skip: currentPage - 1 > 0 ? (currentPage - 1) * take : 0,
            take: take,
            roleCode: selectedUserRole,
            createTime: fromDate ? moment(fromDate).format() : null,
            branch: selectedUserBranch
        };



        if (!param.status) delete param.status
        if (!param.roleCode) delete param.roleCode
        if (!param.createTime) delete param.createTime
        if (!param.branch) delete param.branch

        var query = objToQuery(param);
        get("users" + query, result => {
            let currentUser = getCurrentUser()
            result.items = result.items.filter(item => item.id != currentUser.id)
            if (this.props.onGetListSuccess) this.props.onGetListSuccess()
            let filter = JSON.parse(window.localStorage.getItem(USER_FILTER))
            if (filter) {
                filter.currentPage = currentPage
                filter.take = take
                window.localStorage.setItem(USER_FILTER, JSON.stringify(filter))
            }
            this.setState({
                users: result && result.items,
                total: result && result.total
            });
        }, null);
    }

    handleTableChange(params) {
        this.setState(
            {
                skip: params.skip,
                take: params.take,
                currentPage: params.page
            },
            () => {
                this.handleSearch(this.props.searchParam)
            }
        );
    }
    _closeUpdateUser = () => {
        this.setState({
            openUpdateUser: false
        });
    };

    _deleteUser(id) {
        confirmSubmit(
            <label style={{ fontSize: "1.2rem", marginBottom: "10px" }}>Xoá tài khoản</label>,
            <span style={{ fontSize: "1rem" }}>Bạn có chắc chắn muốn xóa tài khoản này???</span>,
            () => {
                if (id) {
                    _delete("users/" + id, "", () => {
                        showSuccess("Xóa tài khoản thành công");
                        this.handleSearch(this.props)
                    })
                }
            }
        );
    }

    _onUpdateUserInfo = () => {
        this.UpdateInfo.current._onUpdateUserInfo()
    }
    _onUpdateUserRole = () => {
        this.UpdateRole.current._onUpdateUserRole()
    }
    _handleUpdateInfo() {
        this._onUpdateUserInfo()
        this._onUpdateUserRole()
    }
    render() {
        const currentUser = getCurrentUser();
        let {
            users,
            total,
            selectedUser,
            openUpdateUser,
            currentPage
        } = this.state;

        return (
            <div className="box-body product-list">
                {/* <div className="filters-form mb15 balance-search date-time">
                    <table className="table list-item border-none">
                        <tbody>
                            <tr>
                                <td className="lable width100">
                                    <label>Tìm kiếm trạng thái</label>
                                </td>

                                <td className="search-input text-left width200">
                                    <div className={"side"}>
                                        <Select
                                            options={userStatusOptions}
                                            value={selectedUserStatusOption}
                                            onChange={value =>
                                                this.setState({
                                                    selectedUserStatusOption: value
                                                })
                                            }
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="search width100">
                                        <Button
                                            color="primary"
                                            type="input"
                                            onClick={() => this.handleSearch()}
                                        >
                                            Tìm kiếm
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}

                <DataTable
                    data={users}
                    total={total}
                    isPaging={true}
                    currentPage={currentPage}
                    renderHeader={() => {
                        return (
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Tên</th>
                                    <th>Email</th>
                                    <th>Chức vụ</th>
                                    <th>Chi nhánh</th>
                                    <th>Ngày tham gia</th>
                                    <th>Trạng thái</th>
                                    <th />
                                    {
                                        (currentUser && currentUser.roleCode === "DATA_MANAGER" || currentUser && currentUser.roleCode === "ADMIN") ? <th /> : ""
                                    }
                                </tr>
                            </thead>
                        );
                    }}
                    renderBody={items => {
                        return (
                            <tbody>
                                {items.map((item, i) => (
                                    <tr key={i}>
                                        <td>#{item.id ? item.id : ""}</td>
                                        <td>{item.fullName ? item.fullName : ""}</td>
                                        <td>{item.email ? item.email : ""}</td>
                                        <td>{item.roleName ? item.roleName : ""}</td>
                                        <td>{UserBranch[item.branch] ? UserBranch[item.branch].label : UserBranch.None.label}</td>
                                        <td>{formatDateTime(item.createTime)}</td>
                                        <td style={{ color: item.status ? UserStatus[item.status].color : "" }}>{item.status ? UserStatus[item.status].value : ""}</td>
                                        {
                                            <td className="width10">
                                                <Tooltip title="Cập nhật" placement="top">
                                                    <IconButton onClick={() => {
                                                        this.setState({
                                                            openUpdateUser: true,
                                                            selectedUser: item
                                                        });
                                                    }}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        }
                                        {(currentUser && currentUser.roleCode === "DATA_MANAGER" || currentUser && currentUser.roleCode === "ADMIN") &&
                                            <td className="width10">
                                                <Tooltip title="Xóa tài khoản này" placement="top">
                                                    <IconButton onClick={() => this._deleteUser(item.id ? item.id : 0)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        );
                    }}
                    onChange={params => this.handleTableChange(params)}
                />
                <Drawer anchor="right" open={openUpdateUser} onClose={() => this.setState({ openUpdateUser: false, openUpdateUser: null })}>
                    <div className="update-user-drawer">
                        <span className="drawer-header">Cập nhật thông tin nhân viên</span>
                        <div className="drawer-content">
                            <Info
                                id={selectedUser ? selectedUser.id : ""}
                                onClose={() => this.setState({ openUpdateUser: false, openUpdateUser: null })}
                                onUpdateSuccess={() => this.handleSearch(this.props.searchParam)}
                                fullName={selectedUser ? selectedUser.fullName : null}
                                branch={selectedUser ? selectedUser.branch : null}
                                ref={this.UpdateInfo}
                            />
                            <Role
                                id={selectedUser && selectedUser.id}
                                onClose={() => this.setState({ openUpdateUser: false, openUpdateUser: null })}
                                onUpdateSuccess={() => this.handleSearch(this.props.searchParam)}
                                roleCode={selectedUser && selectedUser.roleCode}
                                status={selectedUser && selectedUser.status}
                                ref={this.UpdateRole}
                            />
                        </div>
                        <div className="drawer-actions">
                            {
                                !isInRole(MetubRoles.UPDATE_USER) ? <span style={{ color: "#777", maxWidth: "350px" }}>Tài khoản của bạn không đủ quyền để cập nhật thông tin. Vui lòng chuyển đổi tài khoản khác để thực hiện chức năng này.</span> : ""
                            }
                            {
                                isInRole(MetubRoles.UPDATE_USER) ? <span className="cancel-bt" onClick={() => this.setState({ openUpdateUser: false, openUpdateUser: null })}>Huỷ</span> : ""
                            }
                            {
                                isInRole(MetubRoles.UPDATE_USER) ? <span className="search-bt" onClick={() => this._handleUpdateInfo()}>Lưu thông tin</span> : ""
                            }
                        </div>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default Index;
