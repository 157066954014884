import React from "react";
import { put, _delete } from "../../api";
import TextInput from "../common/text-input";
import { notEmpty, justNumber, isValid } from "../../utils/validators";
import { Button, CardHeader, Avatar } from "@material-ui/core";
import SelectOption from "../common/select-option";
import UserSelectList from "../common/user-select-list";

import { showSuccess, showError } from "../../utils/app";
import {
  OptionType,
  DefaultUserAvatar,
  PartnerType,
  MetubRoles,
  VerifyStatus,
} from "../../constants/constants";
import { get } from "../../api/index";
import { jsonFromUrlParams, confirmSubmit } from "../../utils/common";
import { isInRole, getCurrentUser } from "../../auth";
import { objToArray } from "../../utils/common";
import Select from "../common/select";
import { Videocam as VideocamIcon } from "@material-ui/icons";
import { VIDEO_FILTER } from "../../constants/localStorageKeys";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      youtubeId: "",
      name: "",
      notes: "",
      viewCount: 0,
      sharingRateId: 0,
      optimizerId: null,
      thumbnail: "",
      isVideoInfoLoading: false,
      edit: false,
      defaultValue: null,
      partner: null,
      partnerManager: null,
      verifyStatus: null,
      optionVerifyStatus: objToArray(VerifyStatus),
      selectedVerifyStatus: null,
    };
    this.validationData = {
      url: [notEmpty],
      youtubeId: [notEmpty],
      name: [notEmpty],
      viewCount: [notEmpty, justNumber],
    };
  }

  _updateVideo() {
    let {
      url,
      youtubeId,
      name,
      notes,
      viewCount,
      sharingRateId,
      optimizerId,
      thumbnail,
      selectedVerifyStatus,
    } = this.state;

    let param = {
      youtubeId: youtubeId,
      url: url,
      name: name,
      notes: notes,
      viewCount: viewCount,
      sharingRateId: sharingRateId,
      optimizerId: optimizerId,
      thumbnail: thumbnail,
      id: this.props.videoId,
      verifyStatus: selectedVerifyStatus.value,
    };

    let valid = true;

    if (!isValid(this.validationData)) valid = false;

    if (!optimizerId) {
      valid = false;
      this.setState({
        optimizerIdError: "Người tối ưu không được bỏ trống.",
      });
    }

    if (!sharingRateId) {
      valid = false;
      this.setState({
        sharingRateIdError: "Tỷ lệ chia sẻ không được bỏ trống.",
      });
    }

    if (valid)
      put("videos", param, () => {
        showSuccess("Thay đổi thông tin thành công.");
        this.setState({ edit: false });
      });
    else {
      showError(
        "Nội dung không hợp lệ, vui lòng kiểm tra lại thông tin vừa nhập."
      );
    }
  }

  _setDefaultValue() {
    const { videoId } = this.props;
    get("videos/" + videoId, (result) => {
      this.setState({
        url: result.url,
        youtubeId: result.youtubeId,
        name: result.name,
        notes: result.notes,
        viewCount: result.viewCount,
        sharingRateId: result.sharingRate ? result.sharingRate.id : null,
        optimizerId: result.optimizer ? result.optimizer.id : null,
        thumbnail: result.thumbnail,
        defaultValue: result,
        partner: result.partner,
        partnerManager: result.partnerManager,
        selectedVerifyStatus: VerifyStatus[result.verifyStatus],
        // selectedVerifyStatus: result.verifyStatus
      });
    });
  }

  _resetState() {
    this.setState({
      fullName: "",
      phone: "",
      email: "",
      address: "",
      position: "",
      isDefault: false,
    });
  }

  _handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  _getVideoInfoFromYoutube() {
    const { url } = this.state;
    if (url === "") return;

    let temp = jsonFromUrlParams(url.split("?")[1]);
    if (!temp) return;
    this.setState(
      {
        isVideoInfoLoading: true,
      },
      () => {
        let videoId = temp.v;
        get(
          "youtube/videos/" + videoId,
          (result) => {
            this.setState({
              youtubeId: result.id,
              name: result.snippet.title,
              thumbnail: result.snippet.thumbnails.high.url,
              viewCount: result.statistics.viewCount,
              isVideoInfoLoading: false,
            });
          },
          () => {
            this.setState({ isVideoInfoLoading: false });
          }
        );
      }
    );
  }

  _cancelUpdate() {
    confirmSubmit(
      "Huỷ thay đổi.",
      "Bạn có thật sự muốn huỷ thay đổi? Mọi thay đổi sẽ không được áp dụng.",
      () => {
        let { defaultValue } = this.state;
        this.setState({
          ...defaultValue,
          edit: false,
        });
      }
    );
  }

  componentDidMount() {
    this._setDefaultValue();
  }
  _deleteVideo() {
    confirmSubmit(
      <h4>
        <b>Xoá video.</b>
      </h4>,
      <div className="mt10">Bạn có thật sự muốn xoá video này???</div>,
      () => {
        if (this.props.videoId) {
          const idVideoDelete = this.props.videoId;
          _delete("videos/" + idVideoDelete, "", () => {
            showSuccess("Xóa video thành công");
            this.props.history.push("/videos");
          });
        }
      }
    );
  }

  render() {
    const validationData = this.validationData;
    const {
      url,
      youtubeId,
      name,
      notes,
      viewCount,
      sharingRateId,
      optimizerId,
      isVideoInfoLoading,
      thumbnail,
      edit,
      sharingRateIdError,
      optimizerIdError,
      partner,
      partnerManager,
      verifyStatus,
      optionVerifyStatus,
      selectedVerifyStatus,
    } = this.state;

    const currentUser = getCurrentUser();

    return (
      <div className="admin-box">
        <span>
          <VideocamIcon />
          Thông tin video
        </span>
        <div className="modify">
          {(isInRole(MetubRoles.UPDATE_VIDEO, () => {
            return partnerManager && currentUser.id === partnerManager.id;
          }) ||
            currentUser.roleCode === "DATA_MANAGER") &&
            (!edit ? (
              <div className="text-right width100pc">
                <Button
                  color={"primary"}
                  onClick={() => this.setState({ edit: true })}
                >
                  CHỈNH SỬA
                </Button>
              </div>
            ) : (
              <div className="text-right">
                <Button color={"primary"} onClick={() => this._updateVideo()}>
                  LƯU
                </Button>
                <Button onClick={() => this._cancelUpdate()}>HUỶ</Button>
              </div>
            ))}
          {!edit &&
            (isInRole(MetubRoles.DELETE_VIDEO, () => {
              return partnerManager && currentUser.id === partnerManager.id;
            }) ||
              currentUser.roleCode === "DATA_MANAGER") && (
              <td className="text-right width70">
                <Button color={"primary"} onClick={() => this._deleteVideo()}>
                  XOÁ
                </Button>
              </td>
            )}
        </div>
        <div className={"text-left"}>
          <table className="table border-bottom text-left">
            <tbody>
              <tr>
                {partner && (
                  <td>
                    <label>Thông tin đối tác</label>
                    <CardHeader
                      avatar={<Avatar src={DefaultUserAvatar}></Avatar>}
                      title={
                        partner.type === PartnerType.Individual.value ? (
                          <a
                            href
                            onClick={() => {
                              window.localStorage.removeItem(VIDEO_FILTER);
                              this.props.history.push(
                                "/partners/" + partner.id
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {partner.fullName}
                          </a>
                        ) : (
                          <a
                            href
                            onClick={() => {
                              window.localStorage.removeItem(VIDEO_FILTER);
                              this.props.history.push(
                                "/partners/" + partner.id
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {partner.companyName}
                          </a>
                        )
                      }
                      subheader={
                        partner.type === PartnerType.Individual.value
                          ? partner.stageName
                          : ""
                      }
                    />
                  </td>
                )}
              </tr>
              <tr>
                <td className={"border-none"}>
                  <label>Url</label>
                  <a
                    href={this.state.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TextInput
                      className="url"
                      value={url}
                      name={"url"}
                      placeholder={"Nhập đường dẫn kênh"}
                      displayName={"URL"}
                      onChange={(e) => {
                        this.setState({ url: e.target.value });
                      }}
                      validations={validationData.url}
                      edit={edit}
                      onBlur={() => this._getVideoInfoFromYoutube()}
                      disable={isVideoInfoLoading}
                    />
                  </a>
                </td>
              </tr>
              {thumbnail && (
                <tr>
                  <td className="border-none text-center">
                    {thumbnail.length > 0 && (
                      <img
                        alt="img"
                        className="video-thumbnail"
                        style={{ width: "50%" }}
                        src={thumbnail}
                      />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="coloumn-2">
            <div className="left-box">
              <table className="table border-none mb10pc input-form">
                <tbody>
                  <tr>
                    <td>
                      <label>YouTube ID</label>
                      <TextInput
                        className="youtubeId"
                        value={youtubeId}
                        name={"youtubeId"}
                        placeholder={"YouTube ID"}
                        displayName={"YouTube ID"}
                        onChange={(e) => {
                          this.setState({ youtubeId: e.target.value });
                        }}
                        validations={validationData.youtubeId}
                        edit={edit}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Tên video</label>
                      <TextInput
                        className="name"
                        value={name}
                        name={"name"}
                        placeholder={"Tên kênh"}
                        displayName={"Tên kênh"}
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                        }}
                        validations={validationData.name}
                        edit={edit}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Lượt xem</label>
                      <TextInput
                        className="viewCount"
                        value={viewCount}
                        name={"viewCount"}
                        placeholder={"Lượt theo dõi"}
                        displayName={"Lượt theo dõi"}
                        onChange={(e) => {
                          this.setState({ viewCount: e.target.value });
                        }}
                        validations={validationData.viewCount}
                        edit={edit}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Tỷ lệ chia sẻ</label>
                      <SelectOption
                        optionType={OptionType.SharingRate.value}
                        onChange={(value) =>
                          this.setState({
                            sharingRateId: value ? value.value : null,
                            sharingRateIdError: null,
                          })
                        }
                        edit={edit}
                        value={sharingRateId}
                        allowNull={false}
                        errors={sharingRateIdError}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Người tối ưu</label>
                      <UserSelectList
                        edit={
                          currentUser.roleCode === "DATA_MANAGER" ||
                            currentUser.roleCode === "ADMIN" ||
                            currentUser.roleCode === "OPTIMIZE_MANAGER"
                            ? edit
                            : false
                        }
                        value={optimizerId}
                        onChange={(value) =>
                          this.setState({
                            optimizerId: value ? value.id : null,
                            optimizerIdError: null,
                          })
                        }
                        errors={optimizerIdError}
                        outLine
                        filter={(users) => {
                          return users.filter(
                            (item) =>
                              item.roleCode == MetubRoles.OPTIMIZE ||
                              item.roleCode == MetubRoles.OPTIMIZE_MANAGER
                          );
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="right-box">
              <table className="table border-none mb10pc input-form">
                <tbody>
                  <tr>
                    <td className="coin text-left width300 border-none">
                      <label>Trạng thái chờ duyệt</label>
                      <Select
                        options={optionVerifyStatus}
                        onChange={(value) =>
                          this.setState({ selectedVerifyStatus: value })
                        }
                        value={selectedVerifyStatus}
                        edit={
                          currentUser.roleCode === "DATA_MANAGER" ||
                            currentUser.roleCode === "ADMIN"
                            ? edit
                            : false
                        }
                        allowNull={false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="">
                      <label>Ghi chú</label>
                      <TextInput
                        className="notes"
                        value={notes}
                        name={"notes"}
                        placeholder={"Ghi chú"}
                        displayName={"Ghi chú"}
                        multiline={edit}
                        onChange={(e) => {
                          this.setState({ notes: e.target.value });
                        }}
                        edit={edit}
                        minHeight={"200px"}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
