import React from "react";
import ImportModal from '../payment/import'
import { Button } from "@material-ui/core";
import DashboardPartnerManager from './dashboardPartnerManager';
import DashboardDataManager from "./dashboardDataManager";
import DashboardAdminBOD from './dashboardAdminBOD';
import DashboardOptimize from './dashboardOptimize'
import { getCurrentUser } from "../../auth";
import { MetubRoles } from "../../constants/constants";


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openImportModal: false,
        }
    }

    render() {
        const currentUser = getCurrentUser();
        let {
            openImportModal
        } = this.state

        return (
            <div className="dashboard-page">

                <ImportModal
                    open={openImportModal}
                    onClose={() => this.setState({ openImportModal: false })}
                />
                {
                    (currentUser.roleCode === "PARTNER_MANAGER") && <DashboardPartnerManager history={this.props.history} />

                }
                {
                    currentUser.roleCode === "DATA_MANAGER" && <DashboardDataManager history={this.props.history} />
                }
                {
                    (currentUser.roleCode === MetubRoles.ADMIN || currentUser.roleCode === MetubRoles.BOD || currentUser.roleCode === MetubRoles.LEADER_PARTNER_MANAGER) && <DashboardAdminBOD history={this.props.history} />
                }
                {
                    (currentUser.roleCode === MetubRoles.OPTIMIZE || currentUser.roleCode === MetubRoles.OPTIMIZE_MANAGER) && <DashboardOptimize />
                }
            </div>
        );
    }
}

export default Index;
