import React from "react";
import ListAcceptanceRate from "../listAcceptanceRate";
import ListAccepted from "../listAccepted";
import ListStatusChannelInMonth from "../listStatusChannelInMonth";

import { get, put } from "../../../api";
import Typography from "@material-ui/core/Typography";
import { getCurrentUser } from "../../../auth";
import {
  Fab,
  List,
  ListItem,
  ListItemText,
  Badge,
  Paper,
  ClickAwayListener,
} from "@material-ui/core";
import Notifications from "@material-ui/icons/Notifications";
import moment from "moment";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CancelIcon from "@material-ui/icons/Cancel";
import DirectionsIcon from "@material-ui/icons/Directions";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import {
  Today as TodayIcon,
  Equalizer as EqualizerIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  PieChart as PieChartIcon,
  DesktopAccessDisabled as DesktopAccessDisabledIcon,
} from "@material-ui/icons";
import ListChannelMissing from "../listChannelMissing";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staticticPartner: "",
      openPinMenu: false,
      notifications: "",
      recents: [],
      recentSelected: null,
    };
  }

  _getStaticticPartner() {
    get(
      "partners/Statistic-Partner",
      (result) => {
        this.setState({
          staticticPartner: result[0],
        });
      },
      null,
      true
    );
  }
  componentDidMount() {
    this._getStaticticPartner();
    this._getNotification();
    this._handleGetRecent();
  }
  handleOpenPinMenu() {
    this.setState({ openPinMenu: !this.state.openPinMenu });
  }
  handleClosePinMenu = () => {
    this.setState({ openPinMenu: false });
  };
  _getNotification = () => {
    get("notifications/get-all", (result) => {
      this.setState({ notifications: result.items });
    });
  };
  _handleGetRecent() {
    get(
      "recents",
      (result) => {
        const year = moment().year();
        this.setState({
          recents: result.items,
          recentSelected: result.items.find((item) => item.value == year),
        });
      },
      null,
      true
    );
  }
  _isReadNotify(id, referenceId, type) {
    if (id) {
      let params = {
        isRead: true,
        id: id,
      };
      put("notifications", params, () => {
        this._getNotification();
        if (referenceId) {
          if (type == "CreateChannel") {
            this.props.history.push(`/channels/${referenceId}`);
          } else {
            this.props.history.push(`/partners/${referenceId}`);
          }
        }
      });
    }
  }
  _makeIsReadNotify(id) {
    if (id) {
      let params = {
        isRead: true,
        id: id,
      };
      put("notifications", params, () => {
        this._getNotification();
      });
    }
  }

  render() {
    let {
      staticticPartner,
      notifications,
      openPinMenu,
      recents,
      recentSelected,
    } = this.state;
    const currentUser = getCurrentUser();
    return (
      <div className="dashboardPartnerManager partner-management ">
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          className="box-title"
        >
          <div>
            Bảng điều khiển [{" "}
            {currentUser && currentUser.roleName ? currentUser.roleName : ""} ]
            <p>
              Thông tin điều khiển dành cho tài khoản{" "}
              {currentUser && currentUser.roleName ? currentUser.roleName : ""}.
            </p>
          </div>
        </Typography>
        <div className="reward-box">
          <div className="">
            <TodayIcon />
            <span>Thống kê trong năm </span>
            {recents && recents.length > 0 ? (
              <div>
                <Select
                  className="custom-select-list"
                  options={recents}
                  value={recentSelected ? recentSelected : null}
                  onChange={(value) =>
                    this.setState({
                      recentSelected: value,
                    })
                  }
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="reward">
            <img src="https://image.flaticon.com/icons/svg/1170/1170646.svg" />
            <b>Đối tác cần liên hệ: </b>
            <span className="">
              {staticticPartner ? staticticPartner.totalNeedContact : ""}
            </span>
          </div>
          <div className="reward">
            <img src="https://image.flaticon.com/icons/svg/2219/2219490.svg" />
            <b>Đối tác quá hạn: </b>
            <span>
              {staticticPartner ? staticticPartner.totalOutOfContact : ""}
            </span>
          </div>
        </div>
        {/* {
                    <Fab color="primary" aria-label="Add"
                        style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: "1199" }}
                        onClick={() => this.handleOpenPinMenu()}
                    >
                        {
                            notifications && notifications.length > 0
                                ? <Badge color="secondary" badgeContent={notifications.length} ><Notifications /></Badge>
                                : <Notifications />
                        }
                    </Fab>

                } */}
        {/* <div className={"pin-menu" + (openPinMenu ? " active" : "")}>
                    <Paper>
                        <ClickAwayListener onClickAway={this.handleClosePinMenu}>
                            <List className={"request-pending"}>
                                {
                                    notifications && notifications.length > 0 && notifications.map((item, i) =>
                                        <ListItem onClick={() => this._isReadNotify(item.id, item.referenceId, item.type)} key={i} >
                                            <ListItemText

                                                primary={
                                                    <span style={{ fontSize: "15px" }}>
                                                        {item.type === "CreateChannel" ? `Kênh ${item.channelName} vừa được submit vào hệ thống` : `Đối tác ${item.partnerName} vừa được submit vào hệ thống`}
                                                    </span>
                                                }
                                                secondary={
                                                    <span style={{ justifyContent: "space-between", display: "flex" }}>
                                                        <span>Được submit bởi <b>{item.createByName}</b></span>
                                                        <span>{moment(item.displayTime).format("DD/MM/YYYY hh:mm")}</span>
                                                    </span>
                                                }

                                            />
                                            <ListItemSecondaryAction onClick={() => this._makeIsReadNotify(item.id)}>
                                                <IconButton>
                                                    <CancelIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                }
                            </List>
                        </ClickAwayListener>
                    </Paper>
                </div> */}

        <div className="admin-box">
          <span>
            <PieChartIcon />
            Tỉ lệ Acceptance rate
          </span>
          <div>
            {
              <ListAcceptanceRate
                recentYear={recentSelected ? recentSelected.value : null}
              />
            }
          </div>
        </div>
        <div className="admin-box">
          <span>
            <AssignmentTurnedInIcon />
            Số lượng kênh của đối tác đã được chuyển sang trạng thái accepted
            mỗi tháng
          </span>
          <div>
            {
              <ListAccepted
                recentYear={recentSelected ? recentSelected.value : null}
              />
            }
          </div>
        </div>
        <div className="admin-box">
          <span>
            <DesktopAccessDisabledIcon />
            Danh sách các kênh đang còn thiếu thông tin
          </span>
          {recentSelected ? (
            <ListChannelMissing recentYear={recentSelected.value} />
          ) : (
            ""
          )}
        </div>
        <div className="admin-box">
          <span>
            {" "}
            <EqualizerIcon /> Trạng thái kênh trong tháng
          </span>
          <div>
            {
              <ListStatusChannelInMonth
                recentYear={recentSelected ? recentSelected.value : null}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
