import React from "react";
import DataTable from '../common/datatable'
import { objToQuery } from '../../utils/common'
import { get } from '../../api'
import { Tooltip, IconButton, CardHeader } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/Info'
import { PaymentStatus } from '../../constants/constants'
import PaymentDetail from './update'


import moment from "moment";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchParam: {
                skip: 0,
                take: 10
            },
            payments: null,
            total: 0,
            openPaymentDetailModal: false,
            currentPayment: null,
            isEdit: true
        };
    }

    _getPayments(param) {
        if (!param) return
        else {
            this.setState({
                searchParam: param
            })

            let getParam = {
                skip: param.skip,
                take: param.take
            }

            let { partnerId } = this.props

            if (!partnerId) return
            getParam.partnerId = partnerId
            getParam.status = PaymentStatus.None.value
            var query = objToQuery(getParam);

            get("Payenpayments" + query, result => {
                this.setState({
                    payments: result.items,
                    total: result.total
                })
            })
        }
    }

    onUpdateSuccess() {
        let { searchParam } = this.state
        this._getPayments(searchParam)
        this.setState({ openPaymentDetailModal: false })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.isListChange !== nextProps.isListChange) {
            let { searchParam } = this.state;
            this._getPayments(searchParam)
        }
    }

    componentDidMount() {
        let { searchParam } = this.state;
        this._getPayments(searchParam)
    }

    render() {

        let {
            payments,
            total,
            openPaymentDetailModal,
            currentPaymentId,
            isEdit
        } = this.state


        return (
            <div className="payment-list mt20">
                <DataTable
                    data={payments}
                    total={total}
                    isPaging={true}
                    renderHeader={() => {
                        return (
                            <thead>
                                <tr>
                                    <th className="text-center">ID</th>
                                    <th className="text-left">Ngày tạo</th>
                                    <th className="text-left">Khách hàng</th>
                                    <th className="text-left">Chi phí</th>
                                    <th className="text-left">Trạng thái</th>
                                    <th className="text-left">Ghi chú</th>
                                    <th className="text-center width100" />
                                </tr>
                            </thead>
                        );
                    }}
                    renderBody={items => {
                        return (
                            <tbody>
                                {
                                    items && items.map((item, i) => (
                                        <tr key={i}>
                                            <td className='text-center'>#{item.id}</td>
                                            <td className='text-left'>
                                                <span>{moment(item.createTime).format("DD/MM/YYYY hh:mm")}</span>
                                            </td>
                                            <td className='text-left' style={{ fontSize: "15px" }}>
                                                <CardHeader
                                                    className={"p00"}
                                                    title={<span style={{ fontSize: "14px" }}>{item.fullName ? item.fullName : "-/-"}</span>}
                                                    subheader={item.email ? item.email : "-/-"}
                                                />
                                            </td>
                                            <td className="text-left">
                                                <span>$ {item.amount.toFixed(2)}</span>
                                            </td>
                                            <td className="text-left">
                                                <span>{PaymentStatus[item.status].label}</span>
                                            </td>
                                            <td className="text-left"></td>
                                            <td className="width150 text-right">
                                                <Tooltip title="Xem chi tiết" placement="top">
                                                    <IconButton
                                                        onClick={() => this.setState({ currentPaymentId: item.id }, () => {
                                                            this.setState({ openPaymentDetailModal: true, isEdit: false })
                                                        })}
                                                    >
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Cập nhật thông tin" placement="top">
                                                    <IconButton
                                                        onClick={() => this.setState({ currentPaymentId: item.id }, () => {
                                                            this.setState({ openPaymentDetailModal: true, isEdit: true })
                                                        })}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        );
                    }}
                    onChange={params => this._getPayments(params)}
                />
                {
                    currentPaymentId && <PaymentDetail
                        open={openPaymentDetailModal}
                        onClose={() => this.setState({ openPaymentDetailModal: false })}
                        paymentId={currentPaymentId}
                        onUpdateSuccess={() => this.onUpdateSuccess()}
                        edit={isEdit}
                    />
                }
            </div>
        )
    }
}
export default Index;