import React from "react";
import { NavLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import DataTable from "../../common/datatable";
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import { get, put } from "../../../api";
import { objToQuery, confirmSubmit } from "../../../utils/common";
import { TableSizeDefault, PartnerType, MetubRoles } from "../../../constants/constants";
import { isInRole } from '../../../auth'
import { showSuccess } from "../../../utils/app";
import EditIcon from '@material-ui/icons/Edit';
import Notes from "./notes";
import Button from '@material-ui/core/Button';
import {
    Call as CallIcon
} from '@material-ui/icons'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partners: [],
            total: null,
            params: {
                skip: 0,
                take: TableSizeDefault.value
            },
            color: 'default',
            openUpdateNote: false,
            selectedPartner: "",
        };
    }

    componentDidMount() {
        let { params } = this.state;
        this._handleGetPartners(params);
    }


    // handleCheckProps(props, nextProps) {
    //     let output = false
    //     if (Object.entries(props.searchKey).toString() != Object.entries(nextProps.searchKey).toString()) {
    //         output = true
    //     }
    //     if (Object.entries(props.searchKey.selectedVerifyStatus ? props.searchKey.selectedVerifyStatus : {}).toString() != Object.entries(nextProps.searchKey.selectedVerifyStatus ? nextProps.searchKey.selectedVerifyStatus : {}).toString()) {
    //         output = true
    //     }
    //     return output
    // }

    componentWillReceiveProps(nextprops) {

        let { params } = this.state
        if (this.props.searchKey !== nextprops.searchKey) {
            params.searchKey = nextprops.searchKey
            this.setState({
                params
            }, () => {
                this._handleGetPartners(params);
            })
        }
        if (this.props.selectedVerifyStatus !== nextprops.selectedVerifyStatus) {
            params.selectedVerifyStatus = nextprops.selectedVerifyStatus;
            this.setState({ params }, () => this._handleGetPartners(params))
        }
        if (this.props.selectedTypePartner !== nextprops.selectedTypePartner) {
            params.selectedTypePartner = nextprops.selectedTypePartner;
            this.setState({ params }, () => this._handleGetPartners(params))
        }
        if (this.props.selectedTypeDate !== nextprops.selectedTypeDate) {
            params.selectedTypeDate = nextprops.selectedTypeDate;
            this.setState({ params }, () => this._handleGetPartners(params))
        }
    }
    _handleGetPartners(params) {
        this.setState(
            {
                params: params
            },
            () => {
                let param = {
                    Search: this.state.params.searchKey ? this.state.params.searchKey : "",
                    IdentityNumber: "",
                    TaxCode: "",
                    VerifyStatus: this.state.params.selectedVerifyStatus ? this.state.params.selectedVerifyStatus.value : "None",
                    ChooseTypePartner: this.state.params.selectedTypePartner ? this.state.params.selectedTypePartner.value : "None",
                    ChooseTypeDate: this.state.params.selectedTypeDate ? this.state.params.selectedTypeDate.value : "None",
                    Skip: params.skip,
                    Take: params.take
                };
                var query = objToQuery(param);
                get("partners/Partner-To-Contact" + query, result => {
                    this.setState({
                        partners: result.items,
                        total: result.total
                    }, () => this.props.countNeedContact());
                });
            }
        );
    }
    _handleReload() {
        let param = {
            search: "",
            identityNumber: "",
            taxCode: "",
            ChooseTypeDisplay: "Date",
            skip: this.state.params.skip,
            take: this.state.params.take
        };
        var query = objToQuery(param);
        get("partners/Partner-To-Contact" + query, result => {
            this.setState({
                partners: result.items,
                total: result.total
            }, () => this.props.countNeedContact());
        });
    }
    _handleMakeContact(id, ColorContact) {
        let param = {
            ColorContacted: ColorContact == false ? true : false,
            id: id
        };
        put("partners/mark-contacted/", param, () => {
            this._handleReload();
            showSuccess("Thay đổi thông tin thành công");
        });
    }
    _checkDate(item) {
        var dt2 = new Date();
        var dt1 = new Date(item)
        let day = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
        return day;
    }
    _handleCloseUpdateNote = () => {
        this.setState({ openUpdateNote: false });
    };
    _countPartnerNeedContact = () => {
        let partnersContact = this.state.partners;
        if (partnersContact.length > 0) {
            let needContact = partnersContact.filter(partnerContact => (this._checkDate(partnerContact.lastContact) <= 40 && this._checkDate(partnerContact.lastContact) >= 30))
            return needContact.length
        }
        return 0;
    }
    _countPartnerExpire = () => {
        let partnersContact = this.state.partners;
        if (partnersContact.length > 0) {
            let needContact = partnersContact.filter(partnerContact => (this._checkDate(partnerContact.lastContact) > 40))
            return needContact.length
        }
        return 0;
    }

    render() {
        let { partners, total, openUpdateNote, selectedPartner } = this.state;

        return (
            <div className="partner-list">
                <DataTable
                    data={partners}
                    total={total}
                    isPaging={true}
                    renderHeader={() => {
                        return (
                            <thead>
                                <tr>
                                    <th className="text-center">ID</th>
                                    <th className="text-left">Tên</th>
                                    <th className="text-left">Loại</th>
                                    <th className="text-left width100">
                                        <span className="ellipsis">Số CMND | Mã số thuế</span>
                                    </th>
                                    <th className="text-left">Số điện thoại</th>
                                    <th className="text-left">Email</th>
                                    <th className="text-left">Status</th>
                                    <th className="text-left width150" >Lần liên lạc gần nhất</th>
                                    <th className="text-center">Check</th>
                                    <th className="text-left width70">Ghi chú</th>
                                    <th className="text-center" />
                                </tr>
                            </thead>
                        );
                    }}
                    renderBody={items => {
                        return (
                            <tbody>
                                {items.map((item, i) => (
                                    <tr key={i}>
                                        <td style={{ textAlign: "center" }}>#{item.id ? item.id : ""}</td>
                                        <td className="width110 pr00 mr00">
                                            <Tooltip title={item.type && item.type === PartnerType.Individual.value ? (item.fullName ? item.fullName : "") : (item.companyName ? item.companyName : "")} placement="top-start">
                                                <NavLink className="ellipsis" to={isInRole(MetubRoles.VIEW_PARTNER) ? `partners/${item.id ? item.id : ""}` : "#"}>
                                                    {item.type && item.type === PartnerType.Individual.value
                                                        ? (item.fullName ? item.fullName : "")
                                                        : (item.companyName ? item.companyName : "")}
                                                </NavLink>
                                            </Tooltip>
                                        </td>
                                        <td className="width60">
                                            <span className="ellipsis">
                                                {item.type && item.type === PartnerType.Individual.value
                                                    ? PartnerType.Individual.label
                                                    : PartnerType.Company.label}
                                            </span>
                                        </td>
                                        <td className="width50">
                                            {item.type && item.type === PartnerType.Individual.value
                                                ? (item.identityNumber ? item.identityNumber : "")
                                                : (item.taxCode ? item.taxCode : "")}
                                        </td>
                                        <td >{item.phone ? item.phone : ""}</td>
                                        <td className="width100">{item.email ? item.email : ""}</td>
                                        <td className="width60">
                                            <Tooltip title={item.verifyStatus ? item.verifyStatus : ""} placement="top-start">
                                                <span className="ellipsis">
                                                    {item.verifyStatus ? item.verifyStatus : ""}
                                                </span>
                                            </Tooltip>
                                        </td>
                                        {
                                            this._checkDate(item.lastContact) > 45 && item.colorContacted == false
                                                ? <td style={{ backgroundColor: "rgba(220, 53, 69, 0.71)", width: "100px" }}>{this._checkDate(item.lastContact) == 0 ? "Hôm nay" : this._checkDate(item.lastContact) + " ngày trước"}</td>
                                                : (this._checkDate(item.lastContact) > 30 && item.colorContacted == false
                                                    ? <td style={{ backgroundColor: "rgba(255, 193, 7, 0.44)" }}>{this._checkDate(item.lastContact) == 0 ? "Hôm nay" : this._checkDate(item.lastContact) + " ngày trước"}</td>
                                                    : <td>{this._checkDate(item.lastContact) == 0 ? "Hôm nay" : this._checkDate(item.lastContact) + " ngày trước"}</td>)
                                        }

                                        {
                                            item.colorContacted == false
                                                ? <td className="text-center ">
                                                    <Tooltip title="Đánh dấu dã liên lạc" placement="top">
                                                        <CallIcon style={{ cursor: "pointer", opacity: 0.3 }} onClick={() => this._handleMakeContact(item.id, item.colorContacted)} />
                                                    </Tooltip>
                                                </td>
                                                : <td className=" text-center ">
                                                    <Tooltip title="Bỏ đánh dấu đã liên hệ" placement="top">
                                                        <CallIcon style={{ cursor: "pointer", opacity: 1, color: "#70A901" }} onClick={() => this._handleMakeContact(item.id, item.colorContacted)} />
                                                    </Tooltip>
                                                </td>
                                        }
                                        {
                                            <td className="">
                                                <Tooltip title="Cập nhật" placement="top">
                                                    <IconButton>
                                                        <EditIcon onClick={() => {
                                                            this.setState({
                                                                openUpdateNote: true,
                                                                selectedPartner: item
                                                            });
                                                        }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        }
                                        <td className="width5 text-center">
                                            <NavLink to={`partners/${item.id}`}>
                                                <Tooltip title="Xem chi tiết" placement="top">
                                                    <IconButton onClick={() => this._checkDate(item.lastContact)}>
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </NavLink>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        );
                    }}
                    onChange={params => this._handleGetPartners(params)}
                />
                <Notes
                    open={openUpdateNote}
                    onClose={this._handleCloseUpdateNote}
                    id={selectedPartner !== null ? selectedPartner.id : null}
                    onUpdateSucceed={this._handleCloseUpdateNote}
                />

            </div>
        );
    }
}

export default Index;

