import React from "react";
import List from "./list";
import AddNew from "./add-new";
import { Button, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import TextInput from "../common/text-input";
import UserSelectList from "../common/user-select-list";
import { get, put } from "../../api";
import { objToQuery, objToArray, checkRolePM } from "../../utils/common";
import {
  TableSizeDefault,
  ChannelStatus,
  OptionType,
  MetubRoles,
} from "../../constants/constants";
import Select from "../common/select";
import SelectOption from "../common/select-option";
import Modal from "../common/modal";

import { BASE_API } from "../../constants/appSettings";
import { isInRole, getCurrentUser } from "../../auth";
import { showSuccess, showError } from "../../utils/app";
import { Checkbox, Drawer, Typography } from "@material-ui/core";
import {
  BarChart as BarChartIcon,
  FilterList as FilterListIcon,
  Equalizer as EqualizerIcon,
} from "@material-ui/icons";
import { CHANNEL_FILTER } from "../../constants/localStorageKeys";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channels: [],
      total: 0,
      openAddNewDialog: false,
      searchParam: { skip: 0, take: TableSizeDefault.value },
      listType: "list",
      searchKey: "",
      selectedStatus: null,
      managedByEmailId: null,
      showMoreOption: false,
      cmsId: null,
      optimizerId: null,
      selectedSharingRate: null,
      categoryId: null,
      partnerManagerId: null,
      isFilterVerifyStatus: false,
      selected: [],
      openUpdateStatusModal: false,
      selectedStatusForUpdate: null,
      tagIds: [],
      sortType: "None",
      isProccessing: false,
      resetPage: false,
      abc: false,
      canReload: false,
      isSearching: false
    };
    this.isResetPage = false;
  }

  onClose() {
    this.setState({
      openUpdateStatusModal: false,
    });
  }

  _handleUpdateStatus() {
    let { selected, selectedStatusForUpdate } = this.state;
    let param = {
      ids: selected,
      status: selectedStatusForUpdate.value,
    };
    put(
      "channels/convert-status",
      param,
      () => {
        showSuccess("Cập nhật trạng thái thành công.");
        this.setState({
          openUpdateStatusModal: false,
          selectedStatusForUpdate: null,
          selected: [],
        });
        this._handleSearch();
      },
      (error) => {
        this.setState({
          openUpdateStatusModal: false,
          selectedStatusForUpdate: null,
        });
      }
    );
  }

  _handleGetchannels(param) {
    let { isProccessing, searchQuery, isResetPage, canReload } = this.state;
    if (isProccessing == false) {
      let currentUser = getCurrentUser();

      let pathname = window.location.pathname;
      let {
        searchKey,
        selectedStatus,
        partnerManagerId,
        managedByEmailId,
        optimizerId,
        cmsId,
        categoryId,
        selectedSharingRate,
        tagIds,
        sortType,
      } = this.state;
      let statuses = [];

      if (selectedStatus && selectedStatus.length > 0)
        selectedStatus.map((item) => statuses.push(item.value));

      if (!param) return;
      else {
        let currentPage = param.page;
        if (!currentPage) {
          let filter = JSON.parse(window.localStorage.getItem(CHANNEL_FILTER));
          currentPage = filter && filter.currentPage ? filter.currentPage : 1;
        }
        if (isResetPage == true) currentPage = 1;
        this.setState(
          {
            searchParam: param,
            currentPage: currentPage,
          },
          () => {
            let getParam = {
              skip: currentPage - 1 >= 0 ? (currentPage - 1) * param.take : 0,
              take: param.take,
              search: searchKey,
              statuses: statuses,
              partnerManagerId: partnerManagerId ? partnerManagerId : 0,
              managedByEmailId: managedByEmailId ? managedByEmailId : 0,
              verifyStatus: !this.state.isFilterVerifyStatus
                ? currentUser.roleCode === "ADMIN" ||
                  currentUser.roleCode === "DATA_MANAGER" ||
                  currentUser.roleCode === "BOD" ||
                  currentUser.roleCode === "LEADER_PARTNER_MANAGER" ||
                  currentUser.roleCode === "LEGAL_EXECUTIVE" ||
                  currentUser.roleCode === "PARTNER_MANAGER" ||
                  currentUser.roleCode === "ADMIN_OFFICER" ||
                  currentUser.roleCode === "ACCOUNTANT" ||
                  currentUser.roleCode === "CHIEF_ACCOUNTANT" ||
                  currentUser.roleCode === "OPTIMIZE" ||
                  currentUser.roleCode === "OPTIMIZE_MANAGER" ||
                  currentUser.roleCode === "NORMAL_USER"
                  ? "None"
                  : currentUser.roleCode === "CUSTOMER_SP"
                    ? "Pending"
                    : "Accepted"
                : "Pending",
              cmsId: cmsId ? cmsId : 0,
              categoryId: categoryId ? categoryId : 0,
              optimizerId: optimizerId ? optimizerId : 0,
              sharingRateIds: selectedSharingRate
                ? selectedSharingRate.value
                : [],
              tagIds: tagIds ? tagIds.value : [],
              SortOption: sortType,
            };
            var query = objToQuery(getParam);
            if (searchQuery == query && canReload == false) return;
            this.setState({
              isProccessing: true,
            });
            window.localStorage.setItem(
              CHANNEL_FILTER,
              JSON.stringify(this.state)
            );
            if (pathname === "/channels") {
              get(
                "channels" + query,
                (result) => {
                  this.setState({
                    channels: result.items,
                    total: result.total,
                    searchQuery: query,
                    isProccessing: false,
                    isResetPage: false,
                    canReload: false,
                  });
                },
                () => {
                  this.setState({
                    isProccessing: false,
                    isResetPage: false,
                    canReload: false,
                  });
                }
              );
            } else {
              let { partnerId } = this.props;
              if (!partnerId) return;
              getParam.partnerId = partnerId;
              get(
                "partners/" + partnerId + "/channels" + query,
                (result) => {
                  this.setState({
                    channels: result.items,
                    total: result.total,
                    searchQuery: query,
                    isProccessing: false,
                    resetPage: false,
                    canReload: false,
                  });
                },
                () => {
                  this.setState({
                    isProccessing: false,
                    resetPage: false,
                    canReload: false,
                  });
                }
              );
            }
          }
        );
      }
    }
  }



  _handleSearch() {
    let param = this.state.searchParam;
    this._handleGetchannels(param);
    this.setState({
      isSearching: this._handleDetectSearch()
    })
  }
  _onFilterVerifyStatus = async () => {
    let param = this.state.searchParam;
    await this.setState({
      isFilterVerifyStatus: !this.state.isFilterVerifyStatus,
    });
    this._handleGetchannels(param);
  };
  _handleSortTypeChange(type) {
    this.setState(
      {
        sortType: type == this.state.sortType ? "None" : type,
      },
      () => this._handleSearch()
    );
  }

  _exportList() {
    let pathname = window.location.pathname;
    let param = this.state.searchParam;
    let {
      searchKey,
      selectedStatus,
      partnerManagerId,
      managedByEmailId,
      optimizerId,
      cmsId,
      categoryId,
      selectedSharingRate,
      tagIds,
      currentPage,
      sortType,
    } = this.state;
    let { partnerId } = this.props;
    let statuses = [];

    if (selectedStatus && selectedStatus.length > 0)
      selectedStatus.map((item) => statuses.push(item.value));

    let currentUser = getCurrentUser();

    if (!param) return;
    else {
      this.setState({
        searchParam: param,
      });
      const isSearching = this._handleDetectSearch()

      let getParam = {
        isSearch: isSearching,
        skip: currentPage - 1 >= 0 ? (currentPage - 1) * param.take : 0,
        take: param.take,
        search: searchKey,
        statuses: statuses,
        partnerManagerId: partnerManagerId ? partnerManagerId : 0,
        managedByEmailId: managedByEmailId ? managedByEmailId : 0,
        verifyStatus: !this.state.isFilterVerifyStatus
          ? currentUser.roleCode === "ADMIN" ||
            currentUser.roleCode === "DATA_MANAGER" ||
            currentUser.roleCode === "BOD" ||
            currentUser.roleCode === "LEADER_PARTNER_MANAGER" ||
            currentUser.roleCode === "LEGAL_EXECUTIVE" ||
            currentUser.roleCode === "PARTNER_MANAGER" ||
            currentUser.roleCode === "ADMIN_OFFICER" ||
            currentUser.roleCode === "ACCOUNTANT" ||
            currentUser.roleCode === "CHIEF_ACCOUNTANT" ||
            currentUser.roleCode === "OPTIMIZE" ||
            currentUser.roleCode === "OPTIMIZE_MANAGER" ||
            currentUser.roleCode === "NORMAL_USER"
            ? "None"
            : currentUser.roleCode === "CUSTOMER_SP"
              ? "Pending"
              : "Accepted"
          : "Pending",
        cmsId: cmsId ? cmsId : 0,
        categoryId: categoryId ? categoryId : 0,
        optimizerId: optimizerId ? optimizerId : 0,
        sharingRateIds: selectedSharingRate ? selectedSharingRate.value : [],
        tagIds: tagIds ? tagIds.value : [],
        SortOption: sortType,
        partnerId: partnerId,
      };
      if (pathname === "/channels") {
        delete getParam.partnerId;
      }
      var query = objToQuery(getParam);
      get("/channels/export" + query, (result) => {
        window.open(BASE_API + result.relativePath);
      });
    }
  }

  _handleDetectSearch() {
    const {
      searchKey,
      partnerManagerId,
      selectedSharingRate,
      managedByEmailId,
      cmsId,
      optimizerId,
      categoryId,
      selectedStatus,
      tagIds
    } = this.state
    if (window.location.pathname.indexOf("/partners/") >= 0) {
      return true
    }
    if (
      (
        searchKey && searchKey.length > 0)
      || (tagIds && tagIds.value && tagIds.value.length > 0)
      || partnerManagerId > 0
      || (selectedSharingRate && selectedSharingRate.value && selectedSharingRate.value.length > 0)
      || managedByEmailId > 0
      || cmsId > 0
      || optimizerId > 0
      || categoryId > 0
      || (selectedStatus && selectedStatus.length > 0)
    ) {
      return true
    } else {
      return false
    }
  }

  componentWillMount() {
    let filter = JSON.parse(window.localStorage.getItem(CHANNEL_FILTER));
    if (filter)
      this.setState({
        ...filter,
        tagIds: filter.tagIds ? filter.tagIds.value : [],
        showMoreOption: false,
        openAddNewDialog: false,
        openUpdateStatusModal: false,
        isResetPage: false,
      });
  }

  componentDidMount() {
    this._handleSearch();
  }

  render() {
    let {
      channels,
      openAddNewDialog,
      total,
      searchParam,
      searchKey,
      selectedStatus,
      managedByEmailId,
      showMoreOption,
      cmsId,
      optimizerId,
      selectedSharingRate,
      categoryId,
      partnerManagerId,
      selected,
      openUpdateStatusModal,
      selectedStatusForUpdate,
      tagIds,
      sortType,
      currentPage,
      isSearching,
    } = this.state;
    const { partnerId, history, disableHeader, partner } = this.props;
    console.log("partner", partner)
    let ChannelStatusOptions = objToArray(ChannelStatus);
    let ChannelStatusOptionsForUpdate = ChannelStatusOptions.filter(
      (item) => item.value != "None"
    );
    let currentUser = getCurrentUser();

    if (!isInRole(MetubRoles.VIEW_CHANNEL)) return;

    return (
      <div className="contact-page channel">
        {disableHeader == true ? (
          ""
        ) : (
          <Typography
            variant="h6"
            gutterBottom
            align="center"
            className="box-title"
          >
            <div>
              Quản lí kênh
              <p>Quản lí thông tin kênh đang có trong hệ thống.</p>
            </div>
          </Typography>
        )}
        <div className="action">
          <div className="admin-box">
            <div className="channel-reward">
              <span>
                <BarChartIcon />
                Tổng số kênh hiện có: {total ? total : 0}
              </span>
              {
                partner && partner.verifyStatus !== "Discontinued" && <>
                  {(window.location.pathname == "/channels" &&
                    (currentUser.roleCode === MetubRoles.ADMIN ||
                      currentUser.roleCode === MetubRoles.DATA_MANAGER)) ||
                    (window.location.pathname != "/channels" &&
                      isInRole(MetubRoles.CREATE_CHANNEL, () =>
                        checkRolePM(partner, "Channel")
                      )) ? (
                    <Button
                      color={"primary"}
                      onClick={() =>
                        this.setState({ openAddNewDialog: !openAddNewDialog })
                      }
                    >
                      Tạo mới
                    </Button>
                  ) : (
                    ""
                  )}
                </>
              }

            </div>
          </div>
          <div className="admin-box">
            <span>
              <FilterListIcon />
              Bộ lọc dữ liệu
              <IconButton
                onClick={() =>
                  this.setState({ showMoreOption: !showMoreOption })
                }
              >
                <img
                  alt="img"
                  style={{ width: 25, opacity: 0.5 }}
                  src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSItMzMgLTE4MSAxMDY1LjAwMDEgMTA2NSIgd2lkdGg9IjUxMnB4Ij48cGF0aCBkPSJtODI5LjM0NzY1NiA2NS44ODY3MTljLTEyLjc1LTQ0LjU0Mjk2OS01My43OTY4NzUtNzcuMjM4MjgxLTEwMi4zNzEwOTQtNzcuMjM4MjgxLTQ4LjU3NDIxOCAwLTg5LjYyNSAzMi42OTUzMTItMTAyLjM4MjgxMiA3Ny4yMzgyODFoLTYyNi42MTMyODF2NTguNWg2MjYuNjIxMDkzYzEyLjc1IDQ0LjUzNTE1NiA1My44MDA3ODIgNzcuMjQyMTg3IDEwMi4zNzUgNzcuMjQyMTg3IDQ4LjU3NDIxOSAwIDg5LjYyMTA5NC0zMi43MDcwMzEgMTAyLjM3NS03Ny4yNDIxODdoMTY3LjA2NjQwN3YtNTguNXptMCAwIiBmaWxsPSIjMDAwMDAwIi8+PHBhdGggZD0ibTE4NS40ODgyODEgMjMzLjM0Mzc1Yy00OC41NzQyMTkgMC04OS42Mjg5MDYgMzIuNzAzMTI1LTEwMi4zNzEwOTMgNzcuMjM4MjgxaC04NS4xMzY3MTl2NTguNTAzOTA3aDg1LjEzNjcxOWMxMi43NDIxODcgNDQuNTQyOTY4IDUzLjgwMDc4MSA3Ny4yNDIxODcgMTAyLjM3NSA3Ny4yNDIxODcgNDguNTc0MjE4IDAgODkuNjI4OTA2LTMyLjY5OTIxOSAxMDIuMzc1LTc3LjI0MjE4N2g3MDguNTUwNzgxdi01OC41MDM5MDdoLTcwOC41NTA3ODFjLTEyLjc0NjA5NC00NC41MzUxNTYtNTMuODA0Njg4LTc3LjIzODI4MS0xMDIuMzc4OTA3LTc3LjIzODI4MXptMCAwIiBmaWxsPSIjMDAwMDAwIi8+PHBhdGggZD0ibTUxMy42MDE1NjIgNDc4LjA0Njg3NWMtNDguNTc0MjE4IDAtODkuNjM2NzE4IDMyLjY5OTIxOS0xMDIuMzgyODEyIDc3LjI0MjE4N2gtNDEzLjIzODI4MXY1OC41aDQxMy4yMzgyODFjMTIuNzQ2MDk0IDQ0LjUyNzM0NCA1My44MDg1OTQgNzcuMjQyMTg4IDEwMi4zODI4MTIgNzcuMjQyMTg4IDQ4LjU3MDMxMyAwIDg5LjYyODkwNy0zMi43MTQ4NDQgMTAyLjM3MTA5NC03Ny4yNDIxODhoMzgwLjQ0NTMxM3YtNTguNWgtMzgwLjQ0NTMxM2MtMTIuNzQyMTg3LTQ0LjU0Mjk2OC01My44MDA3ODEtNzcuMjQyMTg3LTEwMi4zNzEwOTQtNzcuMjQyMTg3em0wIDAiIGZpbGw9IiMwMDAwMDAiLz48L3N2Zz4K"
                />
              </IconButton>
            </span>
            <div className="input-search">
              <TextInput
                name="searchKey"
                displayName="Thông tin tìm kiếm"
                placeholder="Tìm theo tên hoặc ID kênh"
                type="text"
                value={searchKey}
                onChange={(e) => this.setState({ searchKey: e.target.value })}
                edit={true}
                outLine
                height={42}
                label="asdf"
              />
              <span
                className="search-bt"
                onClick={() =>
                  this.setState({ isResetPage: true }, () =>
                    this._handleSearch()
                  )
                }
              >
                {" "}
                Tìm nhanh
              </span>
            </div>
            <div
              className={
                "more-option-box" + (showMoreOption ? " more" : " less")
              }
            >
              <table className="table border-none width100pc">
                <tbody>
                  <tr>
                    <td className="pl00 text-left width50pc">
                      <p className="pb05 text-left">Tags</p>
                      <SelectOption
                        optionType={OptionType.Tag.value}
                        onChange={(value) =>
                          this.setState(
                            {
                              tagIds: value ? value : null,
                              isResetPage: true,
                            },
                            () => this._handleSearch()
                          )
                        }
                        edit={true}
                        value={tagIds}
                        allowNull={true}
                        isMulti
                        outLine={true}
                      />
                    </td>
                    <td className="pl00 text-left width50pc">
                      <p className="pb05 text-left">Trạng thái</p>
                      <Select
                        options={ChannelStatusOptionsForUpdate}
                        value={selectedStatus}
                        onChange={(value) =>
                          this.setState(
                            {
                              selectedStatus: value,
                              isResetPage: true,
                            },
                            () => this._handleSearch()
                          )
                        }
                        edit={true}
                        outLine
                        allowNull={true}
                        isMulti
                        outLine
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table border-none ">
                <tbody>
                  <tr>
                    {currentUser.roleCode != MetubRoles.OPTIMIZE &&
                      currentUser.roleCode != MetubRoles.OPTIMIZE_MANAGER ? (
                      <td className="pl00 width33pc">
                        <p className="pb05 text-left">Tỉ lệ</p>
                        <SelectOption
                          optionType={OptionType.SharingRate.value}
                          onChange={(value) =>
                            this.setState(
                              {
                                selectedSharingRate: value ? value : null,
                                isResetPage: true,
                              },
                              () => this._handleSearch()
                            )
                          }
                          edit={true}
                          value={selectedSharingRate}
                          allowNull={true}
                          isMulti
                          outLine
                        />
                      </td>
                    ) : (
                      ""
                    )}
                    <td className="pl00 width250 text-left width33pc">
                      <p className="pb05 text-left">Email quản lý</p>
                      <SelectOption
                        optionType={OptionType.ManagerEmail.value}
                        onChange={(value) =>
                          this.setState(
                            {
                              managedByEmailId: value ? value.value : null,
                              isResetPage: true,
                            },
                            () => this._handleSearch()
                          )
                        }
                        edit={true}
                        value={managedByEmailId}
                        allowNull={true}
                        outLine
                      />
                    </td>
                    <td className="pl00 width250 text-left width33pc">
                      <p className="pb05 text-left">Tên CMS</p>
                      <SelectOption
                        optionType={OptionType.CMS.value}
                        onChange={(value) =>
                          this.setState(
                            {
                              cmsId: value ? value.value : null,
                              isResetPage: true,
                            },
                            () => this._handleSearch()
                          )
                        }
                        edit={true}
                        value={cmsId}
                        allowNull={true}
                        outLine
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table border-none ">
                <tbody>
                  <tr>
                    <td className="pl00 text-left width33pc">
                      <p className="pb05 text-left">PM</p>
                      <UserSelectList
                        edit={true}
                        value={partnerManagerId}
                        onChange={(value) =>
                          this.setState(
                            {
                              partnerManagerId: value ? value.id : null,
                              isResetPage: true,
                            },
                            () => this._handleSearch()
                          )
                        }
                        outLine
                        className="for-search"
                        filter={(users) => {
                          return users.filter(
                            (item) =>
                              item.roleCode == MetubRoles.PARTNER_MANAGER ||
                              item.roleCode == MetubRoles.LEADER_PARTNER_MANAGER
                          );
                        }}
                        isHideUserSelected={true}
                      />
                    </td>
                    <td className="pl00 text-left width33pc">
                      <p className="pb05 text-left">Người tối ưu</p>
                      <UserSelectList
                        edit={true}
                        value={optimizerId}
                        onChange={(value) =>
                          this.setState(
                            {
                              optimizerId: value ? value.id : null,
                              isResetPage: true,
                            },
                            () => this._handleSearch()
                          )
                        }
                        outLine
                        className="for-search"
                        filter={(users) => {
                          return users.filter(
                            (item) =>
                              item.roleCode == MetubRoles.OPTIMIZE ||
                              item.roleCode == MetubRoles.OPTIMIZE_MANAGER
                          );
                        }}
                        isHideUserSelected={true}
                      />
                    </td>
                    <td className="pl00 width250 text-left width33pc">
                      <p className="pb05 text-left">Thể loại</p>
                      <SelectOption
                        optionType={OptionType.ChannelCategory.value}
                        onChange={(value) =>
                          this.setState(
                            {
                              categoryId: value ? value.value : null,
                              isResetPage: true,
                            },
                            () => this._handleSearch()
                          )
                        }
                        edit={true}
                        value={categoryId}
                        allowNull={true}
                        outLine
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="admin-box">
          <span>
            <EqualizerIcon />
            Danh sách kênh
            <Button color={"primary"} onClick={() => this._exportList()}>
              Export
            </Button>
          </span>
          <div>
            <div className="table-actions">
              {currentUser.roleCode != MetubRoles.PARTNER_MANAGER ? (
                <div>
                  {selected.length > 0 ? (
                    <span
                      style={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="select-notify"
                    >
                      <span>Đã chọn {selected.length} kênh</span>
                      <span
                        onClick={() =>
                          this.setState({ openUpdateStatusModal: true })
                        }
                      >
                        Cập nhật trạng thái
                      </span>
                      <span onClick={() => this.setState({ selected: [] })}>
                        Bỏ chọn
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div></div>
              )}
              <div className="sort">
                <div className="decs">
                  <Checkbox
                    value="secondary"
                    color="primary"
                    checked={sortType == "Decs"}
                    style={{
                      color: "#327AB7",
                    }}
                    onClick={() => this._handleSortTypeChange("Decs")}
                  />
                  <span>Ngày tạo mới nhất</span>
                </div>
                <div className="asc">
                  <Checkbox
                    value="secondary"
                    color="primary"
                    checked={sortType == "Asc"}
                    style={{
                      color: "#327AB7",
                    }}
                    onClick={() => this._handleSortTypeChange("Asc")}
                  />
                  <span>Ngày tạo cũ nhất</span>
                </div>
              </div>
            </div>
            {isInRole(MetubRoles.VIEW_CHANNEL) && (
              <List
                showPM={isSearching}
                channels={channels}
                total={total}
                onUpdateSuccess={() => this._handleGetchannels()}
                onChange={(param) => this._handleGetchannels(param)}
                onFilter={this._onFilterVerifyStatus}
                listType={"list"}
                history={history}
                selected={selected}
                onSelected={(list) => this.setState({ selected: list })}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>

        {isInRole(MetubRoles.CREATE_CHANNEL) && (
          <AddNew
            open={openAddNewDialog}
            onClose={() => this.setState({ openAddNewDialog: false })}
            onAddSuccess={() =>
              this.setState({ openAddNewDialog: false, canReload: true }, () =>
                this._handleGetchannels(searchParam)
              )
            }
            partnerId={partnerId}
          />
        )}

        <Drawer
          anchor="right"
          open={openUpdateStatusModal}
          onClose={() => this.setState({ openUpdateStatusModal: false })}
        >
          <div className="update-user-drawer">
            <span className="drawer-header">Cập nhật trạng thái kênh</span>
            <div className="drawer-content input-form">
              <div style={{ minHeight: "200px" }}>
                <label>Trạng thái</label>
                <Select
                  options={ChannelStatusOptionsForUpdate}
                  value={selectedStatusForUpdate}
                  onChange={(value) =>
                    this.setState({
                      selectedStatusForUpdate: value,
                    })
                  }
                  edit={true}
                  outLine
                  allowNull={false}
                  className="channel-status"
                />
                <p style={{ margin: "10px 0px" }}>
                  Thao tác này sẽ cập nhật trạng thái cho {selected.length} kênh
                  bạn đã chọn.
                </p>
                <p>Xác nhận cập nhật trạng thái?</p>
              </div>
            </div>
            <div className="drawer-actions">
              {!isInRole(MetubRoles.UPDATE_CHANNEL) ? (
                <span style={{ color: "#777", maxWidth: "350px" }}>
                  Tài khoản của bạn không đủ quyền để cập nhật thông tin. Vui
                  lòng chuyển đổi tài khoản khác để thực hiện chức năng này.
                </span>
              ) : (
                ""
              )}
              {isInRole(MetubRoles.UPDATE_CHANNEL) ? (
                <span
                  className="cancel-bt"
                  onClick={() =>
                    this.setState({ openUpdateStatusModal: false })
                  }
                >
                  Huỷ
                </span>
              ) : (
                ""
              )}
              {isInRole(MetubRoles.UPDATE_CHANNEL) ? (
                <span
                  className="search-bt"
                  onClick={() => this._handleUpdateStatus()}
                >
                  Lưu thông tin
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Drawer>

        {/* <Modal
                    className="add-new-contact"
                    open={openUpdateStatusModal}
                    onClose={() => this.onClose()}
                    fullWidth={true}
                    fullScreen={false}
                    header={
                        <div>
                            <span>CẬP NHẬT TRẠNG THÁI KÊNH</span>
                        </div>
                    }
                    content={<div style={{ minHeight: "200px" }}>
                        <label>Trạng thái</label>
                        <Select
                            options={ChannelStatusOptionsForUpdate}
                            value={selectedStatusForUpdate}
                            onChange={value =>
                                this.setState({
                                    selectedStatusForUpdate: value
                                })
                            }
                            edit={true}
                            outLine
                            allowNull={false}
                            className="channel-status"
                        />
                        <p style={{ margin: "10px 0px" }}>Thao tác này sẽ cập nhật trạng thái cho {selected.length} kênh bạn đã chọn.</p>
                        <p>Xác nhận cập nhật trạng thái?</p>
                    </div>}
                    action={
                        <Button
                            color="primary"
                            onClick={() => this._handleUpdateStatus()}
                        >
                            Cập nhật
                        </Button>
                    }
                    allowCancel={true}
                /> */}
      </div>
    );
  }
}

export default Index;
