import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ToolTip from '@material-ui/core/Tooltip'
import { MetubRoles } from '../../constants/constants'
import red from '@material-ui/core/colors/red';
import Edit from './update'
import { put, _delete } from '../../api';
import { showSuccess } from '../../utils/app';
import { isInRole, getCurrentUser } from '../../auth';
import { confirmSubmit } from "../../utils/common";

const styles = theme => ({
    card: {
        maxWidth: 400,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
});

class RecipeReviewCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            openEditContact: false
        };
    }

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    _onUpdateSuccess() {
        this.props.onUpdateSuccess()
        this.setState({
            openEditContact: false
        })
    }

    _updateDefaut() {
        let param = this.props.info
        if (!param) return
        param.isDefault = !param.isDefault
        put("contacts", param, () => {
            showSuccess("Thay đổi thông tin thành công.")
            this.props.onUpdateSuccess()
        })
    }
    _checkRoleUpdateDefault(info) {
        let currentUser = getCurrentUser();
        switch (currentUser.roleCode) {
            case MetubRoles.PARTNER_MANAGER:
                return true
            case MetubRoles.LEADER_PARTNER_MANAGER:
                return (currentUser.id === this.props.partnerManagerId)
            default: return true
        }
    }
    _deleteContact() {
        confirmSubmit(
            <label style={{ fontSize: "1.2rem", marginBottom: "10px" }}>Xoá liên hệ</label>,
            <span style={{ fontSize: "1rem" }}>Bạn có chắc chắn muốn xóa liên hệ của đối tác này?</span>,
            () => {
                let { contactId } = this.props
                if (contactId) {
                    _delete("contacts/" + contactId, "", () => {
                        showSuccess("Xóa thông tin liên hệ của đối tác thành công");
                        this.props.onUpdateSuccess();
                    })
                }
            }
        );
    }

    render() {
        const { classes, info, isExitDefaultContact, canUpdate } = this.props;
        const { openEditContact } = this.state
        const currentUser = getCurrentUser();
        return (
            <div>
                {
                    info && <Card className={classes.card + " custom-card"} key={info.email} >
                        <CardHeader
                            avatar={
                                <Avatar aria-label="Recipe" className={classes.avatar}>
                                    {
                                        info.fullName ? info.fullName.substr(0, 1).toUpperCase() : ""
                                    }
                                </Avatar>
                            }
                            title={info.fullName ? info.fullName : ""}
                            subheader={info.email ? info.email : ""}
                            action={
                                <Button disabled className={"default-contact-label"}>{info.isDefault ? "MẶC ĐỊNH" : " "}</Button>
                            }
                        />
                        <CardContent>
                            <table className="table border-none input-form">
                                <tbody>
                                    <tr>
                                        <td>
                                            <label>Số điện thoại</label>
                                            <span style={{ color: "#969FAC", display: 'block' }}>{info.phone ? info.phone : ""}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Địa chỉ</label>
                                            <span style={{ color: "#969FAC", display: 'block' }}>{info.address ? info.address : ""}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Chức vụ</label>
                                            <span style={{ color: "#969FAC", display: 'block' }}>{info.position ? info.position : ""}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardContent>
                        <CardActions className={classes.actions + " contact-action"} disableActionSpacing>
                            {
                                (isInRole(MetubRoles.UPDATE_CONTACT,
                                    () => this._checkRoleUpdateDefault(info)) ||
                                    currentUser.roleCode === "DATA_MANAGER") &&
                                <div>
                                    {
                                        info.isDefault
                                            ? <ToolTip title={"Huỷ mặc định"} placement="top">
                                                <IconButton onClick={() => this._updateDefaut()} disabled={!canUpdate}>
                                                    <StarIcon color="secondary" />
                                                </IconButton>
                                            </ToolTip>
                                            : (isExitDefaultContact === true ? null : <ToolTip title={"Đặt làm mặc định"} placement="top">
                                                <IconButton onClick={() => this._updateDefaut()} disabled={!canUpdate}>
                                                    <StarBorder />
                                                </IconButton>
                                            </ToolTip>
                                            )
                                    }
                                </div>
                            }
                            {
                                ((isInRole(MetubRoles.UPDATE_CONTACT,
                                    () => this._checkRoleUpdateDefault(info)) &&
                                    !info.isDefault) ||
                                    currentUser.roleCode === "DATA_MANAGER") &&
                                <ToolTip title="Chỉnh sửa liên hệ" placement="top">
                                    <IconButton onClick={() => this.setState({ openEditContact: true })} disabled={!canUpdate}>

                                        <EditIcon />
                                    </IconButton>
                                </ToolTip>
                            }
                            {
                                (isInRole(MetubRoles.DELETE_CONTACT,
                                    () => this._checkRoleUpdateDefault(info)) &&
                                    !info.isDefault) &&
                                <ToolTip title="Xoá liên hệ" placement="top">
                                    <IconButton onClick={() => this._deleteContact()} disabled={!canUpdate}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ToolTip>
                            }
                        </CardActions>
                    </Card>
                }
                <Edit
                    open={openEditContact}
                    onClose={() => this.setState({ openEditContact: false })}
                    onUpdateSuccess={() => this._onUpdateSuccess()}
                    info={info}
                />
            </div>
        );
    }
}

RecipeReviewCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);