import React from "react";
import DataTable from '../../common/datatable'
import { NavLink } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import moment from 'moment'
import { RequestStatus } from '../../../constants/constants'
import Detail from './detail'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentRequest: null,
            openDetailModal: false,
        };
    }

    _onUpdateSuccess() {
        this.props.onUpdateSuccess()
    }

    _onTablePageChange(param) {
        this.props.onChange(param)
    }



    render() {
        const { requests, listType, total } = this.props
        const { openDetailModal, currentRequest } = this.state
        return (
            requests && requests.length > 0 ? <div className="video-page">
                {
                    listType === "card"
                        ? <div className="video-list">
                            {/* {
                                requests.map((video, i) =>
                                    <ChannelCard info={video} key={i} onUpdateSuccess={() => this._onUpdateSuccess()} />
                                )
                            } */}
                        </div>
                        : <div className="video-list list-view">
                            <DataTable
                                data={requests}
                                total={total}
                                isPaging={true}
                                onChange={param => this._onTablePageChange(param)}
                                renderHeader={
                                    () => {
                                        return (
                                            <thead>
                                                <tr>
                                                    <th className="text-left">Mã</th>
                                                    <th className="text-left width120">Ngày tạo</th>
                                                    <th className="text-left">Nội dung</th>
                                                    <th className="text-left width120">Tình trạng</th>
                                                    <th className="text-center width100" />
                                                </tr>
                                            </thead>
                                        );
                                    }
                                }
                                renderBody={
                                    items => {
                                        return (
                                            <tbody>
                                                {items.map((item, i) => (
                                                    <tr key={i}>
                                                        <td className="text-left">
                                                            {item.id}
                                                        </td>
                                                        <td className="text-left">
                                                            {moment(item.createTime).format("DD/MM/YYYY")}
                                                        </td>
                                                        <td className="text-left">{item.content}</td>
                                                        <td className="text-left">{RequestStatus[item.status].label}</td>
                                                        <td>
                                                            <NavLink to="#" >
                                                                <Tooltip title="Xem chi tiết" placement="top">
                                                                    <IconButton onClick={() => this.setState({ currentRequest: item, openDetailModal: true })}>
                                                                        <InfoIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </NavLink>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        )
                                    }
                                }
                            >
                            </DataTable>
                        </div>
                }
                {
                    currentRequest && <Detail
                        requestId={currentRequest.id}
                        open={currentRequest && openDetailModal ? true : false}
                        onClose={() => this.setState({ openDetailModal: false })}
                        onUpdateSuccess={() => this.props.onUpdateSuccess()}
                    />
                }
            </div> : <label className="empty-list-label">Chưa có yêu cầu nào.</label>
        );
    }
}

export default Index;
