import React from "react";
import MenuItem from "./menu-item";
import $ from "jquery";
import { OptionType, MetubAccessibleFeatures, MetubRoles } from "../../../constants/constants";
import { isInAccessibleFeatures, isInRole } from "../../../auth";
import { get } from "../../../api";
import { Badge } from '@material-ui/core'
import { getNotifyUnread } from '../../../actions/app'
import { connect } from 'react-redux'
import {
    USER_INFO,
    NOTIFICATION_FILTER,
    USER_FILTER,
    PARTNER_FILTER,
    VIDEO_FILTER,
    CHANNEL_FILTER,
    CONTRACT_FILTER,
    REQUEST_FILTER,
    DASHBOARD_FILTER,
    OPTION_FILTER
} from "../../../constants/localStorageKeys";

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: window.location.pathname,
            index: 0
        };
    }

    componentWillMount() {
        this.props.getNotifyUnread()
    }
    componentWillReceiveProps(nexprops) {
        let { index } = this.state
        this.setState({
            index: index + 1
        })
    }

    onMenuChange = (active, key) => {
        this.setState({
            active
        });

        if (key) {
            window.localStorage.removeItem(key)
            if (key == PARTNER_FILTER)
                window.localStorage.removeItem(CHANNEL_FILTER)
        }

        if (active.indexOf("MenuItem") < 0) {
            let windowWidth = $(window).width();
            if (windowWidth < 768) {
                let body = $("body");
                if (body.hasClass("sidebar-collapse")) {
                    body.removeClass("sidebar-collapse");
                }
            }
        }
    };

    render() {
        let { active, index } = this.state;
        const { notifyUnreadSumary, style, onChange } = this.props
        let currentUSer = JSON.parse(window.localStorage.getItem(USER_INFO))


        if (currentUSer && active == '/' && currentUSer.roleCode == MetubRoles.LEGAL_EXECUTIVE)
            active = '/notification'
        if (currentUSer && active == '/' && currentUSer.roleCode == MetubRoles.NORMAL_USER)
            active = '/channels'


        return (
            <aside className="main-sidebar" style={style}>
                <section className="sidebar">
                    <div className="text-center">
                        <img
                            src={require("../../../assets/images/LOGO_METUB_NEW.png")}
                            alt=""
                            width="80%"
                            className="mt10"
                        />
                    </div>
                    <div className="sidebar-menu">
                        <ul >
                            {
                                currentUSer.roleCode != MetubRoles.LEGAL_EXECUTIVE
                                    && currentUSer.roleCode != MetubRoles.CHIEF_ACCOUNTANT
                                    && currentUSer.roleCode != MetubRoles.ACCOUNTANT
                                    && currentUSer.roleCode != MetubRoles.CUSTOMER_SP
                                    && currentUSer.roleCode != MetubRoles.NORMAL_USER
                                    && currentUSer.roleCode != MetubRoles.ADMIN_OFFICER
                                    ? <MenuItem
                                        index={index}
                                        onClick={() => onChange()}
                                        name="Dashboard"
                                        to="/"
                                        icon="fas fa-tachometer-alt"
                                        active={active}
                                        onMenuChange={(active) => this.onMenuChange(active, DASHBOARD_FILTER)}

                                    /> : ""
                            }
                            {
                                (
                                    isInRole(MetubRoles.ADMIN)
                                    || isInRole(MetubRoles.BOD)
                                    || isInRole(MetubRoles.LEGAL_EXECUTIVE)
                                    || isInRole(MetubRoles.PARTNER_MANAGER)
                                    || isInRole(MetubRoles.DATA_MANAGER)
                                    || isInRole(MetubRoles.LEADER_PARTNER_MANAGER)
                                    || isInRole(MetubRoles.OPTIMIZE)
                                    || isInRole(MetubRoles.OPTIMIZE_MANAGER)
                                    || isInRole(MetubRoles.ADMIN_OFFICER)
                                ) ? <MenuItem
                                    index={index}
                                    onClick={() => onChange()}
                                    name={<span>Thông báo {notifyUnreadSumary > 0 ? <span style={{ color: "#F50056", fontSize: '13px' }}>( {notifyUnreadSumary > 99 ? "99+" : notifyUnreadSumary} )</span> : ""}</span>}
                                    to="/notification"
                                    icon="fas fa-bell"
                                    active={active}
                                    onMenuChange={(active) => this.onMenuChange(active, NOTIFICATION_FILTER)}
                                /> : ""
                            }
                            {
                                (currentUSer.roleCode === MetubRoles.DATA_MANAGER
                                    || currentUSer.roleCode === MetubRoles.LEADER_PARTNER_MANAGER
                                    || currentUSer.roleCode === MetubRoles.BOD
                                    || currentUSer.roleCode === MetubRoles.LEGAL_EXECUTIVE) && <MenuItem
                                    index={index}
                                    onClick={() => onChange()}
                                    name={"YC cập nhật"}
                                    to="/requests"
                                    icon="fab fa-buromobelexperte"
                                    active={active}
                                    onMenuChange={(active) => this.onMenuChange(active, REQUEST_FILTER)}
                                />
                            }
                            {
                                isInAccessibleFeatures(MetubAccessibleFeatures.MANAGE_USER) && <MenuItem
                                    index={index}
                                    onClick={() => onChange()}
                                    name="Nhân viên"
                                    to="/users"
                                    icon="fas fa-users-cog"
                                    active={active}
                                    onMenuChange={(active) => this.onMenuChange(active, USER_FILTER)}
                                />
                            }

                            {
                                isInAccessibleFeatures(MetubAccessibleFeatures.MANAGE_PARTNER) && <MenuItem
                                    index={index}
                                    onClick={() => onChange()}
                                    name="Đối tác"
                                    to="/partners"
                                    icon="fab fa-elementor"
                                    active={active}
                                    onMenuChange={(active) => this.onMenuChange(active, PARTNER_FILTER)}
                                />
                            }

                            {
                                isInAccessibleFeatures(MetubAccessibleFeatures.MANAGE_VIDEO) && <MenuItem
                                    index={index}
                                    onClick={() => onChange()}
                                    name="Videos"
                                    to="/videos"
                                    icon="fas fa-video"
                                    active={active}
                                    onMenuChange={(active) => this.onMenuChange(active, VIDEO_FILTER)}
                                />
                            }

                            {
                                isInAccessibleFeatures(MetubAccessibleFeatures.MANAGE_CHANNEL) && <MenuItem
                                    index={index}
                                    onClick={() => onChange()}
                                    name="Kênh"
                                    to="/channels"
                                    icon="fab fa-youtube"
                                    active={active}
                                    onMenuChange={(active) => this.onMenuChange(active, CHANNEL_FILTER)}
                                />
                            }
                            {
                                (isInAccessibleFeatures(MetubAccessibleFeatures.MANAGE_CONTRACT) || currentUSer.roleCode === "ADMIN" || currentUSer.roleCode === "LEGAL_EXECUTIVE") && <MenuItem
                                    index={index}
                                    onClick={() => onChange()}
                                    name="Hợp Đồng"
                                    to="/contracts"
                                    icon="fas fa-file-contract"
                                    active={active}
                                    onMenuChange={(active) => this.onMenuChange(active, CONTRACT_FILTER)}
                                />
                            }
                            {
                                currentUSer.roleCode === "ADMIN" && <MenuItem
                                    index={index}
                                    onClick={() => onChange()}
                                    name={"YC cập nhật"}
                                    to="/requests"
                                    icon="fab fa-buromobelexperte"
                                    active={active}
                                    onMenuChange={(active) => this.onMenuChange(active, REQUEST_FILTER)}
                                />
                            }
                            {
                                isInAccessibleFeatures(MetubAccessibleFeatures.MANAGE_OPTION) && <MenuItem
                                    index={index}
                                    onClick={() => onChange()}
                                    name="Cài đặt"
                                    to={"#"}
                                    icon="fa fa-book"
                                    active={active}
                                    onMenuChange={(active) => this.onMenuChange(active, OPTION_FILTER)}
                                >
                                    <MenuItem
                                        index={index}
                                        onClick={() => onChange()} history={this.props.history}

                                        name={OptionType.Tag.label}
                                        to="/options/Tag"
                                        active={active}
                                        onMenuChange={(active) => this.onMenuChange(active, OPTION_FILTER)}
                                    />
                                    <MenuItem
                                        index={index}
                                        onClick={() => onChange()} history={this.props.history}

                                        name={OptionType.SharingRate.label}
                                        to="/options/SharingRate"
                                        active={active}
                                        onMenuChange={(active) => this.onMenuChange(active, OPTION_FILTER)}
                                    />
                                    <MenuItem
                                        index={index}
                                        onClick={() => onChange()} history={this.props.history}

                                        name={OptionType.CMS.label}
                                        to="/options/CMS"
                                        active={active}
                                        onMenuChange={(active) => this.onMenuChange(active, OPTION_FILTER)}
                                    />
                                    <MenuItem
                                        index={index}
                                        onClick={() => onChange()} history={this.props.history}

                                        name={OptionType.ContractType.label}
                                        to="/options/ContractType"
                                        active={active}
                                        onMenuChange={(active) => this.onMenuChange(active, OPTION_FILTER)}
                                    />
                                    <MenuItem
                                        index={index}
                                        onClick={() => onChange()} history={this.props.history}

                                        name={OptionType.ChannelCategory.label}
                                        to="/options/ChannelCategory"
                                        active={active}
                                        onMenuChange={(active) => this.onMenuChange(active, OPTION_FILTER)}
                                    />
                                    <MenuItem
                                        index={index}
                                        onClick={() => onChange()} history={this.props.history}

                                        name={OptionType.SubCategory.label}
                                        to="/options/SubCategory"
                                        active={active}
                                        onMenuChange={(active) => this.onMenuChange(active, OPTION_FILTER)}
                                    />
                                    <MenuItem
                                        index={index}
                                        onClick={() => onChange()} history={this.props.history}

                                        name={OptionType.ChannelContentType.label}
                                        to="/options/ChannelContentType"
                                        active={active}
                                        onMenuChange={(active) => this.onMenuChange(active, OPTION_FILTER)}
                                    />
                                    <MenuItem
                                        index={index}
                                        onClick={() => onChange()} history={this.props.history}

                                        name={OptionType.ChannelPriority.label}
                                        to="/options/ChannelPriority"
                                        active={active}
                                        onMenuChange={(active) => this.onMenuChange(active, OPTION_FILTER)}
                                    />
                                    <MenuItem
                                        index={index}
                                        onClick={() => onChange()} history={this.props.history}

                                        name={OptionType.PriceQuotation.label}
                                        to="/options/PriceQuotation"
                                        active={active}
                                        onMenuChange={(active) => this.onMenuChange(active, OPTION_FILTER)}
                                    />
                                    <MenuItem
                                        index={index}
                                        onClick={() => onChange()} history={this.props.history}

                                        name={OptionType.ManagerEmail.label}
                                        to="/options/ManagerEmail"
                                        active={active}
                                        onMenuChange={(active) => this.onMenuChange(active, OPTION_FILTER)}
                                    />
                                    <MenuItem
                                        index={index}
                                        onClick={() => onChange()} history={this.props.history}

                                        name={OptionType.ManagerBank.label}
                                        to="/options/ManagerBank"
                                        active={active}
                                        onMenuChange={(active) => this.onMenuChange(active, OPTION_FILTER)}
                                    />
                                    <MenuItem
                                        index={index}
                                        onClick={() => onChange()} history={this.props.history}

                                        name={OptionType.Vertical.label}
                                        to="/options/Vertical"
                                        active={active}
                                        onMenuChange={(active) => this.onMenuChange(active, OPTION_FILTER)}
                                    />
                                </MenuItem>
                            }

                            {/* {
              isInAccessibleFeatures(MetubAccessibleFeatures.MANAGE_CAMPAIGN) && <MenuItem
index={index}
onClick={()=>onChange()} history={this.props.history} 

                name={"Campaigns"}
                to="/campaigns"
                icon="fab fa-buromobelexperte"
                active={active}
                onMenuChange={(active)=>this.onMenuChange(active,null)}
              />
            }

            {
              isInAccessibleFeatures(MetubAccessibleFeatures.MANAGE_FANPAGE) && <MenuItem
index={index}
onClick={()=>onChange()} history={this.props.history} 

                name={"Fanpages"}
                to="/fanpages"
                icon="fab fa-buromobelexperte"
                active={active}
                onMenuChange={(active)=>this.onMenuChange(active,null)}
              />
            } */}


                        </ul>
                    </div>
                </section>
            </aside>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.app,
    }
};

const mapDispatchToProps = dispatch => ({
    getNotifyUnread: () => dispatch(getNotifyUnread())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index);
