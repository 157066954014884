import React from "react";
import List from './list'
import AddNew from './add-new'
import Button from '@material-ui/core/Button'
import { get } from "../../api";
import { objToQuery, objToArray } from '../../utils/common'
import { TableSizeDefault } from '../../constants/constants'
import { isInRole, getCurrentUser } from '../../auth'
import { MetubRoles, OptionType, ContractStatus } from '../../constants/constants'

import { CONTRACT_FILTER } from "../../constants/localStorageKeys";
import {
    Assignment as AssignmentIcon,
    FilterList as FilterListIcon,
    ChevronRight as ChevronRightIcon
} from '@material-ui/icons'
import {
    Typography,
    IconButton
} from '@material-ui/core'
import TextInput from '../common/text-input'
import DateTimePicker from '../common/datetime-picker';
import SelectOption from '../common/select-option'
import Select from '../common/select'
import moment from 'moment'
import { BASE_API } from "../../constants/appSettings";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contracts: [],
            total: 0,
            openAddNewDialog: false,
            searchParam: { skip: 0, take: TableSizeDefault.value },
            listType: "list",
            currentPage: 1,
            showMoreOption: true,
            searchKey: "",
            isResetPage: false,
            fromDate: null,
            toDate: null,
            contractTypeId: null,
            status: null
        };
    }

    _handleExportList(param) {

        let {
            isResetPage,
            searchKey,
            fromDate,
            toDate,
            contractTypeId,
            status
        } = this.state
        const currentUser = getCurrentUser();
        if (!param) return
        let currentPage = param.page
        if (!currentPage) {
            let filter = JSON.parse(window.localStorage.getItem(CONTRACT_FILTER))
            currentPage = filter && filter.currentPage ? filter.currentPage : 0
        }

        if (isResetPage == true)
            currentPage = 1

        this.setState({
            searchParam: param,
            currentPage: currentPage
        }, () => {
            let { partnerId } = this.props

            let getParam = {
                search: searchKey,
                partnerId: partnerId ? partnerId : null,
                skip: currentPage - 1 >= 0 ? (currentPage - 1) * param.take : 0,
                take: param.take,
                signDate: fromDate ? fromDate : "",
                expiryDate: toDate ? toDate : "",
                contractTypeId: contractTypeId ? contractTypeId : "",
                status: status ? status.value : ""
            }

            if (getParam.partnerId == null) {
                delete getParam["partnerId"]
            }

            window.localStorage.setItem(CONTRACT_FILTER, JSON.stringify(this.state))


            var query = objToQuery(getParam);
            get("contracts/export" + query, (result) => {
                window.open(BASE_API + result.relativePath);
            });
        }
        );
    }

    _handleGetContracts(param) {
        let {
            isResetPage,
            searchKey,
            fromDate,
            toDate,
            contractTypeId,
            status
        } = this.state
        const currentUser = getCurrentUser();
        if (!param) return
        let currentPage = param.page
        if (!currentPage) {
            let filter = JSON.parse(window.localStorage.getItem(CONTRACT_FILTER))
            currentPage = filter && filter.currentPage ? filter.currentPage : 0
        }

        if (isResetPage == true)
            currentPage = 1

        this.setState({
            searchParam: param,
            currentPage: currentPage
        }, () => {
            let { partnerId } = this.props

            let getParam = {
                search: searchKey,
                partnerId: partnerId ? partnerId : null,
                skip: currentPage - 1 >= 0 ? (currentPage - 1) * param.take : 0,
                take: param.take,
                signDate: fromDate ? fromDate : "",
                expiryDate: toDate ? toDate : "",
                contractTypeId: contractTypeId ? contractTypeId : "",
                status: status ? status.value : ""
            }

            if (getParam.partnerId == null) {
                delete getParam["partnerId"]
            }

            window.localStorage.setItem(CONTRACT_FILTER, JSON.stringify(this.state))

            if (window.location.pathname === "/contracts") {
                getParam.Status = currentUser.roleCode === "ADMIN_OFFICER" ? "Signed" : "None";
                let query = objToQuery(getParam);
                get("contracts" + query, result => {
                    this.setState({
                        contracts: result.items,
                        total: result.total,
                        isResetPage: false,
                    })
                }, () => {
                    this.setState({
                        isResetPage: false,
                    })
                })
            }
            else {
                let query = objToQuery(getParam);
                get("partners/" + partnerId + "/contracts" + query, result => {
                    this.setState({
                        contracts: result.items,
                        total: result.total,
                        isResetPage: false,
                    })
                }, () => {
                    this.setState({
                        isResetPage: false,
                    })
                })
            }
        })
    }

    componentWillMount() {
        let filter = JSON.parse(window.localStorage.getItem(CONTRACT_FILTER))
        if (filter) {
            this.setState({
                ...filter,
                openAddNewDialog: false
            })
        }
    }

    componentDidMount() {
        let { searchParam } = this.state
        this._handleGetContracts(searchParam)
    }

    render() {
        let {
            contracts,
            openAddNewDialog,
            total,
            searchParam,
            currentPage,
            showMoreOption,
            searchKey,
            fromDate,
            toDate,
            contractTypeId,
            status
        } = this.state
        const { partnerId, disableHeader, partner } = this.props

        let pathName = window.location.pathname
        const ContractStatusOptions = objToArray(ContractStatus)

        return (
            <div className="contact-page">
                {
                    disableHeader == true ? "" : <Typography variant="h6" gutterBottom align="center" className="box-title">
                        <div>
                            Quản lí hợp đồng
                            <p>Quản lí thông tin hợp đồng đang có trong hệ thống.</p>
                        </div>
                    </Typography>
                }
                <div className="admin-box">
                    <span>
                        <FilterListIcon />
                        Bộ lọc dữ liệu
                        <IconButton onClick={() => this.setState({ showMoreOption: !showMoreOption })}
                        >
                            <img alt="img" style={{ width: 25, opacity: 0.5 }} src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSItMzMgLTE4MSAxMDY1LjAwMDEgMTA2NSIgd2lkdGg9IjUxMnB4Ij48cGF0aCBkPSJtODI5LjM0NzY1NiA2NS44ODY3MTljLTEyLjc1LTQ0LjU0Mjk2OS01My43OTY4NzUtNzcuMjM4MjgxLTEwMi4zNzEwOTQtNzcuMjM4MjgxLTQ4LjU3NDIxOCAwLTg5LjYyNSAzMi42OTUzMTItMTAyLjM4MjgxMiA3Ny4yMzgyODFoLTYyNi42MTMyODF2NTguNWg2MjYuNjIxMDkzYzEyLjc1IDQ0LjUzNTE1NiA1My44MDA3ODIgNzcuMjQyMTg3IDEwMi4zNzUgNzcuMjQyMTg3IDQ4LjU3NDIxOSAwIDg5LjYyMTA5NC0zMi43MDcwMzEgMTAyLjM3NS03Ny4yNDIxODdoMTY3LjA2NjQwN3YtNTguNXptMCAwIiBmaWxsPSIjMDAwMDAwIi8+PHBhdGggZD0ibTE4NS40ODgyODEgMjMzLjM0Mzc1Yy00OC41NzQyMTkgMC04OS42Mjg5MDYgMzIuNzAzMTI1LTEwMi4zNzEwOTMgNzcuMjM4MjgxaC04NS4xMzY3MTl2NTguNTAzOTA3aDg1LjEzNjcxOWMxMi43NDIxODcgNDQuNTQyOTY4IDUzLjgwMDc4MSA3Ny4yNDIxODcgMTAyLjM3NSA3Ny4yNDIxODcgNDguNTc0MjE4IDAgODkuNjI4OTA2LTMyLjY5OTIxOSAxMDIuMzc1LTc3LjI0MjE4N2g3MDguNTUwNzgxdi01OC41MDM5MDdoLTcwOC41NTA3ODFjLTEyLjc0NjA5NC00NC41MzUxNTYtNTMuODA0Njg4LTc3LjIzODI4MS0xMDIuMzc4OTA3LTc3LjIzODI4MXptMCAwIiBmaWxsPSIjMDAwMDAwIi8+PHBhdGggZD0ibTUxMy42MDE1NjIgNDc4LjA0Njg3NWMtNDguNTc0MjE4IDAtODkuNjM2NzE4IDMyLjY5OTIxOS0xMDIuMzgyODEyIDc3LjI0MjE4N2gtNDEzLjIzODI4MXY1OC41aDQxMy4yMzgyODFjMTIuNzQ2MDk0IDQ0LjUyNzM0NCA1My44MDg1OTQgNzcuMjQyMTg4IDEwMi4zODI4MTIgNzcuMjQyMTg4IDQ4LjU3MDMxMyAwIDg5LjYyODkwNy0zMi43MTQ4NDQgMTAyLjM3MTA5NC03Ny4yNDIxODhoMzgwLjQ0NTMxM3YtNTguNWgtMzgwLjQ0NTMxM2MtMTIuNzQyMTg3LTQ0LjU0Mjk2OC01My44MDA3ODEtNzcuMjQyMTg3LTEwMi4zNzEwOTQtNzcuMjQyMTg3em0wIDAiIGZpbGw9IiMwMDAwMDAiLz48L3N2Zz4K" />
                        </IconButton>
                    </span>
                    <div className="input-search">
                        <TextInput
                            name="searchKey"
                            displayName="Thông tin tìm kiếm"
                            placeholder="Tìm theo tên hoặc id, mã, tên hợp đồng"
                            type="text"
                            value={searchKey}
                            onChange={(e) => this.setState({ searchKey: e.target.value })}
                            edit={true}
                            outLine
                            height={42}
                            label="asdf"
                        />
                        <span className="search-bt" onClick={() => this.setState({ isResetPage: true }, () => this._handleGetContracts(searchParam))}> Tìm nhanh</span>
                    </div>
                    <div className={"more-option-box " + (showMoreOption ? " more" : " less")}>

                        <table className="table border-none width100pc">
                            <tbody>
                                <tr>
                                    <td className="width15pc">
                                        <label>Ngày tạo</label>
                                        <div className="date-box">
                                            <DateTimePicker
                                                className="fromDate"
                                                type="date"
                                                placeholder={"Ngày tạo"}
                                                onChange={value => this.setState({ fromDate: value, toDate: value ? toDate : null, isResetPage: true }, () => this._handleGetContracts(searchParam))}
                                                value={fromDate}
                                                edit={true}
                                                border={false}
                                                isClearable={true}
                                            />
                                        </div>
                                    </td>
                                    <td className="width15pc">
                                        <label>Ngày hiệu lực</label>
                                        <div className="date-box">
                                            <DateTimePicker
                                                className="toDate"
                                                type="date"
                                                placeholder={"Ngày hiệu lực"}
                                                onChange={value => this.setState({ toDate: value, isResetPage: true }, () => this._handleGetContracts(searchParam))}
                                                value={toDate}
                                                edit={true}
                                                border={false}
                                                minDate={fromDate ? fromDate : null}
                                                isClearable={true}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <label>Loại hợp đồng</label>
                                        <SelectOption
                                            optionType={OptionType.ContractType.value}
                                            edit={true}
                                            onChange={(value) => this.setState({ contractTypeId: value ? value.id : null, isResetPage: true }, () => this._handleGetContracts(searchParam))}
                                            value={contractTypeId}
                                            allowNull={true}
                                        />
                                    </td>
                                    <td>
                                        <label>Trạng thái</label>
                                        <Select
                                            options={ContractStatusOptions}
                                            edit={true}
                                            onChange={(value) => this.setState({ status: value ? value : null, isResetPage: true }, () => this._handleGetContracts(searchParam))}
                                            value={status}
                                            allowNull={true}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="admin-box">
                    <span className="box-head">
                        <AssignmentIcon />
                        Danh sách hợp đồng
                        <div>
                            {
                                partner && partner.verifyStatus !== "Discontinued" && <>
                                    {
                                        pathName != "/contracts" && isInRole(MetubRoles.CREATE_CONTRACT) &&
                                        <Button
                                            color={"primary"}
                                            onClick={() => this.setState({ openAddNewDialog: !openAddNewDialog })}
                                        >
                                            Tạo mới
                                        </Button>
                                    }
                                </>
                            }
                            <Button color={"primary"} onClick={() => this._handleExportList(this.state.searchParam)}>
                                Export
                            </Button>
                        </div>
                    </span>
                    <List
                        contracts={contracts}
                        total={total}
                        onUpdateSuccess={() => this._handleGetContracts(searchParam)}
                        onChange={param => this._handleGetContracts(param)}
                        listType="list"
                        onDeleteSuccess={() => this._handleGetContracts(searchParam)}
                        currentPage={currentPage}
                    />
                </div>

                <AddNew
                    open={openAddNewDialog}
                    onClose={() => this.setState({ openAddNewDialog: false })}
                    onAddSuccess={() => this.setState({ openAddNewDialog: false }, () => this._handleGetContracts(searchParam))}
                    partnerId={partnerId} />
            </div>
        );
    }
}

export default Index;
