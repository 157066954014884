import React from "react";
import ListPartnerInMonth from './listPartnerInMonth'
import ListChannelAssign from './listChannelAssign'

import { get, put } from "../../../api";
import Typography from '@material-ui/core/Typography';
import { getCurrentUser } from "../../../auth";
import {
    Equalizer as EqualizerIcon,
    Contacts as ContactsIcon
} from '@material-ui/icons'


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            staticticPartner: "",
            openPinMenu: false,
            notifications: "",
            recents: [],
            recentSelected: null
        };
    }

    _getStaticticPartner() {
        get("partners/Statistic-Partner", result => {
            this.setState({
                staticticPartner: result[0]
            })
        }, null, true)
    }
    componentDidMount() {
        this._getStaticticPartner();
        this._getNotification();
        this._handleGetRecent()
    }
    handleOpenPinMenu() {
        this.setState({ openPinMenu: !this.state.openPinMenu })
    }
    handleClosePinMenu = () => {
        this.setState({ openPinMenu: false })
    }
    _getNotification = () => {
        get("notifications/get-all", result => {
            this.setState({ notifications: result.items })
        })
    }
    _handleGetRecent() {
        get("recents", result => {
            this.setState({
                recents: result.items,
                recentSelected: result.items[0]
            })
        }, null, true)
    }
    _isReadNotify(id, referenceId, type) {
        if (id) {
            let params = {
                isRead: true,
                id: id
            }
            put("notifications", params, () => {
                this._getNotification();
                if (referenceId) {
                    if (type == "CreateChannel") {
                        this.props.history.push(`/channels/${referenceId}`)
                    } else {
                        this.props.history.push(`/partners/${referenceId}`)
                    }
                }
            })
        }

    }
    _makeIsReadNotify(id) {
        if (id) {
            let params = {
                isRead: true,
                id: id
            }
            put("notifications", params, () => {
                this._getNotification();
            })
        }
    }

    render() {
        const currentUser = getCurrentUser();
        return (
            <div className="dashboardPartnerManager partner-management ">
                <Typography variant="h6" gutterBottom align="center" className="box-title">
                    <div>
                        Bảng điều khiển [ {currentUser && currentUser.roleName ? currentUser.roleName : ""} ]
                        <p>Thông tin điều khiển dành cho tài khoản {currentUser && currentUser.roleName ? currentUser.roleName : ""}.</p>
                    </div>
                </Typography>

                <div className="admin-box" >
                    <span><ContactsIcon />Danh sách đối tác tham gia trong tháng</span>
                    <div>
                        {
                            <ListPartnerInMonth />
                        }
                    </div>
                </div>
                <div className="admin-box" >
                    <span><EqualizerIcon />Danh sách kênh đã được assign</span>
                    <div>
                        {
                            <ListChannelAssign />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;

