import React from "react";
import TextInput from "../../common/text-input";
import {
    notEmpty,
    emailAddress,
    justNumber,
    isValidAsync
} from "../../../utils/validators";
import {
    objToQuery
} from "../../../utils/common";
import { PartnerType, DefaultUserAvatar, UploadFileType, InviteStatus } from "../../../constants/constants";
import { post, getAsync } from "../../../api";
import FileDropZone from "../../common/file-drop-zone";
import { showSuccess, showError } from "../../../utils/app";
import DateTimePicker from '../../common/datetime-picker';
import {
    RadioGroup,
    FormControlLabel,
    Radio
} from '@material-ui/core'
import MultiInput from '../../common/multi-input'

const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: "",
            type: PartnerType.Individual.value,
            phone: "",
            email: "",
            emailcc: "",
            emailErrors: "",
            stageName: "",
            dateOfBirth: null,
            dateOfBirthErrors: null,
            hometown: "",
            identityNumber: "",
            issuedOn: null,
            issuedOnErrors: null,
            issuedBy: "",
            currentAddress: "",
            idImageFrontSide: "",
            idImageFrontSideError: null,
            idImageBackSide: "",
            idImageBackSideError: null,
            taxCode: "",
            companyName: "",
            companyAddress: "",
            representativeName: "",
            representativePosition: "",
            exitedPartner: null,
            gender: "Male",
            emailccErrors: ""
        };
        this.defaultState = this.state
        this.validationData = {
            fullName: [notEmpty],
            phone: [notEmpty, justNumber],
            email: [notEmpty, emailAddress, this._handlePartnerExitByEmail],
            identityNumber: [notEmpty, this._handlePartnerExit],
            stageName: [notEmpty],
            hometown: [notEmpty],
            issuedBy: [notEmpty],
            currentAddress: [notEmpty]
        };

        this.idImageFrontSideRef = React.createRef();
        this.idImageBackSideRef = React.createRef();
    }

    _handleReset() {
        this.setState({
            ...this.defaultState
        });
    }

    _handlePartnerExit = async (displayName, value) => {
        if (!value) return "";
        this.setState({
            exitedPartner: null,
            emailErrors: null
        });

        let { identityNumber } = this.state;
        let param = {
            search: "",
            identityNumber: identityNumber,
            TaxCode: "",
            skip: 0,
            take: 1
        };

        var query = objToQuery(param);
        var repsonse = await getAsync("partners" + query, null, null, true);
        var result = await repsonse.json();

        if (result.total > 0) {
            result.items = result.items.filter(item => item.verifyStatus != InviteStatus.Denied.value && item.verifyStatus != InviteStatus.Discontinued.value)
            this.setState({
                exitedPartner: param.identityNumber ? result.items[0] : null,
            });
            return "";
        }

        return "";
    };

    _handlePartnerExitByEmail = async (displayName, value) => {
        if (!value) return "";
        this.setState({
            emailErrors: null,
            exitedPartnerByEmail: null
        });

        let { email } = this.state;
        let param = {
            search: "",
            email: email,
            TaxCode: "",
            skip: 0,
            take: 1,
        };

        var query = objToQuery(param);
        var repsonse = await getAsync("partners" + query, null, null, true);
        var result = await repsonse.json();

        if (result.total > 0) {
            result.items = result.items.filter(item => item.verifyStatus != InviteStatus.Denied.value && item.verifyStatus != InviteStatus.Discontinued.value)
            this.setState({
                exitedPartnerByEmail: param.email ? result.items[0] : null
            });
            return "";
        }

        return "";
    };

    async _handleAddIndividual() {
        const {
            fullName,
            phone,
            email,
            emailcc,
            stageName,
            dateOfBirth,
            hometown,
            identityNumber,
            issuedOn,
            issuedBy,
            currentAddress,
            idImageFrontSide,
            idImageBackSide,
            taxCode,
            companyName,
            companyAddress,
            representativeName,
            representativePosition,
            exitedPartner,
            exitedPartnerByEmail,
            gender
        } = this.state;

        let param = {
            fullName: fullName,
            type: PartnerType.Individual.value,
            phone: phone,
            email: email,
            emailCCs: emailcc && emailcc.length > 0 ? emailcc.split('\n') : [],
            stageName: stageName,
            dateOfBirth: dateOfBirth,
            hometown: hometown,
            identityNumber: identityNumber,
            issuedOn: issuedOn,
            issuedBy: issuedBy,
            currentAddress: currentAddress,
            idImageFrontSide: idImageFrontSide,
            // idImageFrontSide: "Uploads/Avatars/15_08_2020_02_52_1cc7267157664a998a2263049c3cbc31.png",
            idImageBackSide: idImageBackSide,
            // idImageBackSide: "Uploads/Avatars/15_08_2020_02_52_1cc7267157664a998a2263049c3cbc31.png",
            taxCode: taxCode,
            companyName: companyName,
            companyAddress: companyAddress,
            representativeName: representativeName,
            representativePosition: representativePosition,
            gender: gender
        };

        let valid = true;

        param.emailCCs.map(item => {
            if (item && !emailPattern.test(item.trim())) {
                valid = false
                this.setState({
                    emailccErrors: "Email cc không đúng định dạng."
                })
            }
        })

        if (!(await isValidAsync(this.validationData))) {
            valid = false;
        }
        if (exitedPartner) {
            valid = false
            this.setState({
                cmndErrors: "CMND đối tác đã tồn tại."
            })
        }
        if (exitedPartnerByEmail) {
            valid = false
            this.setState({
                emailErrors: "Email đối tác đã tồn tại."
            })
        }
        if (!issuedOn) {
            valid = false;
            this.setState({
                issuedOnErrors: "Ngày cấp không được bỏ trống."
            })
        }

        if (!dateOfBirth) {
            valid = false;
            this.setState({
                dateOfBirthErrors: "Ngày sinh không được bỏ trống."
            })
        }
        if (!idImageFrontSide) {
            valid = false;
            this.setState({
                idImageFrontSideError: "Ảnh chứng minh mặt trước không được bỏ trống."
            })
        }
        if (!idImageBackSide) {
            valid = false;
            this.setState({
                idImageBackSideError: "Ảnh chứng minh mặt sau không được bỏ trống."
            })
        }

        if (valid) {
            post(
                "partners",
                param,
                (result) => {
                    showSuccess("Thêm đối tác thành công!");
                    this._handleReset();
                    this.idImageFrontSideRef.current._handleReset();
                    this.idImageBackSideRef.current._handleReset();
                    this.props.onAdd();
                    let info = {
                        fullName: fullName,
                        phone: phone,
                        email: email,
                        emailcc: emailcc,
                        address: currentAddress,
                        position: "",
                        isDefault: true,
                        partnerId: result.id
                    }
                    this._addContact(info)
                },
                error => {
                    showError(error);
                }
            );
        }
        else {
            showError("Nội dung không hợp lệ, vui lòng kiểm tra lại thông tin vừa nhập.")
            valid = true;
        }
    }

    _addContact(info) {
        post("contacts", info, () => {
        })
    }

    render() {
        let {
            fullName,
            phone,
            email,
            emailcc,
            stageName,
            dateOfBirth,
            dateOfBirthErrors,
            hometown,
            identityNumber,
            issuedOn,
            issuedOnErrors,
            issuedBy,
            currentAddress,
            exitedPartner,
            emailErrors,
            cmndErrors,
            idImageBackSideError,
            idImageFrontSideError,
            exitedPartnerByEmail,
            gender,
            emailccErrors
        } = this.state;
        let validationData = this.validationData;

        return (
            <table className="table list-item border-none input-form">
                <tbody>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Email</label>
                            <TextInput
                                className="email"
                                value={email}
                                name={"email"}
                                displayName={"Email"}
                                placeholder={"Nhập email"}
                                onChange={e => {
                                    this.setState({ email: e.target.value });
                                }}
                                validations={validationData.email}
                                edit={true}
                                errors={emailErrors}
                            />
                            {exitedPartnerByEmail && (

                                <div className="exited-partner">
                                    <span style={{ color: "#ef4502" }}>Email của đối tác đã tồn tại.</span>
                                    <label>Thông tin đối tác đã tồn tại:</label>
                                    <table className="table border-none">
                                        <tbody>
                                            <tr>
                                                <td className="width50">
                                                    <img
                                                        alt="img"
                                                        src={DefaultUserAvatar}
                                                        style={{ width: "100%" }}
                                                    />
                                                </td>
                                                <td>
                                                    <table className="table border-none">
                                                        <tbody>
                                                            <tr>
                                                                <td>{exitedPartnerByEmail.fullName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{exitedPartnerByEmail.email} | ID: {exitedPartnerByEmail.id}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Email CC</label>
                            <p style={{ opacity: 0.5 }}>Vui lòng nhập đúng định dạng email. Ví dụ: abc@gmail.com</p>
                            <p style={{ opacity: 0.5 }}>Mỗi email một dòng.</p>
                            <TextInput
                                className="emailcc"
                                value={emailcc}
                                name={"emailcc"}
                                displayName={"Email CC"}
                                placeholder={"Email CC"}
                                multiline={true}
                                onChange={e => {
                                    this.setState({ emailcc: e.target.value, emailccErrors: "" });
                                }}
                                edit={true}
                                errors={emailccErrors}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Số CMND</label>

                            <TextInput
                                className="identityNumber"
                                value={identityNumber}
                                name={"identityNumber"}
                                placeholder={"Nhập CMND"}
                                displayName={"Số CMND"}
                                onChange={e => {
                                    this.setState({ identityNumber: e.target.value });
                                }}
                                validations={validationData.identityNumber}
                                edit={true}
                                errors={cmndErrors}
                            />
                            {exitedPartner && (

                                <div className="exited-partner">
                                    <span style={{ color: "#ef4502" }}>Số CMND của đối tác đã tồn tại.</span>
                                    <label>Thông tin đối tác đã tồn tại:</label>
                                    <table className="table border-none">
                                        <tbody>
                                            <tr>
                                                <td className="width50">
                                                    <img
                                                        alt="img"
                                                        src={DefaultUserAvatar}
                                                        style={{ width: "100%" }}
                                                    />
                                                </td>
                                                <td>
                                                    <table className="table border-none">
                                                        <tbody>
                                                            <tr>
                                                                <td>{exitedPartner.fullName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{exitedPartner.email} | ID: {exitedPartner.id}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Ngày cấp</label>

                            <DateTimePicker
                                className="issuedOn"
                                type="date"
                                placeholder={"Ngày cấp"}
                                onChange={value => this.setState({ issuedOn: value, issuedOnErrors: null })}
                                value={issuedOn}
                                edit={true}
                                errors={issuedOnErrors}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Nơi cấp</label>

                            <TextInput
                                className="issuedBy"
                                value={issuedBy}
                                name={"issuedBy"}
                                placeholder={"Nơi cấp cmnd"}
                                displayName={"Nơi cấp CMND"}
                                onChange={e => {
                                    this.setState({ issuedBy: e.target.value });
                                }}
                                validations={validationData.issuedBy}
                                edit={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Nghệ danh</label>
                            <TextInput
                                className="stageName"
                                value={stageName}
                                name={"stageName"}
                                placeholder={"Nhập nghệ danh"}
                                displayName={"Nghệ danh"}
                                onChange={e => {
                                    this.setState({ stageName: e.target.value });
                                }}
                                validations={validationData.stageName}
                                edit={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Họ tên</label>

                            <TextInput
                                className="name"
                                value={fullName}
                                name={"fullName"}
                                placeholder={"Nhập họ tên"}
                                displayName={"Họ tên"}
                                onChange={e => {
                                    this.setState({ fullName: e.target.value });
                                }}
                                validations={validationData.fullName}
                                edit={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Giới tính</label>

                            <RadioGroup aria-label="quiz" name="quiz" style={{ display: "flex", flexDirection: "row", border: "1px solid #dee2e6", borderRadius: "4px", padding: "0px 20px" }} value={gender} onChange={e => this.setState({ gender: e.target.value })}>
                                <FormControlLabel value="Male" style={{ marginRight: "40px", marginBottom: "0px" }} control={<Radio color="primary" />} label="Nam" />
                                <FormControlLabel value="Female" style={{ marginRight: "40px", marginBottom: "0px" }} control={<Radio />} label="Nữ" />
                                <FormControlLabel value="Other" style={{ marginRight: "40px", marginBottom: "0px" }} control={<Radio color="default" />} label="Khác" />
                            </RadioGroup>
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Ngày sinh</label>

                            <DateTimePicker
                                className="dateOfBirth"
                                type="date"
                                placeholder={"Ngày sinh"}
                                onChange={value => this.setState({ dateOfBirth: value, dateOfBirthErrors: null })}
                                value={dateOfBirth}
                                edit={true}
                                errors={dateOfBirthErrors}
                            />
                        </td>
                    </tr>



                    <tr>
                        <td className="coin text-left width300">
                            <label>Nguyên quán</label>
                            <TextInput
                                className="hometown"
                                value={hometown}
                                name={"hometown"}
                                displayName={"Nguyên quán"}
                                placeholder={"Nhập nguyên quán"}
                                onChange={e => {
                                    this.setState({ hometown: e.target.value });
                                }}
                                validations={validationData.hometown}
                                edit={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Địa chỉ hiện tại</label>
                            <TextInput
                                className="currentAddress"
                                value={currentAddress}
                                name={"currentAddress"}
                                displayName={"Địa chỉ hiện tại"}
                                placeholder={"Nhập địa chỉ"}
                                onChange={e => {
                                    this.setState({ currentAddress: e.target.value });
                                }}
                                validations={validationData.currentAddress}
                                edit={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="coin text-left width300">
                            <label>Số điện thoại</label>
                            <TextInput
                                className="phone"
                                value={phone}
                                name={"phone"}
                                placeholder={"Nhập sdt"}
                                displayName={"Số điện thoại"}
                                onChange={e => {
                                    this.setState({ phone: e.target.value });
                                }}
                                validations={validationData.phone}
                                edit={true}
                            />
                        </td>
                    </tr>
                    <tr >
                        <td className="coin text-left width300">
                            <label>Ảnh CMND mặt trước</label>
                            <FileDropZone
                                type={UploadFileType.Avatars.value}
                                edit={true}
                                onChange={files =>
                                    this.setState({
                                        idImageFrontSide:
                                            files.length > 0 ? files[0].relativePath : null,
                                        idImageFrontSideError: null
                                    })
                                }
                                files={this.state.idImageFrontSide}
                                multiImages={false}
                                errors={idImageFrontSideError}
                                ref={this.idImageFrontSideRef}
                            />
                        </td>
                    </tr>
                    <tr >
                        <td className="coin text-left width300 border-none">
                            <label>Ảnh CMND mặt sau</label>
                            <FileDropZone
                                type={UploadFileType.Avatars.value}
                                edit={true}
                                onChange={files =>
                                    this.setState({
                                        idImageBackSide:
                                            files.length > 0 ? files[0].relativePath : null,
                                        idImageBackSideError: null
                                    })
                                }
                                files={this.state.idImageBackSide}
                                multiImages={false}
                                errors={idImageBackSideError}
                                ref={this.idImageBackSideRef}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default Index;
