import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { get, _delete } from "../../../../api";
import { formatDate, objToQuery, confirmSubmit } from '../../../../utils/common'
import DataTable from "../../../common/datatable";
import { TableSizeDefault } from "../../../../constants/constants";
import { showSuccess } from "../../../../utils/app";
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Update from './update'
class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            total: 0,
            skip: 0,
            take: TableSizeDefault.value,
            page: 1,
            quotations: null,
            openAddFanpageQuotation: false,
            openEditFanpageQuotation: false,
            openDeleteOption: false,
            currentOption: undefined
        };
    }

    _getList() {
        let { id } = this.state
        let param = {
            skip: 0,
            take: this.state.take
        }
        var querry = objToQuery(param)
        get("fanpages/" + id + "/quotations" + querry, result => {
            this.setState({
                quotations: result && result.items.sort((a, b) => b.id - a.id),
                total: result && result.total
            });
        });
    }
    handleTableChange(params) {
        this.setState({
            take: params.take
        }, () => {
            this._getList()
        })
    }
    _closeEditFanpageQuotation = () => {
        this.setState({
            openEditFanpageQuotation: false
        })
    }
    componentWillReceiveProps(props) {
        let id = props.id
        if (id !== null && id !== undefined) {
            this.setState({
                id
            },
                () => this._getList()
            )
        }
    }

    componentDidMount() {
        let id = this.props.id
        if (id !== null && id !== undefined) {
            this.setState({
                id
            },
                () => this._getList()
            )
        }
    }
    _onDeleteCampaign = (id) => {
        if (!id) return;
        _delete("fanpage-quotations/" + id, undefined,
            () => {
                this._getList()
                showSuccess("Bỏ thành công")
            })
    }
    render() {
        let {
            quotations,
            total,
            openEditFanpageQuotation
        } = this.state;
        return (
            <div className="box-body product-list campaign-fanpage">
                <DataTable
                    data={quotations}
                    total={total}
                    isPaging={true}
                    renderHeader={() => {
                        return (
                            <thead>
                                <tr>
                                    <th className="text-center">ID</th>
                                    <th className="text-left">Loại </th>
                                    <th className="text-left">Giá</th>
                                    <th className="text-left">Ngày tạo</th>
                                    <th className="text-center" />
                                    <th className="text-center" />
                                </tr>
                            </thead>
                        );
                    }}
                    renderBody={items => {
                        return (
                            <tbody>
                                {items.map((item, i) => (
                                    <tr key={i}>
                                        <td style={{ textAlign: "center" }}>{item.id}</td>
                                        <td className="text-left">{item.quotationName}</td>
                                        <td className="text-left">{item.price}</td>
                                        <td className="text-left">{formatDate(item.createTime)}</td>
                                        <td className="width10">
                                            <Tooltip title="Cập nhật" placement="top">
                                                <IconButton onClick={() => {
                                                    this.setState({
                                                        openEditFanpageQuotation: true,
                                                        currentOption: item
                                                    })
                                                }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </td>

                                        <td className="width10">
                                            <Tooltip title="Loại bỏ" placement="top">
                                                <IconButton>
                                                    <DeleteIcon onClick={() => {
                                                        confirmSubmit("Bỏ campaign", "Bạn có chắc chắn muốn bỏ",
                                                            () => {
                                                                this._onDeleteCampaign(item.id)
                                                            })
                                                    }} />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        );
                    }}
                    onChange={params => this.handleTableChange(params)}
                />
                <Update open={openEditFanpageQuotation}
                    onClose={this._closeEditFanpageQuotation}
                    id={this.state.currentOption !== undefined ? this.state.currentOption.id : ""}
                    onUpdateSucceed={this._getList.bind(this)}
                />
            </div>
        )
    }
}

export default Index;
