import React from "react";
import Modal from '../../common/modal'
import Button from "@material-ui/core/Button";
import TextInput from '../../common/text-input'
import { post } from '../../../api'
import { notEmpty, isValid } from "../../../utils/validators";
import { showSuccess, showError } from "../../../utils/app";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            optionName: "",
            description: "",
            tabIndex: 0
        }
        this.validationData = {
            optionName: [notEmpty]
        };
        this.Individual = React.createRef();
        this.Company = React.createRef();
    }


    _handleAddOption = () => {
        const {
            optionName,
            description
        } = this.state;
        const {
            pageType
        } = this.props

        let param = {
            name: optionName,
            description: description
        };
        if (isValid(this.validationData))
            post("options/" + pageType, param, () => {
                showSuccess("Thêm option thành công")
                this.props.getList()
                this.setState({
                    optionName: "",
                    description: ""
                })
            });
        else {
            showError("Dữ liệu không hợp lệ. Vui lòng kiểm tra lại")
        }
    }


    componentDidMount() {
    }

    render() {
        let {
            optionName,
            description
        } = this.state
        let {
            open,
            onClose
        } = this.props
        return (
            <Modal
                className="add-new-options"
                open={open}
                onClose={onClose}
                fullWidth
                header={
                    <div>
                        <span>THÊM OPTION</span>
                    </div>
                }
                content={
                    <table className="table list-item border-none input-form">
                        <tbody>
                            <tr>
                                <td className="coin text-left width300">
                                    <label>Tên</label>
                                    <TextInput
                                        className="name"
                                        value={optionName}
                                        label={"Tên option"}
                                        name={"optionName"}
                                        placeholder={"Tên option"}
                                        displayName={"Tên option"}
                                        onChange={e =>
                                            this.setState({ optionName: e.target.value })
                                        }
                                        validations={this.validationData.optionName}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left width300">
                                    <label>Mô tả</label>
                                    <TextInput
                                        className="description"
                                        value={description}
                                        label={"Mô tả"}
                                        name={"description"}
                                        placeholder={"Mô tả"}
                                        onChange={e =>
                                            this.setState({ description: e.target.value })
                                        }
                                        displayName={"Mô tả"}
                                        validations={""}
                                        edit={true}
                                        multiline={true}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                action={
                    <Button
                        color="primary"
                        onClick={this._handleAddOption}
                    >
                        Lưu
            </Button>
                }
                allowCancel={true}
            />
        )
    }
}


export default Index;

