import React from "react";
import PaymentCard from './payment-card'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    _onUpdateSuccess() {
        this.props.onUpdateSuccess()
    }

    render() {
        const { paymentInfos, canUpdate } = this.props

        let defaultPayment = []
        if (paymentInfos && paymentInfos.length > 0) {
            defaultPayment = paymentInfos.filter(e => e.isDefault === true)
        }


        return (
            paymentInfos && paymentInfos.length > 0 ? <div className="payment-page">
                <div className="contact-list">
                    {
                        paymentInfos.map((payment, i) =>
                            <PaymentCard
                                info={payment}
                                key={i}
                                isExitDefaultPayment={defaultPayment.length > 0}
                                onUpdateSuccess={() => this._onUpdateSuccess()}
                                paymentId={payment.id}
                                canUpdate={canUpdate}
                            />
                        )
                    }
                </div>
            </div> : <label className="empty-list-label">Chưa có liên hệ nào.</label>
        );
    }
}

export default Index;
