import React from "react";
import Button from "@material-ui/core/Button";
import List from './list'
import AddNew from './add'


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddFanpages: false
    };
    this._getListFanpages = React.createRef()
  }

  _onGetListFanpages = () => {
    this._getListFanpages.current._getList()
  }
  _onCloseAddFanpages = () => {
    this.setState({
      openAddFanpages: false
    })
  }
  componentDidMount() {
    this._onGetListFanpages()
  }

  render() {
    let {
      openAddFanpages
    } = this.state;
    return (
      <div className="box manage-user">
        <div className="box-header">
          <h3 className="box-title">Fanpages</h3>
        </div>
        <div className="box-body product-list">
          <div className="filters-form mb15 balance-search date-time">
            <table className="table list-item border-none">
              <tbody>
                <tr>
                  <td className="text-right">
                    <div className="create-bt">
                      <Button
                        color="primary"
                        className="custom-button"
                        onClick={() =>
                          this.setState({
                            openAddFanpages: true
                          })
                        }
                      >
                        Tạo Fanpage
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <List ref={this._getListFanpages} />
          <AddNew open={openAddFanpages}
            onClose={this._onCloseAddFanpages}
            onAddSucceed={this._onGetListFanpages} />
        </div >

      </div>
    );
  }
}

export default Index;
