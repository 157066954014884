import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton';
import red from '@material-ui/core/colors/red';
import StarIcon from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ToolTip from '@material-ui/core/Tooltip'
import { PaymentType, MetubRoles, TaxPayers } from '../../constants/constants'
import Edit from './update'
import { put, _delete } from '../../api';
import { showSuccess } from '../../utils/app';
import { confirmSubmit } from "../../utils/common";
import { isInRole, getCurrentUser } from '../../auth';


const styles = theme => ({
    card: {
        maxWidth: 400,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
});

class RecipeReviewCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            openEditPayment: false
        };
    }

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    _onUpdateSuccess() {
        this.props.onUpdateSuccess()
        this.setState({
            openEditPayment: false
        })
    }

    _updateDefaut() {
        let param = this.props.info
        if (!param) return
        param.isDefault = !param.isDefault
        put("payment-infos", param, () => {
            showSuccess("Thay đổi thông tin thành công.")
            this.props.onUpdateSuccess()
        })
    }
    _checkRoleUpdateDefault(info) {
        let currentUser = getCurrentUser();
        switch (currentUser.roleCode) {
            case MetubRoles.PARTNER_MANAGER:
                return true
            case MetubRoles.LEADER_PARTNER_MANAGER:
                return (currentUser.id === this.props.partnerManagerId)
            default: return true
        }
    }
    _deletePayment() {
        confirmSubmit(
            "XÓA THÔNG TIN THANH TOÁN",
            "Bạn có chắc chắn muốn xóa thông tin thanh toán của đối tác này???",
            () => {
                let { paymentId } = this.props
                if (paymentId) {
                    _delete("payment-infos/" + paymentId, "", () => {
                        showSuccess("Xóa thông tin thanh toán của đối tác thành công");
                        this.props.onUpdateSuccess();
                    })
                }
            }
        );
    }

    render() {
        const currentUser = getCurrentUser();
        const { classes, info, isExitDefaultPayment, canUpdate } = this.props;

        const { openEditPayment } = this.state

        return (
            <div>
                {
                    info && <Card className={classes.card + " custom-card"}>
                        <CardHeader
                            title={
                                <span className="payment-card-title">
                                    <span>{info.type}</span>
                                    {
                                        info.type ? <span>Thanh toán bằng {PaymentType[info.type].label}</span> : ""
                                    }
                                </span>
                            }
                            action={
                                <Button disabled className={"default-contact-label"}>{info.isDefault ? "MẶC ĐỊNH" : " "}</Button>
                            }
                        />
                        <CardContent>
                            <table className="table border-none">
                                {
                                    info.type === "Paypal"
                                        ? <tbody>
                                            <tr>
                                                <td className="width150">ID Paypal</td>
                                                <td>{info.paypalId}</td>
                                            </tr>
                                            <tr>
                                                <td>Người chịu thuế</td>
                                                <td>{TaxPayers[info.taxPayer] ? TaxPayers[info.taxPayer].label : "-/-"}</td>
                                            </tr>
                                            <tr>
                                                <td>Yêu cầu khác</td>
                                                <td>{info.otherRequest ? info.otherRequest : "-/-"}</td>
                                            </tr>
                                        </tbody>
                                        : <tbody>
                                            <tr>
                                                <td className="width150">Số tài khoản</td>
                                                <td>{info.accountNumber}</td>
                                            </tr>
                                            <tr>
                                                <td>Tên tài khoản</td>
                                                <td>{info.accountName}</td>
                                            </tr>
                                            <tr>
                                                <td>Ngân hàng</td>
                                                <td>{info.bankName}</td>
                                            </tr>
                                            <tr>
                                                <td>Chi nhánh</td>
                                                <td>{info.branchName}</td>
                                            </tr>
                                            <tr>
                                                <td>Mã số thuế</td>
                                                <td>{info.taxCode}</td>
                                            </tr>
                                            <tr>
                                                <td>Người chịu thuế</td>
                                                <td>{TaxPayers[info.taxPayer] ? TaxPayers[info.taxPayer].label : "-/-"}</td>
                                            </tr>
                                            <tr>
                                                <td>Yêu cầu khác</td>
                                                <td>{info.otherRequest ? info.otherRequest : "-/-"}</td>
                                            </tr>
                                        </tbody>
                                }
                            </table>
                        </CardContent>
                        <CardActions className={classes.actions} disableActionSpacing>
                            {
                                (isInRole(MetubRoles.UPDATE_PAYMENT_INFO,
                                    () => this._checkRoleUpdateDefault(info)) ||
                                    currentUser.roleCode === "DATA_MANAGER") &&
                                <div>
                                    {
                                        info.isDefault
                                            ? <ToolTip title={"Huỷ mặc định"} placement="top">
                                                <IconButton onClick={() => this._updateDefaut()} disabled={!canUpdate}>
                                                    <StarIcon color="secondary" />
                                                </IconButton>
                                            </ToolTip>
                                            : (isExitDefaultPayment === true ? null : <ToolTip title={"Đặt làm mặc định"} placement="top">
                                                <IconButton onClick={() => this._updateDefaut()} disabled={!canUpdate}>
                                                    <StarBorder />
                                                </IconButton>
                                            </ToolTip>
                                            )
                                    }
                                </div>
                            }
                            {
                                ((isInRole(MetubRoles.UPDATE_PAYMENT_INFO,
                                    () => this._checkRoleUpdateDefault(info)) &&
                                    !info.isDefault) ||
                                    currentUser.roleCode === "DATA_MANAGER") &&
                                <ToolTip title="Chỉnh sửa thông tin thanh toán" placement="top">
                                    <IconButton onClick={() => this.setState({ openEditPayment: true })} disabled={!canUpdate}>
                                        <EditIcon />
                                    </IconButton>
                                </ToolTip>
                            }
                            {
                                ((isInRole(MetubRoles.DELETE_PAYMENT_INFO,
                                    () => this._checkRoleUpdateDefault(info)) &&
                                    !info.isDefault) ||
                                    currentUser.roleCode === "DATA_MANAGER") &&
                                <ToolTip title="Xoá thông tin thanh toán" placement="top">
                                    <IconButton onClick={() => this._deletePayment()} disabled={!canUpdate}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ToolTip>
                            }
                        </CardActions>
                    </Card>
                }
                <Edit
                    open={openEditPayment}
                    onClose={() => this.setState({ openEditPayment: false })}
                    onUpdateSuccess={() => this._onUpdateSuccess()}
                    info={info}
                />
            </div>
        );
    }
}

RecipeReviewCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);
