import React from "react";
import DataTable from '../common/datatable'
import LogItem from './log-item'
import {
    Book as BookIcon
} from '@material-ui/icons'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    _handleGetchannels(param) {
        let { handleGetLogs } = this.props
        if (!handleGetLogs) return
        handleGetLogs(param)
    }
    _onTablePageChange(param) {
        this.props.onChange(param)
    }



    render() {
        const { logs, total } = this.props
        return (
            <div className="admin-box">
                <span>
                    <BookIcon />
                        Danh sách ghi nhận thay đổi
                        </span>
                {
                    logs.length > 0 ? <div className="logs-list">

                        <DataTable
                            data={logs}
                            total={total}
                            renderHeader={() => {
                                return (
                                    <thead>
                                        <tr>
                                            <th>
                                                <ul className="logs-list-header width100pc">
                                                    <li className="width40pc">Nội dung</li>
                                                    <li className="width30pc">Người chỉnh sửa</li>
                                                    <li className="width20pc">Ngày chỉnh sửa</li>
                                                    <li className="width10pc"></li>
                                                </ul>
                                            </th>
                                        </tr>
                                    </thead>
                                );
                            }}
                            renderBody={items => {
                                return (
                                    <tbody>
                                        {
                                            items.map((item, i) => <tr key={i}>
                                                <td>
                                                    <LogItem logItem={item} />
                                                </td>
                                            </tr>)
                                        }
                                    </tbody>
                                );
                            }}
                            isPaging={true}
                            onChange={param => this._handleGetchannels(param)}
                        />
                    </div> : <label className="empty-list-label">Chưa có ghi nhận nào.</label>
                }
            </div>

        );
    }
}

export default Index;
