import { isInRole, getCurrentUser } from "../auth";
import { MetubRoles, RequestType, ChannelRequestSubType, RequestStatus } from "../constants/constants";
import { objToArray } from "./common";

export function checkRoleUpdateRequest(request) {
    const user = getCurrentUser()

    if (user.roleCode == MetubRoles.LEADER_PARTNER_MANAGER) {
        if (request.status != RequestStatus.Pending.value) return false
        if (request.type === RequestType.Unlink.value)
            return true

        if (request.type === RequestType.UpdateChannel.value && request.subType === ChannelRequestSubType.ChangeSharingRate.value) {
            return true
        }

        return false
    }

    if (user.roleCode === MetubRoles.BOD) {
        if (request.status === RequestStatus.LeaderAccept.value)
            return true

        return false
    }

    return isInRole(MetubRoles.UPDATE_REQUEST);
}

export function roleFilterRequestStatus(request) {

    const user = getCurrentUser()

    let RequestStatusOptions = objToArray(RequestStatus).filter(item => item.value != "None")

    if (user.roleCode == MetubRoles.BOD) {
        RequestStatusOptions = RequestStatusOptions.filter(item => item.value == RequestStatus.BODAccept.value
            || item.value == RequestStatus.LeaderAccept.value
            || item.value == RequestStatus.Denied.value)
    }

    if (user.roleCode == MetubRoles.LEADER_PARTNER_MANAGER) {
        RequestStatusOptions = RequestStatusOptions.filter(item =>
            item.value == RequestStatus.LeaderAccept.value
            || item.value == RequestStatus.Denied.value
            || request && item.value == request.status)
    }

    return RequestStatusOptions

}