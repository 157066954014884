import React from "react";
import Button from "@material-ui/core/Button";
import Individual from "./individual";
import Company from "./company";
import {
  PartnerType,
  MetubRoles,
  InviteStatus,
} from "../../../constants/constants";
import { get } from "../../../api";
import { isInRole, getCurrentUser } from "../../../auth";
import { StickyContainer, Sticky } from "react-sticky";
import { RecentActors as RecentActorsIcon } from "@material-ui/icons";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: null,
      edit: false,
    };
    this.Individual = React.createRef();
    this.Company = React.createRef();
  }

  _handleAddPartner() {
    let { tabIndex } = this.state;
    tabIndex === 0
      ? this.Individual.current._handleAddIndividual()
      : this.Company.current._handleAddCompany();
  }

  _onAdd() {
    this.props.onAdd();
  }

  _getPartner(partnerId) {
    get("partners/" + partnerId, (result) => {
      if (result) {
        this.setState({
          partner: result,
          edit: false,
          defaultInfo: result,
        });
      }
    });
  }

  _cancelUpdate() {
    this.state.partner.type === PartnerType.Individual.value
      ? this.Individual.current._cancelUpdate(() =>
          this.setState({
            edit: false,
          })
        )
      : this.Company.current._cancelUpdate(() =>
          this.setState({
            edit: false,
          })
        );
  }

  _saveUpdate() {
    this.state.partner.type === PartnerType.Individual.value
      ? this.Individual.current._saveUpdate()
      : this.Company.current._saveUpdate();
  }

  componentDidMount() {
    let { partnerId } = this.props;
    if (partnerId) {
      this._getPartner(partnerId);
    }
  }
  _deletePartner() {
    this.state.partner.type === PartnerType.Individual.value
      ? this.Individual.current._deletePartnerIndividual()
      : this.Company.current._deletePartnerCompany();
  }
  _canUpdate() {
    let output = false;
    let { partner } = this.state;
    const currentUser = getCurrentUser();

    if (
      isInRole(MetubRoles.UPDATE_PARTNER, () => {
        return currentUser.id === partner.partnerManager.id;
      }) ||
      currentUser.roleCode === MetubRoles.DATA_MANAGER
    ) {
      output = true;
    }
    if (
      (currentUser.roleCode == MetubRoles.PARTNER_MANAGER ||
        currentUser.roleCode == MetubRoles.LEADER_PARTNER_MANAGER) &&
      InviteStatus[partner.verifyStatus].value != InviteStatus.Pending.value &&
      InviteStatus[partner.verifyStatus].value != InviteStatus.Denied.value
    ) {
      output = false;
    }
    if (currentUser.roleCode === MetubRoles.LEGAL_EXECUTIVE) {
      output = true;
    }
    return output;
  }

  render() {
    let { partner, edit } = this.state;
    let { partnerId } = this.props;
    const currentUser = getCurrentUser();
    return (
      partner && (
        <StickyContainer style={{ padding: 0, position: "relative" }}>
          <div className="admin-box">
            <Sticky topOffset={-50}>
              {({ style }) => (
                <span
                  className="box-head"
                  style={{
                    ...style,
                    top: 50,
                    background: "#fff",
                    zIndex: 1000000,
                  }}
                >
                  <RecentActorsIcon />
                  Thông tin hồ sơ
                  <div>
                    {this._canUpdate() ? (
                      !edit ? (
                        <span style={{ marginLeft: "auto" }}>
                          <Button
                            color={"primary"}
                            onClick={() => this.setState({ edit: true })}
                          >
                            CHỈNH SỬA
                          </Button>
                        </span>
                      ) : (
                        <span style={{ marginLeft: "auto" }}>
                          <Button
                            color={"primary"}
                            onClick={() => this._saveUpdate()}
                          >
                            LƯU
                          </Button>
                          <Button onClick={() => this._cancelUpdate()}>
                            HUỶ
                          </Button>
                        </span>
                      )
                    ) : (
                      ""
                    )}
                    {!edit &&
                      (currentUser.roleCode === "DATA_MANAGER" ||
                        currentUser.roleCode === "ADMIN") && (
                        <span className="width70">
                          <Button
                            color={"primary"}
                            onClick={() => this._deletePartner()}
                          >
                            XOÁ
                          </Button>
                        </span>
                      )}
                  </div>
                </span>
              )}
            </Sticky>
            {partner && (
              <div className="partner-manager">
                {partner.type === PartnerType.Individual.value ? (
                  <Individual
                    ref={this.Individual}
                    onUpdateSuccess={() => this._getPartner(partnerId)}
                    partner={partner}
                    edit={edit}
                    history={this.props.history}
                  />
                ) : (
                  <Company
                    ref={this.Company}
                    onUpdateSuccess={() => this._getPartner(partnerId)}
                    partner={partner}
                    edit={edit}
                    history={this.props.history}
                  />
                )}
              </div>
            )}
          </div>
        </StickyContainer>
      )
    );
  }
}

export default Index;
