import React from "react";
import { post } from "../../api";
import Modal from '../common/modal'
import Button from '@material-ui/core/Button'
import TextInput from '../common/text-input'
import { notEmpty, emailAddress, isValid } from '../../utils/validators'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { showSuccess, showError } from "../../utils/app";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactAddress: "",
            contactPosition: "",
            isDefault: false
        };
        this.validationData = {
            contactName: [notEmpty],
            contactPhone: [notEmpty],
            contactEmail: [emailAddress, notEmpty],
            contactAddress: [notEmpty],
        };
    }

    _addContact() {
        let {
            contactName,
            contactPhone,
            contactEmail,
            contactAddress,
            contactPosition,
            isDefault
        } = this.state

        let param = {
            fullName: contactName,
            phone: contactPhone,
            email: contactEmail,
            address: contactAddress,
            position: contactPosition,
            isDefault: isDefault,
            partnerId: this.props.partnerId
        }
        if (isValid(this.validationData)) {
            post("contacts", param, () => {
                showSuccess("Thêm liên hệ thành công.")
                this.props.onAddSuccess()
                this._resetState()
            })
        } else {
            showError("Vui lòng kiểm tra thông tin bạn vừa nhập.")
        }
    }

    _resetState() {
        this.setState({
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactAddress: "",
            contactPosition: "",
            isDefault: false
        })
    }

    _handleCheckboxChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    render() {
        const validationData = this.validationData
        const {
            contactName,
            contactPhone,
            contactEmail,
            contactAddress,
            contactPosition,
            isDefault
        } = this.state
        const { open } = this.props
        return (
            <Modal
                className="add-new-contact"
                open={open}
                onClose={() => this.props.onClose()}
                fullWidth={true}
                fullScreen={false}
                header={
                    <div>
                        <span>THÊM MỚI LIÊN HỆ</span>
                    </div>
                }
                content={
                    <table className="table border-none input-form">
                        <tbody>
                            <tr>
                                <td>
                                    <label>Họ tên</label>
                                    <TextInput
                                        className="contactName"
                                        value={contactName}
                                        name={"contactName"}
                                        placeholder={"Nhập họ tên"}
                                        displayName={"Họ tên"}
                                        onChange={e => {
                                            this.setState({ contactName: e.target.value })
                                        }}
                                        validations={validationData.contactName}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Số điện thoại</label>
                                    <TextInput
                                        className="contactPhone"
                                        value={contactPhone}
                                        name={"contactPhone"}
                                        placeholder={"Nhập sdt"}
                                        displayName={"Họ tên"}
                                        onChange={e => {
                                            this.setState({ contactPhone: e.target.value })
                                        }}
                                        validations={validationData.contactPhone}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Email</label>
                                    <TextInput
                                        className="contactEmail"
                                        value={contactEmail}
                                        name={"contactEmail"}
                                        placeholder={"Nhập Email"}
                                        displayName={"Email"}
                                        onChange={e => {
                                            this.setState({ contactEmail: e.target.value })
                                        }}
                                        validations={validationData.contactEmail}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Địa chỉ</label>
                                    <TextInput
                                        className="contactAddress"
                                        value={contactAddress}
                                        name={"contactAddress"}
                                        placeholder={"Nhập địa chỉ"}
                                        displayName={"Địa chỉ"}
                                        onChange={e => {
                                            this.setState({ contactAddress: e.target.value })
                                        }}
                                        validations={validationData.contactAddress}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Chức vụ</label>
                                    <TextInput
                                        className="contactPosition"
                                        value={contactPosition}
                                        name={"contactPosition"}
                                        placeholder={"Nhập chức vụ"}
                                        displayName={"Chức vụ"}
                                        onChange={e => {
                                            this.setState({ contactPosition: e.target.value })
                                        }}
                                        validations={validationData.contactPosition}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-right border-none">
                                    <FormControlLabel
                                        className="color-primary"
                                        control={
                                            <Checkbox
                                                checked={isDefault}
                                                onChange={this._handleCheckboxChange('isDefault')}
                                                value={"isDefault"}
                                                color="primary"
                                            />
                                        }
                                        label="Đặt làm mặc định sau khi tạo."
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                }
                action={
                    <Button
                        color="primary"
                        onClick={() => this._addContact()}
                    >
                        Lưu
                    </Button>
                }
                allowCancel={true}
            />
        );
    }
}

export default Index;
