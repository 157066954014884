import React from "react";
import { get } from "../../../api";
import { TableSizeDefault } from '../../../constants/constants'

import { objToQuery } from "../../../utils/common";
import List from './list'
import {
    Book as BookIcon
} from '@material-ui/icons'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: null,
            searchParam: { skip: 0, take: TableSizeDefault.value },
            total: 0
        };
    }

    _handleGetLogs(param) {
        if (!param) return

        let { channelId } = this.props
        if (!channelId) return

        let getParam = {
            Skip: param.skip,
            Take: param.take
        }
        var query = objToQuery(getParam);

        get("channels/" + channelId + "/modification-logs" + query, result => {
            this.setState({
                logs: result.items,
                total: result.total
            })
        })
    }

    componentDidMount() {
        let { searchParam } = this.state
        this._handleGetLogs(searchParam)
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.changeLogs !== nextProps.changeLogs) {
            let { searchParam } = this.state
            this._handleGetLogs(searchParam)
        }
    }

    render() {
        let {
            logs,
            total
        } = this.state

        return (
            <div className="admin-box">
                <span>
                    <BookIcon />
                    Ghi nhận hệ thống
                </span>
                <div className="logs-page">
                    {
                        logs && <List logs={logs} total={total} handleGetLogs={(param) => this._handleGetLogs(param)} />
                    }
                </div>
            </div>
        );
    }
}

export default Index;
