import React from "react";
import List from './list'
import AddNew from './add-new'
import Button from '@material-ui/core/Button'
import { get } from "../../api";
import {
    Note as NoteIcon
} from '@material-ui/icons'

import { getCurrentUser } from "../../auth";
import { MetubRoles } from "../../constants/constants";
import { checkRolePM } from "../../utils/common";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: [],
            openAddNewDialog: false
        };
    }

    _handleGetNotes(partnerId) {
        get("partners/" + partnerId + "/notes", result => {
            this.setState({
                notes: result.items
            })
        })
    }

    componentDidMount() {
        let { partnerId } = this.props
        this._handleGetNotes(partnerId)
    }

    render() {
        let { notes, openAddNewDialog } = this.state
        const { partnerId, partner } = this.props
        let currentUser = getCurrentUser()
        return (
            <div className="contact-page">
                <div className="action">
                    <div className="admin-box">
                        <span>
                            <NoteIcon />
                            Danh sách ghi chú
                            {
                                partner && partner.verifyStatus !== "Discontinued" && <>
                                    {
                                        checkRolePM(partner) ? <Button
                                            color={"primary"}
                                            onClick={() => this.setState({ openAddNewDialog: !openAddNewDialog })}
                                        >
                                            Tạo mới
                                        </Button> : ""
                                    }
                                </>
                            }
                        </span>
                        <List notes={notes} onUpdateSuccess={() => this._handleGetNotes(partnerId)} partner={partner} />
                    </div>
                </div>
                <AddNew
                    open={openAddNewDialog}
                    onClose={() => this.setState({ openAddNewDialog: false })}
                    onAddSuccess={() => this.setState({ openAddNewDialog: false }, () => this._handleGetNotes(partnerId))}
                    partnerId={partnerId} />
            </div>
        );
    }
}

export default Index;
