import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Fab, CardHeader, Avatar, Divider, List, ListItem, ListItemText, Button, Badge, Paper, ClickAwayListener, Typography } from '@material-ui/core'
import FeaturedPlayList from '@material-ui/icons/FeaturedPlayList';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import "react-tabs/style/react-tabs.css";
import CustomTab from "../../common/tabs";
import PartnerInfo from "./partner-info";
import Contact from "../../contacts";
import Channel from '../../channel';
import Video from '../../video';
import Contract from '../../contracts'
import PaymentInfo from '../../payment-info'
import Payment from '../../payment'
import MonthlyPayment from '../../monthly-payment'
import Note from '../../notes'
import Request from '../../requests'
import Logs from '../../logs'
import { jsonFromUrlParams, objToArray, objToQuery } from "../../../utils/common";
import { PINED_REQUEST } from '../../../constants/localStorageKeys'
import moment from 'moment'
import { BASE_API } from '../../../constants/appSettings'
import { RequestStatus, UploadFileType, MetubRoles, UserRole } from '../../../constants/constants'
import FileDropZone from '../../common/file-drop-zone'
import Select from '../../common/select'
import TextInput from '../../common/text-input'
import { put, get } from '../../../api';
import { showSuccess } from '../../../utils/app';
import { isInRole, getCurrentUser } from '../../../auth';
import $ from 'jquery'
import { checkRoleUpdateRequest } from '../../../utils/roleChecker';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        display: 'block',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        marginTop: "50px"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
});

class PersistentDrawerRight extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            tabIndex: 0,
            pinRequest: null,
            edit: false,
            selectedStatus: null,
            notes: "",
            openPinMenu: false,
            partner: null,
            requestPending: null,
            changeLogs: false,
            reGetListRequest: false
        };
    }

    _pinRequest() {
        window.sessionStorage.removeItem(PINED_REQUEST)
        this.setState({
            pinRequest: null
        })
    }
    _getPartner(partnerId) {
        get("partners/" + partnerId, result => {
            this.setState({
                partner: result
            })
        })
    }
    _updateRequestStatus() {
        if (this.state.pinRequest) {
            let {
                notes,
                selectedStatus,
                pinRequest
            } = this.state
            let param = {
                notes: notes,
                status: selectedStatus.value,
                id: pinRequest ? pinRequest.id : 0
            }
            put("requests/status", param, () => {
                showSuccess("Cập nhật trạng thái thành công!")
                this.setState({
                    open: false,
                    edit: false,
                    reGetListRequest: !this.state.reGetListRequest
                })
                this._getRequestPending()
            })
        }
    }

    _renderTabIndex(array, exceptArray) {
        if (!exceptArray) return array
        exceptArray.map(item => {
            if (item) {
                array = array.filter(e => e.toString() != item.toString())
            }
        })
        return array
    }
    _renderTabContent(array, exceptArray) {
        if (!exceptArray) return array
        for (let i = exceptArray.length - 1; i >= 0; i--) {
            if (exceptArray[i] !== null) {
                array.splice(exceptArray[i], 1)
            }
        }
        return array
    }

    _getRequestPending() {
        var partnerId = this.props.match.params.id;
        if (!partnerId) return
        let getParam = {
            id: partnerId
        }
        var query = objToQuery(getParam);

        get("partners/" + partnerId + "/requests" + query, result => {
            let requestPending = result.items.filter(item => item.status === RequestStatus.Pending.value)
            this.setState({
                requestPending: requestPending
            })
        })
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleOpenPinMenu() {
        this.setState({ openPinMenu: !this.state.openPinMenu })
    }
    handleClosePinMenu = () => {
        this.setState({ openPinMenu: false })
    }

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    handleToggle = () => {
        this.setState(state => ({ open: !this.state.open }));
    };

    hanldeClickRequest(request) {
        this.setState({
            pinRequest: request
        }, this.handleClosePinMenu)

        this.handleDrawerOpen()
    }


    componentWillMount() {
        let searchParam = jsonFromUrlParams(window.location.search)
        if (searchParam && searchParam.tab) {
            this.setState({ tabIndex: parseInt(searchParam.tab) })
        }
        $(window.opera ? 'html' : 'html, body').animate({
            scrollTop: 0
        }, 'slow');
    }

    componentDidMount() {
        let partnerId = this.props.match.params.id;
        partnerId && this._getPartner(partnerId)
        isInRole(MetubRoles.VIEW_REQUEST) && this._getRequestPending()
        this.remindPayment()
    }

    remindPayment() {
        const currentUser = getCurrentUser()
        let partnerId = this.props.match.params.id;
        if (currentUser.roleCode == MetubRoles.PARTNER_MANAGER) {
            get("partners/" + partnerId + "/payment-infos", result => {
                if (result.items.length == 0)
                    alert("Vui lòng cập nhật thông tin thanh toán cho tối tác");
            })
        }
    }

    onChangeLogs = () => {
        this.setState({ changeLogs: !this.state.changeLogs })
    }

    // hanleCheckRole(){
    //     const currentUser = getCurrentUser()
    //     switch (currentUser.roleCode){
    //         case UserRole.LeaderPartnerManager:
    //             return true
    //         case UserRole.PartnerManager:
    //             return this.state.partner.partnerManager.id === currentUser.id
    //     }
    // }

    render() {
        const { classes, theme, history } = this.props;
        let { open, tabIndex, pinRequest, edit, selectedStatus, notes, openPinMenu, partner, requestPending } = this.state;
        let RequestStatusOptions = objToArray(RequestStatus).filter(item => item.value != "None")
        let pinRequestStograte = window.sessionStorage.getItem(PINED_REQUEST)
        pinRequest = pinRequest ? pinRequest : (pinRequestStograte ? JSON.parse(pinRequestStograte).request : null)

        if (!pinRequest) open = false
        if (pinRequest !== this.state.pinRequest)
            this.setState({
                pinRequest: pinRequest
            })

        const currentUser = getCurrentUser()

        return (
            <div className={classes.root}>
                <Typography variant="h6" gutterBottom align="center" className="box-title">
                    <div>
                        Thông tin đối tác <span className="ellipsis header-name">{partner ? partner.fullName : ""}</span>
                        <p>Quản lý thông tin chi tiết đối tác.</p>
                    </div>
                </Typography>
                <main
                    className={classNames(classes.content, {
                        [classes.contentShift]: open,
                    })}
                    style={{
                        padding: "0px",
                    }}
                >
                    {
                        partner && <div className="home-page">
                            <CustomTab
                                onChange={tabIndex => this.props.history.replace("?tab=" + tabIndex)}
                                value={tabIndex}
                                disableScroll={true}
                                tabLabels={
                                    this._renderTabIndex([
                                        "Thông tin",
                                        "Liên hệ",
                                        "Kênh",
                                        "Video",
                                        "Hợp đồng",
                                        "TT thanh toán",
                                        // "LS Thanh toán",
                                        // "TT hằng tháng",
                                        "YC Cập nhật",
                                        "Ghi chú",
                                        <span onClick={this.onChangeLogs}>Logs</span>
                                        // ],[
                                        //         !isInRole(MetubRoles.VIEW_CONTACT, () => { return partner.partnerManager.id === currentUser.id }) ? "Liên hệ" : null,
                                        //         !isInRole(MetubRoles.VIEW_VIDEO, () => { return partner.partnerManager.id === currentUser.id }) ? "Video" : null,
                                        //         !isInRole(MetubRoles.VIEW_CONTRACT, () => { return partner.partnerManager.id === currentUser.id }) ? "Hợp đồng" : null,
                                        //         !isInRole(MetubRoles.VIEW_PAYMENT_INFO, () => { return partner.partnerManager.id === currentUser.id }) ? "TT thanh toán" : null,
                                        //         !isInRole(MetubRoles.VIEW_PAYMENT, () => { return partner.partnerManager.id === currentUser.id }) ? "LS Thanh toán" : null,
                                        //         !isInRole(MetubRoles.VIEW_MONTHLY_PAYMENT, () => { return partner.partnerManager.id === currentUser.id }) ? "TT hằng tháng" : null,
                                        //         !isInRole(MetubRoles.VIEW_REQUEST, () => { return partner.partnerManager.id === currentUser.id }) ? "YC Cập nhật" : null,
                                        //         // !isInRole(null,null ) ? "Ghi chú" : null,
                                        //         !isInRole(MetubRoles.VIEW_LOGS) ? <span onClick={this.onChangeLogs}>Logs</span> : null
                                        //     ])
                                    ], [
                                        !isInRole(MetubRoles.VIEW_CONTACT) ? "Liên hệ" : null,
                                        !isInRole(MetubRoles.VIEW_CHANNEL) ? "Kênh" : null,
                                        !isInRole(MetubRoles.VIEW_VIDEO) ? "Video" : null,
                                        !isInRole(MetubRoles.VIEW_CONTRACT) ? "Hợp đồng" : null,
                                        !isInRole(MetubRoles.VIEW_PAYMENT_INFO) ? "TT thanh toán" : null,
                                        // !isInRole(MetubRoles.VIEW_PAYMENT) ? "LS Thanh toán" : null,
                                        // !isInRole(MetubRoles.VIEW_MONTHLY_PAYMENT) ? "TT hằng tháng" : null,
                                        !isInRole(MetubRoles.VIEW_REQUEST) ? "YC Cập nhật" : null,
                                        false ? "Ghi chú" : null,
                                        !isInRole(MetubRoles.VIEW_LOGS) ? <span onClick={this.onChangeLogs}>Logs</span> : null
                                    ])
                                }
                                tabContainers={
                                    this._renderTabContent([
                                        <PartnerInfo partnerId={partner.id} partner={partner} history={history} />,
                                        <Contact partner={partner} partnerManagerId={partner.partnerManager.id} history={history} />,
                                        <Channel partnerId={partner.id} partner={partner} history={history} disableHeader={true} />,
                                        <Video partnerId={partner.id} partner={partner} disableHeader={true} />,
                                        <Contract partnerId={partner.id} partner={partner} partnerManager={partner.partnerManager} disableHeader={true} />,
                                        <PaymentInfo partner={partner} />,
                                        // <Payment partnerId={partner.id} />,
                                        // <MonthlyPayment partnerId={partner.id} />,
                                        <Request partnerId={partner.id} partner={partner} onUpdateSuccess={() => this._getRequestPending()} onAddNewSuccess={() => this._getRequestPending()} reGetListRequest={this.state.reGetListRequest} />,
                                        <Note partnerId={partner.id} partner={partner} />,
                                        <Logs partnerId={partner.id} changeLogs={this.state.changeLogs} />
                                        // ],[
                                        //     !isInRole(MetubRoles.VIEW_CONTACT, () => { return partner.partnerManager.id === currentUser.id }) ? 1 : null,
                                        //     !isInRole(MetubRoles.VIEW_VIDEO, () => { return partner.partnerManager.id === currentUser.id }) ? 3 : null,
                                        //     !isInRole(MetubRoles.VIEW_CONTRACT, () => { return partner.partnerManager.id === currentUser.id }) ? 4 : null,
                                        //     !isInRole(MetubRoles.VIEW_PAYMENT_INFO, () => { return partner.partnerManager.id === currentUser.id }) ? 5 : null,
                                        //     !isInRole(MetubRoles.VIEW_PAYMENT, () => { return partner.partnerManager.id === currentUser.id }) ? 6 : null,
                                        //     !isInRole(MetubRoles.VIEW_MONTHLY_PAYMENT, () => { return partner.partnerManager.id === currentUser.id }) ? 7 : null,
                                        //     !isInRole(MetubRoles.VIEW_REQUEST, () => { return partner.partnerManager.id === currentUser.id }) ? 8 : null,
                                        //     // !isInRole(null, () => { return partner.partnerManager.id === currentUser.id }) ? 9 : null,
                                        //     !isInRole(MetubRoles.VIEW_LOGS) ? 10 : null
                                        // ])
                                    ], [
                                        !isInRole(MetubRoles.VIEW_CONTACT) ? 1 : null,
                                        !isInRole(MetubRoles.VIEW_VIDEO) ? 3 : null,
                                        !isInRole(MetubRoles.VIEW_CONTRACT) ? 4 : null,
                                        !isInRole(MetubRoles.VIEW_PAYMENT_INFO) ? 5 : null,
                                        // !isInRole(MetubRoles.VIEW_PAYMENT) ? 6 : null,
                                        // !isInRole(MetubRoles.VIEW_MONTHLY_PAYMENT) ? 7 : null,
                                        !isInRole(MetubRoles.VIEW_REQUEST) ? 6 : null,
                                        false ? 7 : null,
                                        // !isInRole(null, () => { return partner.partnerManager.id === currentUser.id }) ? 9 : null,
                                        !isInRole(MetubRoles.VIEW_LOGS) ? 8 : null
                                    ])
                                }

                            />
                            {

                                isInRole(MetubRoles.VIEW_REQUEST, () => { return partner.partnerManager.id === currentUser.id })
                                && <Fab color="primary" aria-label="Add"
                                    style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: "1199" }}
                                    onClick={() => this.handleOpenPinMenu()}
                                >
                                    {
                                        requestPending && requestPending.length > 0
                                            ? <Badge color="secondary" badgeContent={requestPending.length} ><FeaturedPlayList /></Badge>
                                            : <FeaturedPlayList />
                                    }
                                </Fab>

                            }
                            <div className={"pin-menu" + (openPinMenu ? " active" : "")}>
                                <Paper>
                                    <ClickAwayListener onClickAway={this.handleClosePinMenu}>
                                        <List className={"request-pending"}>
                                            {
                                                requestPending && requestPending.length > 0 && requestPending.map((item, i) =>
                                                    <ListItem onClick={() => this.hanldeClickRequest(item)} key={i} >
                                                        <ListItemText

                                                            primary={
                                                                <span style={{ fontSize: "15px" }}>{
                                                                    item.content && item.content.length > 50 ? (item.content.substr(0, 49) + "...") : item.content
                                                                }</span>
                                                            }
                                                            secondary={
                                                                <div style={{ justifyContent: "space-between", display: "flex" }}>
                                                                    <span>{item.status}</span>
                                                                    <span>{moment(item.createTime).format("DD/MM/YYYY hh:mm")}</span>
                                                                </div>
                                                            }
                                                        />
                                                    </ListItem>
                                                )
                                            }
                                        </List>
                                    </ClickAwayListener>
                                </Paper>
                            </div>
                        </div>
                    }
                </main>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="right"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={this.handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>

                    </div>
                    {
                        pinRequest && <div style={{ padding: "0px 20px " }}>
                            {
                                !edit ? <div>
                                    <div style={{ padding: "10px 0px" }}>
                                        {
                                            edit ? <label>Thay đổi trạng thái</label> : <label>Yêu cầu cập nhật</label>
                                        }
                                    </div>
                                    <Divider />
                                    <label style={{ margin: "10px 0px 5px 0px" }}>Thông tin</label>
                                    <table className="table border-bottom">
                                        <tbody>
                                            <tr>
                                                <td className="width120">
                                                    <span>Ngày tạo</span>
                                                </td>
                                                <td>
                                                    {
                                                        moment(pinRequest.createTime).format("DD/MM/YYYY hh:mm")
                                                    }
                                                </td>
                                            </tr>
                                            {
                                                pinRequest.requestUser && <tr className="border-none">
                                                    <td>
                                                        <span>Người tạo</span>
                                                    </td>
                                                    <td>
                                                        <CardHeader
                                                            avatar={
                                                                <Avatar src={BASE_API + pinRequest.requestUser.avatar} >
                                                                </Avatar>
                                                            }
                                                            title={
                                                                pinRequest.requestUser.fullName
                                                            }
                                                            subheader={
                                                                pinRequest.requestUser.email
                                                            }
                                                            style={{
                                                                padding: "0px"
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td>
                                                    <span>Trạng thái</span>
                                                </td>
                                                <td>
                                                    <table className="table border-none">
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-left">
                                                                    <label>
                                                                        {
                                                                            RequestStatus[pinRequest.status].label
                                                                        }
                                                                    </label>
                                                                </td>
                                                                <td className="text-right">
                                                                    {checkRoleUpdateRequest(pinRequest) && <span
                                                                        onClick={() => this.setState({ edit: true })}
                                                                        style={{
                                                                            color: "#3F51B5",
                                                                            cursor: "pointer"
                                                                        }}
                                                                    >Cập nhật</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    {
                                                        (pinRequest.status !== RequestStatus.None.value && pinRequest.status !== RequestStatus.Pending.value)
                                                        && <tabel className="table border-bottom">
                                                            <tbody>
                                                                {
                                                                    pinRequest.confirmUser && <tr>
                                                                        <td>
                                                                            <Divider />
                                                                            <span>Người duyệt</span>
                                                                            <CardHeader
                                                                                avatar={
                                                                                    <Avatar src={BASE_API + pinRequest.confirmUser.avatar} >
                                                                                    </Avatar>
                                                                                }
                                                                                title={
                                                                                    pinRequest.confirmUser.fullName
                                                                                }
                                                                                subheader={
                                                                                    pinRequest.confirmUser.email
                                                                                }
                                                                                style={{
                                                                                    padding: "0px"
                                                                                }}
                                                                            />
                                                                            <Divider />
                                                                            <span>Ghi chú</span>
                                                                            <pre>
                                                                                {
                                                                                    pinRequest.notes
                                                                                }
                                                                            </pre>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </tabel>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <label style={{ margin: "10px 0px 5px 0px" }}>Nội dung cập nhật</label>
                                    <pre>
                                        {
                                            pinRequest.content
                                        }
                                    </pre>
                                    <label style={{ margin: "10px 0px 5px 0px" }}>File mô tả</label>
                                    {
                                        pinRequest.files.length > 0 ? <FileDropZone
                                            type={UploadFileType.Contracts.value}
                                            edit={false}
                                            multiImages={true}
                                            files={pinRequest.files}
                                        /> : <span className="empty-list-label">Chưa có file mô tả</span>
                                    }

                                </div> : <div>
                                    <table className="table border-none">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table className="table border-none">
                                                        <tr>
                                                            <td className="width150">
                                                                Trạng thái
                                                            </td>
                                                            <td>
                                                                <Select
                                                                    options={RequestStatusOptions}
                                                                    value={selectedStatus ? selectedStatus : (pinRequest ? RequestStatus[pinRequest.status] : RequestStatus.None)}
                                                                    allowNull={false}
                                                                    onChange={value => this.setState({ selectedStatus: value })}
                                                                    edit={true}
                                                                />
                                                            </td>
                                                        </tr>

                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Ghi chú</label>
                                                    <TextInput
                                                        multiline={true}
                                                        edit={true}
                                                        value={notes}
                                                        onChange={e => this.setState({ notes: e.target.value })}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                {
                                                    edit && <td className="text-right">
                                                        <Button
                                                            onClick={() => this.setState({ edit: false })}
                                                            className="m10"
                                                        >
                                                            Huỷ
                                                        </Button>
                                                        <Button variant="contained" color="primary" onClick={() => this._updateRequestStatus()}>
                                                            Lưu
                                                        </Button>
                                                    </td>
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    }
                </Drawer>
            </div>
        );
    }
}

PersistentDrawerRight.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PersistentDrawerRight);

