import React from "react";
import Button from "@material-ui/core/Button";
import { get, put } from "../../../api";
import update from 'react-addons-update';
import { notEmpty, justNumber, isValid } from "../../../utils/validators";
import CardHeader from '@material-ui/core/CardHeader';
import TextInput from '../../common/text-input'
import ListFanpage from './fanpage-campaigns'
import ListQuotation from './fanpage-quotation'
import DatetimePicker from '../../common/datetime-picker'
import { Card, CardContent } from "@material-ui/core";
import { showSuccess, showError } from "../../../utils/app";
import CustomTabs from '../../common/tabs'



class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fanpage: null,
            isEdit: false,
            selectedStatus: null,
            createTime: null,
            toDate: null,
            openAddFanpage: false
        };
        this.validationData = {
            name: [notEmpty],
            likeCount: [notEmpty, justNumber],
            viewCount: [notEmpty, justNumber]
        };
    }

    handleTableChange(params) {
        this.setState({
            take: params.take
        }, () => {
            this._getList()
        })
    }
    _getDetails = () => {
        let id = this.props.match.params.id
        if (!id) return
        get("fanpages/" + id,
            (res) => {
                this.setState({
                    fanpage: res && res,
                    createTime: res && res.createTime
                })
            })
    }

    _onUpdateDetails = () => {
        let param = {
            name: this.state.fanpage.name,
            createTime: this.state.fanpage.fromDate,
            id: this.state.fanpage.id,
            likeCount: this.state.fanpage.likeCount,
            viewCount: this.state.fanpage.viewCount
        }
        if (isValid(this.validationData))
            put("fanpages", param, () => {
                this._getDetails()
                this.setState({
                    isEdit: false
                })
                showSuccess("Cập nhật thành công")
            })
        else {
            showError("Dữ liệu không hợp lệ. Vui lòng kiểm tra lại")
        }
    }
    componentWillReceiveProps() {

    }

    componentDidMount() {
        this._getDetails()
    }

    render() {
        let {
            fanpage,
            isEdit
        } = this.state;
        if (!fanpage) return null
        return (
            <div className="box manage-user">
                <Card className="card">
                    <CardHeader
                        subheader={
                            <div className="campaign-name">
                                <h3><span>Fanpage:</span>
                                    <TextInput
                                        className="customer-name"
                                        value={fanpage.name}
                                        label={""}
                                        name={"name"}
                                        placeholder={"Tên fanpage"}
                                        onChange={e => this.setState({
                                            fanpage: update(fanpage, {
                                                name: {
                                                    $set: e.target.value
                                                }
                                            })
                                        })
                                        }
                                        validationData={this.validationData.nam}
                                        displayName={"Tên fanpage"}
                                        edit={isEdit}
                                        multiline={false}
                                    />
                                </h3>
                            </div>
                        }
                        action={
                            <div>
                                {
                                    isEdit === false ?
                                        <div className="create-bt">
                                            <Button
                                                color="primary"
                                                className="custom-button"
                                                onClick={() =>
                                                    this.setState({
                                                        isEdit: true
                                                    })
                                                }
                                            >
                                                Chỉnh sửa
                                            </Button>
                                        </div>
                                        :
                                        <div className="create-bt">
                                            <Button
                                                color="primary"
                                                className="custom-button"
                                                onClick={() =>
                                                    this._onUpdateDetails()
                                                }
                                            >
                                                Lưu
                                            </Button>
                                            <Button
                                                color="default"
                                                className="custom-button"
                                                onClick={() =>
                                                    this.setState({
                                                        isEdit: false
                                                    },
                                                        () => {
                                                            this._getDetails()
                                                        })
                                                }
                                            >
                                                Hủy
                                            </Button>
                                        </div>
                                }
                            </div>
                        }
                    />
                    <CardContent >
                        <table className="table list-item border-none">
                            <tbody>
                                <tr>
                                    <td>
                                        <table className="tb-child">
                                            <tbody>
                                                <tr>
                                                    <td className="td left"><span>ID</span></td>
                                                    <td>
                                                        <TextInput
                                                            className="customer-name"
                                                            value={fanpage.id}
                                                            label={""}
                                                            name={"description"}
                                                            placeholder={"ID"}
                                                            validationData={""}
                                                            displayName={"ID"}
                                                            edit={false}
                                                            multiline={false}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="td left"><span>Ngày tạo</span></td>
                                                    <td>
                                                        <td>
                                                            <DatetimePicker
                                                                className="cooperatedDate"
                                                                placeholder={"Ngày tạo"}
                                                                onChange={value => this.setState({
                                                                    fanpage: update(fanpage, {
                                                                        createTime: {
                                                                            $set: value
                                                                        }
                                                                    })
                                                                })
                                                                }
                                                                value={fanpage.createTime}
                                                                edit={isEdit}
                                                            />
                                                        </td>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>
                                        <table className="tb-child">
                                            <tbody>
                                                <tr>
                                                    <td className="td left"><span>Lượt like</span></td>
                                                    <td>
                                                        <TextInput
                                                            className="customer-name"
                                                            value={fanpage.likeCount}
                                                            label={""}
                                                            name={"likeCount"}
                                                            placeholder={"Lượt like"}
                                                            onChange={e => this.setState({
                                                                fanpage: update(fanpage, {
                                                                    likeCount: {
                                                                        $set: e.target.value
                                                                    }
                                                                })
                                                            })
                                                            }
                                                            validationData={this.validationData.likeCount}
                                                            displayName={"Lượt like"}
                                                            edit={isEdit}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="td left"><span>Lượt xem</span></td>
                                                    <td>
                                                        <TextInput
                                                            className="customer-name"
                                                            value={fanpage.viewCount}
                                                            label={""}
                                                            name={"viewCount"}
                                                            placeholder={"Lượt xem"}
                                                            onChange={e => this.setState({
                                                                fanpage: update(fanpage, {
                                                                    viewCount: {
                                                                        $set: e.target.value
                                                                    }
                                                                })
                                                            })
                                                            }
                                                            validationData={this.validationData.viewCount}
                                                            displayName={"Lượt like"}
                                                            edit={isEdit}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </CardContent>
                </Card>
                <CustomTabs
                    value={0}
                    tabLabels={["Danh sách campaigns", "Danh sách báo giá"]}
                    tabContainers={[
                        <ListFanpage id={fanpage.id} />,
                        <ListQuotation id={fanpage.id} />
                    ]}
                ></CustomTabs>
            </div>
        );
    }
}

export default Index;
