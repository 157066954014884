import React from "react";
import { Button } from '@material-ui/core'
import Modal from '../common/modal'
import { UploadFileType } from '../../constants/constants'
import FileDropZone from '../common/file-drop-zone'

import { post, get } from "../../api";
import moment from "moment";
import { showSuccess, showError } from "../../utils/app";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentFile: "",
            paymentFileError: null
        };
        this.paymentFileRef = React.createRef();
    }

    _import() {
        let { paymentFile } = this.state
        let valid = true;

        if (!paymentFile) {
            valid = false;
            this.setState({
                paymentFileError: "Vui lòng chọn file trước khi import."
            })
        }

        if (valid) {
            let param = {
                date: moment(),
                fileRelativePath: paymentFile
            }
            post("monthly-payments/import", param, result => {
                get("Payenpayments/scan", () => {
                    showSuccess("Import thông tin thành công.")
                    let { onClose } = this.props
                    if (onClose) onClose()
                })
            }, errors => {
                showError("File bạn chọn không đúng định dạng.")
            })
        }
    }

    render() {
        let {
            open,
        } = this.props
        let {
            paymentFileError
        } = this.state
        return (
            <div className="import-payment">
                <Modal
                    className="import-payment"
                    open={open}
                    onClose={() => this.props.onClose()}
                    fullWidth={true}
                    fullScreen={false}
                    header={
                        <div>
                            <span>IMPORT THÔNG TIN THANH TOÁN</span>
                        </div>
                    }
                    content={
                        <table className="table border-bottom">
                            <tbody>
                                <tr>
                                    <td className=" vertical-top">
                                        <FileDropZone
                                            type={UploadFileType.Avatars.value}
                                            edit={true}
                                            onChange={files =>
                                                this.setState({
                                                    paymentFile:
                                                        files.length > 0 ? files[0].relativePath : null,
                                                    paymentFileError: null
                                                })
                                            }
                                            multiImages={false}
                                            errors={paymentFileError}
                                            ref={this.paymentFileRef}
                                        />
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    }
                    action={
                        <Button
                            color="primary"
                            onClick={() => this._import()}
                        >
                            Import
                        </Button>
                    }
                    allowCancel={true}
                />
            </div>
        )
    }
}
export default Index;