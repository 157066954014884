import React from "react";
import ContactCard from './contact-card'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    _onUpdateSuccess() {
        this.props.onUpdateSuccess()
    }

    render() {
        const { contacts, partnerId, history, canUpdate } = this.props
        let defaultContact = []
        if (contacts && contacts.length > 0) {
            defaultContact = contacts.filter(e => e.isDefault === true)
        }
        return (
            contacts && contacts.length > 0 ? <div className="contact-page">
                <div className="contact-list">
                    {
                        contacts.map((contact, i) =>
                            <ContactCard
                                info={contact} key={i}
                                isExitDefaultContact={defaultContact.length > 0}
                                onUpdateSuccess={() => this._onUpdateSuccess()}
                                partnerManagerId={this.props.partnerManagerId}
                                partnerId={partnerId}
                                contactId={contact.id}
                                history={history}
                                canUpdate={canUpdate}
                            />
                        )
                    }
                </div>
            </div> : <label className="empty-list-label">Chưa có liên hệ nào.</label>
        );
    }
}

export default Index;
