import React from "react";
import Note from './note'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: []
        };
    }

    _onUpdateSuccess() {
        this.props.onUpdateSuccess()
    }

    _swapItem(oldIndex, newIndex) {
        let { notes } = this.state
        this.setState({
            notes: notes
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.notes !== nextProps.notes)
            nextProps.notes && nextProps.notes.map((note, i) => {
                note.index = i
            })
        this.setState({
            notes: nextProps.notes
        })
    }

    componentDidMount() {
        const { notes } = this.props
        notes && notes.map((note, i) => {
            note.index = i
        })
        this.setState({
            notes: notes
        })
    }

    render() {
        const { notes } = this.state
        let{partner}=this.props

        return (
            notes && notes.length > 0 ? <div className="note-page">
                <div className="note-list">

                    {/* <SortableHoc onChange={(oldIndex, newIndex) => { }}> */}
                    {
                        notes.map((note, i) =>
                            // <SortableHocItem index={note.index} key={i} className="note-list">
                            <Note className="note-item"
                                info={note}
                                index={note.index}
                                onUpdateSuccess={() => this._onUpdateSuccess()}
                                onDeleteSuccess={() => this._onUpdateSuccess()}
                                key={i}
                                partner={partner}
                            />
                            // </SortableHocItem>
                        )
                    }
                    {/* </SortableHoc> */}
                </div>
            </div> : <label className="empty-list-label">Chưa có ghi chú nào.</label>
        );
    }
}

export default Index;
