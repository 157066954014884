import React from "react";
import Modal from '../common/modal'
import Button from "@material-ui/core/Button";
import TextInput from '../common/text-input'
import { put, get } from '../../api'
import { notEmpty, isValid } from "../../utils/validators";

import { confirmSubmit, objToQuery, objToArray } from "../../utils/common";
import { showSuccess, showError } from "../../utils/app";
import { OptionType } from '../../constants/constants'
import Select from '../common/select'
import { optionManagerStatus } from '../../constants/constants';


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.validationData = {
            optionName: [notEmpty],
            selectedStatus: '',
        }
    }
    _getOptionDetail() {
        let { id } = this.props
        let { pageType } = this.state
        if (id) {
            get("options/" + pageType + '/' + id, (res) => {
                this.setState({
                    ...res,
                    selectedStatus: optionManagerStatus[res.status],
                })
            })
        }

    }

    componentWillReceiveProps(props) {
        let { pageType } = props
        if (props.id != this.props.id) {
            this.setState({
                pageType
            },
                this._getOptionDetail())
        }
    }
    componentDidMount() {
        let { pageType } = this.props
        if (pageType) {
            this.setState({
                pageType
            },
                this._getOptionDetail())
        }

    }
    _updateOption = () => {
        let param = {
            id: this.state.id,
            name: this.state.name,
            Status: this.state.selectedStatus ? this.state.selectedStatus.value : 'None',
            // selectedStatus: this.state.selectedStatus,
            description: this.state.description
        };
        var querry = objToQuery(param)
        if (isValid(this.validationData))
            put("options/" + this.state.pageType + querry, undefined, () => {
                showSuccess("Cập nhật thành công")
                this.props._getList()
                this.props.onClose()
            });
        else {
            showError("Dữ liệu không hợp lệ. Vui lòng kiểm tra lại")
        }
    }
    render() {
        let {
            open,
            onClose,
        } = this.props
        let { selectedStatus } = this.state;
        return (
            <Modal
                className="add-new-options"
                open={open}
                onClose={onClose}
                fullWidth
                header={
                    <div>
                        <span>Cập nhật</span>
                    </div>
                }
                content={
                    <table className="table list-item border-none input-form">
                        <tbody>
                            <tr>
                                <td className="coin text-left width300">
                                    <label>Tên</label>
                                    <TextInput
                                        className="name"
                                        value={this.state.name}
                                        label={"Tên option"}
                                        name={"optionName"}
                                        placeholder={"Tên option"}
                                        displayName={"Tên option"}
                                        onChange={e =>
                                            this.setState({ name: e.target.value })
                                        }
                                        validations={this.validationData.optionName}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left width300">
                                    <label>Mô tả</label>
                                    <TextInput
                                        className="description"
                                        value={this.state.description}
                                        label={"Mô tả"}
                                        name={"description"}
                                        placeholder={"Mô tả"}
                                        onChange={e =>
                                            this.setState({ description: e.target.value })
                                        }
                                        validationData={""}
                                        displayName={"Mô tả"}
                                        edit={true}
                                        multiline={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left width300">
                                    <label>Trạng thái</label>
                                    <Select
                                        options={objToArray(optionManagerStatus)}
                                        value={selectedStatus}
                                        onChange={value =>
                                            this.setState({
                                                selectedStatus: value
                                            })
                                        }
                                        edit={true}
                                        allowNull={false}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                action={
                    <Button
                        color="primary"
                        onClick={() => {
                            confirmSubmit(
                                <div>
                                    <table className="table border-none">
                                        <tr>
                                            <td className="width60">
                                                <img alt="" style={{ width: 50 }} src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ1MS43NCA0NTEuNzQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ1MS43NCA0NTEuNzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8cGF0aCBzdHlsZT0iZmlsbDojRTI0QzRCOyIgZD0iTTQ0Ni4zMjQsMzY3LjM4MUwyNjIuODU3LDQxLjY5MmMtMTUuNjQ0LTI4LjQ0NC01OC4zMTEtMjguNDQ0LTczLjk1NiwwTDUuNDM1LDM2Ny4zODEgIGMtMTUuNjQ0LDI4LjQ0NCw0LjI2Nyw2NCwzNi45NzgsNjRoMzY1LjUxMUM0NDIuMDU3LDQyOS45NTksNDYxLjk2OCwzOTUuODI1LDQ0Ni4zMjQsMzY3LjM4MXoiLz4KPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0yMjUuODc5LDYzLjAyNWwxODMuNDY3LDMyNS42ODlINDIuNDEzTDIyNS44NzksNjMuMDI1TDIyNS44NzksNjMuMDI1eiIvPgo8Zz4KCTxwYXRoIHN0eWxlPSJmaWxsOiMzRjQ0NDg7IiBkPSJNMTk2LjAxMywyMTIuMzU5bDExLjM3OCw3NS4zNzhjMS40MjIsOC41MzMsOC41MzMsMTUuNjQ0LDE4LjQ4OSwxNS42NDRsMCwwICAgYzguNTMzLDAsMTcuMDY3LTcuMTExLDE4LjQ4OS0xNS42NDRsMTEuMzc4LTc1LjM3OGMyLjg0NC0xOC40ODktMTEuMzc4LTM0LjEzMy0yOS44NjctMzQuMTMzbDAsMCAgIEMyMDcuMzksMTc4LjIyNSwxOTQuNTksMTkzLjg3LDE5Ni4wMTMsMjEyLjM1OXoiLz4KCTxjaXJjbGUgc3R5bGU9ImZpbGw6IzNGNDQ0ODsiIGN4PSIyMjUuODc5IiBjeT0iMzM2LjA5MiIgcj0iMTcuMDY3Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==" />
                                            </td>
                                            <td className="text-left" style={{ verticalAlign: "top" }}>
                                                <p style={{ fontSize: 20, fontWeight: "bold" }}>Cập nhật {OptionType[this.props.pageType].label.toLowerCase()}</p>
                                                <p style={{ fontSize: 15, marginTop: 10 }}>
                                                    Bạn có thật sự muốn lưu sự thay đổi này?
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>,
                                "",
                                () => {

                                    this._updateOption()
                                },
                                () => {

                                })
                        }}
                    >
                        Lưu
                    </Button>
                }
                allowCancel={true}
            />
        )
    }
}


export default Index;

