import React from "react";
import { put } from "../../api";
import Modal from '../common/modal'
import Button from '@material-ui/core/Button'
import TextInput from '../common/text-input'
import { notEmpty, isValid, justNumber, none } from '../../utils/validators'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { showSuccess, showError } from "../../utils/app";
import { PaymentType, OptionType, TaxPayers } from "../../constants/constants";
import SelectOption from '../common/select-option'
import Select from '../common/select'
import { objToArray } from "../../utils/common";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: null,
            typeError: null,
            accountNumber: "",
            accountName: "",
            bankName: "",
            branchName: "",
            taxCode: "",
            paypalId: "",
            isDefault: false,
            bankSelected: null,
            bankSelectedError: "",
            optionBankId: null,
            taxPayer: null,
            taxPayerError: null,
            otherRequest: ""
        };
        this.validationData = {
            accountNumber: [notEmpty],
            accountName: [notEmpty],
            bankName: [notEmpty],
            branchName: [notEmpty],
            paypalId: [(this.state.type && this.state.type.value === "Paypal") ? notEmpty : none]
        };
    }

    _updatePaymentInfo() {
        let {
            type,
            accountNumber,
            accountName,
            bankName,
            branchName,
            taxCode,
            paypalId,
            isDefault,
            bankSelected,
            taxPayer,
            otherRequest
        } = this.state

        let param = {
            type: type.value,
            accountNumber: type.value !== "Paypal" ? accountNumber : "",
            accountName: type.value !== "Paypal" ? accountName : "",
            bankName: type.value !== "Paypal" ? bankName : "",
            branchName: type.value !== "Paypal" ? branchName : "",
            taxCode: type.value !== "Paypal" ? taxCode : "",
            paypalId: paypalId,
            isDefault: isDefault,
            id: this.props.info.id,
            taxPayer: taxPayer ? taxPayer.value : null,
            otherRequest: otherRequest
        }

        let valid = true
        const validateParam = this.validationData


        if (this.state.type.value === "Paypal") {
            if (this.state.type.value === "Paypal") {
                delete validateParam.accountName
                delete validateParam.taxCode
                delete validateParam.accountNumber
                delete validateParam.bankName
                delete validateParam.branchName
            }
        } else {
            if (!bankSelected) {
                valid = false
                this.setState({
                    bankSelectedError: "Vui lòng chọn ngân hàng cho phương thức thanh toán này."
                })
            } else {
                param.bankName = bankSelected.name
                param.optionBankId = bankSelected.value
            }
        }

        if (!taxPayer) {
            valid = false
            this.setState({
                taxPayerError: "Vui lòng chọn Người chịu thuế."
            })
        }


        if (valid && isValid(this.validationData)) {
            put("payment-infos", param, () => {
                showSuccess("Thay đổi thông tin thành công.")
                this.props.onUpdateSuccess()
                this._resetState()
            })
        } else {
            showError("Lỗi thông tin. Vui lòng kiểm tra lại thông tin bạn vừa nhập.")
        }
    }

    _setDefaultValue(info) {
        if (!info) return
        this.setState({
            type: PaymentType[info.type],
            typeError: null,
            accountNumber: info.accountNumber,
            accountName: info.accountName,
            bankName: info.bankName,
            branchName: info.branchName,
            taxCode: info.taxCode,
            paypalId: info.paypalId,
            isDefault: info.isDefault,
            optionBankId: info.optionBankId,
            taxPayer: TaxPayers[info.taxPayer],
            otherRequest: info.otherRequest
        })
    }

    _resetState() {
        this.setState({
            contactFullName: "",
            contactPhone: "",
            email: "",
            address: "",
            position: "",
            isDefault: false,
        })
    }

    _handleCheckboxChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    _typeChange(value) {
        this.setState({
            ...this.defaultState,
            type: value ? value : null, typeError: null
        })
    }

    componentWillReceiveProps(nextProps) {
        console.log("nextProps", nextProps)
        if (Object.entries(nextProps.info).toString() != Object.entries(this.props.info).toString()) {
            this._setDefaultValue(nextProps.info)
        }
    }


    componentDidMount() {
        this._setDefaultValue(this.props.info)
    }

    render() {
        const validationData = this.validationData
        const {
            type,
            typeError,
            accountNumber,
            accountName,
            taxPayer,
            taxPayerError,
            branchName,
            taxCode,
            paypalId,
            isDefault,
            otherRequest,
            bankSelectedError,
            optionBankId
        } = this.state

        const { open } = this.props
        this.validationData.paypalId = [(this.state.type && this.state.type.value === "Paypal") ? notEmpty : none]

        const PaymentTypeOptions = objToArray(PaymentType)

        var taxPayerOption = objToArray(TaxPayers)
        if (type && (type.value === "Paypal" || type.value === "USD")) {
            taxPayerOption = objToArray(TaxPayers).filter(item => item.value != "Metub")
        }

        return (
            <Modal
                className="edit-contact"
                open={open}
                onClose={() => this.props.onClose()}
                fullWidth={true}
                fullScreen={false}
                header={
                    <div>
                        <span>THAY ĐỔI THÔNG TIN LIÊN HỆ</span>
                    </div>
                }
                content={
                    <table className="table border-none input-form">
                        <tbody>
                            <tr>
                                <td>
                                    <label>Hình thức thanh toán</label>
                                    <Select
                                        options={PaymentTypeOptions}
                                        edit={true}
                                        onChange={(value) => this._typeChange(value)}
                                        value={type}
                                        allowNull={false}
                                        errors={typeError}
                                    />
                                </td>
                            </tr>
                            {
                                type && type.value !== "Paypal" && <tr>
                                    <td>
                                        <label>Số tài khoản</label>
                                        <TextInput
                                            className="accountNumber"
                                            value={accountNumber}
                                            name={"accountNumber"}
                                            placeholder={"Số tài khoản"}
                                            displayName={"Số tài khoản"}
                                            onChange={e => {
                                                this.setState({ accountNumber: e.target.value })
                                            }}
                                            validations={validationData.accountNumber}
                                            edit={true}
                                        />
                                    </td>
                                </tr>
                            }
                            {
                                type && type.value !== "Paypal" && <tr>
                                    <td>
                                        <label>Tên tài khoản</label>
                                        <TextInput
                                            className="accountName"
                                            value={accountName}
                                            name={"accountName"}
                                            placeholder={"Tên chủ tài khoản"}
                                            displayName={"Tên chủ tài khoản"}
                                            onChange={e => {
                                                this.setState({ accountName: e.target.value })
                                            }}
                                            validations={validationData.accountName}
                                            edit={true}
                                        />
                                    </td>
                                </tr>
                            }
                            {
                                type && type.value !== "Paypal" && <tr>
                                    <td>
                                        <label>Ngân hàng</label>
                                        <SelectOption
                                            optionType={OptionType.ManagerBank.value}
                                            onChange={(value) => this.setState({ bankSelected: value ? value : null, bankSelectedError: null })}
                                            edit={true}
                                            value={optionBankId}
                                            allowNull={true}
                                            errors={bankSelectedError}
                                        />
                                    </td>
                                </tr>
                            }
                            {
                                type && type.value !== "Paypal" && <tr>
                                    <td>
                                        <label>Chi nhánh</label>
                                        <TextInput
                                            className="branchName"
                                            value={branchName}
                                            name={"branchName"}
                                            placeholder={"Chi nhánh"}
                                            displayName={"Chi nhánh"}
                                            onChange={e => {
                                                this.setState({ branchName: e.target.value })
                                            }}
                                            validations={validationData.branchName}
                                            edit={true}
                                        />
                                    </td>
                                </tr>
                            }
                            {
                                type && type.value !== "Paypal" && <tr>
                                    <td>
                                        <label>Mã số thuế</label>
                                        <TextInput
                                            className="taxCode"
                                            value={taxCode}
                                            name={"taxCode"}
                                            placeholder={"Mã số thuế"}
                                            displayName={"Mã số thuế"}
                                            onChange={e => {
                                                this.setState({ taxCode: e.target.value })
                                            }}
                                            validations={validationData.taxCode}
                                            edit={true}
                                        />
                                    </td>
                                </tr>
                            }
                            {
                                type && type.value === "Paypal" && <tr>
                                    <td>
                                        <label>ID Paypal</label>
                                        <TextInput
                                            className="paypalId"
                                            value={paypalId}
                                            name={"paypalId"}
                                            placeholder={"ID Paypal"}
                                            displayName={"ID Paypal"}
                                            onChange={e => {
                                                this.setState({ paypalId: e.target.value })
                                            }}
                                            validations={validationData.paypalId}
                                            edit={true}
                                        />
                                    </td>
                                </tr>
                            }
                            {
                                <tr>
                                    <td>
                                        <label>Người chịu thuế</label>
                                        <Select
                                            className="select-status"
                                            options={taxPayerOption}
                                            onChange={(value) => this.setState({
                                                taxPayer: value
                                            })}
                                            allowNull={true}
                                            value={taxPayer}
                                            errors={taxPayerError}
                                            edit={true}
                                        />
                                    </td>
                                </tr>
                            }
                            <tr>
                                <td className="coin text-left width300">
                                    <label>Yêu cầu khác khi Thanh toán</label>
                                    <TextInput
                                        className="otherRequest"
                                        value={otherRequest}
                                        name={"otherRequest"}
                                        displayName={"Yêu cầu khác"}
                                        placeholder={"Ghi chú"}
                                        multiline={true}
                                        onChange={e => {
                                            this.setState({ otherRequest: e.target.value });
                                        }}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-right border-none">
                                    <FormControlLabel
                                        className="color-primary"
                                        control={
                                            <Checkbox
                                                checked={isDefault}
                                                onChange={this._handleCheckboxChange('isDefault')}
                                                value={"isDefault"}
                                                color="primary"
                                            />
                                        }
                                        label="Đặt làm mặc định sau khi tạo."
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                }
                action={
                    <Button
                        color="primary"
                        onClick={() => this._updatePaymentInfo()}
                    >
                        Lưu
                    </Button>
                }
                allowCancel={true}
            />
        );
    }
}

export default Index;
