import React from "react";
import Modal from '../common/modal'
import TextInput from '../common/text-input'
import { Button, CardHeader, Avatar, Divider } from '@material-ui/core'
import { BASE_API } from '../../constants/appSettings'

import { get, put } from '../../api/index';
import { objToArray } from '../../utils/common'
import Select from '../common/select'
import FileDropZone from '../common/file-drop-zone'
import { RequestStatus, UploadFileType, MetubRoles, RequestType, DefaultUserAvatar, NotifyType, ChannelRequestSubType } from '../../constants/constants'
import moment from 'moment'
import { showSuccess } from "../../utils/app";
import { isInRole, getCurrentUser } from '../../auth'
import { checkRoleUpdateRequest, roleFilterRequestStatus } from "../../utils/roleChecker";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            currentRequest: null,
            selectedStatus: null,
            notes: "",
        };

        this.defaultState = this.state

    }

    _getRequestDetail(id) {
        let {
            isProccessing
        } = this.state
        if (isProccessing == true) return
        this.setState({
            isProccessing: true
        })
        get("requests/" + id, result => {
            this.setState({
                currentRequest: result,
                selectedStatus: RequestStatus[result.status],
                isProccessing: false
            }, () => {
                this.setState({ edit: false, isProccessing: false })
            })
        }, null, true)
    }

    _updateRequestStatus() {
        let {
            notes,
            selectedStatus
        } = this.state
        let param = {
            notes: notes,
            status: selectedStatus.value,
            id: this.props.requestId
        }
        put("requests/status", param, () => {
            showSuccess("Cập nhật trạng thái thành công!")
            this._getRequestDetail(this.props.requestId)
            const { onUpdateSuccess } = this.props
            if (onUpdateSuccess) onUpdateSuccess()
        })
    }



    componentDidMount() {
        let { requestId } = this.props
        if (requestId)
            this._getRequestDetail(requestId)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.requestId !== nextProps.requestId)
            this.setState({
                currentRequest: null
            }, () => this._getRequestDetail(nextProps.requestId))

    }



    render() {

        let {
            edit,
            currentRequest,
            selectedStatus,
            notes,
            isProccessing
        } = this.state

        const RequestStatusOptions = roleFilterRequestStatus(currentRequest);

        console.log("currentRequest", currentRequest)

        return (

            currentRequest ? <div style={{ width: "500px", padding: "10px" }}>
                {
                    !edit ? <div>
                        <table className="table border-bottom">
                            <tbody>
                                <tr>
                                    <td className="width120">
                                        <span>Ngày tạo</span>
                                    </td>
                                    <td>
                                        {
                                            moment(currentRequest.createTime).format("DD/MM/YYYY hh:mm")
                                        }
                                    </td>
                                </tr>
                                {
                                    currentRequest.requestUser && <tr >
                                        <td>
                                            <span>Người tạo</span>
                                        </td>
                                        <td>
                                            <CardHeader
                                                avatar={
                                                    <Avatar src={currentRequest.requestUser.avatar ? (BASE_API + currentRequest.requestUser.avatar) : DefaultUserAvatar} >
                                                    </Avatar>
                                                }
                                                title={
                                                    currentRequest.requestUser.fullName
                                                }
                                                subheader={
                                                    currentRequest.requestUser.email
                                                }
                                                style={{
                                                    padding: "0px"
                                                }}
                                            />
                                        </td>
                                    </tr>
                                }
                                <tr >
                                    <td className="vertical-top">
                                        <span>Trạng thái</span>
                                    </td>
                                    <td>
                                        <table className="table border-none">
                                            <tbody>
                                                <tr className="border-none">
                                                    <td className="text-left border-none">
                                                        <label>
                                                            {
                                                                RequestStatus[currentRequest.status].label
                                                            }
                                                        </label>
                                                    </td>
                                                    <td className="text-right">
                                                        {
                                                            checkRoleUpdateRequest(currentRequest)
                                                                ? <span
                                                                    onClick={() => this.setState({ edit: true })}
                                                                    style={{
                                                                        color: "#3F51B5",
                                                                        cursor: "pointer"
                                                                    }}
                                                                >Cập nhật</span> : ""
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                {
                                    (currentRequest.status !== RequestStatus.None.value && currentRequest.status !== RequestStatus.Pending.value) ? <tr>
                                        <td><span>Người duyệt</span></td>
                                        <td>

                                            <CardHeader
                                                avatar={
                                                    <Avatar src={currentRequest.confirmUser.avatar ? (BASE_API + currentRequest.confirmUser.avatar) : DefaultUserAvatar} >
                                                    </Avatar>
                                                }
                                                title={
                                                    currentRequest.confirmUser.fullName
                                                }
                                                subheader={
                                                    currentRequest.confirmUser.email
                                                }
                                                style={{
                                                    padding: "0px"
                                                }}
                                            />
                                        </td>
                                    </tr> : ""
                                }
                                {
                                    (currentRequest.status !== RequestStatus.None.value && currentRequest.status !== RequestStatus.Pending.value) && currentRequest.notes !== "" ? <tr>
                                        <td><span>Ghi chú</span></td>
                                        <td>
                                            <pre>
                                                {
                                                    currentRequest.notes
                                                }
                                            </pre>
                                        </td>
                                    </tr> : ""
                                }
                            </tbody>
                        </table>
                        <label style={{ margin: "10px 0px 5px 0px" }}>Nội dung yêu cầu</label>
                        <pre>
                            {
                                currentRequest.content
                            }
                        </pre>
                        <label style={{ margin: "10px 0px 5px 0px" }}>File mô tả</label>
                        <FileDropZone
                            type={UploadFileType.Contracts.value}
                            edit={false}
                            multiImages={true}
                            files={currentRequest.files}
                        />
                    </div> : <div>
                        <table className="table border-none">
                            <tbody>
                                <tr>
                                    <td>
                                        <table className="table border-none">
                                            <tr>
                                                <td className="width150">
                                                    Trạng thái
                                                </td>
                                                <td>
                                                    <Select
                                                        options={RequestStatusOptions}
                                                        value={selectedStatus ? selectedStatus : RequestStatus.None}
                                                        allowNull={false}
                                                        onChange={value => this.setState({ selectedStatus: value })}
                                                        edit={true}
                                                    />
                                                </td>
                                            </tr>

                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Ghi chú</label>
                                        <TextInput
                                            multiline={true}
                                            edit={true}
                                            value={notes}
                                            onChange={e => this.setState({ notes: e.target.value })}
                                        />
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                }
                <div style={{ textAlign: "right" }}>{
                    edit && <Button color="primary" onClick={() => this._updateRequestStatus()}>
                        Lưu cập nhật
                    </Button>
                }
                </div>
            </div> : <div style={{ width: "500px", padding: "10px" }}>

            </div>
        );
    }
}

export default Index;
