import React from "react";
import List from './list'

import {
    LocalAtm as LocalAtmIcon
} from '@material-ui/icons'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isListChange: false
        };
    }

    _reloadList() {
        this.setState({
            isListChange: !this.state.isListChange
        })
    }

    render() {
        let {
            partnerId
        } = this.props
        let {
            isListChange
        } = this.state
        return (
            <div className="contact-page">
                <div className="action">
                    <div className="admin-box">
                        <span>
                            <LocalAtmIcon />
                            Danh sách thanh toán hàng tháng
                        </span>
                        <List partnerId={partnerId} />

                    </div>
                </div>
            </div>
        )
    }
}
export default Index;