import React from "react";
import List from './list'
import AddNew from './add-new'
import Button from '@material-ui/core/Button'
import { get } from "../../../api";
import {
    Note as NoteIcon
} from '@material-ui/icons'

import { getCurrentUser, isInRole } from "../../../auth";
import { MetubRoles } from "../../../constants/constants";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: [],
            openAddNewDialog: false
        };
    }

    _handleGetNotes(channelId) {
        get("channels/" + channelId + "/notes", result => {
            this.setState({
                notes: result.items
            })
        })
    }

    componentDidMount() {
        let { channelId } = this.props
        this._handleGetNotes(channelId)
    }

    render() {
        let { notes, openAddNewDialog } = this.state
        const { channelId, optimizer } = this.props
        let currentUser = getCurrentUser()
        return (
            <div className="admin-box">
                <span>
                    <NoteIcon />
                    Danh sách ghi chú
                    {
                        (currentUser.roleCode != MetubRoles.OPTIMIZE && currentUser.roleCode != MetubRoles.OPTIMIZE_MANAGER) ? <Button
                            color={"primary"}
                            onClick={() => this.setState({ openAddNewDialog: !openAddNewDialog })}
                        >
                            Tạo mới
                        </Button> : (
                            currentUser && optimizer && currentUser.id == optimizer.id ? <Button
                                color={"primary"}
                                onClick={() => this.setState({ openAddNewDialog: !openAddNewDialog })}
                            >
                                Tạo mới
                            </Button> : ""
                        )
                    }
                </span>
                <div className="contact-page">
                    <List notes={notes} onUpdateSuccess={() => this._handleGetNotes(channelId)} />
                    <AddNew
                        open={openAddNewDialog}
                        onClose={() => this.setState({ openAddNewDialog: false })}
                        onAddSuccess={() => this.setState({
                            openAddNewDialog: false
                        }, () => this._handleGetNotes(channelId))}
                        channelId={channelId} />
                </div>
            </div>
        );
    }
}

export default Index;
