import React from "react";
import { get, post } from "../../api";
import Modal from '../common/modal'
import Button from '@material-ui/core/Button'
import TextInput from '../common/text-input'
import { notEmpty, isValid } from '../../utils/validators'
import SelectOption from '../common/select-option'
import Select from '../common/select'

import { showSuccess } from "../../utils/app";
import { OptionType, PartnerType, ContractStatus, DeliveryPartner, UploadFileType } from "../../constants/constants";
import { jsonFromUrlParams, objToArray } from '../../utils/common'
import DatetimePicker from '../common/datetime-picker'
import FileDropZone from '../common/file-drop-zone'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contractName: "",
            contractCode: "",
            edit: true,
            partnerType: null,
            partnerTypeError: null,
            signDate: null,
            signDateError: null,
            expiryDate: null,
            expiryDateError: null,
            contractTypeId: null,
            contractTypeIdError: null,
            status: null,
            statusError: null,
            deliveryId: "",
            deliveryPartner: null,
            files: null,
            defaultFiles: null,
            notes: ""
        };

        this.defaultState = this.state

        this.ContractImageRef = React.createRef()

        this.validationData = {
            contractName: [notEmpty],
            contractCode: [notEmpty],
        };

    }

    _addContract() {
        let {
            contractName,
            contractCode,
            partnerType,
            signDate,
            expiryDate,
            contractTypeId,
            status,
            deliveryId,
            deliveryPartner,
            files,
            notes
        } = this.state

        let param = {
            name: contractName,
            code: contractCode,
            partnerType: partnerType ? partnerType.value : null,
            signDate: signDate,
            expiryDate: expiryDate,
            notes: notes,
            deliveryId: deliveryId,
            deliveryPartner: deliveryPartner ? deliveryPartner.value : "None",
            status: status ? status.value : null,
            files: files,
            contractTypeId: contractTypeId,
            partnerId: this.props.partnerId
        }

        if (this._checkInputValue())
            post("contracts", param, () => {
                showSuccess("Thêm hợp đồng thành công.")
                this.props.onAddSuccess()
                this._resetState()
                this.ContractImageRef.current._handleReset()
            })
    }

    _checkInputValue() {
        let {
            signDate,
            expiryDate,
            partnerType,
            contractTypeId,
            status
        } = this.state
        let valid = true
        if (!isValid(this.validationData))
            valid = false

        if (!signDate) {
            valid = false
            this.setState({
                signDateError: "Ngày ký không được bỏ trống."
            })
        }
        if (!expiryDate) {
            valid = false
            this.setState({
                expiryDateError: "Ngày hết hiệu lực không được bỏ trống."
            })
        }

        if (!partnerType) {
            valid = false
            this.setState({
                partnerTypeError: "Loại đối tác không được bỏ trống."
            })
        }

        if (!contractTypeId) {
            valid = false
            this.setState({
                contractTypeIdError: "Loại hợp đồng không được bỏ trống."
            })
        }
        if (!status) {
            valid = false
            this.setState({
                statusError: "Trạng thái hợp đồng không được bỏ trống."
            })
        }
        return valid
    }

    _resetState() {
        this.setState({
            ...this.defaultState,
            optimizerId: null
        })
    }


    _getVideoInfoFromYoutube() {
        const { url } = this.state
        if (url === "")
            return

        let temp = jsonFromUrlParams(url.split('?')[1]);
        if (!temp) return
        this.setState({
            isVideoInfoLoading: true
        }, () => {
            let videoId = temp.v
            get("youtube/videos/" + videoId,
                result => {
                    this.setState({
                        youtubeId: result.id,
                        name: result.snippet.title,
                        thumbnail: result.snippet.thumbnails.high.url,
                        viewCount: result.statistics.viewCount,
                        isVideoInfoLoading: false
                    })
                },
                () => {
                    this.setState({ isVideoInfoLoading: false })
                }
            )
        })

    }

    render() {
        const validationData = this.validationData
        const {
            notes,
        } = this.state

        let {
            contractName,
            contractCode,
            edit,
            partnerType,
            partnerTypeError,
            signDate,
            signDateError,
            expiryDate,
            expiryDateError,
            contractTypeId,
            contractTypeIdError,
            status,
            statusError,
            deliveryId,
            deliveryPartner,
        } = this.state


        const { open } = this.props
        const PartnerTypeOptions = objToArray(PartnerType)
        const ContractStatusOptions = objToArray(ContractStatus)
        const DeliveryPartnerOptions = objToArray(DeliveryPartner)

        return (
            <Modal
                className="add-new-contact"
                open={open}
                onClose={() => this.props.onClose()}
                fullWidth={true}
                fullScreen={false}
                header={
                    <div>
                        <span>THÊM MỚI HỢP ĐỒNG</span>
                    </div>
                }
                content={
                    <div>
                        <table className="table border-none input-form">
                            <tbody>
                                <tr>
                                    <td>
                                        <label>Tên hợp đồng</label>
                                        <TextInput
                                            className="contractName"
                                            value={contractName}
                                            name={"contractName"}
                                            placeholder={"Tên hợp đồng"}
                                            displayName={"Tên hợp đồng"}
                                            onChange={e => {
                                                this.setState({ contractName: e.target.value })
                                            }}
                                            validations={validationData.contractName}
                                            edit={true}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Mã hợp đồng</label>
                                        <TextInput
                                            className="contractCode"
                                            value={contractCode}
                                            name={"contractCode"}
                                            placeholder={"Mã hợp đồng"}
                                            displayName={"Mã hợp đồng"}
                                            onChange={e => {
                                                this.setState({ contractCode: e.target.value })
                                            }}
                                            validations={validationData.contractCode}
                                            edit={true}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="coin text-left width300">
                                        <label>Loại hợp đồng</label>
                                        <SelectOption
                                            optionType={OptionType.ContractType.value}
                                            edit={edit}
                                            onChange={(value) => this.setState({ contractTypeId: value ? value.id : null, contractTypeIdError: null })}
                                            value={contractTypeId}
                                            allowNull={false}
                                            errors={contractTypeIdError}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="coin text-left width300">
                                        <label>Tình trạng hợp đồng</label>
                                        <Select
                                            options={ContractStatusOptions}
                                            edit={edit}
                                            onChange={(value) => this.setState({ status: value ? value : null, statusError: null })}
                                            value={status}
                                            allowNull={false}
                                            errors={statusError}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="coin text-left width300">
                                        <label>Đối tác</label>
                                        <Select
                                            options={PartnerTypeOptions}
                                            edit={edit}
                                            onChange={(value) => this.setState({ partnerType: value ? value : null, partnerTypeError: null })}
                                            value={partnerType}
                                            allowNull={false}
                                            errors={partnerTypeError}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="coin text-left width300">
                                        <label>Ngày ký</label>
                                        <DatetimePicker
                                            className="signDate"
                                            placeholder={"Ngày ký"}
                                            onChange={value => this.setState({ signDate: value, signDateError: null })}
                                            value={signDate}
                                            edit={edit}
                                            errors={signDateError}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="coin text-left width300">
                                        <label>Ngày hết hiệu lực</label>
                                        <DatetimePicker
                                            className="expiryDate"
                                            placeholder={"Ngày ký"}
                                            onChange={value => this.setState({ expiryDate: value, expiryDateError: null })}
                                            value={expiryDate}
                                            edit={edit}
                                            errors={expiryDateError}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>ID chuyển phát</label>
                                        <TextInput
                                            className="deliveryId"
                                            value={deliveryId}
                                            name={"deliveryId"}
                                            placeholder={"Mã chuyển phát"}
                                            displayName={"Mã chuyển phát"}
                                            onChange={e => {
                                                this.setState({ deliveryId: e.target.value })
                                            }}
                                            edit={true}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="coin text-left width300">
                                        <label>Đơn vị chuyển phát</label>
                                        <Select
                                            options={DeliveryPartnerOptions}
                                            edit={edit}
                                            onChange={(value) => this.setState({ deliveryPartner: value ? value : null })}
                                            value={deliveryPartner}
                                            allowNull={true}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-none">
                                        <label>Ghi chú</label>
                                        <TextInput
                                            className="notes"
                                            value={notes}
                                            name={"notes"}
                                            placeholder={"Ghi chú"}
                                            displayName={"Ghi chú"}
                                            multiline={true}
                                            onChange={e => {
                                                this.setState({ notes: e.target.value })
                                            }}
                                            edit={true}
                                            minHeight={"200px"}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label >Hình ảnh</label>
                                        <FileDropZone
                                            accept="application/pdf"
                                            type={UploadFileType.Contracts.value}
                                            edit={true}
                                            multiImages={true}
                                            onChange={(file) => {
                                                let files = []
                                                file && file.length > 0 && file.map(item => {
                                                    files.push(item.relativePath)
                                                })
                                                this.setState({ files: files })
                                            }}
                                            ref={this.ContractImageRef}

                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                }
                action={
                    <Button
                        color="primary"
                        onClick={() => this._addContract()}
                    >
                        Lưu
                    </Button>
                }
                allowCancel={true}
            />
        );
    }
}

export default Index;
