import React from "react";
import List from './list'
import { get } from "../../api";
import { objToQuery, objToArray } from '../../utils/common'
import { TableSizeDefault, RequestType, ChannelRequestSubType, PartnerRequestSubType, UpdateChannelPartner, RequestStatus, MetubRoles } from "../../constants/constants";

import { getCurrentUser, isInRole } from "../../auth";
import { REQUEST_FILTER } from "../../constants/localStorageKeys";
import {
    Update as UpdateIcon,
    FilterList as FilterListIcon,
    ChevronRight as ChevronRightIcon
} from '@material-ui/icons'
import {
    Button,
    Typography
} from '@material-ui/core'
import DateTimePicker from '../common/datetime-picker';
import Select from '../common/select'
import { BASE_API } from "../../constants/appSettings";

let RequestTypeOptions = objToArray(RequestType).filter(item => item.value != "None").slice(0, 4)
let ChannelRequestSubTypeOptions = objToArray(ChannelRequestSubType)
let PartnerRequestSubTypeOptions = objToArray(PartnerRequestSubType)
let UpdateChannelPartnerOptions = objToArray(UpdateChannelPartner)
let RequestStatusOptions = objToArray(RequestStatus).filter(item => item.value != "None")

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests: [],
            total: 0,
            searchParam: { skip: 0, take: TableSizeDefault.value },
            listType: "list",
            currentPage: 1,
            toDate: null,
            fromDate: null
        };
    }

    _handleGetRequests(param) {
        if (!param) return
        let currentPage = param.page
        if (!currentPage) {
            let filter = JSON.parse(window.localStorage.getItem(REQUEST_FILTER))
            currentPage = filter ? filter.currentPage : 1
        }
        let {
            requestTypeSelected,
            requestSubTypeSelected,
            fromDate,
            toDate,
            requestStatusSelected
        } = this.state
        this.setState({
            searchParam: param,
            currentPage: currentPage
        }, () => {
            let getParam = {
                skip: currentPage - 1 >= 0 ? (currentPage - 1) * param.take : 0,
                take: param.take,
                type: "None"
            }
            if (requestTypeSelected) getParam.type = requestTypeSelected.value
            if (requestTypeSelected && requestSubTypeSelected) getParam.subType = requestSubTypeSelected.value
            if (fromDate) getParam.fromDate = fromDate
            if (toDate) getParam.toDate = toDate
            if (requestStatusSelected) getParam.status = requestStatusSelected.value
            var query = objToQuery(getParam);

            window.localStorage.setItem(REQUEST_FILTER, JSON.stringify(this.state))

            get("requests" + query, result => {
                this.setState({
                    requests: result.items,
                    total: result.total
                })
            })
        })
    }

    _exportList(param) {

        if (!param) return
        let currentPage = param.page
        if (!currentPage) {
            let filter = JSON.parse(window.localStorage.getItem(REQUEST_FILTER))
            currentPage = filter ? filter.currentPage : 1
        }
        let {
            requestTypeSelected,
            requestSubTypeSelected,
            fromDate,
            toDate,
            requestStatusSelected
        } = this.state
        this.setState({
            searchParam: param,
            currentPage: currentPage
        }, () => {
            let getParam = {
                type: "None"
            }
            if (requestTypeSelected) getParam.type = requestTypeSelected.value
            if (requestTypeSelected && requestSubTypeSelected) getParam.subType = requestSubTypeSelected.value
            if (fromDate) getParam.fromDate = fromDate
            if (toDate) getParam.toDate = toDate
            if (requestStatusSelected) getParam.status = requestStatusSelected.value
            var query = objToQuery(getParam);

            window.localStorage.setItem(REQUEST_FILTER, JSON.stringify(this.state))

            get("/requests/export" + query, result => {
                window.open(BASE_API + result.relativePath)
            })
        })
    }

    componentWillMount() {
        let filter = JSON.parse(window.localStorage.getItem(REQUEST_FILTER))
        if (filter) {
            this.setState({
                ...filter,
            })
        }
    }

    componentDidMount() {
        let { searchParam } = this.state
        this._handleGetRequests(searchParam)
    }

    render() {
        let {
            requests,
            total,
            currentPage,
            toDate,
            fromDate,
            searchParam,
            requestTypeSelected,
            requestSubTypeSelected,
            requestStatusSelected
        } = this.state

        return (
            <div className="contact-page notification">
                <Typography variant="h6" gutterBottom align="center" className="box-title">
                    <div>
                        Quản lí yêu cầu cập nhật
                        <p>Quản lí danh sách yêu cầu được ghi nhận.</p>

                    </div>
                </Typography>
                <div className="admin-box" style={{ position: "relative" }}>
                    <span><FilterListIcon />Bộ lọc dữ liệu</span>
                    <div className="search-option input-form">
                        <div className="width30pc ">
                            <label>Thời gian</label>
                            <div className="date-box">
                                <DateTimePicker
                                    className="fromDate"
                                    type="date"
                                    placeholder={"Từ ngày"}
                                    onChange={value => this.setState({ fromDate: value, toDate: value ? toDate : null }, () => this._handleGetRequests(searchParam))}
                                    value={fromDate}
                                    edit={true}
                                    border={false}
                                    isClearable={true}
                                />
                                <ChevronRightIcon />
                                <DateTimePicker
                                    className="toDate"
                                    type="date"
                                    placeholder={"Đến ngày"}
                                    onChange={value => this.setState({ toDate: value }, () => this._handleGetRequests(searchParam))}
                                    value={toDate}
                                    edit={true}
                                    border={false}
                                    minDate={fromDate ? fromDate : null}
                                    isClearable={true}
                                />
                            </div>
                        </div>
                        <div className="width35pc">
                            <label>Loại yêu cầu</label>
                            <Select
                                className="select-status"
                                options={RequestTypeOptions}
                                onChange={(value) => this.setState({
                                    requestTypeSelected: value,
                                    requestSubTypeSelected: value ? requestSubTypeSelected : null
                                }, () => this._handleGetRequests(searchParam))}
                                value={requestTypeSelected}
                                edit={true}
                                allowNull={true}
                                placeholder="Lọc theo loại yêu cầu"
                            />
                        </div>

                        {/* <div className="width35pc mr00">
                                <label>Trạng thái</label>
                                <Select
                                    className="select-status"
                                    options={NotifyStatuOptions}
                                    onChange={(value) => this.setState({
                                        statusSelected: value
                                    }, () => this._getNotification())}
                                    value={statusSelected}
                                    edit={true}
                                    allowNull={true}
                                    placeholder="Lọc theo trạng thái"
                                />
                            </div> */}
                        {
                            requestTypeSelected && requestTypeSelected.value == RequestType.UpdateChannel.value ?
                                <td className="width50pc">
                                    <label>Nội dung cập nhật</label>
                                    <Select
                                        options={ChannelRequestSubTypeOptions}
                                        onChange={value =>
                                            this.setState({
                                                requestSubTypeSelected: value,
                                            }, () => this._handleGetRequests(searchParam))
                                        }
                                        placeholder="Chọn nội dung cập nhật"
                                        value={requestSubTypeSelected}
                                        edit={true}
                                        allowNull={true}
                                        outLine
                                    />
                                </td> : ""

                        }
                        {
                            requestTypeSelected && requestTypeSelected.value == RequestType.UpdatePartner.value ?
                                <td className="width50pc">
                                    <label>Nội dung cập nhật</label>
                                    <Select
                                        options={PartnerRequestSubTypeOptions}
                                        onChange={value =>
                                            this.setState({
                                                requestSubTypeSelected: value
                                            }, () => this._handleGetRequests(searchParam))
                                        }
                                        placeholder="Chọn nội dung cập nhật"
                                        value={requestSubTypeSelected}
                                        edit={true}
                                        allowNull={true}
                                        outLine
                                    />
                                </td> : ""

                        }
                        {
                            requestTypeSelected && requestTypeSelected.value == RequestType.UpdateChannelPartner.value ?
                                <td className="width50pc mr10">
                                    <label>Nội dung cập nhật</label>
                                    <Select
                                        options={UpdateChannelPartnerOptions}
                                        onChange={value =>
                                            this.setState({
                                                requestSubTypeSelected: value
                                            }, () => this._handleGetRequests(searchParam))
                                        }
                                        placeholder="Chọn nội dung cập nhật"
                                        value={requestSubTypeSelected}
                                        edit={true}
                                        allowNull={true}
                                        outLine
                                    />
                                </td> : ""

                        }
                        <td className="width200">
                            <label>Trạng thái</label>
                            <Select
                                options={RequestStatusOptions}
                                onChange={value =>
                                    this.setState({
                                        requestStatusSelected: value
                                    }, () => this._handleGetRequests(searchParam))
                                }
                                placeholder="Chọn trạng thái"
                                value={requestStatusSelected}
                                edit={true}
                                allowNull={true}
                                outLine
                            />
                        </td>
                    </div>
                </div>
                <div className="admin-box">
                    <span>
                        <UpdateIcon />
                        Danh sách yêu cầu cập nhật
                        {
                            (isInRole(MetubRoles.ADMIN) || isInRole(MetubRoles.DATA_MANAGER) || isInRole(MetubRoles.LEGAL_EXECUTIVE)) && <Button color={"primary"} onClick={() => this._exportList(searchParam)}>Export</Button>
                        }
                    </span>
                    <List
                        requests={requests}
                        total={total}
                        listType="list"
                        onChange={param => this._handleGetRequests(param)}
                        currentPage={currentPage}
                        onUpdateSuccess={() => this._handleGetRequests(searchParam)}
                    />

                </div>
            </div>
        );
    }
}

export default Index;
