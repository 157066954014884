import React from "react";
import Select from "react-select";
import { IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  Fab,
  List,
  ListItem,
  ListItemText,
  Badge,
  Paper,
  ClickAwayListener,
} from "@material-ui/core";
import {
  Today as TodayIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  PieChart as PieChartIcon,
  HeadsetMic as HeadsetMicIcon,
  Compare as CompareIcon,
  Equalizer as EqualizerIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons";
import Notifications from "@material-ui/icons/Notifications";
import moment from "moment";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CancelIcon from "@material-ui/icons/Cancel";
//-----------------------------------------------------------------------------------------------
import { getCurrentUser } from "../../../auth";
import TextInput from "../../common/text-input";
import { get, put } from "../../../api";
import ListPartnerMissing from "../listPartnerMissing";
import {
  VerifyStatus,
  TypePartner,
  TypeDate,
} from "../../../constants/constants";
import { objToArray } from "../../../utils/common";
import CustomSelect from "../../common/select";
import ListStatusChannelInMonth from "../listStatusChannelInMonth";
import ListPartnerContact from "./listPartnerContact";




class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreOption: false,
      openPinMenu: false,
      notifications: "",
      searchKey: "",
      selectedType: null,
      selectedVerifyStatus: null,
      selectedTypePartner: null,
      selectedTypeDate: null,
      countNeedContact: 0,
      countPartnerExpire: 0,
      countStatusChannel: 0,
      recents: [],
      recentSelected: null,
      stringForSearch: "",
    };
    this.countPartnersContact = React.createRef();
  }
  _getStatusChannels(recentYear) {
    if (!recentYear) return;

    get("channels/count/" + recentYear, (result) => {
      this.setState({
        countStatusChannel: result[0],
      });
    });
  }
  _countNeedContact() {
    let result = this.countPartnersContact.current._countPartnerNeedContact();
    let result2 = this.countPartnersContact.current._countPartnerExpire();
    this.setState({
      countNeedContact: result,
      countPartnerExpire: result2,
    });
  }

  componentWillMount() {
    this._handleGetRecent();
  }
  componentDidMount() {
    let { recentSelected } = this.state;
    this._getNotification();
    if (recentSelected) this._getStatusChannels(recentSelected.value);
    let result = this.countPartnersContact.current._countPartnerNeedContact();
    let result2 = this.countPartnersContact.current._countPartnerExpire();
    this.setState({
      countNeedContact: result,
      countPartnerExpire: result2,
    });
  }
  handleOpenPinMenu() {
    this.setState({ openPinMenu: !this.state.openPinMenu });
  }
  handleClosePinMenu = () => {
    this.setState({ openPinMenu: false });
  };
  _getNotification = () => {
    get("partners/notifications-channels", (result) => {
      this.setState({ notifications: result.items });
    });
  };
  _isReadNotify(id, referenceId) {
    if (id) {
      let params = {
        isRead: true,
        id: id,
      };
      put("notifications", params, () => {
        this._getNotification();
        this.props.history.push(`/channels/${referenceId}`);
      });
    }
  }
  _makeIsReadNotify(id) {
    if (id) {
      let params = {
        isRead: true,
        id: id,
      };
      put("notifications", params, () => {
        this._getNotification();
      });
    }
  }
  _handleGetRecent() {
    get("recents", (result) => {
      const year = moment().year();
      this.setState({
        recents: result.items,
        recentSelected: result.items.find((item) => item.value == year),
      });
    });
  }

  render() {
    let {
      stringForSearch,
      searchKey,
      selectedVerifyStatus,
      selectedTypePartner,
      selectedTypeDate,
      recents,
      recentSelected,
    } = this.state;
    let { openPinMenu, notifications } = this.state;
    let VerifyStatusOptions = objToArray(VerifyStatus);
    let TypePartnerOptions = objToArray(TypePartner);
    let TypeDateOptions = objToArray(TypeDate);
    const currentUser = getCurrentUser();

    return (
      <div className="dashboardPartnerManager partner-management">
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          className="box-title"
        >
          <div>
            Bảng điều khiển [{" "}
            {currentUser && currentUser.roleName ? currentUser.roleName : ""} ]
            <p>
              Thông tin điều khiển dành cho tài khoản{" "}
              {currentUser && currentUser.roleName ? currentUser.roleName : ""}.
            </p>
          </div>
        </Typography>
        <div className="reward-box">
          <div className="">
            <TodayIcon />
            <span>Thống kê trong năm </span>
            {recents && recents.length > 0 ? (
              <div>
                <Select
                  className="custom-select-list"
                  options={recents}
                  value={recentSelected ? recentSelected : null}
                  onChange={(value) =>
                    this.setState({
                      recentSelected: value,
                    })
                  }
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="reward">
            <img src="https://image.flaticon.com/icons/svg/1170/1170646.svg" />
            <b>Cần liên hệ hôm nay: </b>
            <span className="">{this.state.countNeedContact}</span>
          </div>
          <div className="reward">
            <img src="https://image.flaticon.com/icons/svg/2219/2219490.svg" />
            <b>Đối tác quá hạn: </b>
            <span>{this.state.countPartnerExpire}</span>
          </div>
        </div>
        <div className="filters-form mb15 balance-search date-time admin-box">
          <span>
            <FilterListIcon />
            Bộ lọc dữ liệu
          </span>
          <div className="search-advance">
            <div className="search-input">
              <TextInput
                name="searchKey"
                displayName="Thông tin tìm kiếm"
                placeholder="Tìm kiếm theo tên đối tác(không áp dụng cho đối tác thiếu thông tin)"
                type="text"
                value={stringForSearch}
                onChange={(e) =>
                  this.setState({ stringForSearch: e.target.value })
                }
                edit={true}
                outLine
                height={35}
              />
              <span
                className="search-bt"
                onClick={() => this.setState({ searchKey: stringForSearch })}
              >
                Tìm nhanh
              </span>
            </div>
          </div>

          <div className="input-form">
            <table className="table border-none ">
              <tbody>
                <tr>
                  <td className="text-left">
                    <label className="text-left">Trạng thái</label>
                    <CustomSelect
                      options={VerifyStatusOptions}
                      value={selectedVerifyStatus}
                      onChange={(value) =>
                        this.setState({
                          selectedVerifyStatus: value,
                        })
                      }
                      edit={true}
                      outLine
                      allowNull={true}
                    />
                  </td>
                  <td className=" text-left">
                    <label className="text-left">Kiểu đối tác</label>
                    <CustomSelect
                      options={TypePartnerOptions}
                      value={selectedTypePartner}
                      onChange={(value) =>
                        this.setState({
                          selectedTypePartner: value,
                        })
                      }
                      edit={true}
                      outLine
                      allowNull={true}
                    />
                  </td>
                  <td className=" text-left">
                    <label className="text-left">Lọc theo ngày liên lạc</label>
                    <CustomSelect
                      options={TypeDateOptions}
                      value={selectedTypeDate}
                      onChange={(value) =>
                        this.setState({
                          selectedTypeDate: value,
                        })
                      }
                      edit={true}
                      outLine
                      allowNull={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {
          <Fab
            color="primary"
            aria-label="Add"
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: "1199",
            }}
            onClick={() => this.handleOpenPinMenu()}
          >
            {notifications && notifications.length > 0 ? (
              <Badge color="secondary" badgeContent={notifications.length}>
                <Notifications />
              </Badge>
            ) : (
              <Notifications />
            )}
          </Fab>
        }
        <div className={"pin-menu" + (openPinMenu ? " active" : "")}>
          <Paper>
            <ClickAwayListener onClickAway={this.handleClosePinMenu}>
              <List className={"request-pending"}>
                {notifications &&
                  notifications.length > 0 &&
                  notifications.map((item, i) => (
                    <ListItem
                      onClick={() =>
                        this._isReadNotify(item.id, item.referenceId)
                      }
                      key={i}
                    >
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "15px" }}>
                            {`Kênh ${item.channelName} đã được chuyển từ ${item.notificationItems[0].previous} sang ${item.notificationItems[0].current}`}
                          </span>
                        }
                        secondary={
                          <span
                            style={{
                              justifyContent: "space-between",
                              display: "flex",
                            }}
                          >
                            <span>
                              Được thay đổi bởi <b>{item.createByName}</b>
                            </span>
                            <span>
                              {item.displayTime
                                ? moment(item.displayTime).format(
                                  "DD/MM/YYYY hh:mm"
                                )
                                : "-/-"}
                            </span>
                          </span>
                        }
                      />
                      <ListItemSecondaryAction
                        onClick={() => this._makeIsReadNotify(item.id)}
                      >
                        <IconButton>
                          <CancelIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </ClickAwayListener>
          </Paper>
        </div>
        <div className="admin-box">
          <span>
            <HeadsetMicIcon />
            Danh sách đối tác cần liên hệ
          </span>
          {
            <ListPartnerContact
              searchKey={searchKey}
              selectedVerifyStatus={selectedVerifyStatus}
              selectedTypePartner={selectedTypePartner}
              selectedTypeDate={selectedTypeDate}
              countNeedContact={() => this._countNeedContact()}
              ref={this.countPartnersContact}
              recentYear={recentSelected ? recentSelected.value : null}
            />
          }
        </div>
        <div className="admin-box">
          <span>
            <CompareIcon />
            Danh sách đối tác còn thiếu thông tin
          </span>
          {
            <ListPartnerMissing
              recentYear={recentSelected ? recentSelected.value : null}
            />
          }
        </div>
        <div className="admin-box">
          <span>
            <EqualizerIcon />
            Trạng thái kênh trong tháng
          </span>
          {
            <ListStatusChannelInMonth
              recentYear={recentSelected ? recentSelected.value : null}
            />
          }
        </div>
      </div>
    );
  }
}

export default Index;
