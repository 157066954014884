import React from "react";
import Modal from '../../common/modal'
import Button from "@material-ui/core/Button";
import Tabs from '../../common/tabs'
import Individual from './individual'
import Company from './company'
import { PartnerType } from '../../../constants/constants'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: -1
        }
        this.Individual = React.createRef();
        this.Company = React.createRef();
    }


    _handleAddPartner() {
        let { tabIndex } = this.state
        tabIndex === 0 ? this.Individual.current._handleAddIndividual() : this.Company.current._handleAddCompany()
    }

    _onAdd() {
        this.props.onAdd()
    }


    componentDidMount() {
    }

    render() {
        let {
            tabIndex
        } = this.state
        let {
            open
        } = this.props
        return (
            <Modal
                className="add-new-partner"
                open={open}
                onClose={() => this.setState({ tabIndex: -1 }, () => this.props.onClose())}
                fullWidth={true}
                fullScreen={false}
                header={
                    <div>
                        <span>THÊM MỚI ĐỐI TÁC</span>
                    </div>
                }
                content={
                    <Tabs
                        onChange={(index) => this.setState({ tabIndex: index })}
                        value={tabIndex}
                        disableScroll={true}
                        tabLabels={[PartnerType.Individual.label, PartnerType.Company.label]}
                        tabContainers={[
                            <Individual ref={this.Individual} onAdd={() => this._onAdd()} />,
                            <Company ref={this.Company} onAdd={() => this._onAdd()} />
                        ]}
                    ></Tabs>
                }
                action={
                    <Button
                        color="primary"
                        onClick={() => this._handleAddPartner()}
                    >
                        Lưu
            </Button>
                }
                allowCancel={true}
            />)
    }
}

export default Index;

