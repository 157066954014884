import React from "react";
import List from './list'
import AddNew from './add-new'
import Button from '@material-ui/core/Button'
import { get } from "../../../api";
import {
    Note as NoteIcon
} from '@material-ui/icons'


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: [],
            openAddNewDialog: false
        };
    }

    _handleGetNotes(contractId) {
        get("contracts/" + contractId + "/notes", result => {
            this.setState({
                notes: result.items
            })
        })
    }

    componentDidMount() {
        let { contractId } = this.props
        this._handleGetNotes(contractId)
    }

    render() {
        let { notes, openAddNewDialog } = this.state
        const { contractId } = this.props
        return (
            <div className="contact-page">
                <div className="action">
                    <div className="admin-box">
                        <span>
                            <NoteIcon />
                            Danh sách ghi chú
                            {
                                <Button
                                    color={"primary"}
                                    onClick={() => this.setState({ openAddNewDialog: !openAddNewDialog })}
                                >
                                    Tạo mới
                                </Button>

                            }
                        </span>
                        <List
                            notes={notes}
                            onUpdateSuccess={() => this._handleGetNotes(contractId)}
                        />
                    </div>
                </div>
                <AddNew
                    open={openAddNewDialog}
                    onClose={() => this.setState({ openAddNewDialog: false })}
                    onAddSuccess={() => this.setState({ openAddNewDialog: false }, () => this._handleGetNotes(contractId))}
                    contractId={contractId} />
            </div>
        );
    }
}

export default Index;
