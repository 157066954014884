import React from "react";
import Button from '@material-ui/core/Button'
import { TableSizeDefault } from "../../../../constants/constants";
import AddFanpage from './add'
import ListCampaignsFanpages from '../../../campaign-fanpage/list'
class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            total: 0,
            skip: 0,
            take: TableSizeDefault.value,
            page: 1,
            fanpages: null,
            openAddFanpage: false,
            openEditOption: false,
            openDeleteOption: false,
            currentOption: undefined
        };
        this.getList = React.createRef()
    }

    _closeAddFanpage = () => {
        this.setState({
            openAddFanpage: false
        })
    }
    componentWillReceiveProps(props) {
        let id = props.id
        if (id !== null && id !== undefined) {
            this.setState({
                id
            })
        }
    }

    componentDidMount() {
        let id = this.props.id
        if (id !== null && id !== undefined) {
            this.setState({
                id
            })
        }
    }
    _callback = () => {
        this.forceUpdate()
    }
    render() {
        
        return (
            <div className="box-body product-list campaign-fanpage">
                <div className="box-body product-list">
                    <div className="filters-form mb15 balance-search date-time">
                        <table className="table list-item border-none">
                            <tbody>
                                <tr>
                                    <td><h3 className="box-title"></h3></td>
                                    <td className="text-right">
                                        <div className="create-bt">
                                            <Button
                                                color="primary"
                                                className="custom-button"
                                                onClick={() =>
                                                    this.setState({
                                                        openAddFanpage: true
                                                    })
                                                }
                                            >
                                                Thêm campaign
                                                </Button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <ListCampaignsFanpages id={this.state.id}
                    type={"fanpage"}
                    ref={this.getList} />
                <AddFanpage id={this.state.id}
                    open={this.state.openAddFanpage}
                    onClose={this._closeAddFanpage}
                    onAddSucceed={this._callback} />
            </div>
        )
    }
}

export default Index;
