import React from "react";
import TextInput from "../../common/text-input";
import {
  notEmpty,
  emailAddress,
  justNumber,
  isValid,
} from "../../../utils/validators";
import { objToQuery, confirmSubmit, getDate } from "../../../utils/common";
import {
  PartnerType,
  DefaultUserAvatar,
  MetubRoles,
  Gender,
} from "../../../constants/constants";
import { getAsync, put, _delete } from "../../../api";
import { showSuccess, showError } from "../../../utils/app";
import Select from "../../common/select";
import { objToArray } from "../../../utils/common";
import DatetimePicker from "../../common/datetime-picker";
import UserSelectList from "../../common/user-select-list";
import { isInRole } from "../../../auth";
import { ChooseTier, VerifyStatus } from "../../../constants/constants";
import { USER_INFO } from "../../../constants/localStorageKeys";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@material-ui/core";
const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      type: PartnerType.Company.value,
      phone: "",
      email: "",
      emailcc: "",
      companyEmailErrors: "",
      taxCode: "",
      companyName: "",
      companyAddress: "",
      tradingAddress: "",
      representativeName: "",
      representativePosition: "",
      dashboardId: "",
      cooperatedDate: null,
      cannotInviteNotes: "",
      id: 0,
      partnerManager: null,
      partnerManagerId: null,
      partnerManagerIdErrors: null,
      tier: "",
      optionTier: objToArray(ChooseTier),
      selectedTier: null,
      verifyStatus: "",
      optionVerifyStatus: objToArray(VerifyStatus),
      selectedVerifyStatus: null,
      deniedReason: "",
      gender: Gender.Male.value,
      genderErrors: null,
      emailccErrors: ""
    };
    this.state = this.defaultState;

    this.validationData = {
      companyAddress: [notEmpty],
      tradingAddress: [notEmpty],
      companyPhone: [notEmpty, justNumber],
      companyEmail: [notEmpty, emailAddress],
      taxCode: [notEmpty],
      companyName: [notEmpty],
      representativeName: [notEmpty],
      representativePosition: [notEmpty],
    };
  }

  _handlePartnerExist = async () => {
    this.setState({
      exitedPartner: null,
      companyEmailErrors: null,
    });

    let { taxCode, email } = this.state;
    let param = {
      search: "",
      TaxCode: taxCode,
      skip: 0,
      take: 1,
    };

    const partner = this.props.partner;

    var query = objToQuery(param);
    var repsonse = await getAsync("partners" + query);
    var result = await repsonse.json();

    if (result.total > 0) {
      if (result.items[0].id == partner.id) return "";
      else {
        this.setState({ exitedPartner: result.items[0] });
        return this.state.exitedPartner;
      }
    }

    return "";
  };
  _handlePartnerExistByEmail = async () => {
    this.setState({
      companyEmailErrors: null,
      exitedPartnerByEmail: null,
    });

    let { taxCode, email } = this.state;
    let param = {
      search: "",
      identityNumber: "",
      email: email,
      skip: 0,
      take: 1,
    };

    const partner = this.props.partner;

    var query = objToQuery(param);
    var repsonse = await getAsync("partners" + query);
    var result = await repsonse.json();

    if (result.total > 0) {
      if (result.items[0].id == partner.id) return "";
      else {
        this.setState({ exitedPartnerByEmail: result.items[0] });
        return this.state.exitedPartnerByEmail;
      }
    }

    return "";
  };

  _reload() {
    const { partner } = this.props;
    if (partner) {
      let emailCC = "";
      if (partner.emailCCs) {
        partner.emailCCs.map((email, index) => {
          if (index == 0) {
            emailCC += email;
          } else {
            emailCC += ", " + email;
          }
        });
      }
      this.setState({
        dashboardId: partner.dashboardId,
        phone: partner.phone,
        email: partner.email,
        emailcc: emailCC,
        emailccErrors: "",
        taxCode: partner.taxCode,
        companyName: partner.companyName,
        companyAddress: partner.companyAddress,
        tradingAddress: partner.tradingAddress,
        cooperatedDate: partner.cooperatedDate,
        cannotInviteNotes: partner.cannotInviteNotes,
        representativeName: partner.representativeName,
        representativePosition: partner.representativePosition,
        exitedPartner: null,
        id: partner.id,
        partnerManager: partner.partnerManager,
        partnerManagerId: partner.partnerManager.id,
        selectedTier: ChooseTier[partner.tier],
        tier: partner.tier,
        verifyStatus: partner.verifyStatus,
        selectedVerifyStatus: VerifyStatus[partner.verifyStatus],
        deniedReason: partner.deniedReason,
        gender: partner.gender ? partner.gender : "Other",
        acceptedDate: partner.acceptedDate ? partner.acceptedDate : null,
        deniedDate: partner.deniedDate ? partner.deniedDate : null,
        discontinuedDate: partner.discontinuedDate ? partner.discontinuedDate : null,
      });
    }
  }

  _cancelUpdate(callback) {
    confirmSubmit(
      <label style={{ fontSize: "1.2rem", marginBottom: "10px" }}>
        Huỷ thay đổi
      </label>,
      <span style={{ fontSize: "1rem" }}>
        Bạn có thật sự muốn huỷ thay đổi. Mọi thay đổi sẽ không được áp dụng.
      </span>,
      () => {
        callback();
        this._reload();
      }
    );
  }

  async _saveUpdate() {
    const {
      dashboardId,
      phone,
      email,
      emailcc,
      taxCode,
      companyName,
      companyAddress,
      tradingAddress,
      cooperatedDate,
      cannotInviteNotes,
      representativeName,
      representativePosition,
      partnerManagerId,
      id,
      selectedTier,
      selectedVerifyStatus,
      deniedReason,
      gender,
      acceptedDate,
      deniedDate,
      discontinuedDate
    } = this.state;

    const param = {
      dashboardId: dashboardId,
      type: PartnerType.Company.value,
      phone: phone,
      email: email,
      emailCCs: emailcc && emailcc.length > 0 ? emailcc.split('\n') : [],
      taxCode: taxCode,
      companyName: companyName,
      companyAddress: companyAddress,
      tradingAddress: tradingAddress,
      cooperatedDate: cooperatedDate,
      cannotInviteNotes: cannotInviteNotes,
      representativeName: representativeName,
      representativePosition: representativePosition,
      partnerManagerId: partnerManagerId,
      id: id,
      tier: selectedTier.tier,
      verifyStatus: selectedVerifyStatus.value,
      deniedReason: deniedReason,
      gender: gender,
      acceptedDate: acceptedDate,
      deniedDate: deniedDate,
      discontinuedDate: discontinuedDate
    };
    if (selectedVerifyStatus.value != VerifyStatus.Denied.value) {
      delete param.deniedReason;
    }

    var valid = true;

    param.emailCCs.map(item => {
      if (item && !emailPattern.test(item.trim())) {
        valid = false
        this.setState({
          emailccErrors: "Email cc không đúng định dạng."
        })
      }
    })

    if (selectedVerifyStatus.value === VerifyStatus.Accepted.value && !acceptedDate) {
      valid = false;
      this.setState({
        dateError: "Ngày accepted không được bỏ trống.",
      });
    }

    if (selectedVerifyStatus.value === VerifyStatus.Denied.value && !deniedDate) {
      valid = false;
      this.setState({
        dateError: "Ngày denied không được bỏ trống.",
      });
    }
    if (selectedVerifyStatus.value === VerifyStatus.Discontinued.value && !discontinuedDate) {
      valid = false;
      this.setState({
        dateError: "Ngày discontinued không được bỏ trống.",
      });
    }
    if (!partnerManagerId) {
      valid = false;
      this.setState({
        partnerManagerIdErrors: "Nhân viên quản lý không được bỏ trống.",
      });
    }

    let exitedPartner = await this._handlePartnerExist();
    let exitedPartnerByEmail = await this._handlePartnerExistByEmail();

    if (exitedPartner) {
      valid = false;
      this.setState({
        mstErrors: "MST đối tác đã tồn tại.",
      });
    }

    if (exitedPartnerByEmail) {
      valid = false;
      this.setState({
        companyEmailErrors: "Email đối tác đã tồn tại.",
      });
    }
    if (!Gender[gender]) {
      valid = false;
      this.setState({
        genderErrors: "Vui lòng chọn giới tính.",
      });
    }

    if (isValid(this.validationData) && valid)
      put("partners", param, () => {
        showSuccess("Thay đổi thông tin thành công");
        this.props.onUpdateSuccess();
        this.setState({
          mstErrors: "",
          partnerManagerIdErrors: "",
          companyEmailErrors: "",
          genderErrors: "",
        });
      });
    else {
      showError(
        "Nội dung không hợp lệ, vui lòng kiểm tra lại thông tin vừa nhập."
      );
      valid = true;
    }
  }

  componentDidMount() {
    this._reload();
  }

  _deletePartnerCompany() {
    confirmSubmit(
      <label style={{ fontSize: "1.2rem", marginBottom: "10px" }}>
        Xoá đối tác
      </label>,
      <span style={{ fontSize: "1rem" }}>
        Bạn có chắc chắn muốn xóa đối tác này?
      </span>,
      () => {
        if (this.props.partner) {
          const partnerId = this.props.partner.id;
          _delete("partners/" + partnerId, "", () => {
            showSuccess("Xóa thông tin đối tác thành công");
            this.props.history.push("/partners");
          });
        }
      }
    );
  }

  render() {
    let {
      dashboardId,
      phone,
      email,
      emailcc,
      taxCode,
      companyName,
      companyAddress,
      tradingAddress,
      exitedPartner,
      cooperatedDate,
      cannotInviteNotes,
      representativeName,
      representativePosition,
      partnerManagerId,
      partnerManagerIdErrors,
      optionTier,
      selectedTier,
      verifyStatus,
      optionVerifyStatus,
      selectedVerifyStatus,
      companyEmailErrors,
      mstErrors,
      exitedPartnerByEmail,
      deniedReason,
      gender,
      genderErrors,
      dateError,
      emailccErrors
    } = this.state;

    let { edit, partner } = this.props;

    let validationData = this.validationData;

    let currentUser = JSON.parse(window.localStorage.getItem(USER_INFO));

    if (
      currentUser.roleCode != MetubRoles.ADMIN &&
      currentUser.roleCode != MetubRoles.DATA_MANAGER
    ) {
      optionVerifyStatus = optionVerifyStatus.filter(
        (item) => item.value != "Denied"
      );
    }

    if (currentUser.roleCode == MetubRoles.PARTNER_MANAGER) {
      optionVerifyStatus = optionVerifyStatus.filter(
        (item) =>
          item.value != VerifyStatus.Denied.value &&
          item.value != VerifyStatus.Accepted.value
      );
    }

    return (
      <div className="update-partner">
        <div className="left input-form">
          <div>
            <label>ID Đối tác</label>
            <TextInput
              className="partnerId"
              value={partner ? partner.id : "-/-"}
              name={"partnerId"}
              placeholder={"ID đối tác"}
              displayName={"ID đối tác"}
              edit={false}
            />
          </div>
          <div>
            <label>Dashboard ID</label>
            <TextInput
              className="dashboardId"
              value={dashboardId}
              name={"dashboardId"}
              placeholder={"Dashboard ID"}
              displayName={"Dashboard ID"}
              onChange={(e) => {
                this.setState({ dashboardId: e.target.value });
              }}
              edit={
                edit &&
                currentUser.roleCode != MetubRoles.PARTNER_MANAGER &&
                currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
              }
            />
          </div>
          <div>
            <label>Mã số thuế</label>
            <TextInput
              className="taxCode"
              value={taxCode}
              name={"taxCode"}
              placeholder={"Mã số thuế"}
              displayName={"Mã số thuế"}
              onChange={(e) => {
                this.setState({ taxCode: e.target.value });
              }}
              validations={validationData.taxCode}
              edit={edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE}
              errors={mstErrors}
            />
            {exitedPartner && (
              <div className="exited-partner">
                <span style={{ color: "#ef4502", fontSize: "12px" }}>
                  Mã số thuế của đối tác đã tồn tại.
                </span>
                <label>Thông tin đối tác đã tồn tại:</label>
                <table className="table border-none">
                  <tbody>
                    <tr>
                      <td className="width50">
                        <img
                          alt="anh cmnd"
                          src={DefaultUserAvatar}
                          style={{ width: "100%" }}
                        />
                      </td>
                      <td>
                        <table className="table border-none">
                          <tbody>
                            <tr>
                              <td>{exitedPartner.companyName}</td>
                            </tr>
                            <tr>
                              <td>
                                {exitedPartner.email +
                                  " | ID: " +
                                  exitedPartner.id}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div>
            <label>Tên công ty</label>
            <TextInput
              className="companyName"
              value={companyName}
              name={"companyName"}
              placeholder={"Tên công ty"}
              displayName={"Tên công ty"}
              onChange={(e) => {
                this.setState({ companyName: e.target.value });
              }}
              validations={validationData.companyName}
              edit={edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE}
            />
          </div>
          <div>
            <label>Địa chỉ giao dịch</label>
            <TextInput
              className="tradingAddress"
              value={tradingAddress}
              name={"tradingAddress"}
              multiline={true}
              placeholder={"Địa chỉ giao dịch"}
              displayName={"Địa chỉ giao dịch"}
              onChange={(e) => {
                this.setState({ tradingAddress: e.target.value });
              }}
              validations={validationData.tradingAddress}
              edit={edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE}
            />
          </div>
          <div>
            <label>Địa chỉ xuất hoá đơn</label>
            <TextInput
              className="companyAddress"
              value={companyAddress}
              multiline={true}
              name={"companyAddress"}
              placeholder={"Địa chỉ xuất hoá đơn"}
              displayName={"Địa chỉ xuất hoá đơn"}
              onChange={(e) => {
                this.setState({ companyAddress: e.target.value });
              }}
              validations={validationData.companyAddress}
              edit={edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE}
            />
          </div>

          <div>
            <label>Số điện thoại</label>
            <TextInput
              className="phone"
              value={phone}
              name={"phone"}
              placeholder={"Nhập sdt"}
              displayName={"Số điện thoại"}
              onChange={(e) => {
                this.setState({ phone: e.target.value });
              }}
              validations={validationData.phone}
              edit={edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE}
            />
          </div>
          <div>
            <label>Email</label>
            <TextInput
              className="email"
              value={email}
              name={"email"}
              displayName={"Email"}
              placeholder={"Nhập email"}
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
              validations={validationData.email}
              edit={edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE}
              errors={companyEmailErrors}
            />
            {exitedPartnerByEmail && (
              <div className="exited-partner">
                <span style={{ color: "#ef4502", fontSize: "12px" }}>
                  Email của đối tác đã tồn tại.
                </span>
                <label>Thông tin đối tác đã tồn tại:</label>
                <table className="table border-none">
                  <tbody>
                    <tr>
                      <td className="width50">
                        <img
                          alt="anh cmnd"
                          src={DefaultUserAvatar}
                          style={{ width: "100%" }}
                        />
                      </td>
                      <td>
                        <table className="table border-none">
                          <tbody>
                            <tr>
                              <td>{exitedPartnerByEmail.companyName}</td>
                            </tr>
                            <tr>
                              <td>
                                {exitedPartnerByEmail.email +
                                  " | ID: " +
                                  exitedPartnerByEmail.id}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div>
            <label>Email CC</label>
            <TextInput
              className="emailcc"
              value={emailcc}
              name={"emailcc"}
              displayName={"Email CC"}
              placeholder={"Email CC"}
              multiline={true}
              onChange={(e) => {
                this.setState({ emailcc: e.target.value, emailccErrors: "" });
              }}
              edit={edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE}
              errors={emailccErrors}
            />
          </div>
          <div>
            <label>Đại diện</label>
            <TextInput
              className="representativeName"
              value={representativeName}
              name={"representativeName"}
              displayName={"Tên người đại diện"}
              placeholder={"Tên người đại diện"}
              onChange={(e) => {
                this.setState({ representativeName: e.target.value });
              }}
              validations={validationData.representativeName}
              edit={edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE}
            />
          </div>
          <div>
            <label>Giới tính</label>
            {edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE ? (
              <div
                className="quiz"
                style={{
                  border: "1px solid #dee2e6",
                  borderRadius: "4px",
                  overflow: "hidden",
                }}
              >
                <RadioGroup
                  aria-label="quiz"
                  className="quiz"
                  name="quiz"
                  style={{
                    display: "block",
                    flexDirection: "row",
                    padding: "0px 20px",
                  }}
                  value={gender}
                  onChange={(e) =>
                    this.setState({ gender: e.target.value, genderErrors: "" })
                  }
                >
                  <FormControlLabel
                    value={Gender.Male.value}
                    style={{ marginRight: "40px", marginBottom: "0px" }}
                    control={<Radio color="primary" />}
                    label={Gender.Male.label}
                  />
                  <FormControlLabel
                    value={Gender.Female.value}
                    style={{ marginRight: "40px", marginBottom: "0px" }}
                    control={<Radio />}
                    label={Gender.Female.label}
                  />
                  <FormControlLabel
                    value={Gender.Other.value}
                    style={{ marginRight: "40px", marginBottom: "0px" }}
                    control={<Radio color="default" />}
                    label={Gender.Other.label}
                  />
                </RadioGroup>
                {edit && genderErrors ? (
                  <FormHelperText
                    style={{
                      color: "#ef4502",
                      margin: "0px",
                      background: "#fff4f2",
                      padding: "10px",
                      textAlign: "right",
                      fontSize: "inherit",
                    }}
                  >
                    {genderErrors}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <span className="custom-date-time-picker view">
                {Gender[gender] ? Gender[gender].label : "-/-"}
              </span>
            )}
          </div>
          <div>
            <label>Chức vụ</label>
            <TextInput
              className="representativePosition"
              value={representativePosition}
              name={"representativePosition"}
              displayName={"Chức vụ"}
              placeholder={"Chức vụ"}
              onChange={(e) => {
                this.setState({ representativePosition: e.target.value });
              }}
              validations={validationData.representativePosition}
              edit={edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE}
            />
          </div>
          {/* <div>
                            <label>CMS ID</label>
                            <SelectOption
                                optionType={OptionType.CMS.value}
                                edit={edit}
                                onChange={(value) => this.setState({ cmsId: value.id })}
                                value={cmsId}
                                allowNull={true}
                            />
                        </div> */}
        </div>
        <div className="right  input-form">
          <div>
            <label>Ngày hợp tác</label>
            <DatetimePicker
              className="cooperatedDate"
              placeholder={"Ngày hợp tác"}
              onChange={(value) => this.setState({ cooperatedDate: value })}
              value={cooperatedDate}
              edit={
                edit &&
                currentUser.roleCode != MetubRoles.PARTNER_MANAGER &&
                currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
              }
            />
          </div>
          <div>
            <label>Tier</label>
            <Select
              options={optionTier}
              onChange={(value) => this.setState({ selectedTier: value })}
              value={selectedTier}
              edit={
                verifyStatus === "Accepted" &&
                  (currentUser.roleCode === "DATA_MANAGER" ||
                    currentUser.roleCode === "ADMIN")
                  ? edit
                  : false
              }
              allowNull={false}
            />
          </div>
          <div>
            <label>Partner Manager</label>
            <UserSelectList
              edit={isInRole(MetubRoles.ADMIN) ? edit : false}
              value={partnerManagerId}
              outLine={true}
              onChange={(value) =>
                this.setState({
                  partnerManagerId: value ? value.id : null,
                  partnerManagerIdErrors: null,
                })
              }
              errors={partnerManagerIdErrors}
            />
          </div>
          <div>
            <label>Ghi chú</label>
            <TextInput
              className="cannotInviteNotes"
              value={cannotInviteNotes}
              name={"cannotInviteNotes"}
              placeholder={"Ghi chú"}
              displayName={"Ghi chú"}
              multiline={true}
              onChange={(e) => {
                this.setState({ cannotInviteNotes: e.target.value });
              }}
              edit={edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE}
            />
          </div>
          <div>
            <label>Trạng thái chờ duyệt</label>
            <Select
              options={optionVerifyStatus}
              onChange={(value) =>
                this.setState({ selectedVerifyStatus: value })
              }
              value={selectedVerifyStatus}
              edit={
                currentUser.roleCode === "DATA_MANAGER" ||
                  currentUser.roleCode === "ADMIN" ||
                  currentUser.roleCode === MetubRoles.LEGAL_EXECUTIVE ||
                  ((currentUser.roleCode == MetubRoles.PARTNER_MANAGER ||
                    currentUser.roleCode == MetubRoles.LEADER_PARTNER_MANAGER) &&
                    VerifyStatus[partner.verifyStatus].value) ==
                  VerifyStatus.Denied.value
                  ? edit
                  : false
              }
              allowNull={false}
            />
          </div>
          {selectedVerifyStatus && selectedVerifyStatus.value && selectedVerifyStatus.value != VerifyStatus.Pending.value && (
            <div>
              <label>
                Ngày{" "}
                {selectedVerifyStatus.label.toString().toLowerCase()}
              </label>
              <DatetimePicker
                className="issuedOn"
                type="date"
                placeholder={
                  "Ngày " +
                  selectedVerifyStatus.label.toString().toLowerCase()
                }
                onChange={(value) =>
                  this.setState({
                    [selectedVerifyStatus.key]: value,
                    dateError: null,
                  })
                }
                value={this.state[selectedVerifyStatus.key]}
                edit={
                  currentUser.roleCode === "DATA_MANAGER" ||
                    currentUser.roleCode === "ADMIN" ||
                    currentUser.roleCode === MetubRoles.LEGAL_EXECUTIVE ||
                    ((currentUser.roleCode == MetubRoles.PARTNER_MANAGER ||
                      currentUser.roleCode ==
                      MetubRoles.LEADER_PARTNER_MANAGER) &&
                      VerifyStatus[partner.verifyStatus].value) ==
                    VerifyStatus.Denied.value
                    ? edit
                    : false
                }
                errors={dateError}
              />
            </div>)
          }
          {selectedVerifyStatus &&
            selectedVerifyStatus.value == VerifyStatus.Denied.value ? (
            <div>
              <label>Lý do từ chối</label>
              <TextInput
                className="deniedReason"
                value={deniedReason}
                name={"deniedReason"}
                placeholder={"Lý do từ chối"}
                displayName={"Lý do từ chối"}
                multiline={true}
                onChange={(e) => {
                  this.setState({ deniedReason: e.target.value });
                }}
                edit={edit}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default Index;
