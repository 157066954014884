import React from "react";
import { put } from "../../../api";
import Modal from '../../common/modal'
import Button from '@material-ui/core/Button'
import TextInput from '../../common/text-input'
import { notEmpty, isValid } from '../../../utils/validators'

import { showSuccess } from "../../../utils/app";
import { getCurrentUser } from "../../../auth";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            content: "",
            id: "",
            isDefault: false
        };
        this.validationData = {
            title: [notEmpty],
            content: [notEmpty]
        };
    }

    _updateNote() {
        let {
            title,
            content,
            id
        } = this.state

        let param = {
            title: title,
            content: content,
            id: id
        }
        if (isValid(this.validationData)) {
            put("notes", param, () => {
                showSuccess("Thay đổi ghi chú thành công.")
                this.props.onUpdateSuccess()
                this._resetState()
            })
        }
    }

    _setDefaultValue() {
        const { info } = this.props
        if (!info) return
        this.setState({
            title: info.title,
            content: info.content,
            id: info.id
        })
    }

    _resetState() {
        this.setState({
            title: "",
            content: "",
            id: ""
        })
    }

    _handleCheckboxChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    componentDidMount() {
        this._setDefaultValue()
    }

    render() {
        const validationData = this.validationData
        const {
            title,
            content,
        } = this.state
        const { open, edit, info } = this.props
        return (
            <Modal
                className="edit-contact"
                open={open}
                onClose={() => { this._setDefaultValue(); this.props.onClose() }}
                fullWidth={true}
                fullScreen={false}
                header={
                    <div>
                        <span>{edit ? "THAY ĐỔI GHI CHÚ" : "CHI TIẾT GHI CHÚ"}</span>
                    </div>
                }
                content={
                    <table className="table border-none input-form">
                        <tbody>
                            <tr>
                                <td>
                                    <label>Tiêu đề</label>
                                    <TextInput
                                        className="contactFullName"
                                        value={title}
                                        name={"title"}
                                        placeholder={"Nhập họ tên"}
                                        displayName={"Họ tên"}
                                        onChange={e => {
                                            this.setState({ title: e.target.value })
                                        }}
                                        validations={validationData.title}
                                        edit={edit}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Nội dung</label>
                                    <TextInput
                                        className="note-content"
                                        value={content}
                                        name={"content"}
                                        placeholder={"Nhập nội dung"}
                                        displayName={"Nội dung"}
                                        onChange={e => {
                                            this.setState({ content: e.target.value })
                                        }}
                                        validations={validationData.content}
                                        edit={edit}
                                        multiline={true}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </ table>

                }
                action={
                    getCurrentUser().id === info.userId && <Button
                        color="primary"
                        onClick={() => edit ? this._updateNote() : this.props.changeToUpdate()}
                    >
                        {
                            edit ? "Lưu" : "Cập nhật"
                        }
                    </Button>
                }
                allowCancel={true}
            />
        );
    }
}

export default Index;
