import React from "react";
import Update from "../update";

class Index extends React.Component {

  render() {
    return (
      <div>
        <Update
          onEdit={() => this._handleAddPartner()}
          partnerId={this.props.partnerId}
          history={this.props.history}
          partner={this.props.partner}
        />
      </div>
    );
  }
}

export default Index;
