import React from "react";
import List from './list'
import AddNew from './add-new'
import Button from '@material-ui/core/Button'
import { get } from "../../../api";
import { MetubRoles } from '../../../constants/constants'

import { isInRole } from "../../../auth"
import {
    Note as NoteIcon
} from '@material-ui/icons'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: [],
            openAddNewDialog: false
        };
    }

    _handleGetNotes(videoId) {
        get("videos/" + videoId + "/notes", result => {
            this.setState({
                notes: result.items
            })
        })
    }

    componentDidMount() {
        let { videoId } = this.props
        this._handleGetNotes(videoId)
    }

    render() {

        let { notes, openAddNewDialog } = this.state
        const { videoId } = this.props
        return (
            <div className="admin-box">
                <span>
                    <NoteIcon />
                    Danh sách ghi chú
                    {
                        isInRole(MetubRoles.CREATE_VIDEO) && <Button
                            color={"primary"}
                            onClick={() => this.setState({ openAddNewDialog: !openAddNewDialog })}
                        >
                            Tạo mới
                        </Button>

                    }
                </span>
                <div className="contact-page">
                    <List notes={notes} onUpdateSuccess={() => this._handleGetNotes(videoId)} />
                    <AddNew
                        open={openAddNewDialog}
                        onClose={() => this.setState({ openAddNewDialog: false })}
                        onAddSuccess={() => this.setState({ openAddNewDialog: false }, () => this._handleGetNotes(videoId))}
                        videoId={videoId} />
                </div>
            </div>
        );
    }
}

export default Index;
