import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ToolTip from '@material-ui/core/Tooltip'
import red from '@material-ui/core/colors/red';
import { get, _delete } from '../../../api'
import Edit from './update'
import { put } from '../../../api';
import { showSuccess } from '../../../utils/app';
import { BASE_API } from '../../../constants/appSettings';
import { getCurrentUser } from '../../../auth';
import moment from 'moment';
import {
    confirmSubmit
} from "../../../utils/common";

const styles = theme => ({
    card: {
        maxWidth: 400,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
});

class RecipeReviewCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            openEditNote: false,
            userInfo: null,
            isEdit: true
        };
        this.currentUser = getCurrentUser()
    }

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    _onUpdateSuccess() {
        this.props.onUpdateSuccess()
        this.setState({
            openEditNote: false
        })
    }

    _updateDefaut() {
        let param = this.props.info
        if (!param) return
        param.isDefault = !param.isDefault
        put("contacts", param, () => {
            showSuccess("Thay đổi thông tin thành công.")
            this.props.onUpdateSuccess()
        })
    }

    _getInfo(noteId) {
        get("notes/" + noteId, result => {
            this.setState({
                userInfo: result.user
            })
        })
    }

    _deleteNote(noteId) {
        confirmSubmit(
            <label style={{ fontSize: "1.2rem", marginBottom: "10px" }}>Xoá ghi chú</label>,
            <span style={{ fontSize: "1rem" }}>Bạn có chắc chắn muốn xóa thông tin ghi chú này?</span>,
            () => {
                if (noteId) {
                    _delete("notes/" + noteId, null, () => {
                        showSuccess("Xoá ghi chú thành công.")
                        this.props.onDeleteSuccess()
                    })
                }
            }
        );

    }


    componentWillReceiveProps(nextProps) {
        if (this.props.info !== nextProps.info) {
            this._getInfo(nextProps.info.id)
        }
    }

    componentDidMount() {
        let { info } = this.props
        if (info && info.id) {
            this._getInfo(info.id)
        }
        this.setState({
            currentUserId: this.currentUser.id
        })
    }
    render() {
        const { classes, info, className } = this.props;

        const { openEditNote, userInfo, isEdit } = this.state
        return (
            <div className={className}>
                {
                    info && <Card className={classes.card + " custom-card"}>
                        {
                            userInfo && <CardHeader
                                avatar={
                                    <Avatar
                                        aria-label="Recipe"
                                        className={classes.avatar + " vertical-top"}
                                        src={BASE_API + userInfo.avatar}
                                    >
                                    </Avatar>
                                }
                                className="note-header"
                                title={<b>{info.title}</b>}
                                subheader={<div style={{ paddingRight: "20px" }}>
                                    <p className="subheader-custom">{info.content}</p>
                                    <span style={{ marginTop: "10px", display: "inline-block", fontWeight: "bold" }}>Được cập nhật bởi {userInfo.fullName} - {moment(info.createTime).format("DD/MM/YYYY hh:mm")} </span>
                                </div>}
                                action={
                                    [
                                        <ToolTip title="Xem chi tiết" placement="top">
                                            <IconButton onClick={() => this.setState({ openEditNote: true, isEdit: false })}>
                                                <InfoIcon />
                                            </IconButton>
                                        </ToolTip>,
                                        getCurrentUser().id === info.userId ? <ToolTip title="Cập nhật" placement="top">
                                            <IconButton onClick={() => this.setState({ openEditNote: true, isEdit: true })}>
                                                <EditIcon />
                                            </IconButton>
                                        </ToolTip> : null,
                                        getCurrentUser().id === info.userId ? <ToolTip title="Xoá" placement="top">
                                            <IconButton onClick={() => this._deleteNote(info.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ToolTip> : null

                                    ]
                                }
                            />
                        }
                    </Card>
                }
                <Edit
                    open={openEditNote}
                    onClose={() => this.setState({ openEditNote: false })}
                    onUpdateSuccess={() => this._onUpdateSuccess()}
                    changeToUpdate={() => this.setState({ isEdit: true })}
                    info={info}
                    edit={isEdit}
                />
            </div>
        );
    }
}

RecipeReviewCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);