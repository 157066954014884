import React from "react";
import { get, post } from "../../api";
import Modal from "../common/modal";
import Button from "@material-ui/core/Button";
import TextInput from "../common/text-input";
import { notEmpty, justNumber, isValid } from "../../utils/validators";
import SelectOption from "../common/select-option";
import UserSelectList from "../common/user-select-list";

import { showSuccess } from "../../utils/app";
import { OptionType, MetubRoles } from "../../constants/constants";
import { jsonFromUrlParams } from "../../utils/common";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      youtubeId: "",
      name: "",
      notes: "",
      viewCount: 0,
      sharingRateId: 0,
      sharingRateIdError: null,
      optimizerId: null,
      optimizerIdError: null,
      thumbnail: "",
      isVideoInfoLoading: false,
    };

    this.defaultState = this.state;

    this.validationData = {
      url: [notEmpty],
      youtubeId: [notEmpty],
      name: [notEmpty],
      viewCount: [notEmpty, justNumber],
    };
  }

  _addChannel() {
    let {
      url,
      youtubeId,
      name,
      notes,
      viewCount,
      sharingRateId,
      optimizerId,
      thumbnail,
    } = this.state;

    let param = {
      url: url,
      youtubeId: youtubeId,
      name: name,
      notes: notes,
      viewCount: viewCount,
      sharingRateId: sharingRateId,
      optimizerId: optimizerId,
      partnerId: this.props.partnerId,
      thumbnail: thumbnail,
    };

    let valid = true;
    if (!isValid(this.validationData)) valid = false;

    if (!sharingRateId) {
      valid = false;
      this.setState({
        sharingRateIdError: "Tỷ lệ chia sẻ không được bỏ trống.",
      });
    }

    if (!optimizerId) {
      valid = false;
      this.setState({
        optimizerIdError: "Người tối ưu không được bỏ trống.",
      });
    }

    if (valid)
      post("videos", param, () => {
        showSuccess("Thêm video thành công.");
        this.props.onAddSuccess();
        this._resetState();
      });
  }

  _resetState() {
    this.setState({
      ...this.defaultState,
      optimizerId: null,
    });
  }

  _getVideoInfoFromYoutube() {
    const { url } = this.state;
    if (url === "") return;

    let temp = url.split("?v=");
    if (!temp) return;
    this.setState(
      {
        isVideoInfoLoading: true,
      },
      () => {
        let videoId = temp[temp.length - 1];
        get(
          "youtube/videos/" + videoId,
          (result) => {
            this.setState({
              youtubeId: result.id,
              name: result.snippet.title,
              thumbnail: result.snippet.thumbnails.high.url,
              viewCount: result.statistics.viewCount,
              isVideoInfoLoading: false,
            });
          },
          () => {
            this.setState({ isVideoInfoLoading: false });
          }
        );
      }
    );
  }

  render() {
    const validationData = this.validationData;
    const {
      url,
      youtubeId,
      name,
      notes,
      viewCount,
      sharingRateId,
      optimizerId,
      isVideoInfoLoading,
      thumbnail,
      sharingRateIdError,
      optimizerIdError,
    } = this.state;
    const { open } = this.props;
    return (
      <Modal
        className="add-new-contact"
        open={open}
        onClose={() => this.props.onClose()}
        fullWidth={true}
        fullScreen={false}
        header={
          <div>
            <span>THÊM MỚI VIDEO</span>
          </div>
        }
        content={
          <div>
            <table className="table border-bottom">
              <tbody>
                <tr>
                  <td className={"border-none"}>
                    <label>Url</label>
                    <TextInput
                      className="url"
                      value={url}
                      name={"url"}
                      placeholder={"Nhập đường dẫn video"}
                      displayName={"URL"}
                      onChange={(e) => {
                        this.setState({ url: e.target.value });
                      }}
                      validations={validationData.url}
                      edit={true}
                      onBlur={() => this._getVideoInfoFromYoutube()}
                      disable={isVideoInfoLoading}
                    />
                  </td>
                </tr>
                {thumbnail && (
                  <tr>
                    <td className="border-none text-center">
                      {thumbnail.length > 0 && (
                        <img alt="" style={{ width: "50%" }} src={thumbnail} />
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="border-none">
                    <label>Thông tin video</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table border-none input-form">
              <tbody>
                <tr>
                  <td>
                    <label>YouTube ID</label>
                    <TextInput
                      className="youtubeId"
                      value={youtubeId}
                      name={"youtubeId"}
                      placeholder={"YouTube ID"}
                      displayName={"YouTube ID"}
                      onChange={(e) => {
                        this.setState({ youtubeId: e.target.value });
                      }}
                      validations={validationData.youtubeId}
                      edit={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Tên video</label>
                    <TextInput
                      className="name"
                      value={name}
                      name={"name"}
                      placeholder={"Tên kênh"}
                      displayName={"Tên kênh"}
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      validations={validationData.name}
                      edit={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Lượt xem</label>
                    <TextInput
                      className="viewCount"
                      value={viewCount}
                      name={"viewCount"}
                      placeholder={"Lượt theo dõi"}
                      displayName={"Lượt theo dõi"}
                      onChange={(e) => {
                        this.setState({ viewCount: e.target.value });
                      }}
                      validations={validationData.viewCount}
                      edit={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Tỷ lệ chia sẻ</label>
                    <SelectOption
                      optionType={OptionType.SharingRate.value}
                      onChange={(value) =>
                        this.setState({
                          sharingRateId: value ? value.value : null,
                          sharingRateIdError: null,
                        })
                      }
                      edit={true}
                      value={sharingRateId}
                      allowNull={false}
                      errors={sharingRateIdError}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Người tối ưu</label>
                    <UserSelectList
                      edit={true}
                      value={optimizerId}
                      onChange={(value) =>
                        this.setState({
                          optimizerId: value ? value.id : null,
                          optimizerIdError: null,
                        })
                      }
                      errors={optimizerIdError}
                      outLine
                      filter={(users) => {
                        return users.filter(
                          (item) =>
                            item.roleCode == MetubRoles.OPTIMIZE ||
                            item.roleCode == MetubRoles.OPTIMIZE_MANAGER
                        );
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="border-none">
                    <label>Ghi chú</label>
                    <TextInput
                      className="notes"
                      value={notes}
                      name={"notes"}
                      placeholder={"Ghi chú"}
                      displayName={"Ghi chú"}
                      multiline={true}
                      onChange={(e) => {
                        this.setState({ notes: e.target.value });
                      }}
                      edit={true}
                      minHeight={"200px"}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
        action={
          <Button color="primary" onClick={() => this._addChannel()}>
            Lưu
          </Button>
        }
        allowCancel={true}
      />
    );
  }
}

export default Index;
