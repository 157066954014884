import React from "react";
import ChannelCard from "./channel-card";
import DataTable from "../common/datatable";
import { NavLink } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import Tooltip from "@material-ui/core/Tooltip";
import { isInRole } from "../../auth";
import { MetubRoles, ChannelStatus } from "../../constants/constants";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { Checkbox } from "@material-ui/core";
import { getCurrentUser } from "../../auth";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentId: null,
      openEditChannel: false,
      isFilter: false,
      isShow: false
    };
  }

  _onUpdateSuccess() {
    this.props.onUpdateSuccess();
  }
  _onTablePageChange(param) {
    this.props.onChange(param);
  }
  _handleSelect(item) {
    let { selected, onSelected, channels } = this.props;
    if (item == "All" && channels && channels.length > 0) {
      if (selected && channels && selected.length >= channels.length) {
        selected = [];
      } else {
        selected = [];
        channels.map((item) => selected.push(item.id));
      }
    } else {
      if (selected.find((id) => id == item.id)) {
        selected = selected.filter((id) => id != item.id);
      } else {
        selected.push(item.id);
      }
    }

    if (onSelected) onSelected(selected);
  }
  handleShowAll(isShow) {
    this.setState({
      isShow: isShow
    })
  }


  render() {
    const {
      channels,
      listType,
      total,
      history,
      selected,
      currentPage,
      showPM
    } = this.props;
    const {
      isShow
    } = this.state

    console.log("showPM", showPM)
    const currentUser = getCurrentUser();
    return channels && channels.length > 0 ? (
      <div className="channel-page">
        {listType === "card" ? (
          <div className="channel-list card-view">
            {channels.map((channel, i) => (
              <ChannelCard
                info={channel}
                key={i}
                history={history}
                onUpdateSuccess={() => this._onUpdateSuccess()}
              />
            ))}
          </div>
        ) : (
          <div className="channel-list list-view">
            <DataTable
              data={channels}
              total={total}
              isPaging={true}
              onChange={(param) => this._onTablePageChange(param)}
              currentPage={currentPage}
              renderHeader={() => {
                return (
                  <thead>
                    <tr>
                      {currentUser.roleCode != MetubRoles.PARTNER_MANAGER ? (
                        <th>
                          <Checkbox
                            value="secondary"
                            color="primary"
                            checked={
                              selected &&
                              channels &&
                              selected.length >= channels.length
                            }
                            style={{
                              color: "#327AB7",
                              marginLeft: "-3px",
                            }}
                            onClick={() => this._handleSelect("All")}
                          />
                        </th>
                      ) : (
                        ""
                      )}
                      <th className="text-left">ID Kênh</th>
                      <th className="text-left width200"><span className="ellipsis">Tên kênh</span></th>
                      <th className="text-left"><span className="ellipsis">Tình trạng</span></th>
                      <th className="text-center">
                        <span className="ellipsis">
                          Ngày tham gia
                          {
                            isShow
                              ? <ArrowBack onClick={() => this.handleShowAll(false)} style={{ margin: "0px 10px", width: "20px", height: "20px", cursor: "pointer", marginBottom: "-5px" }} />
                              : <ArrowForward onClick={() => this.handleShowAll(true)} style={{ margin: "0px 10px", width: "20px", height: "20px", cursor: "pointer", marginBottom: "-5px" }} />
                          }
                        </span>
                      </th>
                      <th className="text-left" style={{ display: isShow ? "table-cell" : "none" }}><span className="ellipsis">Ngày submit</span></th>
                      <th className="text-left" style={{ display: isShow ? "table-cell" : "none" }}><span className="ellipsis">Ngày invite</span></th>
                      <th className="text-left" style={{ display: isShow ? "table-cell" : "none" }}><span className="ellipsis">Ngày denined</span></th>
                      <th className="text-left" style={{ display: isShow ? "table-cell" : "none" }}><span className="ellipsis">Ngày unlink 1</span></th>
                      <th className="text-left" style={{ display: isShow ? "table-cell" : "none" }}><span className="ellipsis">Ngày unlink 2</span></th>
                      <th className="text-left" style={{ display: isShow ? "table-cell" : "none" }}><span className="ellipsis">Ngày appealing</span></th>
                      {
                        showPM && <th className="text-left width150"><span className="ellipsis">PM</span></th>
                      }
                      <th className="text-left"><span className="ellipsis">Lượt đăng ký</span></th>
                      <th className="text-left"><span className="ellipsis">Tên CMS</span></th>
                      <th className="text-left"><span className="ellipsis">Email quản lý</span></th>
                      <th className="text-left"><span className="ellipsis">Người tối ưu</span></th>
                      <th className="text-left"><span className="ellipsis">Tỷ lệ chia sẻ</span></th>
                      <th className="text-left width20">
                        {currentUser.roleCode === "DATA_MANAGER" ? (
                          <Button size="small" onClick={this.props.onFilter}>
                            <i className="material-icons text-left">
                              arrow_drop_down
                            </i>
                          </Button>
                        ) : (
                          ""
                        )}
                      </th>
                      <th className="text-left"></th>
                    </tr>
                  </thead>
                );
              }}
              renderBody={(items) => {
                return (
                  <tbody>
                    {items.map((item, i) => (
                      <tr key={i}>
                        {currentUser.roleCode != MetubRoles.PARTNER_MANAGER ? (
                          <td>
                            <Checkbox
                              value="secondary"
                              color="primary"
                              checked={selected.includes(item.id)}
                              style={{
                                color: "#327AB7",
                              }}
                              onClick={() => this._handleSelect(item)}
                            />
                          </td>
                        ) : (
                          ""
                        )}
                        <td className="text-left">{item.youtubeId}</td>
                        <td className="text-left">
                          <NavLink
                            to={
                              isInRole(MetubRoles.VIEW_CHANNEL)
                                ? `/channels/${item.id}`
                                : "#"
                            }
                          >
                            {item.name}
                          </NavLink>
                        </td>
                        <td className="text-left">
                          {ChannelStatus[item.status]
                            ? ChannelStatus[item.status].label
                            : item.status}
                        </td>
                        <td className="text-left">
                          {item.cooperationDate
                            ? moment(item.cooperationDate).format("DD/MM/YYYY")
                            : "-/-"}
                        </td>

                        <td className="text-left" style={{ display: isShow ? "table-cell" : "none" }}>
                          {item.submitTime
                            ? moment(item.submitTime).format("DD/MM/YYYY")
                            : "-/-"}
                        </td>
                        <td className="text-left" style={{ display: isShow ? "table-cell" : "none" }}>
                          {item.inviteTime
                            ? moment(item.inviteTime).format("DD/MM/YYYY")
                            : "-/-"}
                        </td>
                        <td className="text-left" style={{ display: isShow ? "table-cell" : "none" }}>
                          {item.deniedTime
                            ? moment(item.deniedTime).format("DD/MM/YYYY")
                            : "-/-"}
                        </td>
                        <td className="text-left" style={{ display: isShow ? "table-cell" : "none" }}>
                          {item.unlink1Time
                            ? moment(item.unlink1Time).format("DD/MM/YYYY")
                            : "-/-"}
                        </td>
                        <td className="text-left" style={{ display: isShow ? "table-cell" : "none" }}>
                          {item.unlink2Time
                            ? moment(item.unlink2Time).format("DD/MM/YYYY")
                            : "-/-"}
                        </td>
                        <td className="text-left" style={{ display: isShow ? "table-cell" : "none" }}>
                          {item.appealingTime
                            ? moment(item.appealingTime).format("DD/MM/YYYY")
                            : "-/-"}
                        </td>

                        {
                          showPM && <td className="text-left">
                            {item.partnerManager
                              ? item.partnerManager.fullName
                              : "-/-"}
                          </td>
                        }
                        <td className="text-left">{item.subscriberCount}</td>
                        <td className="text-left">
                          {item.cms ? item.cms.name : "-/-"}
                        </td>
                        <td className="text-left">
                          {
                            item.managedByEmail ? item.managedByEmail.name : "-/-"
                          }
                        </td>
                        <td className="text-left">
                          {item.optimizer ? item.optimizer.fullName : "-/-"}
                        </td>
                        <td className="text-left">
                          {item.sharingRate ? item.sharingRate.name : ""}
                        </td>

                        <td className="text-left width20">
                          {isInRole(MetubRoles.VIEW_CHANNEL) && (
                            <NavLink to={`/channels/${item.id}`}>
                              <Tooltip title="Xem chi tiết" placement="top">
                                <IconButton>
                                  {item.verifyStatus === "Accepted" ? (
                                    <InfoIcon color="primary" />
                                  ) : (
                                    <InfoIcon />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </NavLink>
                          )}
                        </td>
                        <td>
                          <a href={item.url} target="blank">
                            <Tooltip title="Liên kết Youtube" placement="top">
                              <IconButton>
                                <SubscriptionsIcon />
                              </IconButton>
                            </Tooltip>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                );
              }}
            ></DataTable>
          </div>
        )}
      </div>
    ) : (
      <label className="empty-list-label">Chưa có kênh nào.</label>
    );
  }
}

export default Index;
