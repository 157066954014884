import React from "react";
import { get } from "../../api";
import { TableSizeDefault } from '../../constants/constants'

import { objToQuery } from "../../utils/common";
import List from './list'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: null,
            searchParam: { skip: 0, take: TableSizeDefault.value },
            total: 0
        };
    }

    _handleGetLogs(param) {
        if (!param) return

        let { partnerId } = this.props
        if (!partnerId) return

        let getParam = {
            partnerId: partnerId,
            skip: param.skip,
            take: param.take
        }
        var query = objToQuery(getParam);

        get("partners/" + partnerId + "/modification-logs" + query, result => {
            this.setState({
                logs: result.items,
                total: result.total
            })
        })
    }

    componentDidMount() {
        let { searchParam } = this.state
        this._handleGetLogs(searchParam)
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.changeLogs !== nextProps.changeLogs) {
            let { searchParam } = this.state
            this._handleGetLogs(searchParam)
        }
    }

    render() {
        let {
            logs,
            total
        } = this.state
        return (
            <div className="logs-page">
                {
                    logs && <List logs={logs} total={total} handleGetLogs={(param) => this._handleGetLogs(param)} />
                }
            </div>
        );
    }
}

export default Index;
