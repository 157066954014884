import React from "react";
import Modal from '../common/modal'
import Button from "@material-ui/core/Button";
import TextInput from '../common/text-input'
import { post } from '../../api'
import { notEmpty, justNumber, isValid } from "../../utils/validators";
import { showSuccess, showError } from "../../utils/app";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            likeCount: null,
            viewCount: null
        }
        this.validationData = {
            name: [notEmpty],
            likeCount: [justNumber, notEmpty],
            viewCount: [justNumber, notEmpty]
        };
    }


    _onAddFanpage = () => {
        const {
            name,
            likeCount,
            viewCount
        } = this.state;
        const {
            onAddSucceed
        } = this.props

        let param = {
            name: name,
            likeCount: likeCount,
            viewCount: viewCount
        }
        if (isValid(this.validationData))
            post("fanpages/", param, () => {
                if (onAddSucceed)
                    onAddSucceed()
                showSuccess("Thêm fanpage thành công")
                this.setState({
                    name: null,
                    likeCount: null,
                    viewCount: null
                })
            });
        else {
            showError("Dữ liệu không hợp lệ. Vui lòng kiểm tra lại")
        }
    }


    componentDidMount() {
        this.setState({
        })
    }

    render() {
        let {
            name,
            likeCount,
            viewCount
        } = this.state
        let {
            open,
            onClose
        } = this.props
        return (
            <Modal
                className="add-new-options"
                open={open}
                onClose={onClose}
                fullWidth={true}
                header={
                    <div>
                        <span>TẠO FANPAGE</span>
                    </div>
                }
                content={
                    <table className="table list-item border-bottom">
                        <tbody>
                            <tr>
                                <td className="coin text-left width100">
                                    <span>Tên fanpage</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="name"
                                        value={name}
                                        label={"Tên fanpage"}
                                        name={"name"}
                                        placeholder={"Tên fanpage"}
                                        displayName={"Tên fanpage"}
                                        onChange={e =>
                                            this.setState({ name: e.target.value })
                                        }
                                        validations={this.validationData.name}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left">
                                    <span>Lượt like</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="description"
                                        value={likeCount}
                                        label={"Lượt like"}
                                        name={"likeCount"}
                                        placeholder={"Lượt like"}
                                        onChange={e =>
                                            this.setState({ likeCount: e.target.value })
                                        }
                                        displayName={"Lượt like"}
                                        validations={this.validationData.likeCount}
                                        edit={true}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td className="coin text-left">
                                    <span>Lượt view</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="description"
                                        value={viewCount}
                                        label={"Lượt view"}
                                        name={"viewCount"}
                                        placeholder={"Lượt view"}
                                        onChange={e =>
                                            this.setState({ viewCount: e.target.value })
                                        }
                                        displayName={"Lượt view"}
                                        validations={this.validationData.viewCount}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                action={
                    <Button
                        color="primary"
                        onClick={this._onAddFanpage}
                    >
                        Lưu
            </Button>
                }
                allowCancel={true}
            />
        )
    }
}


export default Index;

