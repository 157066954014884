import React from "react";
import DataTable from "../common/datatable";
import { NavLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";
import {
  RequestStatus,
  RequestType,
  ChannelRequestSubType,
  PartnerRequestSubType,
  ApplyPeriod,
} from "../../constants/constants";
import Detail from "./detail";
import { get } from "../../api";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRequest: null,
    };
  }

  _onTablePageChange(param) {
    this.props.onChange(param);
  }
  getCurrentRequest(referenceId) {
    get(
      "requests/" + referenceId,
      (result) => {
        this.setState({
          currentRequest: result,
          openDetailModal: true,
        });
      },
      null,
      true
    );
  }

  render() {
    const { requests, total, currentPage } = this.props;
    const { openDetailModal, currentRequest } = this.state;
    return requests && requests.length > 0 ? (
      <div className="partner-list">
        <DataTable
          data={requests}
          total={total}
          isPaging={true}
          onChange={(param) => this._onTablePageChange(param)}
          currentPage={currentPage}
          renderHeader={() => {
            return (
              <thead>
                <tr>
                  <th className="text-left">Mã</th>
                  <th className="text-left width100"><span className="ellipsis">Ngày tạo</span></th>
                  <th className="text-left width120"><span className="ellipsis">Áp dụng từ ký đối soát</span></th>

                  <th className="text-left width200"><span className="ellipsis">Loại</span></th>
                  <th className="text-left width80"><span className="ellipsis">ID Đối tác</span></th>
                  <th className="text-left width80"><span className="ellipsis">Tên Đối tác</span></th>
                  <th className="text-left width80"><span className="ellipsis">ID Kênh</span></th>
                  <th className="text-left width80"><span className="ellipsis">UC Kênh</span></th>
                  <th className="text-left width120"><span className="ellipsis">Tên Kênh</span></th>
                  <th className="text-left width450"><span className="ellipsis">Nội dung</span></th>
                  <th className="text-left width80"><span className="ellipsis">Tình trạng</span></th>
                  <th className="text-center width100" />
                </tr>
              </thead>
            );
          }}
          renderBody={(items) => {
            return (
              <tbody>
                {items.map((item, i) => (
                  <tr key={i}>
                    <td className="text-left">{item.id}</td>
                    <td className="text-left">
                      {moment(item.createTime).format("DD/MM/YYYY")}
                    </td>
                    <td className="text-left">{ApplyPeriod[item.applyPeriod] ? ApplyPeriod[item.applyPeriod].label : "-/-"}</td>

                    <td className="text-left width200">
                      {RequestType[item.type].label}

                      {ChannelRequestSubType[item.subType]
                        ? " > " + ChannelRequestSubType[item.subType].label
                        : PartnerRequestSubType[item.subType]
                          ? " > " + PartnerRequestSubType[item.subType].label
                          : null}
                    </td>
                    <td className="text-left ">{item.partnerId}</td>
                    <td className="text-left ">{item.partnerName}</td>
                    <td className="text-left">{item.channelId > 0 ? item.channelId : '--'}</td>
                    <td className="text-left">{item.youtubeId ? item.youtubeId : '--'}</td>
                    <td className="text-left">{item.channelName ? item.channelName : '--'}</td>
                    <td className="text-left">{item.content}</td>
                    <td className="text-left">
                      {RequestStatus[item.status].label}
                    </td>
                    <td>
                      <Tooltip title="Xem chi tiết" placement="top">
                        <IconButton
                          onClick={() =>
                            this.setState({
                              currentRequest: item,
                              openDetailModal: true,
                            })
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            );
          }}
        ></DataTable>
        {currentRequest && (
          <Detail
            requestId={currentRequest.id}
            open={currentRequest && openDetailModal ? true : false}
            onClose={() => this.setState({ openDetailModal: false })}
            onUpdateSuccess={() => this.props.onUpdateSuccess()}
          />
        )}
      </div>
    ) : (
      <label className="empty-list-label">Chưa có yêu cầu nào.</label>
    );
  }
}

export default Index;
