import React from "react";
import { post } from "../../../api";
import Modal from '../../common/modal'
import Button from '@material-ui/core/Button'
import TextInput from '../../common/text-input'
import { notEmpty, isValid } from '../../../utils/validators'

import { showSuccess } from "../../../utils/app";
import { UploadFileType, RequestType } from "../../../constants/constants";
import FileDropZone from '../../common/file-drop-zone'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requestType: RequestType.UpdateVideo.value,
            content: "",
            files: null,
        };

        this.defaultState = this.state

        this.RequestImageRef = React.createRef()

        this.validationData = {
            content: [notEmpty],
        };

    }

    _addRequest() {
        let {
            requestType,
            content,
            files,
        } = this.state

        let param = {
            type: requestType,
            referenceId: this.props.videoId,
            content: content,
            files: files
        }

        if (isValid(this.validationData))
            post("requests", param, () => {
                showSuccess("Thêm yêu cầu thành công.")
                this.props.onAddSuccess()
                this._resetState()
                this.RequestImageRef.current._handleReset()
            })
    }

    _resetState() {
        this.setState({
            ...this.defaultState
        })
    }


    render() {
        const {
            content,
        } = this.state


        const { open } = this.props

        return (
            <Modal
                className="add-new-contact"
                open={open}
                onClose={() => this.props.onClose()}
                fullWidth={true}
                fullScreen={false}
                header={
                    <div>
                        <span>TẠO YÊU CẦU CẬP NHẬT</span>
                    </div>
                }
                content={
                    <div>
                        <table className="table border-none input-form ">
                            <tbody>
                                <tr>
                                    <td className="border-none p00">
                                        <label>Nội dung cập nhật</label>
                                        <TextInput
                                            className="content p00"
                                            value={content}
                                            name={"content"}
                                            placeholder={"Nhập nội dung"}
                                            displayName={"Nội dung"}
                                            multiline={true}
                                            onChange={e => {
                                                this.setState({ content: e.target.value })
                                            }}
                                            edit={true}
                                            minHeight={"100px"}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label >Hình ảnh</label>
                                        <FileDropZone
                                            type={UploadFileType.Contracts.value}
                                            edit={true}
                                            multiImages={true}
                                            onChange={(file) => {
                                                let files = []
                                                file && file.length > 0 && file.map(item => {
                                                    files.push(item.relativePath)
                                                })
                                                this.setState({ files: files })
                                            }}
                                            ref={this.RequestImageRef}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                }
                action={
                    <Button
                        color="primary"
                        onClick={() => this._addRequest()}
                    >
                        Lưu
                    </Button>
                }
                allowCancel={true}
            />
        );
    }
}

export default Index;
