import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Typography,
  IconButton,
  Tooltip,
  Drawer,
} from "@material-ui/core";
import {
  NotifyStatus,
  MetubRoles,
  NotifyType,
  DateSortType,
  RequestType,
  ChannelRequestSubType,
  PartnerRequestSubType,
  UpdateChannelPartner,
  RequestStatus
} from "../../constants/constants";
import Pagination from "../common/pagingation";
import Select from "../common/select";

import { get, put } from "../../api";
import { objToArray, objToQuery } from "../../utils/common";
import { getCurrentUser, isInRole } from "../../auth";
import moment from "moment";
import { NOTIFICATION_FILTER, REQUEST_FILTER } from "../../constants/localStorageKeys";
import { showSuccess } from "../../utils/app";
import {
  FilterList as FilterListIcon,
  Visibility as VisibilityIcon,
  ChevronRight as ChevronRightIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import TextInput from "../common/text-input";
import { StickyContainer, Sticky } from "react-sticky";
import { getNotifyUnread } from "../../actions/app";
import { connect } from "react-redux";
import DateTimePicker from "../common/datetime-picker";
import Detail from "./detail";

let NotifyStatuOptions = objToArray(NotifyStatus);
let NotifyTypeOptions = objToArray(NotifyType);
let DateSortTypeOptions = objToArray(DateSortType);
let RequestTypeOptions = objToArray(RequestType).filter(item => item.value != "None").slice(0, 4)
let ChannelRequestSubTypeOptions = objToArray(ChannelRequestSubType)
let PartnerRequestSubTypeOptions = objToArray(PartnerRequestSubType)
let UpdateChannelPartnerOptions = objToArray(UpdateChannelPartner)
let RequestStatusOptions = objToArray(RequestStatus).filter(item => item.value != "None")

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skip: 0,
      take: 20,
      selecteds: [],
      isSelectAll: false,
      statusSelected: null,
      typeSelected: null,
      isHideReadNotify: false,
      total: 10,
      pageSize: 20,
      page: 1,
      searchKey: "",
      fromDate: null,
      toDate: null,
      sortOptionSelected: null,
    };
  }

  _getNotification = () => {
    let {
      skip,
      take,
      isHideReadNotify,
      statusSelected,
      typeSelected,
      searchKey,
      fromDate,
      toDate,
      sortOptionSelected,
      requestTypeSelected,
      requestSubTypeSelected,
      requestStatusSelected
    } = this.state;
    let isRead = "";
    if (statusSelected && statusSelected.value == NotifyStatus.read.value)
      isRead = true;
    if (statusSelected && statusSelected.value == NotifyStatus.unRead.value)
      isRead = false;
    if (isHideReadNotify == true) isRead = false;

    let param = {
      skip: skip,
      take: take,
      isRead: isRead,
      Search: searchKey,
      Type: typeSelected ? typeSelected.value : "None",
      fromDate: fromDate,
      toDate: toDate,
      sortOption: sortOptionSelected ? sortOptionSelected.value : "None",
    };
    if (!param.fromDate) delete param["fromDate"];
    if (!param.toDate) delete param["toDate"];
    if (requestTypeSelected) param.requestType = requestTypeSelected.value
    if (requestTypeSelected && requestSubTypeSelected) param.requestSubType = requestSubTypeSelected.value
    if (requestStatusSelected) param.requestStatus = requestStatusSelected.value

    let queryParam = objToQuery(param);
    window.localStorage.setItem(
      NOTIFICATION_FILTER,
      JSON.stringify(this.state)
    );
    get("notifications/get-all" + queryParam, (result) => {
      this.setState({
        notifications: result.items,
        total: result.total,
        selecteds: [],
      });
    });
  };
  readNotify(id) {
    if (id) {
      let params = {
        isRead: true,
        id: id,
      };
      put("notifications", params, () => {
        this._getNotification();
        this.setState({
          selecteds: this.state.selecteds.filter((item) => item != id),
        });
      });
    }
  }
  unReadNotify(id) {
    if (id) {
      let params = {
        isRead: false,
        id: id,
      };
      put("notifications", params, () => {
        this._getNotification();
        this.setState({
          selecteds: this.state.selecteds.filter((item) => item != id),
        });
      });
    }
  }
  _handleReadNotify() {
    let { selecteds } = this.state;
    if (selecteds.length == 0) return;
    selecteds.map((item) => {
      this.readNotify(item);
    });
  }
  _handleUnReadNotify() {
    let { selecteds } = this.state;
    if (selecteds.length == 0) return;
    selecteds.map((item) => {
      this.unReadNotify(item);
    });
  }
  _handleSelect(id) {
    let { selecteds } = this.state;
    if (selecteds.includes(id)) {
      selecteds = selecteds.filter((item) => item != id);
    } else {
      selecteds.push(id);
    }
    this.setState({
      selecteds,
    });
  }
  _handleSelectAll() {
    let { selecteds, notifications } = this.state;
    if (selecteds.length != notifications.length) {
      let array = [];
      notifications.map((item) => array.push(item.id));
      this.setState({
        selecteds: array,
        isSelectAll: true,
      });
    } else {
      this.setState({
        selecteds: [],
        isSelectAll: false,
      });
    }
  }
  _isReadNotify(item) {
    if (item) {
      let params = {
        isRead: true,
        id: item.id,
      };
      console.log("item", item);
      put("notifications", params, () => {
        this._getNotification();
        this.props.getNotifyUnread();
        if (item.referenceId) {
          if (
            item.type == NotifyType.Request.value ||
            item.type == NotifyType.RequestUnlink.value ||
            item.type == NotifyType.RequestUpdate.value
          ) {
            if (item.link) {
              this.props.history.push(item.link);
            } else {
              this.props.history.push(`/requests`);
            }
          }
          if (
            item.type == NotifyType.CreatePartner.value ||
            item.type == NotifyType.UpdatePartner.value
          ) {
            this.props.history.push("/partners/" + item.referenceId);
          }
          if (
            item.type == NotifyType.CreateContract.value ||
            item.type == NotifyType.UpdateContract.value
          ) {
            this.props.history.push("/contracts/" + item.referenceId);
          }
          if (
            item.type == NotifyType.CreateChannel.value ||
            item.type == NotifyType.UpdateChannel.value
          ) {
            this.props.history.push("/channels/" + item.referenceId);
          }
        }
      });
    }
  }
  _handleUpateAllNotifyStatus(isRead) {
    let { selecteds } = this.state;
    let param = {
      ids: selecteds,
      isRead: isRead,
    };
    put("notifications/update-isread", param, () => {
      showSuccess("Cập nhật thành công");
      this._getNotification();
      this.props.getNotifyUnread();
    });
  }


  componentWillMount() {
    let filter = JSON.parse(window.localStorage.getItem(NOTIFICATION_FILTER));
    if (filter) {
      this.setState({
        ...filter,
        openNotifyDetail: false,
      });
    }
  }

  componentDidMount() {
    this._getNotification();
  }

  render() {
    if (
      !isInRole(MetubRoles.ADMIN) &&
      !isInRole(MetubRoles.BOD) &&
      !isInRole(MetubRoles.LEGAL_EXECUTIVE) &&
      !isInRole(MetubRoles.PARTNER_MANAGER) &&
      !isInRole(MetubRoles.DATA_MANAGER) &&
      !isInRole(MetubRoles.LEADER_PARTNER_MANAGER) &&
      !isInRole(MetubRoles.OPTIMIZE) &&
      !isInRole(MetubRoles.OPTIMIZE_MANAGER) &&
      !isInRole(MetubRoles.ADMIN_OFFICER)
    )
      return null;

    let {
      notifications,
      page,
      selecteds,
      statusSelected,
      typeSelected,
      isHideReadNotify,
      total,
      pageSize,
      searchKey,
      openNotifyDetail,
      currentNotify,
      fromDate,
      toDate,
      sortOptionSelected,
      requestSubTypeSelected,
      requestTypeSelected,
      requestStatusSelected
    } = this.state;

    const currentUser = getCurrentUser();

    let notifyTypeOptions =
      currentUser.roleCode == MetubRoles.PARTNER_MANAGER
        ? NotifyTypeOptions
        : NotifyTypeOptions.filter(
          (item) => item.value != NotifyType.UpdateChannel.value
        );

    return (
      <div className="notification">
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          className="box-title"
        >
          <div>
            Thông báo hệ thống [{" "}
            {currentUser && currentUser.roleName ? currentUser.roleName : ""} ]
            <p>
              Chỉ những thông báo phù hợp với bạn mới được hiển thị bên dưới.
            </p>
          </div>
        </Typography>
        <div className="admin-box" style={{ position: "relative" }}>
          <span>
            <FilterListIcon />
            Bộ lọc dữ liệu
          </span>
          <div className="search-content">
            <div className="search-key">
              <label>Lọc theo ID</label>
              <div>
                <TextInput
                  name="searchKey"
                  displayName="Thông tin tìm kiếm"
                  placeholder="ID đối tác, ID kênh ..."
                  type="text"
                  value={searchKey}
                  onChange={(e) => this.setState({ searchKey: e.target.value })}
                  edit={true}
                  outLine
                  height={35}
                  label="asdf"
                />
                <span
                  className="search-bt"
                  onClick={() => this._getNotification()}
                >
                  Tìm nhanh
                </span>
              </div>
            </div>
          </div>
          <div className="search-option input-form">
            <div className="width30pc ">
              <label>Thời gian</label>
              <div className="date-box">
                <DateTimePicker
                  className="fromDate"
                  type="date"
                  placeholder={"Từ ngày"}
                  onChange={(value) =>
                    this.setState(
                      { fromDate: value, toDate: value ? toDate : null },
                      () => this._getNotification()
                    )
                  }
                  value={fromDate}
                  edit={true}
                  border={false}
                  isClearable={true}
                />
                <ChevronRightIcon />
                <DateTimePicker
                  className="toDate"
                  type="date"
                  placeholder={"Đến ngày"}
                  onChange={(value) =>
                    this.setState({ toDate: value }, () =>
                      this._getNotification()
                    )
                  }
                  value={toDate}
                  edit={true}
                  border={false}
                  minDate={fromDate ? fromDate : null}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="width35pc">
              <label>Loại thông báo</label>
              <Select
                className="select-status"
                options={NotifyTypeOptions}
                onChange={(value) =>
                  this.setState(
                    {
                      typeSelected: value,
                    },
                    () => this._getNotification()
                  )
                }
                value={typeSelected}
                edit={true}
                allowNull={true}
                placeholder="Lọc theo loại yêu cầu"
              />
            </div>
            <div className="width35pc mr00">
              <label>Trạng thái thông báo</label>
              <Select
                className="select-status"
                options={NotifyStatuOptions}
                onChange={(value) =>
                  this.setState(
                    {
                      statusSelected: value,
                    },
                    () => this._getNotification()
                  )
                }
                value={statusSelected}
                edit={true}
                allowNull={true}
                placeholder="Lọc theo trạng thái"
              />
            </div>


          </div>
          <div className="search-option input-form">
            <div className="width35pc mr10">
              <label>Loại yêu cầu</label>
              <Select
                className="select-status"
                options={RequestTypeOptions}
                onChange={(value) => this.setState({
                  requestTypeSelected: value,
                  requestSubTypeSelected: value ? requestSubTypeSelected : null
                }, () => this._getNotification())}
                value={requestTypeSelected}
                edit={true}
                allowNull={true}
                placeholder="Lọc theo loại yêu cầu"
              />
            </div>
            {
              requestTypeSelected && requestTypeSelected.value == RequestType.UpdateChannel.value ?
                <td className="width50pc mr10">
                  <label>Nội dung cập nhật</label>
                  <Select
                    options={ChannelRequestSubTypeOptions}
                    onChange={value =>
                      this.setState({
                        requestSubTypeSelected: value,
                      }, () => this._getNotification())
                    }
                    placeholder="Chọn nội dung cập nhật"
                    value={requestSubTypeSelected}
                    edit={true}
                    allowNull={true}
                    outLine
                  />
                </td> : ""

            }
            {
              requestTypeSelected && requestTypeSelected.value == RequestType.UpdatePartner.value ?
                <td className="width50pc mr10">
                  <label>Nội dung cập nhật</label>
                  <Select
                    options={PartnerRequestSubTypeOptions}
                    onChange={value =>
                      this.setState({
                        requestSubTypeSelected: value
                      }, () => this._getNotification())
                    }
                    placeholder="Chọn nội dung cập nhật"
                    value={requestSubTypeSelected}
                    edit={true}
                    allowNull={true}
                    outLine
                  />
                </td> : ""

            }
            {
              requestTypeSelected && requestTypeSelected.value == RequestType.UpdateChannelPartner.value ?
                <td className="width50pc mr10">
                  <label>Nội dung cập nhật</label>
                  <Select
                    options={UpdateChannelPartnerOptions}
                    onChange={value =>
                      this.setState({
                        requestSubTypeSelected: value
                      }, () => this._getNotification())
                    }
                    placeholder="Chọn nội dung cập nhật"
                    value={requestSubTypeSelected}
                    edit={true}
                    allowNull={true}
                    outLine
                  />
                </td> : ""

            }
            <td className="width200">
              <label>Trạng thái yêu cầu</label>
              <Select
                options={RequestStatusOptions}
                onChange={value =>
                  this.setState({
                    requestStatusSelected: value
                  }, () => this._getNotification())
                }
                placeholder="Chọn trạng thái"
                value={requestStatusSelected}
                edit={true}
                allowNull={true}
                outLine
              />
            </td>
          </div>
        </div>

        <div className="admin-box" style={{ overflow: "hidden" }}>
          <StickyContainer style={{ padding: 0 }}>
            <Sticky topOffset={-50}>
              {({ style, wasSticky }) => (
                <div
                  className={"notify-header " + (wasSticky ? " wasSticky" : "")}
                  style={{
                    ...style,
                    top: 50,
                    background: "#fff",
                    zIndex: 1,
                    transform: "unset",
                  }}
                >
                  <div className="selected">
                    <Checkbox
                      value="secondary"
                      color="primary"
                      checked={
                        selecteds &&
                          notifications &&
                          selecteds.length == notifications.length &&
                          notifications.length > 0
                          ? true
                          : false
                      }
                      style={{
                        color: "#327AB7",
                      }}
                      onClick={() => this._handleSelectAll()}
                    />
                    {selecteds && selecteds.length > 0 ? (
                      <div className="handle">
                        <span>Đã chọn {selecteds.length} thông báo.</span>
                        <span
                          className="action"
                          onClick={() => this._handleUpateAllNotifyStatus(true)}
                        >
                          Đánh dấu đã xem
                        </span>
                        <span
                          className="action"
                          onClick={() =>
                            this._handleUpateAllNotifyStatus(false)
                          }
                        >
                          Đánh dấu chưa xem
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="record-action">
                    <Checkbox
                      value="secondary"
                      color="primary"
                      checked={isHideReadNotify}
                      style={{
                        color: "#327AB7",
                      }}
                      onClick={() =>
                        this.setState(
                          { isHideReadNotify: !isHideReadNotify },
                          () => this._getNotification()
                        )
                      }
                    />
                    <span>Ẩn tin đã xem</span>
                    <span className="pl20 pr20">|</span>
                    <Select
                      className="select-status width200"
                      options={DateSortTypeOptions}
                      onChange={(value) =>
                        this.setState(
                          {
                            sortOptionSelected: value,
                          },
                          () => this._getNotification()
                        )
                      }
                      value={sortOptionSelected}
                      edit={true}
                      allowNull={true}
                      placeholder="Không sắp xếp"
                    />
                  </div>
                </div>
              )}
            </Sticky>

            {notifications && notifications.length > 0 ? (
              <List
                className={"request-pending"}
                style={{ minHeight: notifications.length * 71 + "px" }}
              >
                {notifications.map((item, i) => (
                  <ListItem
                    className={"item" + (item.isRead == false ? " unread" : "")}
                    key={i}
                  >
                    <Checkbox
                      defaultChecked
                      checked={selecteds.includes(item.id)}
                      value="secondary"
                      color="primary"
                      style={{
                        color: "#327AB7",
                      }}
                      onClick={() => this._handleSelect(item.id)}
                    />
                    <ListItemText
                      onClick={() => this._isReadNotify(item)}
                      className={
                        "notification-message" +
                        (item.isRead == false ? " unread" : "")
                      }
                      primary={
                        <span style={{ fontSize: "15px" }}>
                          {item.type === NotifyType.Request.value ||
                            item.type === NotifyType.RequestUnlink.value ? (
                            <span
                              className="ellipsis"
                              style={{ width: "100%" }}
                            >
                              <span style={{ color: "#EF5660" }}>
                                [{" "}
                                {item.type == NotifyType.RequestUnlink.value
                                  ? "Yêu cầu unlink"
                                  : "Yêu cầu cập nhật"}{" "}
                                ]{" "}
                              </span>
                              {item.message}
                            </span>
                          ) : (
                            <span
                              className="ellipsis"
                              style={{ width: "100%" }}
                            >
                              {item.message}
                            </span>
                          )}
                        </span>
                      }
                      secondary={
                        <span style={{ alignItems: "center", display: "flex" }}>
                          <span>
                            Được submit bởi{" "}
                            <b>{item.createByName ? item.createByName : ""}</b>
                          </span>
                          {item.displayTime && moment(item.displayTime) ? (
                            <span
                              style={{
                                marginLeft: "30px",
                                fontStyle: "italic",
                              }}
                            >
                              {" "}
                              lúc {moment(item.displayTime).format(
                                "hh:mm"
                              )}{" "}
                              ngày{" "}
                              {moment(item.displayTime).format("DD/MM/YYYY")}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      }
                    />
                    <div className="action">
                      <Tooltip title="Xem chi tiết" placement="left">
                        <IconButton
                          onClick={() =>
                            this.setState(
                              {
                                openNotifyDetail: true,
                                currentNotify: item,
                              },
                              () => this.readNotify(item.id)
                            )
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </ListItem>
                ))}
              </List>
            ) : (
              <label className="empty-list-label">Chưa có thông báo nào.</label>
            )}
            {total > pageSize ? (
              <Pagination
                total={total}
                pageSize={pageSize}
                currentPage={page}
                onChange={(page) => {
                  if (page >= 1)
                    this.setState(
                      {
                        skip: (page - 1) * pageSize,
                        page: page,
                      },
                      () => this._getNotification()
                    );
                }}
              />
            ) : (
              ""
            )}
          </StickyContainer>
          <Drawer
            anchor="right"
            open={openNotifyDetail}
            onClose={() =>
              this.setState({
                openNotifyDetail: false,
                currentNotify: null,
              })
            }
          >
            {currentNotify &&
              currentNotify.type != NotifyType.Request.value &&
              currentNotify.type != NotifyType.RequestUnlink.value ? (
              <div className="update-user-drawer">
                <span className="drawer-header">
                  Chi tiết thông báo{" "}
                  <IconButton
                    onClick={() =>
                      this.setState({
                        openNotifyDetail: false,
                        currentNotify: null,
                      })
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
                {currentNotify ? (
                  <div className="notfiy-detail">
                    <label className="noti-type">
                      [ {NotifyType[currentNotify.type].label} ]
                    </label>
                    <span>Thông tin:</span>
                    <pre>{currentNotify.message}</pre>
                    {/* <span>Được submit bởi</span>
                                        <pre>{currentNotify.createByName}</pre> */}
                    <span>Thời gian</span>
                    <pre>
                      {currentNotify.createTime
                        ? moment(currentNotify.createTime).format(
                          "HH:mm [ngày] DD/MM/YYYY"
                        )
                        : "-/-"}
                    </pre>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {(currentNotify &&
              currentNotify.type == NotifyType.Request.value) ||
              (currentNotify &&
                currentNotify.type == NotifyType.RequestUnlink.value) ||
              (currentNotify &&
                currentNotify.type == NotifyType.RequestUpdate.value) ? (
              <div className="update-user-drawer">
                <span className="drawer-header">Chi tiết yêu cầu cập nhật</span>
                <Detail
                  requestId={currentNotify.referenceId}
                  onClose={() => this.setState({ openNotifyDetail: false })}
                  onUpdateSuccess={() => this._getNotification()}
                />
              </div>
            ) : (
              ""
            )}
          </Drawer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.app,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNotifyUnread: () => dispatch(getNotifyUnread()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
