import React from "react";
import DataTable from '../../common/datatable'
import { NavLink } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import SubscriptionsIcon from '@material-ui/icons/Subscriptions'
import Tooltip from '@material-ui/core/Tooltip'
import { isInRole } from '../../../auth'
import moment from "moment"
import { get } from "../../../api";
import { objToQuery } from '../../../utils/common'
import { TableSizeDefault, MetubRoles } from '../../../constants/constants'


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            channelMissing: [],
            total: null,
            skip: 0,
            take: 10,
            page: 1
        };
    }

    componentWillMount() {
        let filter = JSON.parse(window.localStorage.getItem("DASHBOARD_PAGE"))
        if (filter) {
            this.setState({
                page: filter.currentPage ? filter.currentPage : 1
            })
        }
    }

    componentDidMount() {
        this._getChannelMissing();
    }
    _getChannelMissing() {
        let {
            skip,
            take,
            page
        } = this.state
        let getParam = {
            skip: (page - 1 > 0 ? (page - 1) : 0) * take,
            take: take,
        }
        var query = objToQuery(getParam);
        get("channels/channels-of-optimizer" + query, result => {
            window.localStorage.setItem("DASHBOARD_PAGE", JSON.stringify({ currentPage: page }))
            this.setState({
                channelMissing: result.items,
                total: result.total
            })
        })
    }
    _onTablePageChange(params) {
        this.setState({
            skip: params && params.skip ? params.skip : 0,
            take: params && params.take ? params.take : 10,
            page: params && params.page ? params.page : 1
        }, () => {

            this._getChannelMissing();
        })
    }

    render() {
        const { channelMissing, total, page } = this.state;
        return (
            channelMissing && channelMissing.length > 0 ? <div className="channel-page">
                {
                    <div className="channel-list list-view">
                        <DataTable
                            data={channelMissing}
                            total={total}
                            isPaging={true}
                            onChange={param => this._onTablePageChange(param)}
                            currentPage={page}
                            renderHeader={
                                () => {
                                    return (
                                        <thead>
                                            <tr>
                                                <th className="text-left">ID Kênh</th>
                                                <th className="text-left">Tên kênh</th>
                                                <th className="text-left">Tình trạng</th>
                                                <th className="text-left">Ngày tham gia</th>
                                                <th className="text-left">Email quản lý</th>
                                                <th className="text-center">Lượt đăng ký</th>
                                                <th className="text-left">Tên CMS</th>
                                                <th className="text-center">Người tối ưu</th>
                                                <th className="text-left width61" > Tỷ lệ chia sẻ</th>
                                                <th className="text-left width20" ></th>
                                                <th className="text-left" ></th>
                                            </tr>
                                        </thead>
                                    );
                                }
                            }
                            renderBody={
                                items => {
                                    if (!items || items.length < 1) return
                                    return (
                                        <tbody>
                                            {items.map((item, i) => (

                                                <tr key={i}>
                                                    <td className="text-left">
                                                        {item.youtubeId ? item.youtubeId : ""}
                                                    </td>
                                                    <td className="text-left">
                                                        <NavLink to={isInRole(MetubRoles.VIEW_CHANNEL) ? `/channels/${item.id ? item.id : ""}` : "#"}>
                                                            {item.name ? item.name : ""}
                                                        </NavLink>
                                                    </td>
                                                    <td className="text-left">
                                                        {item.status ? item.status : ""}
                                                    </td>
                                                    <td className="text-left">
                                                        {
                                                            item.createTime ? moment(item.createTime).format("DD/MM/YYYY") : ""
                                                        }
                                                    </td>
                                                    <td className="text-left">{item.managedByEmail && item.managedByEmail.name ? item.managedByEmail.name : "-/-"}</td>
                                                    <td className="text-center">{item.subscriberCount ? item.subscriberCount : ""}</td>
                                                    <td className="text-left">{item.cms && item.cms.name ? item.cms.name : "-/-"}</td>
                                                    <td className="text-left">
                                                        {
                                                            item.optimizer && item.optimizer.fullName ? item.optimizer.fullName : ""
                                                        }
                                                    </td>
                                                    <td className="text-left">
                                                        {item.sharingRate && item.sharingRate.name ? item.sharingRate.name : ""}
                                                    </td>

                                                    <td className="text-left width20">
                                                        {
                                                            isInRole(MetubRoles.VIEW_CHANNEL) && <NavLink to={`/channels/${item.id ? item.id : ""}`}>
                                                                <Tooltip title="Xem chi tiết" placement="top">
                                                                    <IconButton>
                                                                        {item.verifyStatus && item.verifyStatus === "Accepted" ? <InfoIcon color="primary" /> : <InfoIcon />}
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </NavLink>
                                                        }
                                                    </td>
                                                    <td>
                                                        <a href={item.url ? item.url : "#"} target="blank">
                                                            <Tooltip title="Liên kết Youtube" placement="top">
                                                                <IconButton>
                                                                    <SubscriptionsIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )
                                }
                            }
                        >
                        </DataTable>
                    </div>
                }
            </div> : <label className="empty-list-label">Chưa có kênh nào.</label>
        );
    }
}

export default Index;
