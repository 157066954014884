import React from "react";
import List from './list'
import AddNew from './add-new'
import Button from '@material-ui/core/Button'
import { get } from "../../../api";
import { objToQuery } from '../../../utils/common'
import { TableSizeDefault, MetubRoles } from '../../../constants/constants'
import { isInRole } from '../../../auth'
import {
    Update as UpdateIcon
} from '@material-ui/icons'


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests: [],
            total: 0,
            openAddNewDialog: false,
            searchParam: { skip: 0, take: TableSizeDefault.value },
            listType: "list"
        };
    }

    _handleGetRequests(param) {
        if (!param) return

        this.setState({
            searchParam: param
        })

        let { contractId } = this.props
        if (!contractId) return

        let getParam = {
            id: contractId,
            skip: param.skip,
            take: param.take
        }
        var query = objToQuery(getParam);


        get("contracts/" + contractId + "/requests" + query, result => {
            this.setState({
                requests: result.items,
                total: result.total
            })
        })
    }

    componentDidMount() {
        let { searchParam } = this.state
        this._handleGetRequests(searchParam)
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.changeLogs !== nextProps.changeLogs || this.props.getList !== nextProps.getList) {
            let { searchParam } = this.state
            this._handleGetRequests(searchParam)
        }

    }

    render() {
        let { requests, openAddNewDialog, total, searchParam } = this.state
        const { contractId } = this.props


        return (
            <div className="contact-page">
                <div className="action">
                    <div className="admin-box">
                        <span>
                            <UpdateIcon />
                            Danh sách yêu cầu
                            {
                                isInRole(MetubRoles.CREATE_REQUEST) && <Button
                                    color={"primary"}
                                    onClick={() => this.setState({ openAddNewDialog: !openAddNewDialog })}
                                >
                                    Tạo mới
                                </Button>

                            }
                        </span>

                        <List
                            requests={requests}
                            total={total}
                            // onUpdateSuccess={() => this._handleGetRequests(searchParam)}
                            onChange={param => this._handleGetRequests(param)}
                            listType="list"
                            onUpdateSuccess={() => this.props.onUpdateSuccess()}
                        />
                    </div>
                </div>

                <AddNew
                    open={openAddNewDialog}
                    onClose={() => this.setState({ openAddNewDialog: false })}
                    onAddSuccess={() => {
                        this._handleGetRequests(searchParam)
                        this.setState({ openAddNewDialog: false })
                        this.props.onAddNewSuccess()
                    }}
                    contractId={contractId} />
            </div>
        );
    }
}

export default Index;
