
import React from "react";
import { NavLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import DataTable from "../../common/datatable";
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import { get } from "../../../api";
import { objToQuery } from "../../../utils/common";
import { TableSizeDefault, PartnerType, MetubRoles } from "../../../constants/constants";
import { isInRole } from '../../../auth'
import { DASHBOARD_FILTER } from "../../../constants/localStorageKeys";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partners: [],
            total: null,
            params: {
                skip: 0,
                take: TableSizeDefault.value
            },
            currentPage: 1
        };
    }

    componentDidMount() {
        let { params } = this.state;
        this._handleGetPartners(params);
    }
    componentWillMount() {
        let filter = JSON.parse(window.localStorage.getItem(DASHBOARD_FILTER))
        if (filter) this.setState({
            ...filter,
        })
    }

    _handleGetPartners(params) {
        this.setState(
            {
                params: params,
                currentPage: params.page
            },
            () => {
                let param = {
                    search: "",
                    verifyStatus: "None",
                    identityNumber: "",
                    taxCode: "",
                    skip: params.skip,
                    take: params.take,
                };
                var query = objToQuery(param);
                get("partners/partners-in-month" + query, result => {
                    window.localStorage.setItem(DASHBOARD_FILTER, JSON.stringify(this.state))
                    this.setState({
                        partners: result.items,
                        total: result.total
                    });
                });
            }
        );
    }

    render() {
        let { partners, total, currentPage } = this.state;
        return (
            <div className="partner-list">
                <DataTable
                    data={partners}
                    total={total}
                    isPaging={true}
                    currentPage={currentPage}
                    renderHeader={() => {
                        return (
                            <thead>
                                <tr>
                                    <th className="text-center">ID</th>
                                    <th className="text-left ">Tên</th>
                                    <th className="text-left">Loại</th>
                                    <th className="text-left">
                                        <span className="ellipsis">Số CMND|Mã số thuế</span>
                                    </th>
                                    <th className="text-left">Địa chỉ</th>
                                    <th className="text-left">Số điện thoại</th>
                                    <th className="text-left">Email</th>
                                    <th className="text-left">PM</th>
                                    <th className="text-left">
                                        Trạng thái
                                    </th>
                                    <th className="text-center" />
                                </tr>
                            </thead>
                        );
                    }}
                    renderBody={items => {
                        return (
                            <tbody>
                                {items.map((item, i) => (
                                    <tr key={i}>
                                        <td style={{ textAlign: "center" }}>#{item.id ? item.id : ""}</td>
                                        <td className="width140">
                                            <Tooltip title={item.type && item.type === PartnerType.Individual.value ? (item.fullName ? item.fullName : "") : (item.companyName ? item.companyName : "")} placement="top-start">
                                                <NavLink className="ellipsis" to={isInRole(MetubRoles.VIEW_PARTNER) ? `partners/${item.id}` : "#"}>
                                                    {item.type && item.type === PartnerType.Individual.value
                                                        ? (item.fullName ? item.fullName : "")
                                                        : (item.companyName ? item.companyName : "")}
                                                </NavLink>
                                            </Tooltip>
                                        </td>
                                        <td className="">
                                            <span className="ellipsis">
                                                {item.type && item.type === PartnerType.Individual.value
                                                    ? PartnerType.Individual.label
                                                    : PartnerType.Company.label}
                                            </span>
                                        </td>
                                        <td className="">
                                            {item.type && item.type === PartnerType.Individual.value
                                                ? (item.identityNumber ? item.identityNumber : "")
                                                : (item.taxCode ? item.taxCode : "")}
                                        </td>
                                        <td className="width140">
                                            <Tooltip title={item.type && item.type === PartnerType.Individual.value ? (item.currentAddress ? item.currentAddress : "") : (item.companyAddress ? item.companyAddress : "")} placement="top-start">
                                                <span className="ellipsis">
                                                    {item.type && item.type === PartnerType.Individual.value
                                                        ? (item.currentAddress ? item.currentAddress : "")
                                                        : (item.companyAddress ? item.companyAddress : "")}
                                                </span>
                                            </Tooltip>
                                        </td>
                                        <td className="">{item.phone ? item.phone : ""}</td>
                                        <td className="width140"><span className="ellipsis">{item.email ? item.email + item.email : ""}</span></td>
                                        <td className="width140">
                                            <Tooltip title={item.partnerManager && item.partnerManager.fullName ? item.partnerManager.fullName : ""} placement="top-start">
                                                <span className="ellipsis">
                                                    {item.partnerManager && item.partnerManager.fullName ? item.partnerManager.fullName : ""}
                                                </span>
                                            </Tooltip>
                                        </td>
                                        <td className="">
                                            <Tooltip title={item.partnerManager && item.partnerManager.fullName ? item.partnerManager.fullName : ""} placement="top-start">
                                                <span className="ellipsis">
                                                    {item.verifyStatus ? item.verifyStatus : ""}
                                                </span>
                                            </Tooltip>
                                        </td>
                                        {
                                            isInRole(MetubRoles.VIEW_PARTNER) && <td className="width10 text-center">
                                                <NavLink to={`partners/${item.id ? item.id : ""}`}>
                                                    <Tooltip title="Xem chi tiết" placement="top">
                                                        <IconButton>
                                                            {item.verifyStatus && item.verifyStatus === "Accepted" ? <InfoIcon color="primary" /> : <InfoIcon />}
                                                        </IconButton>
                                                    </Tooltip>
                                                </NavLink>
                                            </td>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        );
                    }}
                    onChange={params => this._handleGetPartners(params)}
                />
            </div>
        );
    }
}

export default Index;
