import React from "react";
import List from './list'
import AddNew from './add-new'
import Button from '@material-ui/core/Button'
import { get } from "../../api";
import { isInRole, getCurrentUser } from '../../auth'
import { MetubRoles } from '../../constants/constants'
import {
    ContactPhone as ContactPhoneIcon
} from "@material-ui/icons";

import { checkRolePM } from "../../utils/common";


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: [],
            openAddNewDialog: false
        };
    }

    _handleGetContacts(partner) {
        if (!partner) return
        get("partners/" + partner.id + "/contacts", result => {
            if (result) {
                this.setState({
                    contacts: result.items
                })
            }
        })
    }

    componentDidMount() {
        let { partner } = this.props
        this._handleGetContacts(partner)
    }


    render() {
        let { contacts, openAddNewDialog } = this.state
        const { partner } = this.props

        let currentUser = getCurrentUser()
        if (!isInRole(MetubRoles.VIEW_CONTACT) || !partner) return
        return (
            <div className="admin-box">
                <span>
                    <ContactPhoneIcon />
                    Thông tin liên hệ
                    {
                        partner && partner.verifyStatus !== "Discontinued" && <>
                            {
                                isInRole(MetubRoles.CREATE_CONTACT, () => checkRolePM(partner)) ? <Button
                                    color={"primary"}
                                    onClick={() => this.setState({ openAddNewDialog: !openAddNewDialog })}
                                >
                                    Tạo mới
                                </Button> : ""
                            }
                        </>
                    }
                </span>
                <div className="contact-page">
                    {
                        isInRole(MetubRoles.VIEW_CONTACT) && <List
                            contacts={contacts}
                            onUpdateSuccess={() => this._handleGetContacts(partner)}
                            partnerManagerId={this.props.partnerManagerId}
                            partnerId={partner.id}
                            history={this.props.history}
                            canUpdate={checkRolePM(partner)}
                        />
                    }
                    {
                        isInRole(MetubRoles.CREATE_CONTACT) && <AddNew
                            open={openAddNewDialog}
                            onClose={() => this.setState({ openAddNewDialog: false })}
                            onAddSuccess={() => {
                                this.setState({
                                    openAddNewDialog: false
                                })
                                this._handleGetContacts(partner)
                            }}
                            partnerId={partner.id} />
                    }
                </div>
            </div>
        );
    }
}

export default Index;
