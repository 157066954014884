import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { get, _delete } from "../../api";
import Update from './update'
import { formatDate, objToQuery, confirmSubmit } from '../../utils/common'
import DataTable from "../common/datatable";
import { TableSizeDefault, OptionType } from "../../constants/constants";

import { showSuccess } from "../../utils/app";
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment'
import { OPTION_FILTER } from '../../constants/localStorageKeys'
class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            skip: 0,
            take: TableSizeDefault.value,
            currentPage: 1,
            pageType: null,
            options: [],
            openEditOption: false,
            openDeleteOption: false,
            currentOption: undefined,
            searchKey: ''
        };
    }

    _getList(searchParams) {
        let { pageType, currentPage, searchKey } = this.state
        let param = {
            skip: (currentPage - 1) * this.state.take,
            take: this.state.take,
            search: searchKey
        }
        if (searchParams) {
            param.search = searchParams.searchKey
            param.skip = (searchParams.currentPage - 1) * this.state.take
            this.setState({
                searchKey: searchParams.searchKey,
                currentPage: searchParams.currentPage
            })
        }
        var querry = objToQuery(param)
        get("options/" + pageType + querry, result => {
            window.localStorage.setItem(OPTION_FILTER, JSON.stringify({ ...this.state, searchKey: param.search, statusSelected: param.statusSelected }))
            this.setState({
                options: result.items.sort((a, b) => b.id - a.id),
                total: result.total,
            });
        });
    }
    handleTableChange(params) {
        this.setState({
            currentPage: params.page,
        }, () => {
            this._getList()
        })
    }
    componentWillReceiveProps(props) {
        let pageType = props.pageType
        if (pageType !== null && pageType !== undefined && pageType != this.state.pageType) {
            this.setState({
                pageType
            },
                () => this._getList()
            )
        }
    }

    componentWillMount() {
        let filter = JSON.parse(window.localStorage.getItem(OPTION_FILTER))
        if (filter) {
            this.setState({
                currentPage: filter.currentPage,
                searchKey: filter.searchKey,
            })
        }
    }

    componentDidMount() {
        let pageType = this.props.pageType
        if (pageType !== null && pageType !== undefined) {

            this.setState({
                pageType
            }, () => this._getList())
        }

    }
    _onDeleteOption = (item) => {
        if (!item) return;
        let param = {
            ids: [item.id]
        }
        _delete("options/" + this.state.pageType, param,
            () => {
                this._getList()
                showSuccess("Xóa option thành công")
            })
    }
    _closeEditOption = () => {
        this.setState({
            openEditOption: false,
            currentOption: null
        })
    }
    _closeDeleteOption = () => {
        this.setState({
            openDeleteOption: false
        })
    }
    _deleteOption(item) {
        confirmSubmit(
            <div>
                <table className="table border-none">
                    <tr>
                        <td className="width60">
                            <img alt="img" style={{ width: 50 }} src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ1MS43NCA0NTEuNzQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ1MS43NCA0NTEuNzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8cGF0aCBzdHlsZT0iZmlsbDojRTI0QzRCOyIgZD0iTTQ0Ni4zMjQsMzY3LjM4MUwyNjIuODU3LDQxLjY5MmMtMTUuNjQ0LTI4LjQ0NC01OC4zMTEtMjguNDQ0LTczLjk1NiwwTDUuNDM1LDM2Ny4zODEgIGMtMTUuNjQ0LDI4LjQ0NCw0LjI2Nyw2NCwzNi45NzgsNjRoMzY1LjUxMUM0NDIuMDU3LDQyOS45NTksNDYxLjk2OCwzOTUuODI1LDQ0Ni4zMjQsMzY3LjM4MXoiLz4KPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0yMjUuODc5LDYzLjAyNWwxODMuNDY3LDMyNS42ODlINDIuNDEzTDIyNS44NzksNjMuMDI1TDIyNS44NzksNjMuMDI1eiIvPgo8Zz4KCTxwYXRoIHN0eWxlPSJmaWxsOiMzRjQ0NDg7IiBkPSJNMTk2LjAxMywyMTIuMzU5bDExLjM3OCw3NS4zNzhjMS40MjIsOC41MzMsOC41MzMsMTUuNjQ0LDE4LjQ4OSwxNS42NDRsMCwwICAgYzguNTMzLDAsMTcuMDY3LTcuMTExLDE4LjQ4OS0xNS42NDRsMTEuMzc4LTc1LjM3OGMyLjg0NC0xOC40ODktMTEuMzc4LTM0LjEzMy0yOS44NjctMzQuMTMzbDAsMCAgIEMyMDcuMzksMTc4LjIyNSwxOTQuNTksMTkzLjg3LDE5Ni4wMTMsMjEyLjM1OXoiLz4KCTxjaXJjbGUgc3R5bGU9ImZpbGw6IzNGNDQ0ODsiIGN4PSIyMjUuODc5IiBjeT0iMzM2LjA5MiIgcj0iMTcuMDY3Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==" />
                        </td>
                        <td className="text-left" style={{ verticalAlign: "top" }}>
                            <p style={{ fontSize: 20, fontWeight: "bold" }}>Xoá {OptionType[this.props.pageType].label.toLowerCase()}</p>
                            <p style={{ fontSize: 15, marginTop: 10 }}>
                                Bạn có thật sự muốn xoá thông tin này.
                            </p>
                        </td>
                    </tr>
                </table>
            </div>,
            "",
            () => {
                this._onDeleteOption(item)
            },
            () => {

            })
    }

    _handleUpdateOption(item) {
        this.setState(
            {
                currentOption: item
            },
            () => {
                this.setState({
                    openEditOption: true,
                    optionName: this.state.currentOption.name,
                    description: this.state.currentOption.description
                });
            }
        );
    }
    render() {
        let {
            options,
            total,
            openEditOption,
            currentOption,
            currentPage
        } = this.state;
        return (
            <div className="box-body product-list">
                <DataTable
                    data={options}
                    total={total}
                    isPaging={true}
                    currentPage={currentPage}
                    renderHeader={() => {
                        return (
                            <thead>
                                <tr>
                                    <th className="text-center">ID</th>
                                    <th className="text-left">Tên</th>
                                    <th className="text-left">Mô tả</th>
                                    <th className="text-left">Ngày tạo</th>
                                    <th className="text-center">Trạng thái</th>

                                    <th className="text-center width10" />
                                    <th className="text-center width10" />
                                </tr>
                            </thead>
                        );
                    }}
                    renderBody={items => {
                        return (
                            <tbody>
                                {items.map((item, i) => (
                                    <tr key={i}>
                                        <td style={{ textAlign: "center" }}>#{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.description}</td>
                                        <td>{moment(item.createTime).format("DD/MM/YYYY")}</td>
                                        <td className="text-center">{item.status}</td>
                                        <td className="width20">
                                            <Tooltip title="Cập nhật" placement="top">
                                                <IconButton onClick={() => this._handleUpdateOption(item)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <Tooltip title="Xóa" placement="top">
                                                <IconButton onClick={() => this._deleteOption(item)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        );
                    }}
                    onChange={params => this.handleTableChange(params)}
                />
                {
                    currentOption ? <Update open={openEditOption}
                        onClose={this._closeEditOption}
                        id={currentOption.id}
                        pageType={this.state.pageType}
                        _getList={this._getList.bind(this)}
                    /> : ""
                }

            </div>
        )
    }
}

export default Index;
