import React from "react";

import $ from 'jquery'
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hashtags: false
    };
  }


  placeCaretAtEnd(el) {
    el.focus();
    if (typeof window.getSelection != "undefined"
      && typeof document.createRange != "undefined") {
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange != "undefined") {
      var textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }

  componentWillMount() {
    let {
      hashtags
    } = this.state
    let that = this
    $(document).on('keydown', '#example-one', function (e) {
      var arrow = {
        hashtag: 51,
        space: 32,
        comma: 44
      };
      var input_field = $(this);
      switch (e.which) {
        case arrow.comma:
          e.preventDefault();
          input_field.html(input_field.html() + "</span>,");
          that.placeCaretAtEnd(this);
          that.setState({ hashtag: true })
          break;
        case arrow.space:
          that.setState({ hashtag: false })
          if (hashtags) {
            e.preventDefault();
            input_field.html(input_field.html() + "&nbsp;");
            that.placeCaretAtEnd(this);
          }
          break;
        default:
          e.preventDefault();
          if (hashtags == false) {
            input_field.html(input_field.html() + "<span class='highlight'>");
          }
          else {
            input_field.html(input_field.html());
          }
          that.placeCaretAtEnd(this);
          that.setState({ hashtag: true })
          break;
      }

    });
  }
  render() {

    return (
      <div>
        <div id="example-one" contenteditable="true">
        </div>
        <input type="hidden" name="text" />
      </div>
    );
  }
}
export default Index;
