import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { get } from "../../api";
import { objToQuery, formatDateTime } from '../../utils/common'
import { TableSizeDefault } from "../../constants/constants";
import { NavLink } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import DataTable from "../common/datatable";

import { showError } from "../../utils/app";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            skip: 0,
            take: TableSizeDefault.value,
            page: 1,
            pageType: null,
            campaigns: [],
            openAddNewOption: false,
            openEditOption: false,
            openDeleteOption: false,
            isMobile: false,
            optionName: "",
            description: "",
            currentOption: undefined
        };
    }

    _getList() {
        let param = {
            Skip: 0,
            Take: this.state.take
        }
        var query = objToQuery(param)
        get("fanpages" + query, (res) => {
            this.setState({
                campaigns: res ? res.items : [],
                total: res ? res.total : []
            })
        },
            () => {
                showError("Server đang bảo trì, vui lòng thử lại sau")
            }
        )
    }
    handleTableChange(params) {
        this.setState({
            take: params.take
        }, () => {
            this._getList()
        })
    }
    componentWillReceiveProps() {
        this._getList();
    }

    componentDidMount() {
        this._getList();
    }

    render() {
        let {
            campaigns,
            total,
        } = this.state;
        return (
            <div>
                <DataTable
                    data={campaigns}
                    total={total}
                    isPaging={true}
                    renderHeader={() => {
                        return (
                            <thead>
                                <tr>
                                    <th className="text-center">ID</th>
                                    <th className="text-left">Tên Fanpage</th>
                                    <th className="text-left">Lượt like</th>
                                    <th className="text-left">Lượt xem</th>
                                    <th className="text-left">Ngày tạo</th>
                                    <th className="text-center" />
                                </tr>
                            </thead>
                        );
                    }}
                    renderBody={items => {
                        return (
                            <tbody>
                                {items.map((item, i) => (
                                    <tr key={i}>
                                        <td style={{ textAlign: "center" }}>{item.id}</td>
                                        <td><NavLink to={"fanpages/" + item.id}>{item.name}</NavLink></td>
                                        <td>{item.likeCount}</td>
                                        <td>{item.viewCount}</td>
                                        <td>{formatDateTime(item.createTime)}</td>
                                        <td>
                                            <NavLink to={"fanpages/" + item.id}>
                                                <Tooltip title="Xem chi tiết" placement="top">
                                                    <IconButton>
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </NavLink>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        );
                    }}
                    onChange={params => this.handleTableChange(params)}
                />
            </div>
        );
    }
}

export default Index;
