import React from "react";
import DataTable from '../common/datatable'
import { NavLink } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment'
import { isInRole, getCurrentUser } from "../../auth";
import { _delete } from '../../api'
import { MetubRoles, ContractStatus } from "../../constants/constants";
import { confirmSubmit } from '../../utils/common'
import { showSuccess, showError } from "../../utils/app";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    _onUpdateSuccess() {
        this.props.onUpdateSuccess()
    }

    _onTablePageChange(param) {
        this.props.onChange(param)
    }

    _deleteContract(contractId) {
        confirmSubmit(
            <h4><b>Xoá hợp đồng.</b></h4>,
            <div className="mt10">Bạn có thật sự muốn xoá hợp đồng này này???</div>,
            () => {
                if (contractId) {
                    _delete("contracts/" + contractId, "", () => {
                        showSuccess("Xóa hợp đồng thành công");
                        let {
                            onDeleteSuccess
                        } = this.props
                        if (onDeleteSuccess) onDeleteSuccess()
                    })
                }
            }
        );
    }



    render() {
        const { contracts, listType, total, currentPage } = this.props
        let currentUser = getCurrentUser()
        return (
            contracts && contracts.length > 0 ? <div className="contract-page">
                {
                    listType === "card"
                        ? <div className="contract-list">
                        </div>
                        : <div className="contract-list list-view">
                            <DataTable
                                data={contracts}
                                total={total}
                                isPaging={true}
                                onChange={param => this._onTablePageChange(param)}
                                currentPage={currentPage ? currentPage : 1}
                                renderHeader={
                                    () => {
                                        return (
                                            <thead>
                                                {/* <tr>
                                                    <th className="text-left">Mã hợp đồng</th>
                                                    <th className="text-left">Tên hợp đồng</th>
                                                    <th className="text-left width200">Loại</th>
                                                    <th className="text-left">Ngày ký</th>
                                                    <th className="text-left">Ngày hết hiệu lực</th>
                                                    <th className="text-left">Tình trạng hợp đồng</th>
                                                    <th className="text-center width100" />
                                                </tr> */}
                                                <tr>
                                                    <th className="text-left">ID Đối tác</th>
                                                    <th className="text-left">Mã hợp đồng</th>
                                                    <th className="text-left">Tên hợp đồng</th>
                                                    <th className="text-left ">Loại</th>
                                                    <th className="text-left">Ngày ký</th>
                                                    <th className="text-left">Ngày hết hiệu lực</th>
                                                    <th className="text-left">Tình trạng hợp đồng</th>
                                                    <th className="text-center " />
                                                </tr>
                                            </thead>
                                        );
                                    }
                                }
                                renderBody={
                                    items => {
                                        return (
                                            <tbody>
                                                {items.map((item, i) => (
                                                    // <tr key={i}>
                                                    //     <td className="text-left">
                                                    //         {item.code}
                                                    //     </td>
                                                    //     <td className="text-left" >
                                                    //         <NavLink style={{display:'flex',width:'150px'}} to={`/contracts/${item.id}`}>
                                                    //             {item.name}
                                                    //         </NavLink>
                                                    //     </td>
                                                    //     <td className="text-left width200" style={{display:'flex'}}>{item.contractType.name}</td>
                                                    //     <td className="text-left">{moment(item.signDate).format("DD/MM/YYYY")}</td>
                                                    //     <td className="text-left">{moment(item.expiryDate).format("DD/MM/YYYY")}</td>
                                                    //     <td className="text-left">{item.status}</td>
                                                    //     <td>
                                                    //     {
                                                    //          isInRole(MetubRoles.VIEW_CONTRACT) && <NavLink to={`/contracts/${item.id}`}>
                                                    //                                                 <Tooltip title="Xem chi tiết" placement="top">
                                                    //                                                     <IconButton>
                                                    //                                                         <InfoIcon />
                                                    //                                                     </IconButton>
                                                    //                                                 </Tooltip>
                                                    //                                             </NavLink>
                                                    //     }
                                                    //     </td>
                                                    // </tr>
                                                    <tr key={i}>
                                                        <td className="text-left">
                                                            {item.partnerId}
                                                        </td>
                                                        <td className="text-left">
                                                            {item.code}
                                                        </td>
                                                        <td className="text-left" >
                                                            <NavLink to={`/contracts/${item.id}`}>
                                                                {item.name}
                                                            </NavLink>
                                                        </td>
                                                        <td className="text-left width200" >{item.contractType.name}</td>
                                                        <td className="text-left">{item.signDate ? moment(item.signDate).format("DD/MM/YYYY") : '-/-'}</td>
                                                        <td className="text-left">{item.expiryDate ? moment(item.expiryDate).format("DD/MM/YYYY") : '-/-'}</td>
                                                        <td className="text-left">{ContractStatus[item.status].label}</td>
                                                        <td className="text-right width100">
                                                            {
                                                                isInRole(MetubRoles.VIEW_CONTRACT) && <NavLink to={`/contracts/${item.id}`}>
                                                                    <Tooltip title="Xem chi tiết" placement="top">
                                                                        <IconButton>
                                                                            <InfoIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </NavLink>
                                                            }
                                                            {
                                                                isInRole(MetubRoles.VIEW_CONTRACT) && item.status == "Writed" && currentUser.roleCode != MetubRoles.BOD && <NavLink to={"#"} onClick={() => this._deleteContract(item.id)}>
                                                                    <Tooltip title="Xoá hợp đồng" placement="top">
                                                                        <IconButton>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </NavLink>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        )
                                    }
                                }
                            >
                            </DataTable>
                        </div>
                }
            </div> : <label className="empty-list-label">Chưa có hợp đồng nào.</label>
        );
    }
}

export default Index;
