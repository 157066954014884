import React from "react";
import { put } from "../../api";
import { CardHeader, Avatar, Button } from '@material-ui/core'
import TextInput from '../common/text-input'
import { notEmpty, isValid } from '../../utils/validators'
import SelectOption from '../common/select-option'

import { showSuccess, showError } from "../../utils/app";
import { get } from '../../api/index';
import { confirmSubmit, objToArray } from '../../utils/common'
import { OptionType, PartnerType, ContractStatus, DeliveryPartner, UploadFileType, MetubRoles, DefaultUserAvatar } from "../../constants/constants";
import Select from '../common/select'
import DatetimePicker from '../common/datetime-picker'
import FileDropZone from '../common/file-drop-zone'
import { isInRole } from "../../auth";
import {
    Assignment as AssignmentIcon
} from '@material-ui/icons'
import { CONTRACT_FILTER } from "../../constants/localStorageKeys";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contractName: "",
            contractCode: "",
            edit: false,
            partnerType: null,
            partnerTypeError: null,
            signDate: null,
            signDateError: null,
            expiryDate: null,
            expiryDateError: null,
            contractTypeId: null,
            contractTypeIdError: null,
            status: null,
            statusError: null,
            deliveryId: "",
            deliveryPartner: null,
            files: null,
            defaultFiles: null,
            notes: "",
            partner: null,
        };

        this.defaultState = this.state

        this.validationData = {
            contractName: [notEmpty],
            contractCode: [notEmpty],
        };
    }

    _checkInputValue() {
        let {
            signDate,
            expiryDate,
            partnerType,
            contractTypeId,
            status
        } = this.state
        let valid = true
        if (!isValid(this.validationData))
            valid = false

        if (!signDate) {
            valid = false
            this.setState({
                signDateError: "Ngày ký không được bỏ trống."
            })
        }
        if (!expiryDate) {
            valid = false
            this.setState({
                expiryDateError: "Ngày hết hiệu lực không được bỏ trống."
            })
        }

        if (!partnerType) {
            valid = false
            this.setState({
                partnerTypeError: "Loại đối tác không được bỏ trống."
            })
        }

        if (!contractTypeId) {
            valid = false
            this.setState({
                contractTypeIdError: "Loại hợp đồng không được bỏ trống."
            })
        }
        if (!status) {
            valid = false
            this.setState({
                statusError: "Trạng thái hợp đồng không được bỏ trống."
            })
        }
        return valid
    }

    _updateContract() {
        let {
            contractName,
            contractCode,
            partnerType,
            signDate,
            expiryDate,
            contractTypeId,
            status,
            deliveryId,
            deliveryPartner,
            defaultFiles,
            notes
        } = this.state

        let param = {
            name: contractName,
            code: contractCode,
            partnerType: partnerType.value,
            signDate: signDate,
            expiryDate: expiryDate,
            notes: notes,
            deliveryId: deliveryId,
            deliveryPartner: deliveryPartner ? deliveryPartner.value : "",
            status: status.value,
            // files: files ? files : (defaultFiles),
            files: defaultFiles,
            contractTypeId: contractTypeId,
            id: this.props.contractId
        }

        if (this._checkInputValue())
            put("contracts", param, () => {
                showSuccess("Thay đổi thông tin thành công.")
                this.setState({ edit: false })
            })
        else {
            showError("Nội dung không hợp lệ, vui lòng kiểm tra lại thông tin vừa nhập.")
        }
    }

    _setDefaultValue() {
        if (this.props.contractId) {
            var contractId = this.props.contractId
        }
        get("contracts/" + contractId, (result) => {
            this.setState({
                contractName: result.name,
                contractCode: result.code,
                partnerType: PartnerType[result.partnerType],
                signDate: result.signDate,
                expiryDate: result.expiryDate,
                contractTypeId: result.contractType.id,
                status: ContractStatus[result.status],
                deliveryId: result.deliveryId,
                deliveryPartner: DeliveryPartner[result.deliveryPartner],
                defaultFiles: result.files,
                notes: result.notes,
                partner: result.partner
            })
        })

    }

    _resetState() {
        this.setState({
            ...this.defaultState
        })
    }

    _cancelUpdate() {
        confirmSubmit(
            "Huỷ thay đổi.",
            "Bạn có thật sự muốn huỷ thay đổi? Mọi thay đổi sẽ không được áp dụng.",
            () => {
                let { defaultValue } = this.state
                this.setState({
                    ...defaultValue,
                    edit: false
                })
            }
        )
    }

    componentDidMount() {
        this._setDefaultValue()
    }

    render() {
        const validationData = this.validationData

        const {
            contractName,
            contractCode,
            edit,
            partnerType,
            partnerTypeError,
            signDate,
            signDateError,
            expiryDate,
            expiryDateError,
            contractTypeId,
            contractTypeIdError,
            status,
            statusError,
            deliveryId,
            deliveryPartner,
            defaultFiles,
            notes,
            partner,
        } = this.state

        const PartnerTypeOptions = objToArray(PartnerType)
        const ContractStatusOptions = objToArray(ContractStatus)
        const DeliveryPartnerOptions = objToArray(DeliveryPartner)
        return (
            <div className="admin-box">
                <span className="box-head">
                    <AssignmentIcon />
                    Thông tin chi tiết hợp đồng
                    <div className="modify">
                        {
                            isInRole(MetubRoles.UPDATE_CONTRACT) && (
                                !edit ?
                                    <Button color={"primary"} onClick={() => this.setState({ edit: true })}><label>CHỈNH SỬA</label></Button>
                                    : <div className="text-right">
                                        <Button color={"primary"} onClick={() => this._updateContract()}>LƯU</Button>
                                        <Button onClick={() => this._cancelUpdate()}>HUỶ</Button>
                                    </div>
                            )
                        }
                    </div>
                </span>
                <div className="coloumn-2">
                    <div>
                        <table className="table border-none input-form">
                            <tbody>
                                <tr>
                                    {
                                        partner && <td className="text-left">
                                            <label>Thông tin đối tác</label>
                                            <CardHeader
                                                avatar={
                                                    <Avatar src={DefaultUserAvatar}>
                                                    </Avatar>
                                                }
                                                title={partner.type === PartnerType.Individual.value
                                                    ? <a href
                                                        onClick={() => {
                                                            window.localStorage.removeItem(CONTRACT_FILTER)
                                                            this.props.history.push("/partners/" + partner.id)
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >{partner.fullName}</a>
                                                    : <a href
                                                        onClick={() => {
                                                            window.localStorage.removeItem(CONTRACT_FILTER)
                                                            this.props.history.push("/partners/" + partner.id)
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >{partner.companyName}</a>}
                                                subheader={partner.type === PartnerType.Individual.value ? partner.stageName : ""}
                                            />
                                        </td>

                                    }
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="text-left">
                                        <label>Tên hợp đồng</label>
                                        <TextInput
                                            className="contractName"
                                            value={contractName}
                                            name={"contractName"}
                                            placeholder={"Tên hợp đồng"}
                                            displayName={"Tên hợp đồng"}
                                            onChange={e => {
                                                this.setState({ contractName: e.target.value })
                                            }}
                                            validations={validationData.contractName}
                                            edit={edit}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-left">
                                        <label>Mã hợp đồng</label>
                                        <TextInput
                                            className="contractCode"
                                            value={contractCode}
                                            name={"contractCode"}
                                            placeholder={"Mã hợp đồng"}
                                            displayName={"Mã hợp đồng"}
                                            onChange={e => {
                                                this.setState({ contractCode: e.target.value })
                                            }}
                                            validations={validationData.contractCode}
                                            edit={edit}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="coin text-left">
                                        <label>Loại hợp đồng</label>
                                        <SelectOption
                                            optionType={OptionType.ContractType.value}
                                            edit={edit}
                                            onChange={(value) => this.setState({ contractTypeId: value ? value.id : null, contractTypeIdError: null })}
                                            value={contractTypeId}
                                            allowNull={false}
                                            errors={contractTypeIdError}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="coin text-left">
                                        <label>Tình trạng hợp đồng</label>
                                        <Select
                                            options={ContractStatusOptions}
                                            edit={edit}
                                            onChange={(value) => this.setState({ status: value ? value : null, statusError: null })}
                                            value={status}
                                            allowNull={false}
                                            errors={statusError}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="coin text-left ">
                                        <label>Đối tác</label>
                                        <Select
                                            options={PartnerTypeOptions}
                                            edit={edit}
                                            onChange={(value) => this.setState({ partnerType: value ? value : null, partnerTypeError: null })}
                                            value={partnerType}
                                            allowNull={false}
                                            errors={partnerTypeError}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="coin text-left ">
                                        <label>Ngày ký</label>
                                        <DatetimePicker
                                            className="signDate"
                                            placeholder={"Ngày ký"}
                                            onChange={value => this.setState({ signDate: value, signDateError: null })}
                                            value={signDate}
                                            edit={edit}
                                            errors={signDateError}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="coin text-left ">
                                        <label>Ngày hết hiệu lực</label>
                                        <DatetimePicker
                                            className="expiryDate"
                                            placeholder={"Ngày ký"}
                                            onChange={value => this.setState({ expiryDate: value, expiryDateError: null })}
                                            value={expiryDate}
                                            edit={edit}
                                            errors={expiryDateError}
                                        />
                                    </td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table className="table border-none input-form">
                            <tbody>
                                <tr>
                                    <td className="text-left">
                                        <label>ID chuyển phát</label>
                                        <TextInput
                                            className="deliveryId"
                                            value={deliveryId}
                                            name={"deliveryId"}
                                            placeholder={"Mã chuyển phát"}
                                            displayName={"Mã chuyển phát"}
                                            onChange={e => {
                                                this.setState({ deliveryId: e.target.value })
                                            }}
                                            edit={edit}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="coin text-left ">
                                        <label>Đơn vị chuyển phát</label>
                                        <Select
                                            options={DeliveryPartnerOptions}
                                            edit={edit}
                                            onChange={(value) => this.setState({ deliveryPartner: value ? value : null })}
                                            value={deliveryPartner}
                                            allowNull={false}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-none text-left">
                                        <label>Ghi chú</label>
                                        <TextInput
                                            className="notes"
                                            value={notes}
                                            name={"notes"}
                                            placeholder={"Ghi chú"}
                                            displayName={"Ghi chú"}
                                            multiline={true}
                                            onChange={e => {
                                                this.setState({ notes: e.target.value })
                                            }}
                                            edit={edit}
                                            minHeight={"200px"}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label >Hình ảnh</label>
                                        <FileDropZone
                                            accept="application/pdf"
                                            type={UploadFileType.Contracts.value}
                                            edit={edit}
                                            multiImages={true}
                                            onChange={(file) => {
                                                let files = []
                                                file && file.length > 0 && file.map(item => {
                                                    files.push(item.relativePath)
                                                })
                                                this.setState({ defaultFiles: files })
                                            }}
                                            files={defaultFiles}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>



                </div>
            </div>

        );
    }
}

export default Index;
