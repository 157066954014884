import React from "react";
import Modal from '../../../common/modal'
import Button from "@material-ui/core/Button";
import TextInput from '../../../common/text-input'
import {  put, get } from '../../../../api'
import { notEmpty, isValid } from "../../../../utils/validators";
import { showSuccess, showError } from "../../../../utils/app";
import update from 'react-addons-update'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            fanpageQuotation: null,
        }
        this.validationData = {
            price: [notEmpty]
        };
    }


    _onUpdateCampaignQuotation = () => {
        const {
            fanpageQuotation
        } = this.state;
        const {
            onUpdateSucceed,
            onClose
        } = this.props

        let param = {
            id: fanpageQuotation.id,
            price: fanpageQuotation.price
        }
        if (isValid(this.validationData))
            put("fanpage-quotations/", param, () => {
                if (onClose)
                    onClose()
                if (onUpdateSucceed())
                    onUpdateSucceed()
                showSuccess("Thêm báo giá thành công")
            });
        else {
            showError("Dữ liệu không hợp lệ. Vui lòng kiểm tra lại")
        }
    }

    _getDetails = () => {
        let { id } = this.state
        if (!id) return
        get("fanpage-quotations/" + id,
            (res) => {
                this.setState({
                    fanpageQuotation: res
                })
            })
    }
    componentDidMount() {
        let { id } = this.props
        this.setState({
            id: id
        },
            () => {
                this._getDetails()
            })
    }
    componentWillReceiveProps(props) {
        let { id } = props
        this.setState({
            id: id
        },
            () => {
                this._getDetails()
            })
    }

    render() {
        let {
            fanpageQuotation
        } = this.state
        let {
            open,
            onClose
        } = this.props
        if (!fanpageQuotation) return null
        return (
            <Modal
                className="add-new-options"
                open={open}
                onClose={onClose}
                fullWidth={true}
                header={
                    <div>
                        <span>Cập nhật</span>
                    </div>
                }
                content={
                    <table className="table list-item border-bottom">
                        <tbody>
                            <tr>
                                <td className="coin text-left width100">
                                    <span>Giá</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="name"
                                        value={fanpageQuotation.price}
                                        label={"Kinh phí"}
                                        name={"budget"}
                                        placeholder={"Kinh phí"}
                                        displayName={"Kinh phí"}
                                        onChange={e => this.setState({
                                            fanpageQuotation: update(fanpageQuotation, {
                                                price: {
                                                    $set: e.target.value
                                                }
                                            })
                                        })
                                        }
                                        validations={this.validationData.price}
                                        edit={true}
                                    />
                                </td>
                            </tr>

                        </tbody>
                    </table>
                }
                action={
                    <Button
                        color="primary"
                        onClick={this._onUpdateCampaignQuotation}
                    >
                        Lưu
            </Button>
                }
                allowCancel={true}
            />
        )
    }
}


export default Index;

