import React from 'react';
import { get } from '../../api';
import DataTable from '../common/datatable';

class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            channelAccepted: 0,
        }
    }

    componentWillMount() {
        if (this.props.recentYear)
            this._getChannelAcceptedInMonth(this.props.recentYear)
    }
    componentWillReceiveProps(nextprops) {
        if (nextprops.recentYear != this.props.recentYear)
            this._getChannelAcceptedInMonth(nextprops.recentYear)
    }
    _getChannelAcceptedInMonth(nextprops) {
        if (!nextprops) return
        get("channels/Total-Acceptance/" + nextprops, result => {
            this.setState({
                channelAccepted: result,
            });
        }, null, true);
    }

    render() {
        let { channelAccepted } = this.state;
        return (
            <div className="partner-list">
                <DataTable
                    total={channelAccepted ? channelAccepted.length : null}
                    data={channelAccepted}
                    renderHeader={() => {
                        return (
                            <thead>
                                <tr>
                                    <th className="text-left">PM Accepted</th>
                                    <th className="text-center">Tháng 1</th>
                                    <th className="text-center">Tháng 2</th>
                                    <th className="text-center">Tháng 3</th>
                                    <th className="text-center">Tháng 4</th>
                                    <th className="text-center">Tháng 5</th>
                                    <th className="text-center">Tháng 6</th>
                                    <th className="text-center">Tháng 7</th>
                                    <th className="text-center">Tháng 8</th>
                                    <th className="text-center ">Tháng 9</th>
                                    <th className="text-center ">Tháng 10</th>
                                    <th className="text-center ">Tháng 11</th>
                                    <th className="text-center ">Tháng 12</th>
                                </tr>
                            </thead>
                        );
                    }}
                    renderBody={items => {
                        if (!items || items.length < 1) return
                        return (
                            <tbody>
                                {items.map((item, i) => (
                                    <tr key={i}>
                                        <td className="text-left">{item.namePartnerManager ? item.namePartnerManager : ""}</td>
                                        {
                                            item.dataCountAccepted && item.dataCountAccepted.length > 0 && item.dataCountAccepted.map(((subItem, j) =>
                                                <td className="text-center">{subItem.totalAcceptedChannel ? subItem.totalAcceptedChannel : ""}</td>
                                            ))
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        );
                    }}
                />
            </div>
        );
    }
}
export default Index;