export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const TOKEN_EXPIRED = "TOKEN_EXPIRED";
export const USER_INFO = "USER_INFO";
export const PINED_REQUEST = "PINED_REQUEST";
export const NOTIFICATION_FILTER = "NOTIFICATION_FILTER";
export const USER_FILTER = "USER_FILTER"
export const PARTNER_FILTER = "PARTNER_FILTER"
export const VIDEO_FILTER = "VIDEO_FILTER"
export const CHANNEL_FILTER = "CHANNEL_FILTER"
export const CONTRACT_FILTER = "CONTRACT_FILTER"
export const REQUEST_FILTER = "REQUEST_FILTER"
export const DASHBOARD_FILTER = "DASHBOARD_FILTER"
export const OPTION_FILTER = "OPTION_FILTER"