import React from "react";
import { post } from "../../../api";
import Modal from '../../common/modal'
import Button from '@material-ui/core/Button'
import TextInput from '../../common/text-input'
import { notEmpty, isValid } from '../../../utils/validators'

import { showSuccess, showError } from "../../../utils/app";
import { NoteType } from '../../../constants/constants'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            noteContent: "",
        };
        this.validationData = {
            title: [notEmpty],
            noteContent: [notEmpty]
        };
    }


    _addNote() {
        let {
            title,
            noteContent,
        } = this.state

        let param = {
            title: title,
            content: noteContent,
            noteType: NoteType.Video.value,
            referenceId: this.props.videoId
        }
        if (isValid(this.validationData)) {
            post("notes", param, () => {
                showSuccess("Thêm ghi chú thành công.")
                this.props.onAddSuccess()
                this.setState({
                    title: "",
                    noteContent: ""
                })
            })
        } else {
            showError("Vui lòng kiểm tra thông tin bạn vừa nhập.")
        }
    }

    _resetState() {
        this.setState({
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactAddress: "",
            contactPosition: "",
            isDefault: false
        })
    }

    _handleCheckboxChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };




    render() {
        const validationData = this.validationData
        const {
            title,
            noteContent,
        } = this.state
        const { open } = this.props
        return (

            <Modal
                className="add-new-contact"
                open={open}
                onClose={() => this.props.onClose()}
                fullWidth={true}
                fullScreen={false}
                header={
                    <div>
                        <span>THÊM MỚI GHI CHÚ</span>
                    </div>
                }
                content={
                    <table className="table border-none input-form">
                        <tbody>
                            <tr>
                                <td>
                                    <label>Tiêu đề</label>
                                    <TextInput
                                        className="title"
                                        value={title}
                                        name={"title"}
                                        placeholder={"Nhập tiêu đề"}
                                        displayName={"Tiêu đề"}
                                        onChange={e => {
                                            this.setState({ title: e.target.value })
                                        }}
                                        validations={validationData.title}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="border-none">
                                    <label>Nội dung</label>
                                    <TextInput
                                        className="noteContent"
                                        value={noteContent}
                                        name={"noteContent"}
                                        placeholder={"Nội dung"}
                                        displayName={"Nội dung"}
                                        multiline={true}
                                        onChange={e => {
                                            this.setState({ noteContent: e.target.value })
                                        }}
                                        validations={validationData.noteContent}
                                        edit={true}
                                    />
                                </td>
                            </tr>

                        </tbody>
                    </table>
                }
                action={
                    <Button
                        color="primary"
                        onClick={() => this._addNote()}
                    >
                        Lưu
                    </Button>
                }
                allowCancel={true}
            />
        );
    }
}

export default Index;
