import React from 'react'
import { Link, NavLink } from "react-router-dom"
import { isArray } from 'util';

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: false
        }
    }

    active = (to) => {
        this.props.onMenuChange(to);
    }

    componentDidMount() {
        this.update(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.update(nextProps)
    }

    update = (props) => {
        const {
            to,
            children
        } = props;
        let isActive = false;
        let active = window.location.pathname
        if (to === active || (active.indexOf(to, 0) >= 0 && active != '/' && to != '/')) {
            isActive = true
        } else if (children) {
            if (isArray(children) && children.find(x => x.props.to === active) != null) {
                isActive = true
            } else if (children.props && children.props.to === active) {
                isActive = true
            }
        }
        this.setState({
            isActive
        })
    }
    render() {
        const {
            icon,
            name,
            to,
            children,
            onClick
        } = this.props;
        const { isActive } = this.state;
        let active = window.location.pathname
        if (children) {
            return <li className={isActive ? "menu-item tree-view menu-open" : "menu-item tree-view"}>
                <a onClick={() => this.setState({ isActive: !this.state.isActive })} >
                    <i className={icon} />
                    <span>{name}</span>
                    <span className="pull-right-container">
                        <i className="fa fa-angle-left pull-right" />
                    </span>
                </a>
                <ul className="tree-view-menu">
                    {children}
                </ul>
            </li>
        }

        return <li className={isActive ? "menu-item active" : "menu-item"} onClick={() => this.active(to)}>
            <NavLink to={active == to ? "#" : to} replace={active == to} >
                <i className={icon} />
                <span>{name}</span>
            </NavLink>
        </li>
    }
}

export default Index