import React from "react";
import { Route, Switch } from "react-router";
import Header from "../template/header";
import Footer from "../template/footer";
import Menu from "../template/menu";
import Home from "../home";
import Partner from "../partners";
import PartnerDetails from "../partners/details";
import ChannelDetails from "../channel/details";
import Users from "../users";
import Options from "../options";
import VideoDetails from '../video/detail'
import ContractDetails from '../contracts/detail'
import Contract from '../contracts'
import Campaigns from '../campaign'
import CampaignDetails from '../campaign/details'
import Fanpages from '../fanpages'
import FanpageDetails from '../fanpages/details'
import Notes from '../notes'
import Video from '../video'
import Channel from '../channel'
import Dashboard from '../dashboard'
import Request from '../requestAll'
import Notification from '../notification'
import $ from "jquery";
// import { setMinHeightWrapper } from "../../assets/js/script";
import { getTokenExpired, signIn, getCurrentUser } from "../../auth";
import { getDate } from "../../utils/common";
import { get } from "../../api";
import { StickyContainer, Sticky } from 'react-sticky';
import { MetubRoles } from "../../constants/constants";


class Main extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            index: 0
        }
    }
    resizeResponsive = () => {
        let body = $("body");
        if (body.hasClass("sidebar-collapse")) {
            let width = $(window).width();
            if (width < 768) {
                body.removeClass("sidebar-collapse");
            } else {
                body.removeClass("sidebar-collapse");
            }
        }
    };


    _refreshToken() {
        var tokenExpiredDate = getTokenExpired();
        var now = getDate();

        if (now < tokenExpiredDate) return;

        get("auth/refresh-token", result => {
            signIn(result);
        });
    }
    componentDidMount() {
        this._refreshToken();
        window.addEventListener("resize", () => {
            // setMinHeightWrapper();
            this.resizeResponsive();
        });
        // setMinHeightWrapper();
    }

    _replaceComponent() {
        let currentUser = getCurrentUser()
        let output = <Dashboard />
        if (currentUser.roleCode == MetubRoles.LEGAL_EXECUTIVE)
            output = <Notification />
        if (currentUser.roleCode == MetubRoles.NORMAL_USER)
            output = <Partner />
        if (currentUser.roleCode == MetubRoles.ADMIN_OFFICER)
            output = <Partner />
        if (currentUser.roleCode == MetubRoles.CHIEF_ACCOUNTANT)
            output = <Partner />
        if (currentUser.roleCode == MetubRoles.ACCOUNTANT)
            output = <Partner />
        if (currentUser.roleCode == MetubRoles.CUSTOMER_SP)
            output = <Partner />
        return output
    }

    componentWillReceiveProps() {
        this.setState({
            pathname: window.location.pathname
        })
    }

    render() {
        let {
            pathname
        } = this.state
        return (
            <div className="wrapper">

                <div className="fix-header" >
                    <Header />
                    <Menu pathname={pathname} />
                </div>
                <main className="content-main">
                    <Switch onChange={() => console.log("onchange")}>
                        <Route exact path="/" component={() => this._replaceComponent()} />
                        <Route exact path="/partners" component={() => <Partner history={this.props.history} />} />
                        <Route exact path="/partners/:id" component={PartnerDetails} />
                        <Route exact path="/users" component={() => <Users history={this.props.history} />} />
                        <Route exact path="/options/:type" component={(props) => <Options match={props.match} />} />
                        <Route exact path="/campaigns" component={Campaigns} />
                        <Route exact path="/campaigns/:id" component={CampaignDetails} />
                        <Route exact path="/fanpages" component={Fanpages} />
                        <Route exact path="/fanpages/:id" component={FanpageDetails} />
                        <Route exact path="/channels/:id" component={ChannelDetails} />
                        <Route exact path="/videos/:id" component={VideoDetails} />
                        <Route exact path="/contracts/:id" component={ContractDetails} />
                        <Route exact path="/notes/:id" component={Notes} />
                        <Route exact path="/videos" component={() => <Video history={this.props.history} />} />
                        <Route exact path="/channels" component={() => <Channel history={this.props.history} />} />
                        <Route exact path="/channels/:id" component={Channel} />
                        <Route exact path="/requests" component={() => <Request history={this.props.history} />} />
                        <Route exact path="/contracts" component={() => <Contract history={this.props.history} />} />
                        <Route exact path="/notification" component={() => <Notification history={this.props.history} />} />
                        <Route exact component={Home} />
                    </Switch>
                </main>
                <Footer />
            </div>

        );
    }
}

export default Main;
