import React from "react";
import List from './list'
import AddNew from './add-new'
import Button from '@material-ui/core/Button'
import { get } from "../../../api";
import { objToQuery } from '../../../utils/common'
import { TableSizeDefault, MetubRoles } from '../../../constants/constants'
import { isInRole } from '../../../auth'
import {
    Update as UpdateIcon
} from '@material-ui/icons'


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests: [],
            total: 0,
            openAddNewDialog: false,
            searchParam: { skip: 0, take: TableSizeDefault.value },
            listType: "list"
        };
    }

    _handleGetRequests(param) {
        if (!param) return

        this.setState({
            searchParam: param
        })

        let { videoId } = this.props
        if (!videoId) return

        let getParam = {
            id: videoId,
            skip: param.skip,
            take: param.take
        }
        var query = objToQuery(getParam);


        get("videos/" + videoId + "/requests" + query, result => {
            this.setState({
                requests: result.items,
                total: result.total
            })
        })
    }

    componentDidMount() {
        let { searchParam } = this.state
        this._handleGetRequests(searchParam)
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.reGetListRequest !== nextProps.reGetListRequest) {
            let { searchParam } = this.state
            this._handleGetRequests(searchParam)
        }
    }

    render() {
        let { requests, openAddNewDialog, total, searchParam } = this.state
        const { videoId } = this.props


        return (
            <div className="contact-page">
                <div className="admin-box">
                    <span>
                        <UpdateIcon />
                        Yêu cầu cập nhật
                        {
                            isInRole(MetubRoles.CREATE_REQUEST)
                            && <Button
                                color={"primary"}
                                onClick={() => this.setState({ openAddNewDialog: !openAddNewDialog })}
                            >
                                Tạo mới
                            </Button>

                        }
                    </span>
                    <List
                        requests={requests}
                        total={total}
                        // onUpdateSuccess={() => this._handleGetRequests(searchParam)}
                        onChange={param => this._handleGetRequests(param)}
                        listType="list"
                        onUpdateSuccess={() => this.props.onUpdateSuccess()}
                    />
                </div>

                <AddNew
                    open={openAddNewDialog}
                    onClose={() => this.setState({ openAddNewDialog: false })}
                    onAddSuccess={() => {
                        this._handleGetRequests(searchParam)
                        this.setState({ openAddNewDialog: false })
                        this.props.onAddNewSuccess()
                    }}
                    videoId={videoId} />
            </div>
        );
    }
}

export default Index;
