import React from "react";
import DataTable from '../common/datatable'
import { objToQuery } from '../../utils/common'
import { get } from '../../api'
import { Tooltip, IconButton, CardHeader } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { PaymentStatus } from '../../constants/constants'


import moment from "moment";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchParam: {
                skip: 0,
                take: 10
            },
            payments: null,
            total: 0,
        };
    }

    _getPayments(param) {
        if (!param) return
        else {
            this.setState({
                searchParam: param
            })

            let getParam = {
                skip: param.skip,
                take: param.take
            }

            let { partnerId } = this.props

            if (!partnerId) return
            getParam.partnerId = partnerId
            var query = objToQuery(getParam);

            get("partners/" + partnerId + "/monthly-payments" + query, result => {
                this.setState({
                    payments: result.items,
                    total: result.total
                })
            })
        }
    }

    componentDidMount() {
        let { searchParam } = this.state;
        this._getPayments(searchParam)
    }

    render() {

        let {
            payments,
            total,
        } = this.state

        return (
            <div className="payment-list mt20">
                <DataTable
                    data={payments}
                    total={total}
                    isPaging={true}
                    renderHeader={() => {
                        return (
                            <thead>
                                <tr>
                                    <th className="text-center">ID</th>
                                    <th className="text-left">Ngày tạo</th>
                                    <th className="text-left">Đối tác</th>
                                    <th className="text-left">Thu nhập</th>
                                    <th className="text-left">Số tiền phải trả</th>
                                    <th className="text-left">Tổng tiền chưa thanh toán</th>
                                    <th className="text-left">Trạng thái</th>
                                    <th className="text-center width100" />
                                </tr>
                            </thead>
                        );
                    }}
                    renderBody={items => {
                        return (
                            <tbody>
                                {
                                    items && items.map((item, i) => (
                                        <tr key={i}>
                                            <td className='text-center'>#{item.id}</td>
                                            <td className='text-left'>
                                                <span>{moment(item.createTime).format("DD/MM/YYYY hh:mm")}</span>
                                            </td>
                                            <td className='text-left' style={{ fontSize: "15px" }}>
                                                <CardHeader
                                                    className={"p00"}
                                                    title={<span style={{ fontSize: "14px" }}>{item.payment ? item.payment.fullName : "-/-"}</span>}
                                                    subheader={item.payment ? item.payment.email : "-/-"}
                                                />
                                            </td>
                                            <td className="text-left">
                                                <span>$ {item.cross.toFixed(2)}</span>
                                            </td>
                                            <td className="text-left">
                                                <span>$ {item.earnings.toFixed(2)}</span>
                                            </td>
                                            <td className="text-left">
                                                <span>$ {item.payment ? item.payment.amount.toFixed(2) : "-/-"}</span>
                                            </td>
                                            <td className="text-left">
                                                <span>{PaymentStatus[item.status].label}</span>
                                            </td>
                                            <td className="width150 text-right">
                                                <Tooltip title="Xem chi tiết" placement="top">
                                                    <IconButton
                                                    >
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        );
                    }}
                    onChange={params => this._getPayments(params)}
                />

            </div>
        )
    }
}
export default Index;