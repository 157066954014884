import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import "react-tabs/style/react-tabs.css";
import CustomTab from "../common/tabs";
import ChannelInfo from "./info";
import ChannelNote from "./channel-notes";
import ChannelLog from "./channel-logs";
import { get, put } from "../../api";
import {
  RequestStatus,
  UploadFileType,
  MetubRoles,
} from "../../constants/constants";
import { BASE_API } from "../../constants/appSettings";
import FileDropZone from "../common/file-drop-zone";
import Select from "../common/select";
import { jsonFromUrlParams } from "../../utils/common";
import TextInput from "../common/text-input";
import {
  Fab,
  Badge,
  Paper,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Drawer,
  IconButton,
  Divider,
  CardHeader,
  Avatar,
  Button,
  Typography,
} from "@material-ui/core";
import FeaturedPlayList from "@material-ui/icons/FeaturedPlayList";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { showSuccess } from "../../utils/app";
import moment from "moment";
import { isInRole, getCurrentUser } from "../../auth";
import $ from "jquery";
import {
  checkRoleUpdateRequest,
  roleFilterRequestStatus,
} from "../../utils/roleChecker";

const drawerWidth = 500;

const styles = (theme) => ({
  root: {
    display: "block",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    marginTop: "50px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
});

class PersistentDrawerRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      requestPending: null,
      openPinMenu: false,
      openDrawer: false,
      pinRequest: null,
      edit: false,
      selectedStatus: null,
      notes: "",
      changeLogs: false,
      reGetListRequest: false,
      optimizer: null,
      channel: null,
    };
  }

  _handleGetChannelInfo() {
    const channelId = this.props.match.params.id;
    get("channels/" + channelId, (result) => {
      this.setState({
        optimizer: result.optimizer,
        channel: result,
      });
    });
  }

  _renderTabIndex(array, exceptArray) {
    if (!exceptArray) return array;
    exceptArray.map((item) => {
      if (item) {
        array = array.filter((e) => e.toString() !== item.toString());
      }
    });
    return array;
  }

  _renderTabContent(array, exceptArray) {
    if (!exceptArray) return array;
    for (let i = exceptArray.length - 1; i >= 0; i--) {
      if (exceptArray[i] !== null) {
        array.splice(exceptArray[i], 1);
      }
    }
    return array;
  }

  _getRequestPending() {
    const channelId = this.props.match.params.id;

    if (!channelId) return;

    get("channels/" + channelId + "/requests", (result) => {
      let requestPending = result.items.filter(
        (item) => item.status === RequestStatus.Pending.value
      );
      this.setState({
        requestPending: requestPending,
      });
    });
  }

  _handleOpenPinMenu() {
    this.setState({ openPinMenu: !this.state.openPinMenu });
  }
  _handleClosePinMenu = () => {
    this.setState({ openPinMenu: false });
  };

  _hanldeClickRequest(request) {
    this.setState(
      {
        pinRequest: request,
      },
      this.handleClosePinMenu
    );

    this._handleDrawerOpen();
  }
  _handleDrawerOpen = () => {
    this.setState({ openDrawer: true });
  };
  _handleDrawerClose = () => {
    this.setState({ openDrawer: false });
  };

  _updateRequestStatus() {
    if (this.state.pinRequest) {
      let { notes, selectedStatus, pinRequest } = this.state;
      let param = {
        notes: notes,
        status: selectedStatus.value,
        id: pinRequest ? pinRequest.id : 0,
      };
      put("requests/status", param, () => {
        showSuccess("Cập nhật trạng thái thành công!");
        this.setState({
          edit: false,
          openDrawer: false,
          reGetListRequest: !this.state.reGetListRequest,
        });
        this._getRequestPending();
      });
    }
  }

  componentWillMount() {
    this._handleGetChannelInfo();
    let searchParam = jsonFromUrlParams(window.location.search);
    if (searchParam && searchParam.tab) {
      this.setState({ tabIndex: parseInt(searchParam.tab) });
    }
    $(window.opera ? "html" : "html, body").animate(
      {
        scrollTop: 0,
      },
      "slow"
    );
  }

  componentDidMount() {
    isInRole(MetubRoles.VIEW_REQUEST) && this._getRequestPending();
  }
  onChangeLogs = () => {
    this.setState({ changeLogs: !this.state.changeLogs });
  };

  render() {
    const { classes, history, theme } = this.props;
    const channelId = this.props.match.params.id;
    let {
      tabIndex,
      requestPending,
      openPinMenu,
      openDrawer,
      pinRequest,
      edit,
      selectedStatus,
      notes,
      optimizer,
      channel,
    } = this.state;

    const RequestStatusOptions = roleFilterRequestStatus(pinRequest);

    let currentUser = getCurrentUser();

    return (
      <div className={classes.root}>
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          className="box-title"
        >
          <div>
            Chi tiết kênh{" "}
            <span className="ellipsis header-name">
              {channel ? channel.name : ""}
            </span>
            <p>Quản lí thông tin kênh đang có trong hệ thống.</p>
          </div>
        </Typography>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: openDrawer,
          })}
          style={{
            padding: "0px",
          }}
        >
          <div className="home-page channel-page">
            <CustomTab
              onChange={(tabIndex) =>
                this.props.history.replace("?tab=" + tabIndex)
              }
              value={tabIndex}
              disableScroll={true}
              tabLabels={this._renderTabIndex(
                [
                  "Thông tin",
                  // "Yêu cầu cập nhật",
                  "Ghi chú",
                  <span onClick={this.onChangeLogs}>Logs</span>,
                ],
                [
                  // !isInRole(MetubRoles.VIEW_REQUEST, () => channel && channel.partnerManager && channel.partnerManager.id == currentUser.id) ? "Yêu cầu cập nhật" : null,
                  currentUser.roleCode != MetubRoles.PARTNER_MANAGER ||
                  (currentUser.roleCode == MetubRoles.PARTNER_MANAGER &&
                    channel &&
                    channel.partnerManager &&
                    channel.partnerManager.id == currentUser.id)
                    ? null
                    : "Ghi chú",
                  !isInRole(MetubRoles.VIEW_LOGS, () =>
                    currentUser.roleCode === MetubRoles.DATA_MANAGER
                      ? true
                      : channel &&
                        channel.partnerManager &&
                        channel.partnerManager.id == currentUser.id
                  ) ? (
                    <span onClick={this.onChangeLogs}>Logs</span>
                  ) : null,
                ]
              )}
              tabContainers={this._renderTabContent(
                [
                  <ChannelInfo
                    history={history}
                    channelId={channelId}
                    optimizer={optimizer}
                  />,
                  // <ChannelRequest history={history} channelId={channelId} onUpdateSuccess={() => this._getRequestPending()} onAddNewSuccess={() => this._getRequestPending()} reGetListRequest={this.state.reGetListRequest} />,
                  <ChannelNote
                    history={history}
                    channelId={channelId}
                    optimizer={optimizer}
                  />,
                  <ChannelLog
                    history={history}
                    channelId={channelId}
                    changeLogs={this.state.changeLogs}
                  />,
                ],
                [
                  // !isInRole(MetubRoles.VIEW_REQUEST, () => channel && channel.partnerManager && channel.partnerManager.id == currentUser.id) ? 1 : null,
                  currentUser.roleCode != MetubRoles.PARTNER_MANAGER ||
                  (currentUser.roleCode == MetubRoles.PARTNER_MANAGER &&
                    channel &&
                    channel.partnerManager &&
                    channel.partnerManager.id == currentUser.id)
                    ? null
                    : 2,
                  !isInRole(
                    MetubRoles.VIEW_LOGS,
                    () =>
                      channel &&
                      channel.partnerManager &&
                      channel.partnerManager.id == currentUser.id
                  )
                    ? 3
                    : null,
                ]
              )}
            />
            {
              <Fab
                color="primary"
                aria-label="Add"
                style={{
                  position: "fixed",
                  bottom: "20px",
                  right: "20px",
                  zIndex: "1199",
                }}
                onClick={() => this._handleOpenPinMenu()}
              >
                {requestPending && requestPending.length > 0 ? (
                  <Badge color="secondary" badgeContent={requestPending.length}>
                    <FeaturedPlayList />
                  </Badge>
                ) : (
                  <FeaturedPlayList />
                )}
              </Fab>
            }
            <div className={"pin-menu" + (openPinMenu ? " active" : "")}>
              <Paper>
                <ClickAwayListener onClickAway={this._handleClosePinMenu}>
                  <List className={"request-pending"}>
                    {requestPending &&
                      requestPending.length > 0 &&
                      requestPending.map((item, i) => (
                        <ListItem
                          onClick={() => this._hanldeClickRequest(item)}
                          key={i}
                        >
                          <ListItemText
                            primary={
                              <span style={{ fontSize: "15px" }}>
                                {item.content && item.content.length > 50
                                  ? item.content.substr(0, 49) + "..."
                                  : item.content}
                              </span>
                            }
                            secondary={
                              <span
                                style={{
                                  justifyContent: "space-between",
                                  display: "flex",
                                }}
                              >
                                <span>{item.status}</span>
                                <span>
                                  {moment(item.createTime).format(
                                    "DD/MM/YYYY hh:mm"
                                  )}
                                </span>
                              </span>
                            }
                          />
                        </ListItem>
                      ))}
                  </List>
                </ClickAwayListener>
              </Paper>
            </div>
          </div>
        </main>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={openDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this._handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          {pinRequest && (
            <div style={{ padding: "0px 20px " }}>
              {!edit ? (
                <div>
                  <div style={{ padding: "10px 0px" }}>
                    {edit ? (
                      <label>Thay đổi trạng thái</label>
                    ) : (
                      <label>Yêu cầu cập nhật</label>
                    )}
                  </div>
                  <Divider />
                  <label style={{ margin: "10px 0px 5px 0px" }}>
                    Thông tin
                  </label>
                  <table className="table border-bottom">
                    <tbody>
                      <tr>
                        <td className="width120">
                          <span>Ngày tạo</span>
                        </td>
                        <td>
                          {moment(pinRequest.createTime).format(
                            "DD/MM/YYYY hh:mm"
                          )}
                        </td>
                      </tr>
                      {pinRequest.requestUser && (
                        <tr className="border-none">
                          <td>
                            <span>Người tạo</span>
                          </td>
                          <td>
                            <CardHeader
                              avatar={
                                <Avatar
                                  src={BASE_API + pinRequest.requestUser.avatar}
                                ></Avatar>
                              }
                              title={pinRequest.requestUser.fullName}
                              subheader={pinRequest.requestUser.email}
                              style={{
                                padding: "0px",
                              }}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <span>Trạng thái</span>
                        </td>
                        <td>
                          <table className="table border-none">
                            <tbody>
                              <tr>
                                <td className="text-left">
                                  <label>
                                    {RequestStatus[pinRequest.status].label}
                                  </label>
                                </td>
                                <td>
                                  {checkRoleUpdateRequest(pinRequest) && (
                                    <span
                                      onClick={() =>
                                        this.setState({ edit: true })
                                      }
                                      style={{
                                        color: "#3F51B5",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Cập nhật
                                    </span>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {pinRequest.status !== RequestStatus.None.value &&
                            pinRequest.status !==
                              RequestStatus.Pending.value && (
                              <tabel className="table border-bottom">
                                <tbody>
                                  {pinRequest.confirmUser && (
                                    <tr>
                                      <td>
                                        <Divider />
                                        <span>Người duyệt</span>
                                        <CardHeader
                                          avatar={
                                            <Avatar
                                              src={
                                                BASE_API +
                                                pinRequest.confirmUser.avatar
                                              }
                                            ></Avatar>
                                          }
                                          title={
                                            pinRequest.confirmUser.fullName
                                          }
                                          subheader={
                                            pinRequest.confirmUser.email
                                          }
                                          style={{
                                            padding: "0px",
                                          }}
                                        />
                                        <Divider />
                                        <span>Ghi chú</span>
                                        <pre>{pinRequest.notes}</pre>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </tabel>
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <label style={{ margin: "10px 0px 5px 0px" }}>
                    Nội dung cập nhật
                  </label>
                  <pre>{pinRequest.content}</pre>
                  <label style={{ margin: "10px 0px 5px 0px" }}>
                    File mô tả
                  </label>
                  {pinRequest.files.length > 0 ? (
                    <FileDropZone
                      type={UploadFileType.Contracts.value}
                      edit={false}
                      multiImages={true}
                      files={pinRequest.files}
                    />
                  ) : (
                    <span className="empty-list-label">Chưa có file mô tả</span>
                  )}
                </div>
              ) : (
                <div>
                  <table className="table border-none">
                    <tbody>
                      <tr>
                        <td>
                          <table className="table border-none">
                            <tr>
                              <td className="width150">Trạng thái</td>
                              <td>
                                <Select
                                  options={RequestStatusOptions}
                                  value={
                                    selectedStatus
                                      ? selectedStatus
                                      : pinRequest
                                      ? RequestStatus[pinRequest.status]
                                      : RequestStatus.None
                                  }
                                  allowNull={false}
                                  onChange={(value) =>
                                    this.setState({ selectedStatus: value })
                                  }
                                  edit={true}
                                />
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Ghi chú</label>
                          <TextInput
                            multiline={true}
                            edit={true}
                            value={notes}
                            onChange={(e) =>
                              this.setState({ notes: e.target.value })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        {edit && (
                          <td className="text-right">
                            <Button
                              onClick={() => this.setState({ edit: false })}
                              className="m10"
                            >
                              Huỷ
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => this._updateRequestStatus()}
                            >
                              Lưu
                            </Button>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </Drawer>
      </div>
    );
  }
}

PersistentDrawerRight.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PersistentDrawerRight);
