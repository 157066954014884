import React from "react";
import TextInput from "../../common/text-input";
import {
  notEmpty,
  emailAddress,
  justNumber,
  isValid,
} from "../../../utils/validators";
import { objToQuery, confirmSubmit, objToArray } from "../../../utils/common";
import {
  PartnerType,
  DefaultUserAvatar,
  UploadFileType,
  MetubRoles,
  Gender,
} from "../../../constants/constants";
import { getAsync, put, _delete } from "../../../api";
import FileDropZone from "../../common/file-drop-zone";
import { showSuccess, showError } from "../../../utils/app";
import DatetimePicker from "../../common/datetime-picker";
import UserSelectList from "../../common/user-select-list";
import { isInRole, getCurrentUser } from "../../../auth";
import {
  ChooseTier,
  VerifyStatus,
  TypesPartner,
} from "../../../constants/constants";
import Select from "../../common/select";
import { RecentActors as RecentActorsIcon } from "@material-ui/icons";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@material-ui/core";
const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      dateOfBirth: null,
      dateOfBirthErrors: null,
      issuedOn: null,
      issuedOnErrors: null,
      cooperatedDate: null,
      type: PartnerType.Individual.value,
      dashboardId: "",
      fullName: "",
      phone: "",
      email: "",
      emailcc: "",
      emailErrors: "",
      stageName: "",
      hometown: "",
      identityNumber: "",
      issuedBy: "",
      currentAddress: "",
      idImageFrontSide: "",
      idImageBackSide: "",
      idImageAuxiliaryFrontSide: "",
      idImageAuxiliaryBackSide: "",
      idImageAuxiliaryFrontSideError: null,
      idImageAuxiliaryBackSideError: null,
      exitedPartner: "",
      exitedPartnerTypeCompany: "",
      idImageFrontSideError: null,
      idImageBackSideError: null,
      cannotInviteNotes: "",
      id: 0,
      cmsId: 0,
      partnerManager: null,
      partnerManagerId: null,
      partnerManagerIdErrors: null,
      tier: "",
      tierError: null,
      optionTier: objToArray(ChooseTier),
      selectedTier: null,
      verifyStatus: "",
      optionVerifyStatus: objToArray(VerifyStatus),
      selectedVerifyStatus: null,
      typePartner: "",
      optionTypesPartner: objToArray(TypesPartner),
      selectedTypePartner: null,
      taxCode: "",
      taxCodeErrors: null,
      companyName: "",
      tradingAddress: "",
      companyAddress: "",
      representativeName: "",
      representativePosition: "",
      deniedReason: "",
      gender: Gender.Male.value,
      genderErrors: null,
      emailccErrors: ""
    };
    this.state = this.defaultState;

    this.validationData = {
      fullName: [notEmpty],
      phone: [notEmpty, justNumber],
      email: [notEmpty, emailAddress],
      stageName: [notEmpty],
      hometown: [notEmpty],
      identityNumber: [notEmpty],
      issuedBy: [notEmpty],
      currentAddress: [notEmpty],
    };

    this.idImageFrontSideRef = React.createRef();
    this.idImageBackSideRef = React.createRef();
    this.idImageAuxiliaryBackSideRef = React.createRef();
    this.idImageAuxiliaryFrontSideRef = React.createRef();
  }

  _handlePartnerExist = async () => {
    this.setState({
      exitedPartner: null,
      emailErrors: null,
    });

    let { email, identityNumber } = this.state;
    if (!email) return "";
    let param = {
      search: "",
      identityNumber: identityNumber,
      TaxCode: "",
      skip: 0,
      take: 1,
    };
    const partner = this.props.partner;
    var query = objToQuery(param);
    var repsonse = await getAsync("partners" + query);
    var result = await repsonse.json();

    if (result.total > 0) {
      if (result.items[0].id == partner.id) return "";
      else {
        this.setState({
          exitedPartner: result.items[0],
        });
        return this.state.exitedPartner;
      }
    }

    return "";
  };
  _handlePartnerExistByEmail = async () => {
    this.setState({
      emailErrors: null,
      exitedPartnerByEmail: null,
    });

    let { email, identityNumber, id } = this.state;
    if (!email) return "";
    let param = {
      search: "",
      email: email,
      TaxCode: "",
      skip: 0,
      take: 1,
    };

    const partner = this.props.partner;

    var query = objToQuery(param);
    var repsonse = await getAsync("partners" + query);
    var result = await repsonse.json();

    if (result.total > 0) {
      if (result.items[0].id == partner.id) return "";
      else {
        this.setState({
          exitedPartnerByEmail: result.items[0],
        });
        return this.state.exitedPartnerByEmail;
      }
    }
    return "";
  };

  _reload() {
    const { partner } = this.props;
    if (partner) {
      let emailCC = "";
      if (partner.emailCCs) {
        partner.emailCCs.map((email, index) => {
          if (index == 0) {
            emailCC += email;
          } else {
            emailCC += ", " + email;
          }
        });
      }
      this.setState(
        {
          dashboardId: partner.dashboardId,
          fullName: partner.fullName,
          phone: partner.phone,
          email: partner.email,
          emailcc: emailCC,
          stageName: partner.stageName,
          dateOfBirth: partner.dateOfBirth,
          hometown: partner.hometown,
          identityNumber: partner.identityNumber,
          issuedOn: partner.issuedOn,
          issuedBy: partner.issuedBy,
          currentAddress: partner.currentAddress,
          idImageFrontSide: partner.idImageFrontSide,
          idImageBackSide: partner.idImageBackSide,
          idImageAuxiliaryFrontSide: partner.idImageAuxiliaryFrontSide,
          idImageAuxiliaryBackSide: partner.idImageAuxiliaryBackSide,
          cmsId: partner.cmsId,
          cooperatedDate: partner.cooperatedDate,
          cannotInviteNotes: partner.cannotInviteNotes,
          exitedPartner: null,
          exitedPartnerTypeCompany: null,
          emailErrors: "",
          cmndErrors: "",
          emailccErrors: "",
          id: partner.id,
          partnerManager: partner.partnerManager,
          partnerManagerId: partner.partnerManager
            ? partner.partnerManager.id
            : null,
          tier: partner.tier,
          selectedTier: ChooseTier[partner.tier],
          verifyStatus: partner.verifyStatus,
          selectedVerifyStatus: VerifyStatus[partner.verifyStatus],
          typePartner: partner.type,
          selectedTypePartner: TypesPartner[partner.type],
          companyName: partner.companyName,
          tradingAddress: partner.tradingAddress,
          companyAddress: partner.companyAddress,
          representativeName: partner.representativeName,
          representativePosition: partner.representativePosition,
          deniedReason: partner.deniedReason,
          gender: partner.gender ? partner.gender : "Other",
          acceptedDate: partner.acceptedDate ? partner.acceptedDate : null,
          deniedDate: partner.deniedDate ? partner.deniedDate : null,
          discontinuedDate: partner.discontinuedDate ? partner.discontinuedDate : null,

        },
        () => {
          this.idImageFrontSideRef.current._handleReset();
          this.idImageBackSideRef.current._handleReset();
          this.idImageAuxiliaryFrontSideRef.current._handleReset();
          this.idImageAuxiliaryBackSideRef.current._handleReset();
        }
      );
    }
  }

  _cancelUpdate(callback) {
    confirmSubmit(
      <label style={{ fontSize: "1.2rem", marginBottom: "10px" }}>
        Huỷ thay đổi
      </label>,
      <span style={{ fontSize: "1rem" }}>
        Bạn có thật sự muốn huỷ thay đổi. Mọi thay đổi sẽ không được áp dụng.
      </span>,
      () => {
        this._reload();
        callback();
      }
    );
  }

  async _saveUpdate() {
    const {
      dashboardId,
      fullName,
      phone,
      email,
      emailcc,
      stageName,
      dateOfBirth,
      hometown,
      identityNumber,
      issuedOn,
      issuedBy,
      currentAddress,
      idImageFrontSide,
      idImageBackSide,
      idImageAuxiliaryFrontSide,
      idImageAuxiliaryBackSide,
      cmsId,
      cooperatedDate,
      cannotInviteNotes,
      partnerManagerId,
      id,
      selectedTier,
      selectedVerifyStatus,
      selectedTypePartner,
      companyName,
      tradingAddress,
      companyAddress,
      representativeName,
      representativePosition,
      deniedReason,
      genderErrors,
      gender,
      acceptedDate,
      deniedDate,
      discontinuedDate
    } = this.state;

    const param = {
      dashboardId: dashboardId,
      type: PartnerType.Individual.value,
      phone: phone,
      email: email,
      emailCCs: emailcc && emailcc.length > 0 ? emailcc.split('\n') : [],
      stageName: stageName,
      fullName: fullName,
      dateOfBirth: dateOfBirth,
      hometown: hometown,
      identityNumber: identityNumber,
      issuedOn: issuedOn,
      issuedBy: issuedBy,
      currentAddress: currentAddress,
      idImageFrontSide: idImageFrontSide,
      idImageBackSide: idImageBackSide,
      idImageAuxiliaryFrontSide: idImageAuxiliaryFrontSide,
      idImageAuxiliaryBackSide: idImageAuxiliaryBackSide,
      cmsId: cmsId,
      cooperatedDate: cooperatedDate,
      cannotInviteNotes: cannotInviteNotes,
      partnerManagerId: partnerManagerId,
      id: id,
      tier: selectedTier.value,
      verifyStatus: selectedVerifyStatus.value,
      type: selectedTypePartner.value,
      companyName: companyName,
      tradingAddress: tradingAddress,
      companyAddress: companyAddress,
      representativeName: representativeName,
      representativePosition: representativePosition,
      deniedReason: deniedReason,
      gender: gender,
      acceptedDate: acceptedDate,
      deniedDate: deniedDate,
      discontinuedDate: discontinuedDate
    };
    let valid = true;

    if (!isValid(this.validationData)) {
      valid = false;
    }

    param.emailCCs.map(item => {
      if (item && !emailPattern.test(item.trim())) {
        valid = false
        this.setState({
          emailccErrors: "Email cc không đúng định dạng."
        })
      }
    })

    let exitedPartner = await this._handlePartnerExist();
    let exitedPartnerByEmail = await this._handlePartnerExistByEmail();

    if (selectedVerifyStatus.value != VerifyStatus.Denied.value) {
      delete param.deniedReason;
    }
    if (selectedVerifyStatus.value === VerifyStatus.Accepted.value && !acceptedDate) {
      valid = false;
      this.setState({
        dateError: "Ngày accepted không được bỏ trống.",
      });
    }

    if (selectedVerifyStatus.value === VerifyStatus.Denied.value && !deniedDate) {
      valid = false;
      this.setState({
        dateError: "Ngày denied không được bỏ trống.",
      });
    }
    if (selectedVerifyStatus.value === VerifyStatus.Discontinued.value && !discontinuedDate) {
      valid = false;
      this.setState({
        dateError: "Ngày discontinued không được bỏ trống.",
      });
    }

    if (exitedPartner) {
      valid = false;
      this.setState({
        cmndErrors: "CMND đối tác đã tồn tại.",
      });
    }
    if (exitedPartnerByEmail) {
      valid = false;
      this.setState({
        emailErrors: "Email đối tác đã tồn tại.",
      });
    }

    if (!dateOfBirth) {
      valid = false;
      this.setState({
        dateOfBirthErrors: "Ngày sinh không được bỏ trống.",
      });
    }

    if (!issuedOn) {
      valid = false;
      this.setState({
        issuedOnErrors: "Ngày cấp chứng minh không được bỏ trống.",
      });
    }

    if (!partnerManagerId) {
      valid = false;
      this.setState({
        partnerManagerIdErrors: "Quản lý đối tác không được bỏ trống.",
      });
    }
    if (!idImageFrontSide) {
      valid = false;
      this.setState({
        idImageFrontSideError: "Ảnh chứng mình mặt trước không được bỏ trống.",
      });
    }
    if (!idImageBackSide) {
      valid = false;
      this.setState({
        idImageBackSideError: "Ảnh chứng mình mặt sau không được bỏ trống.",
      });
    }
    if (!Gender[gender]) {
      valid = false;
      this.setState({
        genderErrors: "Vui lòng chọn giới tính.",
      });
    }

    if (valid) {
      put("partners", param, () => {
        showSuccess("Thay đổi thông tin thành công");
        this.setState({
          cmndErrors: "",
          emailErrors: "",
          dateOfBirthErrors: "",
          issuedOnErrors: "",
          partnerManagerIdErrors: "",
          idImageFrontSideError: "",
          idImageBackSideError: "",
          genderErrors: "",
        });
        this.props.onUpdateSuccess();
      });
    } else {
      showError(
        "Nội dung không hợp lệ, vui lòng kiểm tra lại thông tin vừa nhập."
      );
      valid = true;
    }
  }

  componentDidMount() {
    this._reload();
  }

  _deletePartnerIndividual() {
    confirmSubmit(
      <label style={{ fontSize: "1.2rem", marginBottom: "10px" }}>
        Xoá đối tác
      </label>,
      <span style={{ fontSize: "1rem" }}>
        Bạn có chắc chắn muốn xóa đối tác này?
      </span>,
      () => {
        if (this.props.partner) {
          const partnerId = this.props.partner.id;
          _delete("partners/" + partnerId, "", () => {
            showSuccess("Xóa thông tin đối tác thành công");
            this.props.history.push("/partners");
          });
        }
      }
    );
  }

  render() {
    let {
      dashboardId,
      fullName,
      phone,
      email,
      emailcc,
      emailErrors,
      stageName,
      dateOfBirth,
      dateOfBirthErrors,
      hometown,
      identityNumber,
      issuedOn,
      issuedOnErrors,
      issuedBy,
      currentAddress,
      idImageFrontSide,
      idImageBackSide,
      idImageAuxiliaryFrontSide,
      idImageAuxiliaryBackSide,
      exitedPartner,
      cooperatedDate,
      cannotInviteNotes,
      partnerManagerId,
      partnerManagerIdErrors,
      optionTier,
      selectedTier,
      verifyStatus,
      selectedVerifyStatus,
      optionVerifyStatus,
      optionTypesPartner,
      selectedTypePartner,
      idImageFrontSideError,
      idImageBackSideError,
      cmndErrors,
      exitedPartnerByEmail,
      deniedReason,
      gender,
      genderErrors,
      dateError,
      emailccErrors
    } = this.state;

    let { edit, partner } = this.props;

    let validationData = this.validationData;

    let currentUser = getCurrentUser();

    let isLegal = currentUser.roleCode === MetubRoles.LEGAL_EXECUTIVE;

    if (
      currentUser.roleCode != MetubRoles.ADMIN &&
      currentUser.roleCode != MetubRoles.DATA_MANAGER &&
      currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
    ) {
      optionVerifyStatus = optionVerifyStatus.filter(
        (item) => item.value != VerifyStatus.Denied.value
      );
    }
    if (currentUser.roleCode == MetubRoles.PARTNER_MANAGER) {
      optionVerifyStatus = optionVerifyStatus.filter(
        (item) =>
          item.value != VerifyStatus.Denied.value &&
          item.value != VerifyStatus.Accepted.value
      );
    }

    return (
      <div className="update-partner">
        <div className="left-box ">
          <table className="table list-item border-none input-form">
            <tbody>
              <tr>
                <td className="text-left width300">
                  <label>ID Đối tác</label>
                  <TextInput
                    className="partnerId"
                    value={partner ? partner.id : "-/-"}
                    name={"partnerId"}
                    placeholder={"ID đối tác"}
                    displayName={"ID đối tác"}
                    edit={false}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-left width300">
                  <label>Dashboard ID</label>
                  <TextInput
                    className="dashboardId"
                    value={dashboardId}
                    name={"dashboardId"}
                    placeholder={"Dashboard ID"}
                    displayName={"Dashboard ID"}
                    onChange={(e) => {
                      this.setState({ dashboardId: e.target.value });
                    }}
                    edit={
                      edit &&
                      currentUser.roleCode != MetubRoles.PARTNER_MANAGER &&
                      currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="coin text-left width300">
                  <label>Email</label>
                  <TextInput
                    className="email"
                    value={email}
                    name={"email"}
                    displayName={"Email"}
                    placeholder={"Nhập email"}
                    onChange={(e) => {
                      this.setState({ email: e.target.value });
                    }}
                    validations={validationData.email}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                    errors={emailErrors}
                  />
                  {exitedPartnerByEmail && (
                    <div className="exited-partner">
                      <span style={{ color: "#ef4502" }}>
                        Email của đối tác đã tồn tại.
                      </span>
                      <label>Thông tin đối tác đã tồn tại:</label>
                      <table className="table border-none">
                        <tbody>
                          <tr>
                            <td className="width50">
                              <img
                                alt=""
                                src={DefaultUserAvatar}
                                style={{ width: "100%" }}
                              />
                            </td>
                            <td>
                              <table className="table border-none">
                                <tbody>
                                  <tr>
                                    <td>{exitedPartnerByEmail.fullName}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {exitedPartnerByEmail.email +
                                        " | ID: " +
                                        exitedPartnerByEmail.id}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td className="coin text-left width300">
                  <label>Email CC</label>
                  <TextInput
                    className="emailcc"
                    value={emailcc}
                    name={"emailcc"}
                    displayName={"Email CC"}
                    placeholder={"Email CC"}
                    multiline={true}
                    onChange={(e) => {
                      this.setState({ emailcc: e.target.value, emailccErrors: "" });
                    }}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                    errors={emailccErrors}
                  />
                </td>
              </tr>
              <tr>
                <td className="coin text-left width100">
                  <label>Số CMND</label>
                  <TextInput
                    className="identityNumber"
                    value={identityNumber}
                    name={"identityNumber"}
                    placeholder={"Nhập CMND"}
                    displayName={"Số CMND"}
                    onChange={(e) => {
                      this.setState({ identityNumber: e.target.value });
                    }}
                    validations={validationData.identityNumber}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                    errors={cmndErrors}
                  />
                  {exitedPartner && (
                    <div className="exited-partner">
                      <span style={{ color: "#ef4502" }}>
                        Số CMND của đối tác đã tồn tại.
                      </span>
                      <label>Thông tin đối tác đã tồn tại:</label>
                      <table className="table border-none">
                        <tbody>
                          <tr>
                            <td className="width50">
                              <img
                                alt=""
                                src={DefaultUserAvatar}
                                style={{ width: "100%" }}
                              />
                            </td>
                            <td>
                              <table className="table border-none">
                                <tbody>
                                  <tr>
                                    <td>{exitedPartner.fullName}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {exitedPartner.email +
                                        " | ID: " +
                                        exitedPartner.id}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td className="coin text-left width300">
                  <label>Ngày cấp</label>
                  <DatetimePicker
                    className="issuedOn"
                    type="date"
                    placeholder={"Ngày cấp"}
                    onChange={(value) =>
                      this.setState({ issuedOn: value, issuedOnErrors: null })
                    }
                    value={issuedOn}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                    errors={issuedOnErrors}
                  />
                </td>
              </tr>
              <tr>
                <td className="coin text-left width300">
                  <label>Nơi cấp</label>
                  <TextInput
                    className="issuedBy"
                    value={issuedBy}
                    name={"issuedBy"}
                    placeholder={"Nơi cấp cmnd"}
                    displayName={"Nơi cấp CMND"}
                    onChange={(e) => {
                      this.setState({ issuedBy: e.target.value });
                    }}
                    validations={validationData.issuedBy}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="coin text-left width300">
                  <label>Nghệ danh</label>
                  <TextInput
                    className="stageName"
                    value={stageName}
                    name={"stageName"}
                    placeholder={"Nhập nghệ danh"}
                    displayName={"Nghệ danh"}
                    onChange={(e) => {
                      this.setState({ stageName: e.target.value });
                    }}
                    validations={validationData.stageName}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="coin text-left width300">
                  <label>Họ tên</label>
                  <TextInput
                    className="name"
                    value={fullName}
                    name={"fullName"}
                    placeholder={"Nhập họ tên"}
                    displayName={"Họ tên"}
                    onChange={(e) => {
                      this.setState({ fullName: e.target.value });
                    }}
                    validations={validationData.fullName}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="coin text-left width300">
                  <label>Giới tính</label>
                  {edit &&
                    currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE ? (
                    <div
                      className="quiz"
                      style={{
                        border: "1px solid #dee2e6",
                        borderRadius: "4px",
                        overflow: "hidden",
                      }}
                    >
                      <RadioGroup
                        aria-label="quiz"
                        className="quiz"
                        name="quiz"
                        style={{
                          display: "block",
                          flexDirection: "row",
                          padding: "0px 20px",
                        }}
                        value={gender}
                        onChange={(e) =>
                          this.setState({
                            gender: e.target.value,
                            genderErrors: "",
                          })
                        }
                      >
                        <FormControlLabel
                          value={Gender.Male.value}
                          style={{ marginRight: "40px", marginBottom: "0px" }}
                          control={<Radio color="primary" />}
                          label={Gender.Male.label}
                        />
                        <FormControlLabel
                          value={Gender.Female.value}
                          style={{ marginRight: "40px", marginBottom: "0px" }}
                          control={<Radio />}
                          label={Gender.Female.label}
                        />
                        <FormControlLabel
                          value={Gender.Other.value}
                          style={{ marginRight: "40px", marginBottom: "0px" }}
                          control={<Radio color="default" />}
                          label={Gender.Other.label}
                        />
                      </RadioGroup>
                      {edit && genderErrors ? (
                        <FormHelperText
                          style={{
                            color: "#ef4502",
                            margin: "0px",
                            background: "#fff4f2",
                            padding: "10px",
                            textAlign: "right",
                            fontSize: "inherit",
                          }}
                        >
                          {genderErrors}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <span className="custom-date-time-picker view">
                      {Gender[gender] ? Gender[gender].label : "-/-"}
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td className="coin text-left width300">
                  <label>Ngày sinh</label>
                  <DatetimePicker
                    className="dateOfBirth"
                    placeholder={"Ngày sinh"}
                    onChange={(value) =>
                      this.setState({
                        dateOfBirth: value,
                        dateOfBirthErrors: null,
                      })
                    }
                    value={dateOfBirth}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                    errors={dateOfBirthErrors}
                  />
                </td>
              </tr>

              <tr>
                <td className="coin text-left width300">
                  <label>Nguyên quán</label>
                  <TextInput
                    className="hometown"
                    value={hometown}
                    name={"hometown"}
                    displayName={"Nguyên quán"}
                    placeholder={"Nhập nguyên quán"}
                    onChange={(e) => {
                      this.setState({ hometown: e.target.value });
                    }}
                    validations={validationData.hometown}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="coin text-left width300">
                  <label>Địa chỉ hiện tại</label>
                  <TextInput
                    className="currentAddress"
                    value={currentAddress}
                    name={"currentAddress"}
                    displayName={"Địa chỉ hiện tại"}
                    placeholder={"Nhập địa chỉ"}
                    multiline={true}
                    onChange={(e) => {
                      this.setState({ currentAddress: e.target.value });
                    }}
                    validations={validationData.currentAddress}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="coin text-left width300">
                  <label>Số điện thoại</label>
                  <TextInput
                    className="phone"
                    value={phone}
                    name={"phone"}
                    placeholder={"Nhập sdt"}
                    displayName={"Số điện thoại"}
                    onChange={(e) => {
                      this.setState({ phone: e.target.value });
                    }}
                    validations={validationData.phone}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                  />
                </td>
              </tr>

              <tr className="">
                <td className="text-left width300">
                  <label>Kiểu đối tác</label>
                  <Select
                    options={optionTypesPartner}
                    onChange={(value) =>
                      this.setState({ selectedTypePartner: value })
                    }
                    value={selectedTypePartner}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                    allowNull={false}
                  />
                </td>
              </tr>

              <tr>
                <td className="coin text-left width300">
                  <label>Ngày hợp tác</label>
                  <DatetimePicker
                    className="cooperatedDate"
                    placeholder={"Ngày hợp tác"}
                    onChange={(value) =>
                      this.setState({ cooperatedDate: value })
                    }
                    value={cooperatedDate}
                    edit={
                      edit &&
                      currentUser.roleCode != MetubRoles.PARTNER_MANAGER &&
                      currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="text-left ">
                  <label>Partner Manager</label>
                  <UserSelectList
                    edit={
                      isInRole(MetubRoles.ADMIN) ||
                        isInRole(MetubRoles.DATA_MANAGER)
                        ? edit
                        : false
                    }
                    value={partnerManagerId}
                    outLine={true}
                    onChange={(value) =>
                      this.setState({
                        partnerManagerId: value ? value.id : null,
                        partnerManagerIdErrors: null,
                      })
                    }
                    errors={partnerManagerIdErrors}
                    filter={(users) => {
                      return users.filter(
                        (item) => item.roleCode == MetubRoles.PARTNER_MANAGER
                      );
                    }}
                  />
                </td>
              </tr>
              {/* <tr>
                                <td className="coin text-left width100 ">
                                    <label>Tier</label>
                                </td>
                                <td className="coin text-left width300">
                                    <Select
                                        options={optionTier}
                                        onChange={(value) => this.setState({ selectedTier: value })}
                                        value={selectedTier}
                                        edit={(verifyStatus === "Accepted" && (currentUser.roleCode === "DATA_MANAGER" || currentUser.roleCode === "ADMIN")) ? edit : false}
                                        allowNull={false}
                                    />

                                </td>
                            </tr> */}
            </tbody>
          </table>
        </div>
        <div className="right-box">
          <table
            className="table list-item border-none input-form"
            style={{ position: "relative" }}
          >
            <tbody>
              <tr>
                <td className="coin text-left width300">
                  <label>Tier</label>
                  <Select
                    options={optionTier}
                    onChange={(value) => this.setState({ selectedTier: value })}
                    value={selectedTier}
                    edit={
                      verifyStatus === "Accepted" &&
                        (currentUser.roleCode === "DATA_MANAGER" ||
                          currentUser.roleCode === "ADMIN")
                        ? edit
                        : false
                    }
                    allowNull={false}
                  />
                </td>
              </tr>

              <tr>
                <td className="text-left width300">
                  <label>Ghi chú</label>
                  <TextInput
                    className="cannotInviteNotes"
                    value={cannotInviteNotes}
                    name={"cannotInviteNotes"}
                    placeholder={"Ghi chú"}
                    displayName={"Ghi chú"}
                    multiline={true}
                    onChange={(e) => {
                      this.setState({ cannotInviteNotes: e.target.value });
                    }}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                  />
                </td>
              </tr>
              <tr className="">
                <td className="text-left width300">
                  <label>Trạng thái chờ duyệt</label>
                  <Select
                    options={optionVerifyStatus}
                    onChange={(value) =>
                      this.setState({ selectedVerifyStatus: value })
                    }
                    value={selectedVerifyStatus}
                    edit={
                      currentUser.roleCode === "DATA_MANAGER" ||
                        currentUser.roleCode === "ADMIN" ||
                        currentUser.roleCode === MetubRoles.LEGAL_EXECUTIVE ||
                        ((currentUser.roleCode == MetubRoles.PARTNER_MANAGER ||
                          currentUser.roleCode ==
                          MetubRoles.LEADER_PARTNER_MANAGER) &&
                          VerifyStatus[partner.verifyStatus].value) ==
                        VerifyStatus.Denied.value
                        ? edit
                        : false
                    }
                    allowNull={false}
                  />
                </td>
              </tr>
              {selectedVerifyStatus && selectedVerifyStatus.value && selectedVerifyStatus.value != VerifyStatus.Pending.value && (
                <tr>
                  <td>
                    <label>
                      Ngày{" "}
                      {selectedVerifyStatus.label.toString().toLowerCase()}
                    </label>
                    <DatetimePicker
                      className="issuedOn"
                      type="date"
                      placeholder={
                        "Ngày " +
                        selectedVerifyStatus.label.toString().toLowerCase()
                      }
                      onChange={(value) =>
                        this.setState({
                          [selectedVerifyStatus.key]: value,
                          dateError: null,
                        })
                      }
                      value={this.state[selectedVerifyStatus.key]}
                      edit={
                        currentUser.roleCode === "DATA_MANAGER" ||
                          currentUser.roleCode === "ADMIN" ||
                          currentUser.roleCode === MetubRoles.LEGAL_EXECUTIVE ||
                          ((currentUser.roleCode == MetubRoles.PARTNER_MANAGER ||
                            currentUser.roleCode ==
                            MetubRoles.LEADER_PARTNER_MANAGER) &&
                            VerifyStatus[partner.verifyStatus].value) ==
                          VerifyStatus.Denied.value
                          ? edit
                          : false
                      }
                      errors={dateError}
                    />
                  </td>
                </tr>)
              }
              {selectedVerifyStatus &&
                selectedVerifyStatus.value == VerifyStatus.Denied.value ? (
                <tr>
                  <td className="text-left width300">
                    <label>Lý do từ chối</label>
                    <TextInput
                      className="deniedReason"
                      value={deniedReason}
                      name={"deniedReason"}
                      placeholder={"Lý do từ chối"}
                      displayName={"Lý do từ chối"}
                      multiline={true}
                      onChange={(e) => {
                        this.setState({ deniedReason: e.target.value });
                      }}
                      edit={edit}
                    />
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>

          <table className="table border-none width100pc mt10pc input-form">
            <tbody>
              <tr>
                <td className="coin text-left">
                  <label>Ảnh CMND mặt trước(1)</label>
                  <FileDropZone
                    type={UploadFileType.Avatars.value}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                    onChange={(files) => {
                      this.setState({
                        idImageFrontSide:
                          files.length > 0 ? files[0].relativePath : null,
                        idImageFrontSideError: null,
                      });
                    }}
                    multiImages={false}
                    errors={idImageFrontSideError}
                    ref={this.idImageFrontSideRef}
                    files={[idImageFrontSide]}
                  />
                </td>
              </tr>
              <tr>
                <td className="coin text-left border-none">
                  <label>Ảnh CMND mặt sau(1)</label>
                  <FileDropZone
                    type={UploadFileType.Avatars.value}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                    onChange={(files) => {
                      this.setState({
                        idImageBackSide:
                          files.length > 0 ? files[0].relativePath : null,
                        idImageBackSideError: null,
                      });
                    }}
                    multiImages={false}
                    errors={idImageBackSideError}
                    ref={this.idImageBackSideRef}
                    files={[idImageBackSide]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table border-none width100pc mt10pc">
            <tbody>
              <tr>
                <td className="coin text-left">
                  <label>Ảnh CMND mặt trước(2)</label>
                  <FileDropZone
                    type={UploadFileType.Avatars.value}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                    onChange={(files) => {
                      this.setState({
                        idImageAuxiliaryFrontSide:
                          files.length > 0 ? files[0].relativePath : null,
                        idImageFrontSideError: null,
                      });
                    }}
                    multiImages={false}
                    errors={idImageFrontSideError}
                    ref={this.idImageAuxiliaryFrontSideRef}
                    files={[idImageAuxiliaryFrontSide]}
                  />
                </td>
              </tr>
              <tr>
                <td className="coin text-left border-none">
                  <label>Ảnh CMND mặt sau(2)</label>
                  <FileDropZone
                    type={UploadFileType.Avatars.value}
                    edit={
                      edit && currentUser.roleCode != MetubRoles.LEGAL_EXECUTIVE
                    }
                    onChange={(files) => {
                      this.setState({
                        idImageAuxiliaryBackSide:
                          files.length > 0 ? files[0].relativePath : null,
                        idImageBackSideError: null,
                      });
                    }}
                    multiImages={false}
                    errors={idImageBackSideError}
                    ref={this.idImageAuxiliaryBackSideRef}
                    files={[idImageAuxiliaryBackSide]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Index;
