import React from "react";
import Modal from '../common/modal'
import Button from "@material-ui/core/Button";
import TextInput from '../common/text-input'
import DatetimePicker from '../common/datetime-picker'
import Select from '../common/select'
import { post } from '../../api'
import { notEmpty, justNumber, isValid } from "../../utils/validators";
import { showSuccess, showError } from "../../utils/app";
import { CampaignStatus } from "../../constants/constants";
import { objToArray, getDate } from "../../utils/common";




class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            customerName: null,
            fromDate: null,
            toDate: null,
            budget: null,
            budgetCurrency: null,
            status: null,
            selectedStatus: null
        }
        this.validationData = {
            name: [notEmpty],
            customerName: [notEmpty],
            budget: [justNumber, notEmpty]
        };
        this.Individual = React.createRef();
        this.Company = React.createRef();
    }


    _onAddCampaign = () => {
        const {
            name,
            customerName,
            fromDate,
            toDate,
            budget,
            selectedStatus
        } = this.state;
        const {
            onAddSucceed,
        } = this.props
        if (selectedStatus) {
            let param = {
                name: name,
                customerName: customerName,
                fromDate: fromDate,
                toDate: toDate,
                budget: budget,
                status: selectedStatus.value
            }
            if (isValid(this.validationData))
                post("campaigns/", param, () => {
                    if (onAddSucceed)
                        onAddSucceed()
                    this.setState({
                        name: null,
                        customerName: null,
                        fromDate: null,
                        toDate: null,
                        budget: null,
                        budgetCurrency: null,
                        status: null,
                        selectedStatus: null
                    })
                    showSuccess("Thêm campaign thành công")
                });
            else {
                showError("Dữ liệu không hợp lệ. Vui lòng kiểm tra lại")
            }
        }
        else {
            showError("Dữ liệu không hợp lệ. Vui lòng kiểm tra lại")
        }
    }

    _onClose = () => {
        this.setState({
            name: null,
            customerName: null,
            fromDate: null,
            toDate: null,
            budget: null,
            budgetCurrency: null,
            status: null,
            selectedStatus: null
        },
            () => this.props.onClose()
        )
    }
    componentDidMount() {
        this.setState({
            fromDate: getDate(),
            toDate: getDate()
        })
    }

    render() {
        let {
            name,
            customerName,
            fromDate,
            toDate,
            budget,
            selectedStatus
        } = this.state
        let {
            open
        } = this.props
        return (
            <Modal
                className="add-new-options"
                open={open}
                onClose={() => this._onClose()}
                fullWidth={true}
                header={
                    <div>
                        <span>THÊM CAMPAIGN</span>
                    </div>
                }
                content={
                    <table className="table list-item border-bottom">
                        <tbody>
                            <tr>
                                <td className="coin text-left width100">
                                    <span>Tên campaign</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="name"
                                        value={name}
                                        label={"Tên campaign"}
                                        name={"name"}
                                        placeholder={"Tên campaign"}
                                        displayName={"Tên campaign"}
                                        onChange={e =>
                                            this.setState({ name: e.target.value })
                                        }
                                        validations={this.validationData.name}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left">
                                    <span>Tên khách hàng</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="description"
                                        value={customerName}
                                        label={"Tên khách hàng"}
                                        name={"customerName"}
                                        placeholder={"Tên khách hàng"}
                                        onChange={e =>
                                            this.setState({ customerName: e.target.value })
                                        }
                                        displayName={"Tên khách hàng"}
                                        validations={this.validationData.customerName}
                                        edit={true}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td className="coin text-left">
                                    <span>Kinh phí</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="description"
                                        value={budget}
                                        label={"Kinh phí"}
                                        name={"budget"}
                                        placeholder={"Kinh phí"}
                                        onChange={e =>
                                            this.setState({ budget: e.target.value })
                                        }
                                        displayName={"Kinh phí"}
                                        validations={this.validationData.budget}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left">
                                    <span>Trạng thái</span>
                                </td>
                                <td className="coin text-left width300">
                                    <Select
                                        className="select-status"
                                        options={objToArray(CampaignStatus)}
                                        onChange={(value) => this.setState({
                                            selectedStatus: value
                                        })}
                                        allowNull={selectedStatus ? true : false}
                                        value={selectedStatus}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left">
                                    <span>Ngày bắt đầu</span>
                                </td>
                                <td className="coin text-left width300">
                                    <DatetimePicker
                                        placeholder={"Ngày bắt đầu"}
                                        onChange={value => this.setState({
                                            fromDate: value
                                        })
                                        }
                                        value={fromDate}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left">
                                    <span>Ngày kết thúc</span>
                                </td>
                                <td className="coin text-left width300">
                                    <DatetimePicker
                                        placeholder={"Ngày kết thúc"}
                                        onChange={value => this.setState({
                                            toDate: value
                                        })
                                        }
                                        value={toDate}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                action={
                    <Button
                        color="primary"
                        onClick={this._onAddCampaign}
                    >
                        Lưu
            </Button>

                }
                allowCancel={true}
            />
        )
    }
}


export default Index;

