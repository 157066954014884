import React from "react";
import Select from '../../common/select'
import { put } from '../../../api'
import { UserRole, UserStatus } from '../../../constants/constants'
import { objToArray } from "../../../utils/common";
import FormLabel from '@material-ui/core/FormLabel';
import { showSuccess, showError } from "../../../utils/app";
import { isInRole } from "../../../auth";
import { MetubRoles } from "../../../constants/constants";


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRole: null,
            selectedStatus: null
        }
    }

    _onFilterCodeToValue = (roleCode) => {
        let userRoleList = objToArray(UserRole)
        for (var i = 0; i <= userRoleList.length; i++) {
            if (userRoleList[i].value === roleCode) {
                this.setState({
                    selectedRole: userRoleList[i]
                })
                return
            }
        }
    }
    _onFilterStatusToValue = (status) => {
        let userStatusList = objToArray(UserStatus)
        for (var i = 0; i <= userStatusList.length; i++) {
            if (userStatusList[i].value === status) {
                this.setState({
                    selectedStatus: userStatusList[i]
                })
                return
            }
        }
    }
    componentWillMount() {
        const { id, status, roleCode } = this.props
        this.setState({
            id,
            status,
            roleCode
        },
            () => {
                this._onFilterCodeToValue(roleCode)
                this._onFilterStatusToValue(status)
            })
    }


    _onUpdateUserRole = () => {
        let {
            selectedStatus,
            selectedRole,
            id
        } = this.state
        if (!id || !selectedRole || !selectedStatus) return
        let param = {
            id: id ? id : null,
            status: selectedStatus ? selectedStatus.value : null,
            roleCode: selectedRole ? selectedRole.value : null
        };
        put("users/role", param,
            () => {
                let {
                    onClose,
                } = this.props
                if (onClose) onClose()
            },
            () => {
                showError("Server đang bảo trì, vui lòng thử lại sau")
            }
        )
    }
    render() {
        let { selectedRole, selectedStatus } = this.state
        return (
            <div className="update-role">
                <label>Trạng thái</label>
                <Select
                    options={objToArray(UserStatus).filter(x => x.value !== "None")}
                    onChange={(value) => this.setState({ selectedStatus: value })}
                    value={selectedStatus}
                    edit={isInRole(MetubRoles.UPDATE_USER) ? true : false}
                />
                <label>Chức vụ</label>
                <Select
                    options={objToArray(UserRole).filter(x => x.value !== "None")}
                    onChange={(value) => this.setState({ selectedRole: value })}
                    value={selectedRole}
                    edit={isInRole(MetubRoles.UPDATE_USER) ? true : false}
                />
            </div>
        )
    }
}


export default Index;

