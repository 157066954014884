import React from "react";
import ChannelCard from "./video-card";
import DataTable from "../common/datatable";
import { NavLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import { isInRole, getCurrentUser } from "../../auth";
import { MetubRoles } from "../../constants/constants";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _onUpdateSuccess() {
    this.props.onUpdateSuccess();
  }

  _onTablePageChange(param) {
    this.props.onChange(param);
  }

  render() {
    const { videos, listType, total, currentPage } = this.props;
    const currentUser = getCurrentUser();
    return videos && videos.length > 0 ? (
      <div className="video-page">
        {listType === "card" ? (
          <div className="video-list">
            {videos.map((video, i) => (
              <ChannelCard
                info={video}
                key={i}
                onUpdateSuccess={() => this._onUpdateSuccess()}
              />
            ))}
          </div>
        ) : (
          <div className="video-list list-view">
            <DataTable
              data={videos}
              total={total}
              onChange={(param) => this._onTablePageChange(param)}
              isPaging={true}
              currentPage={currentPage}
              renderHeader={() => {
                return (
                  <thead>
                    <tr>
                      <th className="text-left">ID</th>
                      <th className="text-left">YouTube ID</th>
                      <th className="text-left">Tên video</th>
                      <th className="text-center">Lượt xem</th>
                      <th className="text-left">Người tối ưu</th>
                      <th className="text-left width30"></th>
                    </tr>
                  </thead>
                );
              }}
              renderBody={(items) => {
                return (
                  <tbody>
                    {items.map((item, i) => (
                      <tr key={i}>
                        <td className="text-left">#{item.id}</td>
                        <td className="text-left">{item.youtubeId}</td>
                        <td className="text-left">
                          <NavLink
                            to={
                              isInRole(MetubRoles.VIEW_VIDEO)
                                ? `/videos/${item.id}`
                                : "#"
                            }
                          >
                            {item.name}
                          </NavLink>
                        </td>
                        <td className="text-center">{item.viewCount}</td>
                        <td className="text-left">
                          {item.optimizer ? item.optimizer.fullName : "-/-"}
                        </td>
                        <td>
                          {isInRole(MetubRoles.VIEW_VIDEO) && (
                            <NavLink to={`/videos/${item.id}`}>
                              <Tooltip title="Xem chi tiết" placement="top">
                                <IconButton>
                                  {item.verifyStatus === "Accepted" ? (
                                    <InfoIcon color="primary" />
                                  ) : (
                                    <InfoIcon />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </NavLink>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                );
              }}
            ></DataTable>
          </div>
        )}
      </div>
    ) : (
      <label className="empty-list-label">Chưa có video nào.</label>
    );
  }
}

export default Index;
