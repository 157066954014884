import React from "react";
import { post, get } from "../../api";
import Modal from '../common/modal'
import Button from '@material-ui/core/Button'
import TextInput from '../common/text-input'
import { notEmpty, isValid } from '../../utils/validators'

import { showSuccess, showError } from "../../utils/app";
import { UploadFileType, RequestType, ChannelRequestSubType, PartnerRequestSubType, UpdateChannelPartner, MetubRoles, ApplyPeriod } from "../../constants/constants";
import Select from "../common/select";
import FileDropZone from '../common/file-drop-zone'
import { objToArray, objToQuery } from "../../utils/common";
import ContentLoader from "react-content-loader"
import UserSelectList from '../common/user-select-list'

let RequestTypeOptions = objToArray(RequestType).filter(item => item.value != "None").slice(0, 3)
let ChannelRequestSubTypeOptions = objToArray(ChannelRequestSubType)
let PartnerRequestSubTypeOptions = objToArray(PartnerRequestSubType)
let UpdateChannelPartnerOptions = objToArray(UpdateChannelPartner)
let ApplyPeriodOptions = objToArray(ApplyPeriod)

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requestType: RequestType.UpdatePartner.value,
            content: "",
            files: null,
            requestTypeSelected: null,
            requestSubTypeSelected: null,
            referrenceSelected: null,
            optimizerId: null,
            optimizerSelected: null,
            partnerManagerId: null,
            partnerManagerSelected: null,
            applyPeriodSelected: null
        };

        this.defaultState = this.state

        this.RequestImageRef = React.createRef()

        this.validationData = {
            content: [notEmpty],
        };

    }

    _addRequest() {
        let {
            requestTypeSelected,
            content,
            files,
            requestSubTypeSelected,
            referrenceSelected,
            partnerManagerSelected,
            optimizerSelected,
            applyPeriodSelected
        } = this.state

        let param = {
            type: requestTypeSelected.value,
            referenceId: this.props.partnerId,
            content: content,
            files: files,
            applyPeriod: applyPeriodSelected ? applyPeriodSelected.value : ""
        }

        if (requestTypeSelected.value == RequestType.UpdateChannel.value) {
            if (referrenceSelected)
                param.referenceId = referrenceSelected.value
            else {
                showError("Đối tác này chưa quản lý bất kì kênh nào.")
                return
            }
        }
        if (requestTypeSelected.value == RequestType.UpdateVideo.value) {
            if (referrenceSelected)
                param.referenceId = referrenceSelected.value
            else {
                showError("Đối tác này chưa quản lý bất kì video nào.")
                return
            }
        }
        if (requestTypeSelected.value == RequestType.UpdateChannelPartner.value && requestSubTypeSelected.value == UpdateChannelPartner.ChangePartnerForChannel.value) {
            if (referrenceSelected)
                param.referenceId = referrenceSelected.value
            else {
                showError("Đối tác này chưa quản lý bất kì video nào.")
                return
            }
        }
        if (requestSubTypeSelected) {
            param.subType = requestSubTypeSelected.value
        }

        if (requestSubTypeSelected && requestSubTypeSelected.value == "ChangePartnerManager") {
            param.content = partnerManagerSelected ? ("Cập nhật người quản lý thành " + partnerManagerSelected.fullName + " - " + partnerManagerSelected.email) : param.content
            param.newPMId = partnerManagerSelected ? partnerManagerSelected.id : null
        }
        if (requestSubTypeSelected && requestSubTypeSelected.value == "ChangeOptimizer") {
            param.content = optimizerSelected ? ("Cập nhật người tối ưu thành " + optimizerSelected.fullName + " - " + optimizerSelected.email) : param.content
        }
        if (!applyPeriodSelected) {
            showError("Vui lòng chọn kỳ đối soát.")
            return
        }

        if (isValid(this.validationData))
            post("requests", param, () => {
                showSuccess("Thêm yêu cầu thành công.")
                this.props.onAddSuccess()
                this._resetState()
                this.RequestImageRef.current._handleReset()
                this.setState({
                    requestTypeSelected: null,
                    requestSubTypeSelected: null
                })
            })
    }

    _getChannel() {
        let {
            partnerId
        } = this.props
        if (!partnerId) return
        let param = {
            skip: 0,
            take: 10000
        }
        let query = objToQuery(param)
        this.setState({
            channels: null,
            isProcessing: true
        }, () => {
            get("partners/" + partnerId + "/channels" + query, result => {
                result.items.map(item => {
                    item.value = item.id
                    item.label = item.name
                })
                this.setState({
                    channels: result.items,
                    isProcessing: false
                })
            }, null, true)
        })

    }
    _getVideo() {
        let {
            partnerId
        } = this.props
        if (!partnerId) return
        let param = {
            skip: 0,
            take: 10000
        }
        let query = objToQuery(param)
        this.setState({
            videos: null,
            isProcessing: true
        }, () => {
            get("partners/" + partnerId + "/videos" + query, result => {
                result.items.map(item => {
                    item.value = item.id
                    item.label = item.name
                })
                this.setState({
                    videos: result.items,
                    isProcessing: false
                })
            }, null, true)
        })

    }

    _resetState() {
        this.setState({
            ...this.defaultState
        })
    }
    onRequestTypeChange(value) {
        this.setState({
            requestTypeSelected: value,
            requestSubTypeSelected: null
        }, () => {
            if (value.value == RequestType.UpdateChannel.value || value.value == RequestType.UpdateChannelPartner.value) {
                this._getChannel()
            }
            if (value.value == RequestType.UpdateVideo.value) {
                this._getVideo()
            }

        })

    }


    render() {
        const {
            content,
            requestTypeSelected,
            requestSubTypeSelected,
            channels,
            referrenceSelected,
            isProcessing,
            videos,
            optimizerId,
            partnerManagerId,
            applyPeriodSelected
        } = this.state

        const { open } = this.props

        return (
            <Modal
                className="add-new-contact"
                open={open}
                onClose={() => this.props.onClose()}
                fullWidth={true}
                fullScreen={false}
                header={
                    <div>
                        <span>TẠO YÊU CẦU CẬP NHẬT</span>
                    </div>
                }
                content={
                    <div>
                        <table className="table border-none input-form">
                            <tbody>
                                <tr >
                                    <td>
                                        <table className="table border-none input-form ">
                                            <tbody>
                                                <tr>
                                                    <td className="width50pc pl00">
                                                        <label>Loại yêu cầu</label>
                                                        <Select
                                                            options={RequestTypeOptions}
                                                            onChange={value => this.onRequestTypeChange(value)}
                                                            placeholder="Chọn loại yêu cầu"
                                                            value={requestTypeSelected}
                                                            edit={true}
                                                            allowNull={false}
                                                            outLine
                                                        />

                                                    </td>
                                                    {
                                                        requestTypeSelected && requestTypeSelected.value == RequestType.UpdateChannel.value ?
                                                            <td className="width50pc">
                                                                <label>Nội dung cập nhật</label>
                                                                <Select
                                                                    options={ChannelRequestSubTypeOptions}
                                                                    onChange={value =>
                                                                        this.setState({
                                                                            requestSubTypeSelected: value,
                                                                        })
                                                                    }
                                                                    placeholder="Chọn nội dung cập nhật"
                                                                    value={requestSubTypeSelected}
                                                                    edit={true}
                                                                    allowNull={false}
                                                                    outLine
                                                                />
                                                            </td> : ""

                                                    }
                                                    {
                                                        requestTypeSelected && requestTypeSelected.value == RequestType.UpdatePartner.value ?
                                                            <td className="width50pc">
                                                                <label>Nội dung cập nhật</label>
                                                                <Select
                                                                    options={PartnerRequestSubTypeOptions}
                                                                    onChange={value =>
                                                                        this.setState({
                                                                            requestSubTypeSelected: value
                                                                        })
                                                                    }
                                                                    placeholder="Chọn nội dung cập nhật"
                                                                    value={requestSubTypeSelected}
                                                                    edit={true}
                                                                    allowNull={false}
                                                                    outLine
                                                                />
                                                            </td> : ""

                                                    }
                                                    {
                                                        requestTypeSelected && requestTypeSelected.value == RequestType.UpdateChannelPartner.value ?
                                                            <td className="width50pc">
                                                                <label>Nội dung cập nhật</label>
                                                                <Select
                                                                    options={UpdateChannelPartnerOptions}
                                                                    onChange={value =>
                                                                        this.setState({
                                                                            requestSubTypeSelected: value
                                                                        })
                                                                    }
                                                                    placeholder="Chọn nội dung cập nhật"
                                                                    value={requestSubTypeSelected}
                                                                    edit={true}
                                                                    allowNull={false}
                                                                    outLine
                                                                />
                                                            </td> : ""

                                                    }
                                                    {
                                                        requestTypeSelected && requestTypeSelected.value != RequestType.UpdatePartner.value && requestTypeSelected && requestTypeSelected.value != RequestType.UpdateChannel.value ? <td className="width50pc"></td> : ""
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                {
                                    requestTypeSelected && requestTypeSelected.value == RequestType.UpdateChannel.value
                                        || requestTypeSelected && requestTypeSelected.value == RequestType.UpdateChannelPartner.value && requestSubTypeSelected && requestSubTypeSelected.value == UpdateChannelPartner.ChangePartnerForChannel.value
                                        ? <tr>
                                            {
                                                !channels ? <td>
                                                    <ContentLoader
                                                        speed={2}
                                                        width={500}
                                                        height={55}
                                                        viewBox="0 0 500 55"
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#ecebeb"
                                                        style={{
                                                            width: "100%",
                                                            height: "auto"
                                                        }}
                                                    >
                                                        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
                                                        <rect x="572" y="230" rx="3" ry="3" width="53" height="11" />
                                                        <rect x="574" y="238" rx="3" ry="3" width="72" height="11" />
                                                        <rect x="595" y="228" rx="3" ry="3" width="37" height="11" />
                                                        <rect x="0" y="20" rx="3" ry="3" width="500" height="30" />
                                                        <rect x="561" y="227" rx="3" ry="3" width="173" height="11" />
                                                        <rect x="580" y="167" rx="0" ry="0" width="56" height="94" />
                                                    </ContentLoader>
                                                </td> : (
                                                    channels && channels.length > 0 ? <td>
                                                        <label>Chọn kênh muốn cập nhật</label>
                                                        <Select
                                                            options={channels}
                                                            onChange={value => this.setState({ referrenceSelected: value })}
                                                            placeholder="Chọn loại yêu cầu"
                                                            value={referrenceSelected}
                                                            edit={true}
                                                            allowNull={false}
                                                            outLine
                                                        />
                                                    </td> : <td>
                                                        <label style={{ opacity: 0.5 }}>Đối tác này hiện chưa quản lí kênh nào</label>
                                                    </td>
                                                )
                                            }
                                        </tr> : ""
                                }
                                {
                                    requestTypeSelected && requestTypeSelected.value == RequestType.UpdateVideo.value ? <tr>
                                        {
                                            !videos ? <td>
                                                <ContentLoader
                                                    speed={2}
                                                    width={500}
                                                    height={55}
                                                    viewBox="0 0 500 55"
                                                    backgroundColor="#f3f3f3"
                                                    foregroundColor="#ecebeb"
                                                    style={{
                                                        width: "100%",
                                                        height: "auto"
                                                    }}
                                                >
                                                    <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
                                                    <rect x="572" y="230" rx="3" ry="3" width="53" height="11" />
                                                    <rect x="574" y="238" rx="3" ry="3" width="72" height="11" />
                                                    <rect x="595" y="228" rx="3" ry="3" width="37" height="11" />
                                                    <rect x="0" y="20" rx="3" ry="3" width="500" height="30" />
                                                    <rect x="561" y="227" rx="3" ry="3" width="173" height="11" />
                                                    <rect x="580" y="167" rx="0" ry="0" width="56" height="94" />
                                                </ContentLoader>
                                            </td> : (
                                                videos && videos.length > 0 ? <td>
                                                    <label>Chọn kênh muốn cập nhật</label>
                                                    <Select
                                                        options={videos}
                                                        onChange={value => this.setState({ referrenceSelected: value })}
                                                        placeholder="Chọn loại yêu cầu"
                                                        value={referrenceSelected}
                                                        edit={true}
                                                        allowNull={false}
                                                        outLine
                                                    />
                                                </td> : <td>
                                                    <label style={{ opacity: 0.5 }}>Đối tác này hiện chưa quản lí video nào</label>
                                                </td>
                                            )
                                        }
                                    </tr> : ""
                                }
                                <tr>
                                    <td>
                                        <label className="mt20">Áp dụng từ kỳ đối soát</label>
                                        <Select
                                            options={ApplyPeriodOptions}
                                            onChange={value => this.setState({ applyPeriodSelected: value })}
                                            placeholder="Chọn kỳ đối soát"
                                            value={applyPeriodSelected}
                                            edit={true}
                                            allowNull={true}
                                            outLine
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    {
                                        requestSubTypeSelected && (requestSubTypeSelected.value == "ChangePartnerManager" || requestSubTypeSelected.value == "ChangeOptimizer") ?
                                            <td>
                                                {
                                                    requestSubTypeSelected.value == "ChangePartnerManager" ? <label className="mt20">Chọn người quản lý</label> : <label className="mt20">Chọn người tối ưu</label>
                                                }
                                                {
                                                    requestSubTypeSelected.value == "ChangePartnerManager" ? <UserSelectList
                                                        edit={true}
                                                        value={partnerManagerId}
                                                        onChange={(value) => this.setState({ partnerManagerId: value ? value.id : null, partnerManagerSelected: value ? value : null })}
                                                        outLine
                                                        className="for-search"
                                                        style={{ zIndex: 999999 }}
                                                        filter={(users) => {
                                                            return users.filter(item => item.roleCode == MetubRoles.PARTNER_MANAGER)
                                                        }}
                                                        isHideUserSelected={true}
                                                    /> : <UserSelectList
                                                        edit={true}
                                                        value={optimizerId}
                                                        onChange={(value) => this.setState({ optimizerId: value ? value.id : null, optimizerSelected: value ? value : null })}
                                                        outLine
                                                        style={{ zIndex: 999999 }}
                                                        className="for-search"
                                                        filter={(users) => {
                                                            return users.filter(item => item.roleCode == MetubRoles.OPTIMIZE || item.roleCode == MetubRoles.OPTIMIZE_MANAGER)
                                                        }}
                                                        isHideUserSelected={true}
                                                    />

                                                }
                                            </td>
                                            : <td className="border-none p00">
                                                <label className="mt20">Mô tả chi tiết yêu cầu</label>
                                                <TextInput
                                                    className="content p00"
                                                    value={content}
                                                    name={"content"}
                                                    placeholder={"Nhập nội dung"}
                                                    displayName={"Nội dung"}
                                                    multiline={true}
                                                    onChange={e => {
                                                        this.setState({ content: e.target.value })
                                                    }}
                                                    edit={true}
                                                    minHeight={"100px"}
                                                />
                                            </td>
                                    }
                                </tr>
                                <tr>
                                    <td>
                                        <label >Hình ảnh</label>
                                        <FileDropZone
                                            type={UploadFileType.Contracts.value}
                                            edit={true}
                                            multiImages={true}
                                            onChange={(file) => {
                                                let files = []
                                                file && file.length > 0 && file.map(item => {
                                                    files.push(item.relativePath)
                                                })
                                                this.setState({ files: files })
                                            }}
                                            ref={this.RequestImageRef}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                }
                action={
                    <Button
                        color="primary"
                        onClick={() => this._addRequest()}
                        disabled={isProcessing}
                    >
                        Lưu
                    </Button>
                }
                allowCancel={true}
            />
        );
    }
}

export default Index;
