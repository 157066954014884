import React from "react";
import Modal from '../../../common/modal'
import Button from "@material-ui/core/Button";
import TextInput from '../../../common/text-input'
import DatetimePicker from '../../../common/datetime-picker'
import SelectCampaigns from '../../../common/select-campaign'
import { post } from '../../../../api'
import { notEmpty, justNumber, isValid } from "../../../../utils/validators";
import { showSuccess, showError } from "../../../../utils/app";
import {  getDate } from "../../../../utils/common";




class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            paymentAmount: null,
            paymentDate: null,
            budget: null,
            budgetCurrency: null,
            notes: null,
            selectedCampaign: null
        }
        this.validationData = {
            name: [notEmpty],
            budget: [justNumber, notEmpty],
            paymentAmount: [justNumber]
        };
    }


    _onAddFanpage = () => {
        const {
            id,
            paymentAmount,
            paymentDate,
            budget,
            selectedCampaign,
            notes
        } = this.state;
        const {
            onAddSucceed,
        } = this.props
        if (selectedCampaign) {
            let param = {
                fanpageId: id,
                campaignId: selectedCampaign.value,
                paymentAmount: paymentAmount,
                paymentDate: paymentDate,
                notes: notes,
                budget: budget,
                budgetCurrency: ""
            }
            if (isValid(this.validationData))
                post("campaign-fanpages", param, () => {
                    if (onAddSucceed) onAddSucceed()
                    this.setState({
                        id: null,
                        paymentAmount: null,
                        paymentDate: null,
                        budget: null,
                        budgetCurrency: null,
                        notes: null,
                        selectedCampaign: null
                    })
                    showSuccess("Thêm fanpapge thành công")
                })
            else {
                showError("Dữ liệu không hợp lệ, vui lòng kiểm tra lại")
            }
        }
        else {
            showError("Dữ liệu không hợp lệ, vui lòng kiểm tra lại")
        }
    }

    componentWillReceiveProps(props) {
        let { id } = props
        if (!id) return
        this.setState({
            id: id,
            paymentDate: getDate()
        })
    }
    componentDidMount() {
        let { id } = this.props
        if (!id) return
        this.setState({
            id: id,
            paymentDate: getDate()
        })
    }
    render() {
        let {
            id,
            notes,
            paymentDate,
            budget,
            paymentAmount,
            selectedCampaign
        } = this.state
        let {
            open,
            onClose
        } = this.props
        return (
            <Modal
                className="add-new-options"
                open={open}
                onClose={onClose}
                fullWidth={true}
                header={
                    <div>
                        <span>THÊM CAMPAIGN</span>
                    </div>
                }
                content={
                    <table className="table list-item border-bottom">
                        <tbody>
                            <tr>
                                <td className="coin text-left width100">
                                    <span>Campaign</span>
                                </td>
                                <td className="coin text-left width300">
                                    <SelectCampaigns id={id}
                                        onChange={(value) => this.setState({ selectedCampaign: value })}
                                        value={selectedCampaign}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left">
                                    <span>Kinh phí</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="description"
                                        value={budget}
                                        label={"Kinh phí"}
                                        name={"budget"}
                                        placeholder={"Kinh phí"}
                                        onChange={e =>
                                            this.setState({ budget: e.target.value })
                                        }
                                        displayName={"Kinh phí"}
                                        validations={this.validationData.budget}
                                        edit={true}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td className="coin text-left">
                                    <span>Đã sử dụng</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="description"
                                        value={paymentAmount}
                                        label={"Chi phí"}
                                        name={"paymentAmount"}
                                        placeholder={"Đã sử dụng"}
                                        onChange={e =>
                                            this.setState({ paymentAmount: e.target.value })
                                        }
                                        displayName={"Chi phí"}
                                        validations={this.validationData.paymentAmount}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left">
                                    <span>Ngày thanh toán</span>
                                </td>
                                <td className="coin text-left width300">
                                    <DatetimePicker
                                        placeholder={"Ngày thanh toán"}
                                        onChange={value => this.setState({
                                            paymentDate: value
                                        })
                                        }
                                        value={paymentDate}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left">
                                    <span>Ghi chú</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="description"
                                        value={notes}
                                        label={"Ghi chú"}
                                        name={"notes"}
                                        placeholder={"Ghi chú"}
                                        onChange={e =>
                                            this.setState({ notes: e.target.value })
                                        }
                                        displayName={"Ghi chú"}
                                        multilines={true}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                action={
                    <Button
                        color="primary"
                        onClick={this._onAddFanpage}
                    >
                        Thêm
                    </Button>
                }
                allowCancel={true}
            />
        )
    }
}


export default Index;

