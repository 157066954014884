import React from "react";
import { Button } from '@material-ui/core'
import Modal from '../common/modal'
import DateTimePicker from '../common/datetime-picker'
import TextInput from '../common/text-input'
import { notEmpty, emailAddress, justNumber } from '../../utils/validators'
import { PaymentStatus } from '../../constants/constants'
import Select from '../common/select'


import { objToArray } from "../../utils/common";
import { get, put } from "../../api";
import { showSuccess } from "../../utils/app";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isListChange: false,
            date: null,
            dateErrors: null,
            fullName: "",
            email: "",
            subject: "",
            amount: 0,
            usdPrice: 0,
            status: null,
            notes: ""
        };
        this.validationData = {
            fullName: [notEmpty],
            email: [emailAddress],
            amount: [justNumber],
            usdPrice: [justNumber]
        };
    }

    _reloadList() {
        this.setState({
            isListChange: !this.state.isListChange
        })
    }
    _handleUpdate() {
        let {
            date,
            fullName,
            email,
            subject,
            amount,
            usdPrice,
            notes,
            status
        } = this.state
        let param = {
            date: date,
            fullName: fullName,
            email: email,
            subject: subject,
            amount: amount,
            usdPrice: usdPrice,
            notes: notes,
            status: status.value,
            id: this.props.paymentId
        }
        put("Payenpayments", param, () => {
            showSuccess("Cập nhật thông tin thành công.")
            this.props.onUpdateSuccess()
        })
    }

    _setDefaultValue() {
        let {
            paymentId
        } = this.props
        if (!paymentId) return
        get("Payenpayments/" + paymentId, result => {
            this.setState({
                date: result.date ? result.date : null,
                fullName: result.fullName ? result.fullName : "",
                email: result.email ? result.email : "",
                subject: result.subject ? result.subject : "",
                amount: result.amount ? result.amount : 0,
                usdPrice: result.usdPrice ? result.usdPrice : 0,
                notes: result.notes ? result.notes : "",
                status: PaymentStatus[result.status ? result.status : "None"],
            })
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.open !== nextProps.open)
            this._setDefaultValue()
    }

    componentDidMount() {
        this._setDefaultValue()
    }

    render() {
        let {
            open,
            edit
        } = this.props
        let {
            date,
            dateErrors,
            fullName,
            email,
            subject,
            amount,
            usdPrice,
            status,
            notes
        } = this.state
        const validationData = this.validationData
        let PaymentStatusOption = objToArray(PaymentStatus)
        PaymentStatusOption = PaymentStatusOption.filter(e => e.value !== "None")
        return (
            <Modal
                className="payment-detail update"
                open={open}
                onClose={() => this.props.onClose()}
                fullWidth={true}
                fullScreen={false}
                header={
                    <div>
                        <span>{edit ? "CẬP NHẬT THÔNG TIN THANH TOÁN" : "THÔNG TIN THANH TOÁN"}</span>
                    </div>
                }
                content={
                    <table className="table border-bottom">
                        <tbody>
                            <tr>
                                <td className="coin text-left width100 ">
                                    <span>Ngày thanh toán</span>
                                </td>
                                <td className="coin text-left width300">
                                    {
                                        <DateTimePicker
                                            className="date"
                                            type="date"
                                            placeholder={"Ngày thanh toán"}
                                            onChange={value => this.setState({ date: value, dateErrors: null })}
                                            value={date}
                                            edit={edit}
                                            errors={dateErrors}
                                        />
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left width100 ">
                                    <span>Tên khách hàng</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="fullName"
                                        value={fullName}
                                        name={"fullName"}
                                        placeholder={"Tên khách hàng"}
                                        displayName={"Tên khách hàng"}
                                        onChange={e => {
                                            this.setState({ fullName: e.target.value });
                                        }}
                                        edit={edit}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left width100 ">
                                    <span>Email khách hàng</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="email"
                                        value={email}
                                        name={"email"}
                                        placeholder={"Email"}
                                        displayName={"Email"}
                                        onChange={e => {
                                            this.setState({ email: e.target.value });
                                        }}
                                        validations={validationData.email}
                                        edit={edit}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left width100 ">
                                    <span>Subject</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="subject"
                                        value={subject}
                                        name={"subject"}
                                        placeholder={"Subject"}
                                        displayName={"Subject"}
                                        onChange={e => {
                                            this.setState({ subject: e.target.value });
                                        }}
                                        // validations={validationData.email}
                                        edit={edit}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left width100 ">
                                    <span>Chi phí</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="amount"
                                        value={amount.toFixed(2)}
                                        name={"amount"}
                                        placeholder={"Chi phí"}
                                        displayName={"Chi phí"}
                                        onChange={e => {
                                            this.setState({ amount: e.target.value });
                                        }}
                                        validations={validationData.amount}
                                        edit={edit}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left width100 ">
                                    <span>Tỷ giá USD</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="usdPrice"
                                        value={usdPrice}
                                        name={"usdPrice"}
                                        placeholder={"Tỷ giá USD"}
                                        displayName={"Tỷ giá USD"}
                                        onChange={e => {
                                            this.setState({ usdPrice: e.target.value });
                                        }}
                                        validations={validationData.usdPrice}
                                        edit={edit}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left width100">
                                    <span>Tình trạng thanh toán</span>
                                </td>
                                <td className="coin text-left width300">
                                    <Select
                                        options={PaymentStatusOption}
                                        edit={edit}
                                        onChange={(value) => this.setState({ status: value ? value : null })}
                                        value={status}
                                        allowNull={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left width100 ">
                                    <span>Ghi chú</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="notes"
                                        value={notes}
                                        name={"notes"}
                                        placeholder={"Ghi chú"}
                                        displayName={"Ghi chú"}
                                        onChange={e => {
                                            this.setState({ notes: e.target.value });
                                        }}
                                        multiline={true}
                                        edit={edit}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                }
                action={
                    edit && <Button
                        color="primary"
                        onClick={() => this._handleUpdate()}
                    >
                        Lưu
                    </Button>
                }
                allowCancel={edit}
            />
        )
    }
}
export default Index;