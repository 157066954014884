import React from "react";
import {  _delete } from "../../../../api";
import Button from '@material-ui/core/Button'
import { TableSizeDefault } from "../../../../constants/constants";
import { showSuccess } from "../../../../utils/app";
import AddQuotation from './add'
import ListQuotation from './list'
class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            total: 0,
            skip: 0,
            take: TableSizeDefault.value,
            page: 1,
            fanpages: null,
            openAddQuotation: false,
            openEditQuotation: false,
            openDeleteQuotation: false,
            currentOption: undefined
        };
        this.getList = React.createRef()
    }

    _getList() {
        this.getList.current._getList()
    }
    handleTableChange(params) {
        this.setState({
            take: params.take
        }, () => {
            this._getList()
        })
    }
    _closeAddQuotation = () => {
        this.setState({
            openAddQuotation: false
        })
    }
    componentWillReceiveProps(props) {
        let id = props.id
        if (id !== null && id !== undefined) {
            this.setState({
                id
            },
                () => this._getList()
            )
        }
    }

    componentDidMount() {
        let id = this.props.id
        if (id !== null && id !== undefined) {
            this.setState({
                id
            },
                () => this._getList()
            )
        }
    }
    _onDeleteOption = () => {
        if (!this.state.currentOption) return;
        let param = {
            ids: [this.state.currentOption.id]
        }
        _delete("options/" + this.state.pageType, param,
            () => {
                this._getList()
                showSuccess("Xóa option thành công")
            })
    }
    _closeEditOption = () => {
        this.setState({
            openEditOption: false
        })
    }
    _closeDeleteOption = () => {
        this.setState({
            openDeleteOption: false
        })
    }
    render() {
        return (
            <div className="box-body product-list campaign-fanpage">
                <div className="box-body product-list">
                    <div className="filters-form mb15 balance-search date-time">
                        <table className="table list-item border-none">
                            <tbody>
                                <tr>
                                    <td className="text-right">
                                        <div className="create-bt">
                                            <Button
                                                color="primary"
                                                className="custom-button"
                                                onClick={() =>
                                                    this.setState({
                                                        openAddQuotation: true
                                                    })
                                                }
                                            >
                                                Thêm báo giá
                                                </Button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <ListQuotation id={this.state.id}
                    ref={this.getList} />
                <AddQuotation id={this.state.id}
                    open={this.state.openAddQuotation}
                    onClose={this._closeAddQuotation}
                    onAddSucceed={this._getList.bind(this)} />
                {/* <Update open={openEditOption}
                    onClose={this._closeEditOption}
                    id={this.state.currentOption !== undefined ? this.state.currentOption.id : ""}
                    pageType={this.state.currentOption !== undefined ? this.state.currentOption.pageType : ""}
                    _getList={this._getList.bind(this)}
                /> */}
            </div>
        )
    }
}

export default Index;
