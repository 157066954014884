import React from "react";
import { Button, IconButton, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import AddNew from "./add-new";
import List from "./list";
import { isInRole, getCurrentUser } from "../../auth";
import TextInput from "../common/text-input";

import {
    MetubRoles,
    TypesPartner,
    VerifyStatus,
    VerifyStatusForSearch,
} from "../../constants/constants";
import { objToArray } from "../../utils/common";
import Select from "../common/select";
import UserSelectList from "../common/user-select-list";
import { PARTNER_FILTER } from "../../constants/localStorageKeys";
import {
    FilterList as FilterListIcon,
    Contacts as ContactsIcon,
} from "@material-ui/icons";

const styles = {
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 400,
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
};

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAddNewPartner: false,
            openEditPartner: false,
            currentPartner: {},
            input: "",
            showMoreOption: false,
            optionTypesPartner: objToArray(TypesPartner),
            optionVerifyStatus: objToArray(VerifyStatusForSearch),
            verifyStatusOptions: objToArray(VerifyStatusForSearch),
            optionSearchDetail: {
                searchKey: "",
                selectedTypePartner: null,
                partnerManagerId: "",
                selectedVerifyStatus: null,
            },
            autoPlay: false,
        };
        this.List = React.createRef();
    }

    _handleAddPartner() {
        this.setState({
            openAddNewPartner: false,
        });
        this.List.current._handleReload();
    }

    _exportList() {
        this.List.current._handleExportList();
    }

    _handleSetFilter() {
        let filter = JSON.parse(window.localStorage.getItem(PARTNER_FILTER));
        filter = { ...this.state };
        window.localStorage.setItem(PARTNER_FILTER, JSON.stringify(filter));
    }

    componentWillMount() {
        let filter = JSON.parse(window.localStorage.getItem(PARTNER_FILTER));
        if (filter)
            this.setState({
                ...filter,
                openAddNewPartner: false,
                showMoreOption: false,
                isResetPage: false,
            });
    }

    render() {
        let {
            openAddNewPartner,
            showMoreOption,
            optionTypesPartner,
            optionVerifyStatus,
            verifyStatusOptions,
            optionSearchDetail,
            input,
            isResetPage,
            autoPlay,
        } = this.state;
        const currentUser = getCurrentUser();
        if (!isInRole(MetubRoles.VIEW_PARTNER)) return;
        return (
            <div className="partner-management">
                <div className="">
                    <Typography
                        variant="h6"
                        gutterBottom
                        align="center"
                        className="box-title"
                    >
                        <div>
                            Quản lí đối tác
                            <p>Quản lí thông tin đối tác đang có trong hệ thống.</p>
                        </div>
                        {isInRole(MetubRoles.CREATE_PARTNER) && (
                            <Button
                                color="primary"
                                onClick={() => this.setState({ openAddNewPartner: true })}
                                className="custom-button"
                            >
                                Tạo mới
                            </Button>
                        )}
                    </Typography>
                    <div className="admin-box">
                        <span>
                            <FilterListIcon />
                            Bộ lọc dữ liệu
                        </span>
                        <div className="input-search">
                            <TextInput
                                name="searchKey"
                                displayName="Thông tin tìm kiếm"
                                placeholder="Tìm kiếm theo tên đối tác"
                                type="text"
                                value={input}
                                onChange={(e) => this.setState({ input: e.target.value })}
                                edit={true}
                                outLine
                                height={35}
                            />
                            <span
                                className="search-bt"
                                onClick={() =>
                                    this.setState(
                                        {
                                            optionSearchDetail: {
                                                ...optionSearchDetail,
                                                searchKey: input,
                                            },
                                            isResetPage: true,
                                        },
                                        () => this._handleSetFilter()
                                    )
                                }
                            >
                                Tìm nhanh
                            </span>
                        </div>
                        <div className="search-option">
                            <table className="table border-none ">
                                <tbody>
                                    <tr>
                                        <td className="">
                                            <label className="pb05 text-left">
                                                Lọc theo kiểu đối tác
                                            </label>
                                            <Select
                                                options={optionTypesPartner}
                                                onChange={(value) =>
                                                    this.setState(
                                                        {
                                                            optionSearchDetail: {
                                                                ...this.state.optionSearchDetail,
                                                                selectedTypePartner: value,
                                                            },
                                                            isResetPage: true,
                                                        },
                                                        () => this._handleSetFilter()
                                                    )
                                                }
                                                value={optionSearchDetail.selectedTypePartner}
                                                edit={true}
                                                allowNull={true}
                                                outLine
                                            />
                                        </td>
                                        <td className="selectOptionPM">
                                            <label className="pb05 text-left">
                                                Lọc theo người quản lý
                                            </label>
                                            <UserSelectList
                                                edit={true}
                                                outLine
                                                isHideUserSelected={true}
                                                value={optionSearchDetail.partnerManagerId}
                                                onChange={(value) =>
                                                    this.setState(
                                                        {
                                                            optionSearchDetail: {
                                                                ...this.state.optionSearchDetail,
                                                                partnerManagerId: value ? value.id : null,
                                                            },
                                                            isResetPage: true,
                                                        },
                                                        () => this._handleSetFilter()
                                                    )
                                                }
                                            />
                                        </td>
                                        {currentUser.roleCode === "DATA_MANAGER" ||
                                            currentUser.roleCode === "ADMIN" ||
                                            currentUser.roleCode === "BOD" ||
                                            currentUser.roleCode === "LEADER_PARTNER_MANAGER" ||
                                            currentUser.roleCode === "PARTNER_MANAGER" ||
                                            currentUser.roleCode === "ADMIN_OFFICER" ||
                                            currentUser.roleCode === "ACCOUNTANT" ||
                                            currentUser.roleCode === "CHIEF_ACCOUNTANT" ? (
                                            <td>
                                                <label className="pb05 text-left">
                                                    Lọc theo trạng thái
                                                </label>
                                                <Select
                                                    options={verifyStatusOptions}
                                                    onChange={(value) =>
                                                        this.setState(
                                                            {
                                                                optionSearchDetail: {
                                                                    ...this.state.optionSearchDetail,
                                                                    selectedVerifyStatus: value,
                                                                },
                                                                isResetPage: true,
                                                            },
                                                            () => this._handleSetFilter()
                                                        )
                                                    }
                                                    value={optionSearchDetail.selectedVerifyStatus}
                                                    edit={true}
                                                    allowNull={false}
                                                    outLine
                                                />
                                            </td>
                                        ) : (
                                            <td></td>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <table className={"table border-none"}>
                        <tbody>
                            <tr>
                                <td className="text-right"></td>
                            </tr>
                        </tbody>
                    </table>
                    {/* search detail */}
                    <div
                        className={"more-option-box" + (showMoreOption ? " more" : " less")}
                    ></div>
                </div>

                {isInRole(MetubRoles.VIEW_PARTNER) && (
                    <div className="admin-box">
                        <span>
                            <ContactsIcon />
                            Danh sách đối tác
                            <Button color={"primary"} onClick={() => this._exportList()}>
                                Export
                            </Button>
                        </span>
                        <List
                            ref={this.List}
                            onEditItem={(item) => this._handleEditPartner(item)}
                            searchKey={optionSearchDetail}
                            isResetPage={isResetPage}
                            onUpdated={() => this.setState({ isResetPage: false })}
                        />
                    </div>
                )}
                {isInRole(MetubRoles.CREATE_PARTNER) && (
                    <AddNew
                        open={openAddNewPartner}
                        onClose={() => this.setState({ openAddNewPartner: false })}
                        onAdd={() => this._handleAddPartner()}
                    />
                )}
            </div>
        );
    }
}
Index.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
