import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { get, _delete } from "../../api";
import { formatDate, objToQuery, confirmSubmit } from '../../utils/common'
import { NavLink } from 'react-router-dom'
import DataTable from "../common/datatable";
import { TableSizeDefault } from "../../constants/constants";
import { showSuccess } from "../../utils/app";
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Update from './update'
class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            type: null,
            total: 0,
            skip: 0,
            take: TableSizeDefault.value,
            page: 1,
            campaignFanpages: null,
            openAddFanpageCampaign: false,
            openEditFanpageCampaign: false,
            openDeleteOptionCampaign: false,
            currentOption: undefined
        };
    }

    _getList() {
        let { id, type } = this.state
        let param = {
            skip: 0,
            take: this.state.take
        }
        var querry = objToQuery(param)
        if (type === "fanpage")
            get("fanpages/" + id + "/campaigns" + querry, result => {
                this.setState({
                    campaignFanpages: result && result.items.sort((a, b) => b.id - a.id),
                    total: result && result.total
                });
            });
        else if (type === "campaign") {
            get("campaigns/" + id + "/fanpages" + querry, result => {
                this.setState({
                    campaignFanpages: result && result.items.sort((a, b) => b.id - a.id),
                    total: result && result.total
                });
            });
        }
    }
    handleTableChange(params) {
        this.setState({
            take: params.take
        }, () => {
            this._getList()
        })
    }
    _closeEditFanpageCampaign = () => {
        this.setState({
            openEditFanpageCampaign: false
        })
    }
    componentWillReceiveProps(props) {
        let { id, type } = props
        if (!id && !type) return
        this.setState({
            id: id,
            type: type
        },
            () => this._getList()
        )
    }

    componentDidMount() {
        let { id, type } = this.props
        if (!id && !type) return;
        this.setState({
            id: id,
            type: type
        },
            () => this._getList()
        )
    }
    _onDeleteCampaign = (id) => {
        if (!id) return;
        _delete("campaign-fanpages/" + id, undefined,
            () => {
                this._getList()
                showSuccess("Bỏ campaign thành công")
            })
    }
    render() {
        let {
            campaignFanpages,
            total,
            type,
            openEditFanpageCampaign
        } = this.state;

        return (
            <div className="box-body product-list campaign-fanpage">
                <DataTable
                    data={campaignFanpages}
                    total={total}
                    isPaging={true}
                    renderHeader={() => {
                        return (
                            <thead>
                                <tr>
                                    <th className="text-center">ID</th>
                                    <th className="text-left">{type === "fanpage" ? "Tên Campaign" : "Tên Fanpage"}</th>
                                    <th className="text-left">Kinh phí</th>
                                    <th className="text-left">Đã sử dụng</th>
                                    <th className="text-left">Ngày thanh toán</th>
                                    <th className="text-left">Ngày tạo</th>
                                    <th className="text-left">Ghi chú</th>
                                    <th className="text-center" />
                                    <th className="text-center" />
                                </tr>
                            </thead>
                        );
                    }}
                    renderBody={items => {
                        return (
                            <tbody>
                                {items.map((item, i) => (
                                    <tr key={i}>
                                        <td style={{ textAlign: "center" }}>{item.id}</td>
                                        <td className="text-left"> {type === "fanpage" ?
                                            < NavLink to={"/campaigns/" + item.campaignId}>{item.campaignName}</NavLink>
                                            :
                                            < NavLink to={"/fanpages/" + item.fanpageId}>{item.fanpageName}</NavLink>
                                        }
                                        </td>
                                        <td className="text-left">{item.budget}</td>
                                        <td className="text-left">{item.paymentAmount}</td>
                                        <td className="text-left">{formatDate(item.paymentDate)}</td>
                                        <td className="text-left">{formatDate(item.createDate)}</td>
                                        <td className="text-left">{item.notes}</td>
                                        <td>
                                            <Tooltip title="Cập nhật" placement="top">
                                                <IconButton onClick={() => {
                                                    this.setState({
                                                        openEditFanpageCampaign: true,
                                                        currentOption: item
                                                    })
                                                }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </td>

                                        <td>
                                            <Tooltip title="Loại bỏ" placement="top">
                                                <IconButton>
                                                    <DeleteIcon onClick={() => {
                                                        confirmSubmit("Bỏ campaign", "Bạn có chắc chắn muốn bỏ",
                                                            () => {
                                                                this._onDeleteCampaign(item.id)
                                                            })
                                                    }} />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        );
                    }
                    }
                    onChange={params => this.handleTableChange(params)}
                />
                <Update open={openEditFanpageCampaign}
                    onClose={this._closeEditFanpageCampaign}
                    id={this.state.currentOption !== undefined ? this.state.currentOption.id : ""}
                    pageType={this.state.currentOption !== undefined ? this.state.currentOption.pageType : ""}
                    onUpdateSucceed={this._getList.bind(this)}
                />
            </div >
        )
    }
}

export default Index;
