import React from "react";
import Button from "@material-ui/core/Button";
import { get, put } from "../../../api";
import { objToArray } from '../../../utils/common'
import AddFanpage from './add'
import update from 'react-addons-update';
import { notEmpty, justNumber } from "../../../utils/validators";
import CardHeader from '@material-ui/core/CardHeader';
import TextInput from '../../common/text-input'
import Select from '../../common/select'
import ListCampaignsFanpages from '../../campaign-fanpage/list'
import DatetimePicker from '../../common/datetime-picker'
import { Card, CardContent } from "@material-ui/core";
import { showSuccess } from "../../../utils/app";
import { CampaignStatus } from "../../../constants/constants";

const validationData = {
    campaignName: [notEmpty],
    customerName: [notEmpty],
    budget: [notEmpty, justNumber]
};

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign: null,
            isEdit: false,
            selectedStatus: null,
            fromDate: null,
            toDate: null,
            openAddFanpage: false
        };
    }
    _getDetails = () => {
        let id = this.props.match.params.id
        get("campaigns/" + id,
            (res) => {
                this.setState({
                    campaign: res,
                    fromDate: res.fromDate,
                    toDate: res.toDate
                },
                    () => {
                        this._onFilterStatusToValue(res.status)
                    })
            }
        )
    }
    _onAddSucceed = () => {
        this._getDetails()
    }
    _onFilterStatusToValue = (status) => {
        let campaignStatusList = objToArray(CampaignStatus)
        for (var i = 0; i <= campaignStatusList.length; i++) {
            if (campaignStatusList[i].value === status) {
                this.setState({
                    selectedStatus: campaignStatusList[i]
                })
                return
            }
        }
    }
    _onUpdateDetails = () => {
        let param = {
            name: this.state.campaign.name,
            customerName: this.state.campaign.customerName,
            fromDate: this.state.campaign.fromDate,
            toDate: this.state.campaign.toDate,
            budget: this.state.campaign.budget,
            budgetCurrency: this.state.campaign.budgetCurrency,
            status: this.state.selectedStatus.value,
            id: this.state.campaign.id
        }
        put("campaigns", param, () => {
            this._getDetails()
            this.setState({
                isEdit: false
            })
            showSuccess("Cập nhật thành công")
        })
    }
    componentWillReceiveProps() {

    }

    componentDidMount() {
        this._getDetails()
    }
    _closeAddFanpage = () => {
        this.setState({
            openAddFanpage: false
        })
    }
    render() {
        let {
            campaign,
            isEdit,
            selectedStatus,
            fromDate,
            toDate
        } = this.state;
        if (!campaign || !selectedStatus) return null
        return (
            <div className="box manage-user">
                <Card className="title-campaign-detail">
                    <CardHeader
                        subheader={
                            <div className="campaign-name">
                                <h3><span>Campaign:</span>
                                    <TextInput
                                        className="customer-name"
                                        value={campaign.name}
                                        label={""}
                                        name={"campaignName"}
                                        placeholder={"Tên campaign"}
                                        onChange={e => this.setState({
                                            campaign: update(campaign, {
                                                name: {
                                                    $set: e.target.value
                                                }
                                            })
                                        })
                                        }
                                        validationData={validationData.campaignName}
                                        displayName={"Tên campaign"}
                                        edit={isEdit}
                                        multiline={false}
                                    />
                                </h3>
                            </div>
                        }
                        action={
                            <div>
                                {
                                    (isEdit === false) ?
                                        <div className="create-bt">
                                            <Button
                                                color="primary"
                                                className="custom-button"
                                                onClick={() =>
                                                    this.setState({
                                                        isEdit: !this.state.isEdit
                                                    })
                                                }
                                            >
                                                Chỉnh sửa
                                            </Button>
                                        </div>
                                        :
                                        <div className="create-bt">
                                            <Button
                                                color="primary"
                                                className="custom-button"
                                                onClick={() =>
                                                    this._onUpdateDetails()
                                                }
                                            >
                                                Lưu
                                            </Button>
                                            <Button
                                                color="default"
                                                className="custom-button"
                                                onClick={() =>
                                                    this.setState({
                                                        isEdit: false
                                                    },
                                                        () => {
                                                            this._getDetails()
                                                        })
                                                }
                                            >
                                                Hủy
                                            </Button>
                                        </div>
                                }
                            </div>
                        }
                    />
                    <CardContent >
                        <table className="table list-item border-none">
                            <tbody>
                                <tr>
                                    <td>
                                        <table className="tb-child">
                                            <tbody>
                                                <tr>
                                                    <td className="td left"><span>ID</span></td>
                                                    <td>
                                                        <TextInput
                                                            className="customer-name"
                                                            value={campaign.id}
                                                            label={""}
                                                            name={"description"}
                                                            placeholder={"ID"}
                                                            validationData={""}
                                                            displayName={"ID"}
                                                            edit={false}
                                                            multiline={false}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="td left"><span>Tên khách hàng</span></td>
                                                    <td>
                                                        <TextInput
                                                            className="customer-name"
                                                            value={campaign.customerName}
                                                            label={""}
                                                            name={"customerName"}
                                                            placeholder={"Mô tả"}
                                                            onChange={e => this.setState({
                                                                campaign: update(campaign, {
                                                                    customerName: {
                                                                        $set: e.target.value
                                                                    }
                                                                })
                                                            })
                                                            }
                                                            validationData={validationData.customerName}
                                                            displayName={"Mô tả"}
                                                            edit={isEdit}
                                                            multiline={false}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="td left"><span>Kinh phí</span></td>
                                                    <td>
                                                        <TextInput
                                                            className="customer-name"
                                                            value={campaign.budget}
                                                            label={""}
                                                            name={"budget"}
                                                            placeholder={"Mô tả"}
                                                            onChange={e => this.setState({
                                                                campaign: update(campaign, {
                                                                    budget: {
                                                                        $set: e.target.value
                                                                    }
                                                                })
                                                            })
                                                            }
                                                            validationData={validationData.budget}
                                                            displayName={"Mô tả"}
                                                            edit={isEdit}
                                                            multiline={false}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>
                                        <table className="tb-child">
                                            <tbody>
                                                <tr>
                                                    <td className="td left"><span>Trạng thái</span></td>
                                                    <td>
                                                        <Select
                                                            className="select-status"
                                                            options={objToArray(CampaignStatus)}
                                                            onChange={(value) => this.setState({
                                                                selectedStatus: value
                                                            })}
                                                            value={selectedStatus}
                                                            edit={isEdit}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="td left"><span>Ngày tạo</span></td>
                                                    <td>
                                                        <DatetimePicker
                                                            className="cooperatedDate"
                                                            placeholder={"Ngày tạo"}
                                                            onChange={value => this.setState({
                                                                fromDate: value
                                                            })
                                                            }
                                                            value={fromDate}
                                                            edit={isEdit}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="td left"><span>Ngày hết hạn</span></td>
                                                    <td>
                                                        <DatetimePicker
                                                            className="cooperatedDate"
                                                            placeholder={"Ngày tạo"}
                                                            onChange={value => this.setState({
                                                                toDate: value
                                                            })
                                                            }
                                                            value={toDate}
                                                            edit={isEdit}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </CardContent>
                </Card>
                {/* <ListFanpage id={campaign.id} /> */}
                <div className="box-body product-list campaign-fanpage">
                    <div className="box-body product-list">
                        <div className="filters-form mb15 balance-search date-time">
                            <table className="table list-item border-none">
                                <tbody>
                                    <tr>
                                        <td><h3 className="box-title">Danh sách fanpage</h3></td>
                                        <td className="text-right">
                                            <div className="create-bt">
                                                <Button
                                                    color="primary"
                                                    className="custom-button"
                                                    onClick={() =>
                                                        this.setState({
                                                            openAddFanpage: true,
                                                            optionName: "",
                                                            description: ""
                                                        })
                                                    }
                                                >
                                                    Thêm Fanpage
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <AddFanpage id={campaign.id}
                    open={this.state.openAddFanpage}
                    onClose={this._closeAddFanpage}
                    onAddSucceed={this._onAddSucceed} />
                <ListCampaignsFanpages id={campaign.id} type="campaign" />
            </div>
        );
    }
}

export default Index;
