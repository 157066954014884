import React from "react";
import DropZone from 'react-drop-zone'
import { postFormData, put } from '../../../api'
import { BASE_API as domain } from '../../../constants/appSettings'
import TextInput from '../../common/text-input'
import { notEmpty, isValid } from '../../../utils/validators'
import defaultAva from '../../../assets/images/avatar.png'
import { showSuccess, showError } from "../../../utils/app";
import { isInRole } from "../../../auth";
import { MetubRoles, UserBranch } from "../../../constants/constants";
import Select from '../../common/select'
import { objToArray } from "../../../utils/common";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultAvatar: "",
            fullName: '',
            branchSelected: UserBranch.HCM
        };
        this.validationData = {
            fullName: [notEmpty]
        };
    }

    _onUpdateDefaultAva = () => {
        this.setState({
            avatar: this.state.defaultAvatar
        })
    }
    _onUpdateUserInfo = () => {
        let {
            id,
            fullName,
            branchSelected
        } = this.state
        let param = {
            id: id,
            fullName: fullName,
            branch: branchSelected ? branchSelected.value : "None"
        };
        if (isValid(this.validationData))
            put("users/info", param,
                () => {
                    showSuccess("Cập nhật thông tin nhân viên thành công")
                    let {
                        onUpdateSuccess,
                        onClose
                    } = this.props
                    if (onUpdateSuccess) onUpdateSuccess()
                    if (onClose) onClose()
                },
                () => {
                    showError("Server đang bảo trì, vui lòng thử lại sau")
                }
            )
        else {
            showError("Vui lòng kiểm tra thông tin vừa nhập")
        }
    }

    componentWillMount() {
        const { fullName, id, branch } = this.props
        this.setState({
            id: id,
            fullName: fullName,
            branchSelected: UserBranch[branch] ? UserBranch[branch] : UserBranch.HCM
        })
    }

    render() {
        let {
            avatar,
            fullName,
            branchSelected
        } = this.state;
        let styleAva = {
            width: 80,
            height: 80,
            backgroundImage: avatar && (avatar !== null) ? "url(" + domain + this.state.avatar + ")" : "url(" + defaultAva + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }
        return (
            <div style={{ textAlign: "center" }}>
                <div className="profile-picture">
                    <div className="avatar" style={styleAva}>
                    </div>
                </div>
                <div className="user-box">
                    <label>Họ tên</label>
                    <TextInput
                        className="fullName"
                        value={fullName}
                        label={"Họ tên"}
                        name={"fullName"}
                        placeholder={"Họ tên"}
                        onChange={e =>
                            this.setState({
                                fullName: e.target.value
                            })
                        }
                        displayName={"Họ tên"}
                        validations={this.validationData.fullName}
                        edit={isInRole(MetubRoles.UPDATE_USER) ? true : false}
                    />
                    <label className="mt10">Chi nhánh</label>
                    <Select
                        options={objToArray(UserBranch).filter(x => x.value !== "None")}
                        onChange={(value) => this.setState({ branchSelected: value })}
                        value={branchSelected}
                        edit={isInRole(MetubRoles.UPDATE_USER) ? true : false}
                    />
                </div>
            </div >
        )
    }
}
export default Index