import {
    GET_NOTIFICATION
} from '../actions/app'

const initialState = {
    notifyUnreadSumary: [],
};

export default (state = initialState, action) => {
    let {
        notifyUnreadSumary
    } = state

    switch (action.type) {

        case GET_NOTIFICATION: {
            notifyUnreadSumary = action.data
            return Object.assign({}, state, {
                notifyUnreadSumary
            });
        }
        default:
            return state;
    }
};