
import {
    get
} from '../api'

export const ADD_PROCESS = "app@ADD_PROCESS";
export const REMOVE_PROCESS = "app@REMOVE_PROCESS";
export const GET_NOTIFICATION = "app@GET_NOTIFICATION"


export const getNotifyUnread = () => {
    return dispatch => {
        get("notifications/get-total-unread", (result) => {
            dispatch({
                type: GET_NOTIFICATION,
                data: result
            })
        }, null, true)
    }
}

