import React from "react";
import { NavLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import DataTable from "../common/datatable";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import { get } from "../../api";
import { objToQuery } from "../../utils/common";
import {
    TableSizeDefault,
    PartnerType,
    MetubRoles,
    VerifyStatus,
    ContractStatus,
} from "../../constants/constants";
import { BASE_API } from "../../constants/appSettings";
import { isInRole, getCurrentUser } from "../../auth";
import moment from 'moment'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partners: [],
            currentPartner: {},
            total: null,
            params: {
                skip: 0,
                take: TableSizeDefault.value,
            },
            search: "",
            searchQueryHistory: "",
            isProccessing: false,
            canReload: false,
        };
    }

    _handleReload() {
        let { params } = this.state;
        this.setState(
            {
                canReload: true,
            },
            () => {
                this._handleGetPartners(params);
            }
        );
    }

    _handleGetPartners(params) {
        let { isProccessing, canReload } = this.state;
        let { onUpdated } = this.props;
        if (isProccessing == false) {
            const currentUser = getCurrentUser();
            let currentPage = params.page;
            if (!currentPage) {
                let filter = JSON.parse(window.localStorage.getItem("PARTNER_PAGE"));
                if (filter) {
                    currentPage = filter.currentPage;
                }
            }

            if (params.isResetPage == true) currentPage = 1;
            this.setState(
                {
                    currentPage: currentPage,
                },
                () => {
                    window.localStorage.setItem(
                        "PARTNER_PAGE",
                        JSON.stringify({ currentPage: currentPage })
                    );
                    let searchKey = this.props.searchKey;
                    let param = {
                        search: searchKey && searchKey.searchKey ? searchKey.searchKey : "",
                        // verifyStatus:
                        //   currentUser.roleCode === "OPTIMIZE"
                        //     ? "Pending"
                        //     : currentUser.roleCode === "DATA_MANAGER" ||
                        //       currentUser.roleCode === "ADMIN" ||
                        //       currentUser.roleCode === "BOD" ||
                        //       currentUser.roleCode === "LEADER_PARTNER_MANAGER" ||
                        //       currentUser.roleCode === "PARTNER_MANAGER" ||
                        //       currentUser.roleCode === "ADMIN_OFFICER" ||
                        //       currentUser.roleCode === "ACCOUNTANT" ||
                        //       currentUser.roleCode === "CHIEF_ACCOUNTANT" ||
                        //       currentUser.roleCode === "OPTIMIZE_MANAGER" ||
                        //       currentUser.roleCode === "CUSTOMER_SP" ||
                        //       currentUser.roleCode === "NORMAL_USER"
                        //     ? "None"
                        //     : "Accepted",
                        Type:
                            searchKey && searchKey.selectedTypePartner
                                ? searchKey.selectedTypePartner.value
                                : "None",
                        partnerManagerId:
                            searchKey && searchKey.partnerManagerId
                                ? searchKey.partnerManagerId
                                : "",
                        identityNumber: "",
                        taxCode: "",
                        skip: (currentPage - 1 >= 0 ? currentPage - 1 : 0) * params.take,
                        take: params.take,
                        // SortVerifyStatus:
                        //   searchKey && searchKey.selectedVerifyStatus
                        //     ? searchKey.selectedVerifyStatus.value
                        //     : "None",
                        verifyStatus:
                            searchKey &&
                                searchKey.selectedVerifyStatus &&
                                searchKey.selectedVerifyStatus.label != "-/-"
                                ? searchKey.selectedVerifyStatus.label
                                : "None",
                    };

                    var query = objToQuery(param);

                    if (query == this.state.searchQueryHistory && canReload == false)
                        return;

                    if (currentUser.roleCode == "LEGAL_EXECUTIVE") {
                        this.setState({
                            isProccessing: true,
                        });
                        get(
                            "partners/Partner-To-Contract" + query,
                            (result) => {
                                this.setState({
                                    partners: result.items,
                                    total: result.total,
                                    searchQueryHistory: query,
                                    isProccessing: false,
                                    canReload: false,
                                });
                                if (onUpdated) onUpdated();
                            },
                            () => {
                                this.setState({
                                    isProccessing: false,
                                    canReload: false,
                                    searchQueryHistory: query,
                                });
                                if (onUpdated) onUpdated();
                            }
                        );
                    } else {
                        if (query == this.state.searchQueryHistory && canReload == false)
                            return;
                        this.setState({
                            isProccessing: true,
                        });
                        get(
                            "partners" + query,
                            (result) => {
                                this.setState({
                                    partners: result.items,
                                    total: result.total,
                                    isProccessing: false,
                                    searchQueryHistory: query,
                                    canReload: false,
                                });
                                if (onUpdated) onUpdated();
                            },
                            () => {
                                this.setState({
                                    isProccessing: false,
                                    canReload: false,
                                });
                                if (onUpdated) onUpdated();
                            }
                        );
                    }
                }
            );
        }
    }

    _handleExportList() {
        let { params, currentPage } = this.state;
        this.setState(
            {
                params: params,
            },
            () => {
                // let param = {
                //     search: this.state.params.search && this.state.params.search.searchKey ? this.state.params.search.searchKey : "",
                //     Type: this.state.params.search && this.state.params.search.selectedTypePartner ? this.state.params.search.selectedTypePartner.value : 'None',
                //     partnerManagerId: this.state.params.search && this.state.params.search.partnerManagerId ? this.state.params.search.partnerManagerId : '',
                //     SortVerifyStatus: this.state.params.search && this.state.params.search.selectedVerifyStatus ? this.state.params.search.selectedVerifyStatus.value : "None",
                //     identityNumber: "",
                //     taxCode: "",
                //     skip: params.skip,
                //     take: params.take
                // };
                // var query = objToQuery(param);
                // get("partners/export" + query, result => {
                //     window.open(BASE_API + result.relativePath)
                // });
                const currentUser = getCurrentUser();
                let searchKey = this.props.searchKey;
                let param = {
                    search: searchKey && searchKey.searchKey ? searchKey.searchKey : "",
                    verifyStatus:
                        currentUser.roleCode === "OPTIMIZE"
                            ? "Pending"
                            : currentUser.roleCode === "DATA_MANAGER" ||
                                currentUser.roleCode === "ADMIN" ||
                                currentUser.roleCode === "BOD" ||
                                currentUser.roleCode === "LEADER_PARTNER_MANAGER" ||
                                currentUser.roleCode === "PARTNER_MANAGER" ||
                                currentUser.roleCode === "ADMIN_OFFICER" ||
                                currentUser.roleCode === "ACCOUNTANT" ||
                                currentUser.roleCode === "CHIEF_ACCOUNTANT" ||
                                currentUser.roleCode === "OPTIMIZE_MANAGER" ||
                                currentUser.roleCode === "CUSTOMER_SP" ||
                                currentUser.roleCode === "NORMAL_USER"
                                ? "None"
                                : "Accepted",
                    Type:
                        searchKey && searchKey.selectedTypePartner
                            ? searchKey.selectedTypePartner.value
                            : "None",
                    partnerManagerId:
                        searchKey && searchKey.partnerManagerId
                            ? searchKey.partnerManagerId
                            : "",
                    identityNumber: "",
                    taxCode: "",
                    // skip: (currentPage - 1 >= 0 ? (currentPage - 1) : 0) * params.take,
                    // take: params.take,
                    SortVerifyStatus:
                        searchKey && searchKey.selectedVerifyStatus
                            ? searchKey.selectedVerifyStatus.value
                            : "None",
                };
                var query = objToQuery(param);
                get("partners/export" + query, (result) => {
                    window.open(BASE_API + result.relativePath);
                });
            }
        );
    }

    componentWillMount() {
        let filter = JSON.parse(window.localStorage.getItem("PARTNER_PAGE"));
        if (filter) {
            this.setState({
                currentPage: filter.currentPage,
            });
        }
    }

    componentDidMount() {
        let { params } = this.state;
        this._handleGetPartners(params);
    }

    handleCheckProps(props, nextProps) {
        let output = false;
        if (
            Object.entries(props.searchKey).toString() !=
            Object.entries(nextProps.searchKey).toString()
        ) {
            output = true;
        }
        if (
            Object.entries(
                props.searchKey.selectedVerifyStatus
                    ? props.searchKey.selectedVerifyStatus
                    : {}
            ).toString() !=
            Object.entries(
                nextProps.searchKey.selectedVerifyStatus
                    ? nextProps.searchKey.selectedVerifyStatus
                    : {}
            ).toString()
        ) {
            output = true;
        }
        return output;
    }

    componentWillReceiveProps(nextprops) {
        if (
            this.handleCheckProps(this.props, nextprops) &&
            this.state.searchQueryHistory
        ) {
            let { params } = this.state;
            params.search = nextprops.searchKey;
            params.isResetPage = nextprops.isResetPage;
            this._handleGetPartners(params);
        }
    }

    render() {
        let { partners, total, currentPage } = this.state;
        return (
            <div className="partner-list">
                <DataTable
                    data={partners}
                    total={total}
                    isPaging={true}
                    currentPage={currentPage}
                    renderHeader={() => {
                        return (
                            <thead>
                                <tr>
                                    <th className="text-center">ID</th>
                                    <th className="text-left width200">Tên</th>
                                    <th className="text-left"><span className="ellipsis">Ngày submit</span></th>
                                    <th className="text-left"><span className="ellipsis">Ngày accepted</span></th>
                                    <th className="text-left"><span className="ellipsis">Ngày denined</span></th>
                                    <th className="text-left"><span className="ellipsis">Ngày discontinued</span></th>
                                    <th className="text-left">Loại</th>
                                    <th className="text-left">Mã hợp đồng</th>
                                    <th className="text-left">Tình trạng hợp đồng</th>
                                    <th className="text-left width80">
                                        <span className="ellipsis">CMND / MST</span>
                                    </th>
                                    <th className="text-left">Địa chỉ</th>
                                    <th className="text-left">SĐT</th>
                                    <th className="text-left">Email</th>
                                    <th className="text-left">PM</th>
                                    <th className="text-center">
                                        <span className="ellipsis">Trạng thái</span>
                                        {/* {(currentUser.roleCode === "DATA_MANAGER" || currentUser.roleCode === "ADMIN" || currentUser.roleCode === "BOD" || currentUser.roleCode === "LEADER_PARTNER_MANAGER" ||  currentUser.roleCode === 'PARTNER_MANAGER' ||  currentUser.roleCode === 'ADMIN_OFFICER' || currentUser.roleCode === "ACCOUNTANT" || currentUser.roleCode === "CHIEF_ACCOUNTANT" || !this.state.params.selectedVerifyStatus) ? <Button size="small" onClick={this._onFilterVerifiStatus} >
                                                                            <i className="material-icons text-center">
                                                                                {this.state.isFilter ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                                                                            </i>
                                                                        </Button> : ""} */}
                                    </th>

                                    <th className="text-center" />
                                </tr>
                            </thead>
                        );
                    }}
                    renderBody={(items) => {
                        return (
                            <tbody>
                                {items.map((item, i) => (
                                    <tr key={i}>
                                        <td style={{ textAlign: "center" }}>#{item.id}</td>
                                        <td className="width150">
                                            <Tooltip
                                                title={
                                                    item.type === PartnerType.Individual.value
                                                        ? item.fullName
                                                        : item.companyName
                                                }
                                                placement="top-start"
                                            >
                                                <NavLink
                                                    className="ellipsis"
                                                    to={
                                                        isInRole(MetubRoles.VIEW_PARTNER)
                                                            ? `partners/${item.id}`
                                                            : "#"
                                                    }
                                                >
                                                    {item.type === PartnerType.Individual.value
                                                        ? item.fullName
                                                        : item.companyName}
                                                </NavLink>
                                            </Tooltip>
                                        </td>
                                        <td>
                                            {item.createTime
                                                ? moment(item.createTime).format("DD/MM/YYYY")
                                                : "-/-"}
                                        </td>
                                        <td>
                                            {item.acceptedDate
                                                ? moment(item.acceptedDate).format("DD/MM/YYYY")
                                                : "-/-"}
                                        </td>
                                        <td>
                                            {item.deniedDate
                                                ? moment(item.deniedDate).format("DD/MM/YYYY")
                                                : "-/-"}
                                        </td>
                                        <td>
                                            {item.discontinuedDate
                                                ? moment(item.discontinuedDate).format("DD/MM/YYYY")
                                                : "-/-"}
                                        </td>
                                        <td className="width100">
                                            <span className="ellipsis">
                                                {item.type === PartnerType.Individual.value
                                                    ? PartnerType.Individual.label
                                                    : PartnerType.Company.label}
                                            </span>
                                        </td>
                                        <td className="width100">
                                            <span className="ellipsis">
                                                {item.contracts && item.contracts[0] ? item.contracts[0].code.split('/').slice(0, 2).join('/') : "-/-"}
                                            </span>
                                        </td>
                                        <td className="width170">
                                            <span className="ellipsis">
                                                {item.contracts && item.contracts[0] ? ContractStatus[item.contracts[0].status].label : "-/-"}
                                            </span>
                                        </td>
                                        <td className="width80">
                                            {item.type === PartnerType.Individual.value
                                                ? item.identityNumber
                                                : item.taxCode}
                                        </td>
                                        <td className="width180">
                                            <Tooltip
                                                title={
                                                    item.type === PartnerType.Individual.value
                                                        ? item.currentAddress
                                                        : item.companyAddress
                                                }
                                                placement="top-start"
                                            >
                                                <span className="ellipsis">
                                                    {item.type === PartnerType.Individual.value
                                                        ? item.currentAddress
                                                        : item.companyAddress}
                                                </span>
                                            </Tooltip>
                                        </td>
                                        <td className="width80">{item.phone}</td>
                                        <td style={{ maxWidth: "200px" }}>{item.email}</td>
                                        <td className="width100">
                                            <Tooltip
                                                title={
                                                    item.partnerManager
                                                        ? item.partnerManager.fullName
                                                        : ""
                                                }
                                                placement="top-start"
                                            >
                                                <span className="ellipsis">
                                                    {item.partnerManager
                                                        ? item.partnerManager.fullName
                                                        : ""}
                                                </span>
                                            </Tooltip>
                                        </td>
                                        <td className="width120">
                                            <Tooltip
                                                title={
                                                    item.partnerManager
                                                        ? item.partnerManager.fullName
                                                        : ""
                                                }
                                                placement="top-start"
                                            >
                                                <span className="ellipsis">
                                                    {VerifyStatus[item.verifyStatus]
                                                        ? VerifyStatus[item.verifyStatus].label
                                                        : item.verifyStatus}
                                                </span>
                                            </Tooltip>
                                        </td>

                                        {isInRole(MetubRoles.VIEW_PARTNER) && (
                                            <td className="width10 text-center">
                                                <NavLink to={`partners/${item.id}`}>
                                                    <Tooltip title="Xem chi tiết" placement="top">
                                                        <IconButton>
                                                            {item.verifyStatus === "Accepted" ? (
                                                                <InfoIcon color="primary" />
                                                            ) : (
                                                                <InfoIcon />
                                                            )}
                                                        </IconButton>
                                                    </Tooltip>
                                                </NavLink>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        );
                    }}
                    onChange={(params) => this._handleGetPartners(params)}
                />
            </div>
        );
    }
}

export default Index;
