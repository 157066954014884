import React from "react";

import Main from "./components/main";
import SignIn from "./components/sign-in";
import { isSignedIn } from "./auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-confirm-alert/src/react-confirm-alert.css'
import "react-datepicker/dist/react-datepicker.css";
import './style/app.scss'
import './App.css'
import moment from "moment";
import { Route, Switch } from "react-router";


const loading = require('./assets/images/loading.png')

class App extends React.Component {

  handleResetAccount() {
    const lastLogin = window.localStorage.getItem("LAST_LOGIN")
    const currentDate = moment().utc().unix() * 1000
    var oldDate = 0
    if (lastLogin && JSON.parse(lastLogin)) {
      oldDate = JSON.parse(lastLogin)
    }
    if (moment(currentDate).day() === 1) {

      if (moment(currentDate).date() != moment(oldDate).date()) {
        window.localStorage.clear();
        window.localStorage.setItem("LAST_LOGIN", JSON.stringify(currentDate))
      }
    }
  }

  componentDidMount() {
    this.handleResetAccount()
  }

  render() {
    return (
      <div>

        {isSignedIn() ? (
          <Route exact path="/*" component={Main} />
        ) : (
          <Switch>
            <Route exact path="/*" component={SignIn} />
          </Switch>
        )}
        <ToastContainer
          position="top-right"
          autoClose={500000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
          className="custom-toast"
        />
        <div id="proccess" className="hide">
          <div>
            <img className={"spinner-img"} src={loading} />
          </div>
        </div>
      </div>

    );
  }
}

export default App;
