import React from "react";
import List from './list'
import AddNew from './add-new'
import Button from '@material-ui/core/Button'
import { get } from "../../api";
import { isInRole, getCurrentUser } from '../../auth'
import { MetubRoles } from '../../constants/constants'
import {
    Payment as PaymentIcon
} from '@material-ui/icons'

import { checkRolePM } from "../../utils/common";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentInfos: [],
            openAddNewDialog: false
        };
    }

    _handleGetPaymentInfo(partner) {
        if (!partner) return
        get("partners/" + partner.id + "/payment-infos", result => {
            this.setState({
                paymentInfos: result.items
            })
        })
    }

    componentDidMount() {
        let { partner } = this.props
        this._handleGetPaymentInfo(partner)
    }

    render() {
        let { paymentInfos, openAddNewDialog } = this.state
        const { partner } = this.props
        let currentUser = getCurrentUser()

        return (
            <div className="contact-page">
                <div className="action">
                    <div className="admin-box">
                        <span>
                            <PaymentIcon />
                            Danh sách thông tin thanh toán
                            {
                                partner && partner.verifyStatus !== "Discontinued" && <>
                                    {
                                        isInRole(MetubRoles.CREATE_PAYMENT_INFO, () => checkRolePM(partner)) ? <Button
                                            color={"primary"}
                                            onClick={() => this.setState({ openAddNewDialog: !openAddNewDialog })}
                                        >
                                            {"Tạo mới"}
                                        </Button> : ""
                                    }
                                </>
                            }
                        </span>
                        <List
                            paymentInfos={paymentInfos}
                            onUpdateSuccess={() => this._handleGetPaymentInfo(partner)}
                            canUpdate={checkRolePM(partner)}
                        />
                    </div>
                </div>

                <AddNew
                    open={openAddNewDialog}
                    onClose={() => this.setState({ openAddNewDialog: false })}
                    onAddSuccess={() => this.setState({ openAddNewDialog: false }, () => this._handleGetPaymentInfo(partner))}
                    partnerId={partner.id} />
            </div>
        );
    }
}

export default Index;
