import React from "react";
import Modal from '../common/modal'
import Button from "@material-ui/core/Button";
import TextInput from '../common/text-input'
import DatetimePicker from '../common/datetime-picker'
import {  put, get } from '../../api'
import { notEmpty,  justNumber, isValid } from "../../utils/validators";
import { showSuccess, showError } from "../../utils/app";
import update from 'react-addons-update'
import { getDate } from "../../utils/common";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignFanpage: null,
            id: null,
            type: null,
        }
        this.validationData = {
            name: [notEmpty],
            budget: [justNumber, notEmpty],
            paymentAmount: [justNumber]
        };
    }


    _onUpdateCampaignFanpage = () => {
        const {
            campaignFanpage
        } = this.state;
        const {
            onUpdateSucceed,
            onClose
        } = this.props

        let param = {
            id: campaignFanpage.id,
            paymentAmount: campaignFanpage.paymentAmount,
            paymentDate: campaignFanpage.paymentDate,
            budget: campaignFanpage.budget,
            notes: campaignFanpage.notes
        }
        if (isValid(this.validationData))
            put("campaign-fanpages", param, () => {
                if (onClose)
                    onClose()
                if (onUpdateSucceed)
                    onUpdateSucceed()
                showSuccess("Cập nhật thành công")
            });
        else {
            showError("Dữ liệu không hợp lệ. Vui lòng kiểm tra lại")
        }
    }

    _getDetails = () => {
        let { id } = this.state
        if (!id) return
        get("campaign-fanpages/" + id,
            (res) => {
                if (res.paymentDate === null) {
                    res.paymentDate = getDate()
                    this.setState({
                        campaignFanpage: res
                    })
                } else {
                    this.setState({
                        campaignFanpage: res
                    })
                }
            })
    }
    componentDidMount() {
        let { id, type } = this.props
        this.setState({
            id: id,
            type: type
        },
            () => {
                this._getDetails()
            })
    }
    componentWillReceiveProps(props) {
        let { type, id } = props
        this.setState({
            id: id,
            type: type
        },
            () => {
                this._getDetails()
            })
    }

    render() {
        let {
            campaignFanpage
        } = this.state
        let {
            open,
            onClose
        } = this.props
        if (!campaignFanpage) return null
        return (
            <Modal
                className="add-new-options"
                open={open}
                onClose={onClose}
                fullWidth={true}
                header={
                    <div>
                        <span>Cập nhật</span>
                    </div>
                }
                content={
                    <table className="table list-item border-bottom">
                        <tbody>
                            <tr>
                                <td className="coin text-left width100">
                                    <span>Kinh phí</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="name"
                                        value={campaignFanpage.budget}
                                        label={"Kinh phí"}
                                        name={"budget"}
                                        placeholder={"Kinh phí"}
                                        displayName={"Kinh phí"}
                                        onChange={e => this.setState({
                                            campaignFanpage: update(campaignFanpage, {
                                                budget: {
                                                    $set: e.target.value
                                                }
                                            })
                                        })
                                        }
                                        validations={this.validationData.budget}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left">
                                    <span>Đã trả</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="name"
                                        value={campaignFanpage.paymentAmount}
                                        label={"Đã sử dụng"}
                                        name={"paymentAmount"}
                                        placeholder={"Đã sử dụng"}
                                        displayName={"Chi phí"}
                                        onChange={e => this.setState({
                                            campaignFanpage: update(campaignFanpage, {
                                                paymentAmount: {
                                                    $set: e.target.value
                                                }
                                            })
                                        })
                                        }
                                        validations={this.validationData.paymentAmount}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left">
                                    <span>Ngày trả</span>
                                </td>
                                <td>
                                    <DatetimePicker
                                        className="cooperatedDate"
                                        placeholder={"Ngày trả"}
                                        onChange={value => this.setState({
                                            campaignFanpage: update(campaignFanpage, {
                                                paymentDate: {
                                                    $set: value
                                                }
                                            })
                                        })
                                        }
                                        value={campaignFanpage.paymentDate}
                                        edit={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="coin text-left">
                                    <span>Ghi chú</span>
                                </td>
                                <td className="coin text-left width300">
                                    <TextInput
                                        className="name"
                                        value={campaignFanpage.notes}
                                        label={"Ghi chú"}
                                        name={"notes"}
                                        placeholder={"Ghi chú"}
                                        displayName={"Ghi chú"}
                                        onChange={e => this.setState({
                                            campaignFanpage: update(campaignFanpage, {
                                                notes: {
                                                    $set: e.target.value
                                                }
                                            })
                                        })
                                        }
                                        edit={true}
                                        multiline={true}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                action={
                    <Button
                        color="primary"
                        onClick={this._onUpdateCampaignFanpage}
                    >
                        Lưu
            </Button>
                }
                allowCancel={true}
            />
        )
    }
}


export default Index;

